import styled from "styled-components";

export const LoyoutWrapperContent = styled.div`
  display: flex;
  position: relative;
`;
export const LayoutResponsiveSidebar = styled.div`
  width: 5em;
  max-width: 5em;
  min-width: 5em;
  padding-right: 1em;
`;
export const LayoutContentWrapper = styled.div`
  width: 92%;
  max-width: 92%;
`;
