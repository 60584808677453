import theme from "./theme";
import indigo from "@material-ui/core/colors/indigo";

export const colours = {
  app: {
    colourPrimary: "#8F66A9",
    colourSecondary: "#4bf4c2",
    colourGradient: "to right, #7ae3f3, #4bf4c2",
    appSuccess: "#29D145",
    appError: "red",
    appDisabled: "lightgrey",
    appBackground: "white",
  },
  scheduler: {
    colourGradient: `to right, ${theme.palette.aws.light}, ${theme.palette.aws.dark}`,
  },
  ssh: {
    colourGradient: `to right, ${theme.palette.ssh.main}, ${theme.palette.ssh.dark}`,
  },
  fui: {
    colourGradient: `to right, ${theme.palette.fui.main}, ${theme.palette.fui.dark}`,
  },
  aws: {
    colourGradient: `to right, ${theme.palette.aws.main}, ${theme.palette.aws.dark}`,
  },
  orange: {
    //aws
    light: "#ffd6a3",
    main: "#ffb252",
    dark: "#f2811d",
    contrastText: "#fff",
  },
  pink: {
    light: "#ff80ab",
    main: "#E91E63",
    dark: "#f50057",
    contrastText: "#fff",
  },
  purple: {
    light: "#b388ff",
    main: "#7c4dff",
    dark: "#651fff",
    contrastText: `${indigo[600]}`,
  },
  grey: {
    light: "#e0e0e0",
    main: "#bdbdbd",
    dark: "#9e9e9e",
    contrastText: "#000",
  },
};
