export const appNameProjectId = {
  ticketAnalysisId: "a5184b9b-c2ea-e511-8053-180373e9b33d",
  devOpsAnalyticsId: "50d04341-b6b7-e611-86d5-c4346b6b73a7",
  automaticTicketResolverId: "40f628ec-a32c-43d4-a42c-ddfb222ccbb2",
  eventOpsId: "ef06bc16-02a8-4225-9f25-8fa0e2700aaf",
  turingId: "6f532d8b-15b9-48cf-9d0b-b9d8f26a5f6d",
  sharepointAddOnId: "292cf9bb-77fb-e711-8724-484d7ee1ad05",
  managedPlatformId: "55cdb588-c8d5-4f4d-8f9c-0905ee090df9",
  dedicatedPlatformId: "9c4f3e6a-c2ea-e511-8053-180373e9b33d",
  utkmPlatformId: "2a16243e-0d90-410a-a79c-9a2d5f0e3e2s",
  genWizardPlatformId: "01d30423-cf0c-43f8-b81f-54c115ca8fa0",
  adPlatformId: "6c7eb40d-d65f-4c55-ae2c-29a06fb370ce",
  windowsProvisioningPlatformId: "34cd1x5z-3cv5-23b2-6911-45s2pp3bza99",
  plainInstanceId: "5b384be8-62bf-419f-bb10-2ff5287afd71",
  reportExtractorAddOnId: "a25f1f68-c56c-4281-a170-11c41274235e",
  onPremiseServiceId: "d94cb489-8b20-4909-9726-0f37949593f0",
  automationAnywhereLicensesId: "7cd9e4f7-4bc5-e811-b18e-484d7ef53e61",
  pamInstanceId: "5d3a49da-637e-43bd-a460-6bb329a2b2af",
  splunkClusterPlatformId: "f6c9bf3a-a401-405b-b701-cd9d98afee3d",
  stack360Id: "d941e736-128b-4102-999d-c3f734369eec",
  stormwatchId: "34e6091e-66ad-4e94-b569-b9f59aeed6ef",
  automationJourneyId: "2d5846ea-b401-459c-a154-789a1d4ff2b2",
  automationPlannerId: "06da93f9-fac6-4fc7-8d13-4371d3d0169d",
  intelligentOpportunityMinerId: "1905acc9-f7fd-445b-88ed-9f67e77d1d67",
  guidedTicketResolverId: "334c57ae-bdaf-4c1e-8737-52857fe5094f",
  klewerId: "89b43051-05dc-459d-a176-da7af7314fd6",
  quasarId: "55af26d8-637f-4496-a1bc-fda9b646bbd3",
  virtualDataScientistId: "8cc7b6e1-4230-4df7-88fc-3d98c4401b16",
  stormseerId: "b0471f50-eca3-4d99-8c3a-e0e42ecd4fc8",
  atrcaasId: "380afbb0-1fb0-4f81-9cc6-3e8b5a8661f0",
  quasarppCaasId: "e0dcc7cd-ec6d-48ed-be47-5dffed5a2554"
};
