import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import ConnectToInstance from "./ConnectToInstance";
import DownsizeInstance from "./DownsizeInstance";
import {
  AcceptButton,
  DeclineButton,
} from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
import { DialogContentText } from "@material-ui/core";
import { connect } from "react-redux";

/**
 * The DialogComponent appears when you want to start stop or terminate an instance
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: ActionItems.js
 */

const DialogComponent = ({
  isDialogOpen,
  handleDialogClose,
  handleDialogConfirm,
  handleDownsizeInstanceTypeDialogConfirm,
  dialogType,
  instance,
  privateDNS,
  classes,
  instanceType,
  downsizeInstanceTypeVal,
  fuiOrders,
  serverList
}) => {
  let bundledInstance = serverList.filter((server) => {
    return instance.orderid === server.orderid;
  });

  const dialogOptions = {
    start: {
      title: "Start Instance?",
      content: "Are you sure you want to start the selected instance? Please confirm.",
    },
    stop: {
      title: "Stop Instance?",
      content: "Are you sure you want to stop the selected instance? Please confirm.",
    },
    ssh: {
      title: "SSH Access",
      content:
        "To Authorize SSH access to this instance please enter a user's email address below.",
    },
    terminate: {
      title: "Terminate Instance",
      content:
        "Are you sure you want to terminate the selected instance? Please confirm. \n This cannot be undone",
    },
    connect: {
      title: "Connect to Instance",
      content:
        "Please see the following options below to select your connection method",
    },
    appUrl: {
      title: "Add Web Endpoint for Instance",
      content:
        "Please fill out the below form to create an app url for your instance",
    },
    enablesmtp: {
      title: "Enable Splunk SMTP Server",
      content: "Are you sure you want to enable the Splunk SMTP Server?",
    },
    downsize: {
      title: "Downsize Instance",
      content: "Please select the instance type for which you want to downsize",
    },
  };

  const [accessLinkURLFromOrders, setAccessLinkURLFromOrders] = useState();


  useEffect(() => {
    let obj = fuiOrders.find(
      (instanceObj) => instanceObj.DMSId === instance.clusterId
    );
    if (obj && obj.AccessLinkURL) {
      setAccessLinkURLFromOrders(obj.AccessLinkURL);
    } else {
      setAccessLinkURLFromOrders("N/A")
    }
  }, []);

  const [isOptionSelected, setIsOptionSelected] = useState(true);

  const [dialogInstanceType, setdialogInstanceType] = useState("");

  const handleInstanceType = (instanceTypeString, targetValue, downsizeInstanceTypeVal) => {
    //console.log("setting this -=-=-=-", instanceTypeString);
    //console.log("also log this  -=-=-=-", downsizeInstanceTypeVal);
    //console.log("also log this  -=-=-=-", targetValue);
    if (targetValue === "No more size to downgrade") {
      setIsOptionSelected(true);
    } else {
      setIsOptionSelected(false);
    }
    setdialogInstanceType(targetValue);
  };




  const handleConfirmClick = () => {
    handleDialogClose();
    //console.log("this has been set &&&&&&", dialogInstanceType);
    handleDialogConfirm(dialogType);
  };

  const handleDownsizeInstanceTypeConfirmClick = (downsizeInstanceTypeVal) => {
    //console.log(dialogInstanceType);
    handleDialogClose();
    handleDownsizeInstanceTypeDialogConfirm(
      dialogType,
      dialogInstanceType
    );
  };

  return (
    <CommonDialog
      open={Boolean(isDialogOpen)}
      onClose={handleDialogClose}
      title={dialogOptions[dialogType].title}
      textContent={dialogType === "connect" || dialogType === "downsize" ? dialogOptions[dialogType].content : ""}
      dialogType={"servers"}
      dialogContent={(() => {
        if (dialogType === "connect") {
          return (
            <ConnectToInstance
              instance={instance}
              privateDNS={privateDNS}
              classes={classes}
            />
          );
        } else if (dialogType === "downsize") {
          return (
            <DownsizeInstance
              instanceType={instanceType}
              instance={instance}
              privateDNS={privateDNS}
              classes={classes}
              handleInstanceType={handleInstanceType}
            />
          );
        } else {
          return (
            <div style={{ padding: "0px 24px 20px 24px" }}>
              {
                (() => {
                  if (bundledInstance !== undefined && bundledInstance.length > 1) {
                    // if(instance.applicationname !== undefined && (instance.applicationname == "genai_ATR" || instance.applicationname == "genai_Quasar")){
                    return <p style={{ lineHeight: "22px", color: "#000000", paddingBottom: "5px" }}>
                      <b style={{ fontWeight: 500 }}>This seems to be a bundled order. {dialogType.charAt(0).toUpperCase() + dialogType.slice(1)} action on the selected instance will affect other dependent instances as well and might not function as expected! <br></br><br></br><br></br>
                        Order ID: {instance.orderid !== undefined
                          ? instance.orderid
                          : "N/A"}
                      </b></p>
                    // }
                  }

                })()
              }
              {/* {instance.applicationname !== undefined && (instance.applicationname == "genai_ATR" || instance.applicationname == "genai_Quasar")
                ? <p style={{ lineHeight: "20px", color:"#000000"}}>
                  <b>This seems to be a bundled order. {dialogType.charAt(0).toUpperCase() + dialogType.slice(1)} action on the selected instance will affect other dependent instances as well and might not function as expected! <br></br><br></br>
                  Order ID: {instance.orderid !== undefined
                    ? instance.orderid
                    : "N/A"}
                </b></p>
                : ""} */}
              {bundledInstance.map((row) => (
                <DialogContentText style={{ lineHeight: "10px" }}>

                  {/* <div style={{border: row.instanceId !== instance.instanceId ? "2px solid #E90D0D" : "", paddingLeft: row.instanceId !== instance.instanceId ? "15px" : "", borderStyle:  row.instanceId !== instance.instanceId ? "dashed" : ""}}> */}
                  {/* <p>Order ID: {row.orderid !== undefined
                      ? row.orderid
                      : "N/A"}
                    </p> */}
                  <div>
                    <p style={{ lineHeight: "20px", color: "#000000", fontWeight: "500" }}> {row.instanceId !== instance.instanceId ? "Dependent Instance" : "Selected Instance"}</p>
                    <p style={{ color: "black"}}>Instance ID: {row.instanceId} </p>
                    <p style={{ color: "black"}}>Private DNS: {row.privateDNSName}</p>
                    {
                      (() => {
                        if (row.InstanceType === "Container" && row.AccessLinkURL !== undefined)
                          return <p style={{ color: "black"}}>Access Link URL: <a href={row.AccessLinkURL}>{row.AccessLinkURL}</a></p>
                        else if (row.accessLinkUrl !== undefined)
                          return <p style={{ color: "black"}}>Access Link URL: <a href={row.accessLinkUrl}>{row.accessLinkUrl}</a></p>
                        else if (accessLinkURLFromOrders === " " || row.accessLinkUrl == " ")
                          return <p style={{ color: "black"}}>Access Link URL: N/A</p>
                        else if (accessLinkURLFromOrders !== "N/A")
                          return <p style={{ color: "black"}}>Access Link URL: <a href={accessLinkURLFromOrders}>{accessLinkURLFromOrders}</a></p>
                        else
                          return <p style={{ color: "black"}}>Access Link URL: N/A</p>
                      })()
                    }
                  </div>
                  <br></br>
                </DialogContentText>
              ))}
              {dialogType == "stop" ? <p><b style={{ fontWeight: "500"}}>Note:</b> Stopping the instance will remove the schedule, and the server will need to be managed manually until a new schedule is set.</p> : ""}
              <h4 style={{ fontWeight: "500"}}>{dialogOptions[dialogType].content}</h4>
            </div>

          )
        }
      })()}
      dialogActions={
        <DialogActions>
          <DeclineButton
            canceldialogbuttoncy="cancelDialogButtonCy"
            onClick={handleDialogClose}
            label={dialogType === "connect" ? "Close" : "Cancel"}
          />
          {(() => {
            if (
              dialogType === "start" ||
              dialogType === "stop" ||
              dialogType === "terminate"
            ) {
              return (
                <AcceptButton
                  confirmdialogbuttoncy="confirmDialogButtonCy"
                  onClick={handleConfirmClick}
                  label={"Confirm"}
                />
              );
            }
            if (dialogType === "downsize") {
              return (
                <AcceptButton
                  disabled={isOptionSelected === false ? false : true}
                  value="mybutton1"
                  confirmdialogbuttoncy="confirmDialogButtonCy"
                  onClick={() =>
                    handleDownsizeInstanceTypeConfirmClick({
                      downsizeInstanceTypeVal,
                    })
                  }
                  label={"Confirm"}
                />
              );
            }
          })()}
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    fuiOrders: state.fui.instanceData,
    serverList: state.servers.instanceList,
  };
};

DialogComponent.propTypes = {
  isDialogOpen: PropTypes.string,
  handleDialogClose: PropTypes.func,
  handleDialogConfirm: PropTypes.func,
  handleDownsizeInstanceTypeDialogConfirm: PropTypes.func,
  downsizeInstanceTypeVal: PropTypes.string,
  dialogType: PropTypes.string,
  /**
   * Data of instance
   */
  instance: PropTypes.object,
  /**
   * PrivateDNS of selected instance
   */
  privateDNS: PropTypes.string,
  /**
   * InstanceType of selected instance
   */
  instanceType: PropTypes.string,
  /**
  * List of all instances
  */
  serverList: PropTypes.array,
};

export default connect(mapStateToProps)(DialogComponent);
