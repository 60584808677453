import React from "react";
import { DialogActions } from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { deleteAdGroup } from "../../redux/admin/index";
import { connect } from "react-redux";

/**
 * Dialog after user submits ssh user access request to with results of attempting to add ssh users.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AddUsersDialog
 */

const DeleteAdGroupDialog = ({
  open,
  handleClose,
  data,
  deleteGroup,
  email,
}) => {
  const handleSubmit = () => {
    deleteGroup(email, { adGroupName: data.ad_group_name });
    handleClose();
  };

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title={"Delete AD Group: " + data.ad_group_name}
      dialogType={"admin"}
      textContent={"Are you sure you want to delete this AD Group?"}
      dialogActions={
        <DialogActions>
          <DeclineButton
            onClick={handleClose}
            label={"Cancel"}
            data-cy="DeleteAdGroupCancel"
            disabled={false}
          />
          <AcceptButton
            onClick={handleSubmit}
            label={"Ok"}
            data-cy="DeleteAdGroupOk"
            disabled={data.ad_group_name === "myWizAiOps.Dev.All"}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const MSTP = (state, ownProps) => ({ email: state.auth.userDetails.email });

export default connect(MSTP, {
  deleteGroup: deleteAdGroup,
})(DeleteAdGroupDialog);
