import React from "react";

import { DialogTextWrapper } from "./AdminStyles";
import { DialogActions } from "@material-ui/core";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { connect } from "react-redux";
import * as adminRedux from "../../redux/admin/index";
import {
  Checkbox,
  Paper,
  Grid,
  ListSubheader,
  IconButton,
} from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightAlt from "@material-ui/icons/ArrowForward";
import ArrowLeftAlt from "@material-ui/icons/ArrowBack";
import difference from "lodash/difference";
import { updateAdGroup } from "../../redux/admin/index";

/**
 * Dialog after user submits ssh user access request to with results of attempting to add ssh users.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AddUsersDialog
 */

const EditGroupPermRolesDialog = ({
  openDialog,
  handleClose,
  data,
  roles,
  updateGroup,
  email,
}) => {
  const closeDialog = () => {
    handleClose();
  };

  //Gets the avaliable roles by comparing existing roles with current roles
  const avaliableRoles = [];
  roles.forEach((role) => {
    data.cam_roles.indexOf(role.roleName) === -1 &&
      avaliableRoles.push(role.roleName);
  });

  const [leftChecked, setLeftChecked] = React.useState([]);
  const [rightChecked, setRightChecked] = React.useState([]);

  const [leftList, setLeftList] = React.useState(avaliableRoles);
  const [rightList, setRightList] = React.useState(data.cam_roles);

  const handleToggle = (value, list) => () => {
    const currentIndex =
      list === "left"
        ? leftChecked.indexOf(value)
        : rightChecked.indexOf(value);
    const newChecked = list === "left" ? [...leftChecked] : [...rightChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    return list === "left"
      ? setLeftChecked(newChecked)
      : setRightChecked(newChecked);
  };

  const handleRolePush = (pushTo) => {
    var diff;
    if (pushTo === "left") {
      setLeftList([...leftList, ...rightChecked]);
      diff = difference(rightList, rightChecked);

      setRightList(diff);
      setRightChecked([]);
    } else {
      setRightList([...leftChecked, ...rightList]);

      diff = difference(leftList, leftChecked);

      setLeftList(diff);
      setLeftChecked([]);
    }
  };

  const handleSubmit = () => {
    updateGroup(email, rightList, data.ad_group_name);
    handleClose();
  };

  return (
    <CommonDialog
      open={openDialog}
      onClose={closeDialog}
      title={`Editing Group: ${data.ad_group_name}`}
      dialogType={"admin"}
      dialogContent={
        <DialogTextWrapper>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Paper variant="outlined">
                <List
                  subheader={<ListSubheader>Avaliable Item(s)</ListSubheader>}
                >
                  {leftList.map((role) => {
                    const labelId = `checkbox-list-label-${role}`;

                    return (
                      <ListItem
                        key={role}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(role, "left")}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={leftChecked.indexOf(role) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ id: labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={role} />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => handleRolePush("left")}
                data-cy="ArrowLeft"
              >
                <ArrowLeftAlt />
              </IconButton>
              <IconButton
                onClick={() => handleRolePush("right")}
                data-cy="ArrowRight"
              >
                <ArrowRightAlt />
              </IconButton>
            </Grid>
            <Grid item xs={5}>
              <Paper variant="outlined">
                <List
                  subheader={<ListSubheader>Selected Item(s)</ListSubheader>}
                >
                  {rightList.map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(value, "right")}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={rightChecked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </DialogTextWrapper>
      }
      dialogActions={
        <DialogActions data-cy="EditGroupPermButtons">
          <DeclineButton
            onClick={handleClose}
            label={"Cancel"}
            disabled={false}
          />
          <AcceptButton
            onClick={handleSubmit}
            label={"Submit"}
            disabled={false}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const MSTP = (state, ownProps) => ({
  email: state.auth.userDetails.email,
  createRoleData: state.adminReducer,
});

export default connect(MSTP, {
  updateAdGroup: adminRedux.updateRoleForm,
  updateGroup: updateAdGroup,
})(EditGroupPermRolesDialog);
