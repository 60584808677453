import React, { useEffect, useState } from "react";
import PendingSshApprovals from "./sshTabPages/PendingSshApprovals";
import MyRequests from "./sshTabPages/MyRequests";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import axios from "axios";
import base from "../../config";
import { tokenValidation } from "../../helper/customizedHooks";
import { getOptions } from "../../helper/api";
import SHHHeader from "./SSHHeader";
import RegistrationForm from "../ssh/SshRegistration/RegistrationForm";
import { StyledCard, UpdateButton } from "./SshStyles";
import { SmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import SSHUserManagement from "./SshUserManagement/SSHUserManagement";
import PropTypes from "prop-types";
import SSHArchive from "./SSHArchive/SSHArchive";
import {
  BackWrapper,
  BackButton,
  StyledArrowBackIosIcon,
} from "../fui/FuiStyles";
import { fetchSSHConfig } from "../../redux/scheduler/schedulerActions";
import { Footer } from "../common/Footer";
import { fetchServersInstances } from "../../redux/ServersReducer/ServersActions";
// import moment from "moment";

/**
 * SSH Dashboard page
 * @author Isabel Sprengers
 * @version 2.1
 * @see Used in component: SSHHeader.js
 */

const Ssh = ({
  history,
  match,
  email,
  idToken,
  isRegistration,
  sshConfigUrl,
  fetchSSHConfig,
  fetchServersInstances,
  activeAccount,
}) => {
  const [pendingData, setPendingData] = useState([]);
  const [requesterData, setRequesterData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  // I added this useState to save the information of the SSH My Requests numbers
  const [resData, setResData] = useState({
    approved: 0,
    declined: 0,
    pending: 0,
    lastUpdatedDate: "loading",
    requests: [],
    keyRotation: 0,
  });

  const mapUrlToTab = {
    sshDashboard: 0,
    sshRegistration: 1,
    sshUserManagement: 2,
    sshArchive: 3,
  };

  //Add the idToken to the options header for api calls.
  getOptions.headers["Authorization"] = idToken;

  const eId = email === undefined ? undefined : email.split("@")[0];
  const requesterURL = `${base.urls.apis.sshNew}requests?requester=${eId}&inititive=${activeAccount}`;
  const pendingURL = `${base.urls.apis.sshNew}requests?instance_owner=${eId}&inititive=${activeAccount}`;

  //Put date into a usable format to find the difference between two times.
  const dateFormat = (dateValue) => {
    var removedSlash = dateValue.split("/");
    var removedSpace = removedSlash[2].split(" ");
    var removedColons = removedSpace[1].split(":");
    return new Date(
      parseInt(removedSpace[0], 10),
      parseInt(removedSlash[1], 10),
      parseInt(removedSlash[0], 10),
      parseInt(removedColons[0], 10),
      parseInt(removedColons[1], 10),
      parseInt(removedColons[2], 10)
    );
  };

  //Api get call to retrieve all orders and sort them.
  const apiCall = (url, type) => {
    axios
      .get(url, getOptions)
      .then((res) => {
        let orderedArray;
        // Here the Url that is executed is verified, if it includes Requester it saves the response of the api in setResData as an object
        if (url.includes("requester")) {
          orderedArray = res.data.requests;
          // let lastUpdated = res.data.lastUpdatedDate;
          setResData(res.data);
        } else {
          // here the response of the Api is processed as an Array
          orderedArray = res.data;
        }
        orderedArray.sort(function(a, b) {
          return dateFormat(b.request_time) - dateFormat(a.request_time);
        });
        if (type === "pending" && orderedArray) {
          setPendingData(orderedArray);
          setLoading(false);
        } else if (type === "requesting" && orderedArray) {
          setRequesterData(orderedArray);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Fetches presigned url to download ssh config once.
  useEffect(() => {
    const eId = email === undefined ? undefined : email.split("@")[0];
    if (eId) {
      fetchServersInstances(eId);
    }
    if (email && sshConfigUrl === "") {
      fetchSSHConfig(email);
    }
  }, [email]);

  //Fetches presigned url to download ssh config once.
  useEffect(() => {
    tokenValidation();
    if (eId) {
      let myTimer;

      //Interval to wait 5 seconds for a change to reload, and show the updated status.
      if (reload) {
        apiCall(pendingURL, "pending");
        myTimer = setInterval(() => {
          apiCall(pendingURL, "pending");
          setReload(false);
        }, 5000);
        return () => clearInterval(myTimer);
      } else {
        apiCall(pendingURL, "pending");
        apiCall(requesterURL, "requesting");
      }
    }
    setTabValue(
      mapUrlToTab[match.params.type] ? mapUrlToTab[match.params.type] : 0
    );
  }, [eId, reload, match.params.type]);

  const backButton = (tabNumber) => {
    return (
      <BackWrapper
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <BackButton
          data-cy="sshBackButton"
          onClick={(e) => handleChange(e, tabNumber)}
        >
          <StyledArrowBackIosIcon />
          Back
        </BackButton>
      </BackWrapper>
    );
  };

  const handleChange = (event, newValue) => {
    history.push(
      `/ssh/${(newValue === 0 && "sshDashboard") ||
        (newValue === 1 && "sshRegistration") ||
        (newValue === 2 && "sshUserManagement") ||
        (newValue === 3 && "sshArchive")}`
    );
    setTabValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (eId) {
        apiCall(pendingURL, "pending");
        apiCall(requesterURL, "requesting");
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ height: "100%" }}>
      {/* <div style={{ paddingTop: "20px" }}> */}
      {tabValue === 1 && (
        <div>
          {backButton(0)}
          <RegistrationForm
            isRegistered={isRegistration}
            sshConfig={sshConfigUrl}
          />
        </div>
      )}

      {tabValue === 0 && (
        <div>
          <SHHHeader
            isRegistered={isRegistration}
            lastUpdated={resData.lastUpdatedDate}
            pendingData={pendingData.filter((ele) => {
              return ele.status === "pending";
            })}
            history={history}
            reload={setReload}
            // this prop passes to MyRequest information as an object
            resData={resData}
            downloadSSHConfigURL={sshConfigUrl}
          />
          <StyledCard style={{ marginTop: "25px", paddingBottom: "1em" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <div>
                    <SmallHeader style={{ padding: "15px" }}>
                      Pending Approvals
                    </SmallHeader>
                    <PendingSshApprovals
                      data={pendingData.filter((ele) => {
                        return ele.status === "pending";
                      })}
                      loading={loading}
                      reload={setReload}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <SmallHeader style={{ padding: "15px" }}>
                      Requested Approvals
                    </SmallHeader>
                    <MyRequests
                      // Comment on this validation since in this way all Requested Approvals cards are shown as pending.
                      // data={requesterData.filter((ele) => {
                      //   return ele.status === "pending";
                      // })}
                      // Instead simply pass all the objects to it so you can have all the states of the cards
                      data={requesterData.filter((ele) => {
                        return ele.status === "pending";
                      })}
                      loading={loading}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UpdateButton
                type="button"
                variant="outlined"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => handleChange(e, 3)}
              >
                See Archive
              </UpdateButton>
            </div>
          </StyledCard>
        </div>
      )}
      {tabValue === 2 && (
        <div style={{ height: "90%" }}>
          <div style={{ height: "10%" }}>{backButton(0)}</div>
          <StyledCard
            style={{ height: "90%", overflow: "scroll" }}
            // style={{ marginTop: "25px" }}
          >
            <SSHUserManagement
              style={{ width: "100%" }}
              sshData={requesterData.filter((ele) => {
                return ele.status === "approved";
              })}
              history={history}
            />
          </StyledCard>
        </div>
      )}
      {tabValue === 3 && (
        <div style={{ height: "90%" }}>
          <div style={{ height: "10%" }}>{backButton(0)}</div>

          <StyledCard style={{ height: "90%", overflowY: "scroll" }}>
            <SSHArchive
              style={{ width: "100%" }}
              data={requesterData.filter((ele) => {
                return ele.status !== "pending";
              })}
              history={history}
            />
          </StyledCard>
        </div>
      )}
      <Footer />
    </div>
  );
};

Ssh.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Match property of route library
   */
  match: PropTypes.object,
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * Identification Token use for API access
   */
  idToken: PropTypes.string,
  /**
   * Boolean that confirms is the user is registrated
   */
  isRegistration: PropTypes.bool,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  sshConfigUrl: PropTypes.string,
  /**
   * Redux api to fetch presigned url from s3 bucket.
   */
  fetchSSHConfig: PropTypes.func,
};

const mapStateToProps = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  isRegistration: state.home.isRegistration,
  sshConfigUrl: state.servers.sshConfig,
  activeAccount: state.auth.activeAccount,
});

export default connect(mapStateToProps, {
  fetchSSHConfig,
  fetchServersInstances,
})(Ssh);
