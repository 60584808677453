//mywizard360-techarch-mycloud-client\src\components\ssh\SshRegistration\RegistrationForm.js
import React, { useState, useEffect, Fragment } from "react";
// import React, { useState, useRef, useEffect, Fragment } from "react";
// import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../redux/ssh";
import { closeSuccessOrFailureDialog } from "../../../redux/ssh/index";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  MenuItem,
  InputLabel,
  Grid,
  TextField,
  CircularProgress,
  Checkbox,
  Tooltip
} from "@material-ui/core";

import {
  StyledFormControl,
  StyledSelect,
  StyledSubmitButton,
} from "../SshStyles";
import { tokenValidation } from "../../../helper/customizedHooks";
import {
  StyledBanner,
  StyledResourceCard,
  NameTextField,
} from "./RegistrationStyles";
import {
  SmallHeader,
  LargeSubtitle,
  LargeHeader,
  ExtraLargeHeader,
  Subtext,
} from "../../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../../assets/index";
import FormResources from "./FormResources";
import PropTypes from "prop-types";
import SuccessfulOrFailedDialog from "./SuccessfulOrFailedDialog/SuccessfulOrFailedDialog";
import base from "../../../config";
import { headers } from "../../../helper/api";
import Axios from "axios";
import { fetchPresignedUrl } from "../../../redux/faq/index";
import { StyledClickableElementForFile } from "../../ec2management/ServerList/styledServerList";

/**
 * The registration/update form of SSH
 * @author Isabel Sprengers
 * @version 2.1
 * @see Used in component: Ssh.js
 */

const RegistrationForm = ({
  submitRegistration,
  submitSSHUpdate,
  enableMFA,
  mfaData,
  isRegistered,
  email,
  isSubmitLoading,
  isRegistrationSuccessful,
  history,
  sshConfig,
  openSuccessfulOrFailedDialog,
  closeSuccessOrFailureDialogAction,
  isRegistration,
  isRegistrationLoading,
  idToken,
  eid,
  saveSshPublicKeyAction,
  currentPublicKey,
  presignedUrls,
  presignedUrlIsLoading,
  fetchPresignedUrl,
  activeAccount,
}) => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    initiative: "",
    team: "",
    publicKey: "",
  });
  const [SSHUpdateValues, setSSHUpdateValues] = useState({
    publicKey: "",
  });
  const [isPublicKeyValid, setIsPublicKeyValid] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isTeamDropDown, setIsTeamDropdown] = useState(false);
  const [isInitiativeDropDown, setIsInitiativeDropdown] = useState(false);
  const [isEnabledRegisterButton, setIsEnableRegisterButton] = useState(true);
  const [isEnabledUpdateButton, setIsEnableUpdateButton] = useState(true);

  useEffect(() => {
    setIsEnableRegisterButton(
      isPublicKeyValid && isFirstNameValid && isLastNameValid && isTeamDropDown && isInitiativeDropDown
        ? false
        : true
    );
    setIsEnableUpdateButton(isPublicKeyValid ? false : true);
    if (selected === "myIndustry"){
      setIsTeamDropdown(true);
    }else if (selected === "IEMP") {
      setIsTeamDropdown(true);
    }
  }, [
    email,
    isPublicKeyValid,
    isFirstNameValid,
    isLastNameValid,
    isInitiativeDropDown,
    isTeamDropDown,
  ]);

  const handleChange = (field) => (e) => {
    e.preventDefault();
    setValues({ ...values, [field]: e.target.value });
    setSSHUpdateValues({ ...SSHUpdateValues, [field]: e.target.value });
    if (field === "publicKey") {
      setIsPublicKeyValid(checkPublicKey(e.target.value));
    }
    if (field === "firstName") {
      setIsFirstNameValid(checkName(e.target.value));
    }
    if (field === "lastName") {
      setIsLastNameValid(checkName(e.target.value));
    }

    if (field === "initiative") {
      setIsInitiativeDropdown(e.target.value !== "" ? true : false);
      setSelected(e.target.value);
    }
    
    if (field === "team") {
      setIsTeamDropdown(e.target.value !== "" ? true : false);
    }
  };

  //Check the public key
  const regex = new RegExp("^ssh-rsa .*?" + email + "$");
  const checkPublicKey = (input) => {
    return regex.test(input) ? true : false;
  };

  //Check the FirstName and LastName
  const checkName = (input) => {
    const nameStart = /^[A-Za-z-]+$/i;
    return nameStart.test(input) ? true : false;
  };

  const submitData = {
    first_name: values.firstName,
    last_name: values.lastName,
    public_key: values.publicKey,
    initiative: values.initiative,
    team: values.team,
  };

  const handleSubmit = () => {
    console.log("submitdata", submitData)
    tokenValidation();
    submitRegistration(submitData);
    enableMFA(eid)
  };

  const submitDataSSHUpdate = {
    public_key: SSHUpdateValues.publicKey,
  };

  const handleSubmitSSHUpdate = () => {
    tokenValidation();
    submitSSHUpdate(submitDataSSHUpdate);
  };

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  const initiatives = [
    {
      value: "myWizard AiOps",
      label: "myWizard AiOps"
    },
    {
      value: "IEMP",
      label: "IEMP"
    },
    {
      value: "myWizard Core",
      label: "myWizard Core"
    }
  ];


  const myWizardAiOps = [
    {
      value: "applicationdev",
      label: "Application Dev"
    },
    {
      value: "eusupport",
      label: "EU Support"
    },
    {
      value: "idcsupport",
      label: "IDC Support"
    },
    {
      value: "pdcsupport",
      label: "PDC Support"
    },
    {
      value: "mdcsupport",
      label: "MDC Support"
    },
    {
      value: "swat",
      label: "SWAT"
    },
    {
      value: "clientprojectteam",
      label: "Client Project Team"
    },
    {
      value: "techarch",
      label: "Tech Arch"
    }
  ];

  // const myIndustryIEMP = [];

  const myIndustry = [];

  const IEMP = [];

  const myWizardCore = [
    {
      value: "L3-eu-infra-support",
      label: "L3 EU Infra Support"
    },
    {
      value: "L3-infra-support",
      label: "L3 Infra Support"
    },
    {
      value: "ocd",
      label: "ocd"
    },
    {
      value: "techarch",
      label: "techarch"
    },
    {
      value: "L3-app-support",
      label: "L3 App Support"
    },
    {
      value: "app-support",
      label: "App Support"
    },
    {
      value: "developer",
      label: "Developer"
    },
    {
      value: "L2-app-support",
      label: "L2 App Support"
    },
    {
      value: "L2-eu-app",
      label: "L2 EU App"
    },
    {
      value: "L3-eu-app-support",
      label: "L3 EU App Support"
    },
    {
      value: "tester",
      label: "Tester"
    },
    {
      value: "secrem",
      label: "SecRem"
    },
    {
      value: "L3-aiops-app-support",
      label: "L3 AIOPS App Support"
    },
    {
      value: "eu-ocd",
      label: "EU ocd"
    },
    {
      value: "app-infra",
      label: "App Infra"
    }
  ];

  const [selected, setSelected] = useState("");

  let type = myWizardAiOps;

  if (selected === "myWizard AiOps") {
    type = myWizardAiOps;
  } else if (selected === "myIndustry") {
    type = myIndustry;
  } else if (selected === "IEMP") {
    type = IEMP;
  } 
  else if (selected === "myWizard Core") {
    type = myWizardCore;
  }

  
  useEffect(() => {
    if (eid && idToken) {
      let url = `${base.urls.apis.sshNew}user-management`;
      Axios.get(url, {
        headers: { ...headers, Authorization: idToken },
        params: {
          customer_enterprise_id: eid,
          get_public_key: 'get_public_key',
          inititive: activeAccount, 
        },
      })
      .then((res) =>
        saveSshPublicKeyAction(res.data)
      );
    }
  }, [eid, idToken]);


  const fetchFilePresignedUrl = (event) => {
    event.preventDefault();
    const urls = `${base.urls.apis.faq}/mfa_registration_userguide.pdf`;
    Axios.get(urls, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eid
      },
    }).then((res)=>{
      const data = res.data
      window.open(data[0], "_blank");
    }).catch((error) => {
      console.log('Error', error)
    })    
  };

  return (
    <div>
      <div>
        <ExtraLargeHeader>Welcome!</ExtraLargeHeader>
        {/*}
        <TextField
          style={{ width: "100%" }}
          id="outlined-multiline-static"
          label="Current SSH Public Key"
          multiline
          rows={5}
          value={currentPublicKey}
          variant="outlined"
          disabled
        />
  */}
      </div>
      <SmallHeader>
        To begin using SSH Management, we require you to input the public key
      </SmallHeader>
      <StyledBanner src={images.SSHRegistrationSplashscreen} />

      <Grid container>
        <Grid item xs={6}>
          <LargeHeader>I don't have an SSH Key</LargeHeader>
          <FormResources
            downloadSSHConfigURL={sshConfig}
            presignedUrls={presignedUrls}
            isLoading={presignedUrlIsLoading}
            fetchPresignedUrl={fetchPresignedUrl}
            email={email}
          />
        </Grid>
        <Grid item xs={6}>
          <LargeHeader>I have an SSH Key</LargeHeader>
          <StyledResourceCard>
            {isRegistrationLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Fragment>
                <LargeSubtitle style={{ padding: "2%", textAlign: "center" }}>
                  Please insert the following information to{" "}
                  {isRegistered ? `update` : `register`} your SSH key
                </LargeSubtitle>
                <div style={{ padding: "0% 5% 5% 5%" }}>
                  {isRegistered ? (
                    <div></div>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6} style={{ paddingBottom: "0" }}>
                        <NameTextField
                          label="First Name"
                          margin="dense"
                          variant="outlined"
                          value={values.firstName}
                          onChange={handleChange("firstName")}
                          inputProps={{ firstnamecy: "firstNameCy" }}
                          helperText={
                            !isFirstNameValid &&
                            !!values.firstName &&
                            `Please provide a valid First Name.`
                          }
                          error={
                            !isFirstNameValid && !!values.firstName && true
                          }
                        />
                      </Grid>
                      <Grid item xs={6} style={{ paddingBottom: "0" }}>
                        <NameTextField
                          label="Last Name"
                          margin="dense"
                          variant="outlined"
                          value={values.lastName}
                          onChange={handleChange("lastName")}
                          inputProps={{ lastnamecy: "lastNameCy" }}
                          helperText={
                            !isLastNameValid &&
                            !!values.lastName &&
                            `Please provide a valid Last Name.`
                          }
                          error={!isLastNameValid && !!values.lastName && true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingBottom: "0", paddingTop: "0" }}
                      >
                        <StyledFormControl variant="outlined" margin="dense">
                          <InputLabel htmlFor="outlined-initiative-select">
                            Initiative
                          </InputLabel>
                          <StyledSelect
                            value={values.initiative}
                            onChange={handleChange("initiative")}
                            label="Initiative"
                          >
                            <MenuItem value="">
                              <em>--Select initiative--</em>
                            </MenuItem>
                            {initiatives.map((initiative) => 
                              <MenuItem value={initiative.value}>
                              {initiative.label}
                              </MenuItem>
                            )}
                          </StyledSelect>
                        </StyledFormControl>
                        {(Object.keys(type).length > 0) ? (
                          <StyledFormControl variant="outlined" margin="dense">
                            <InputLabel htmlFor="outlined-team-select">
                              Team
                            </InputLabel>
                            <StyledSelect
                              value={values.team}
                              onChange={handleChange("team")}
                              label="Team"
                            >
                              <MenuItem value="">
                                <em>--Select a team--</em>
                              </MenuItem>
                              {type.map((team) => 
                                <MenuItem value={team.value}>
                                {team.label}
                                </MenuItem>
                              )}
                            </StyledSelect>
                          </StyledFormControl>
                        ): (" ")}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        style={{ width: "100%" }}
                        label="Insert Public Key"
                        id="publicKey"
                        margin="dense"
                        variant="outlined"
                        value={values.publicKey}
                        onChange={handleChange("publicKey")}
                        inputProps={{ puclickeycy: "puclicKeyCy" }}
                        multiline
                        rows={isRegistered ? (vh > 800 ? "10" : "6") : "6"}
                        helperText={
                          !isPublicKeyValid &&
                          !!values.publicKey &&
                          `Please provide a valid Public Key.`
                        }
                        error={!isPublicKeyValid && !!values.publicKey && true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Subtext>
                       Your public key can be found in the *.pub file of your
                      SSH folder.
                    </Subtext>
                  </Grid>
                  {isRegistered ? (
                    <div></div>
                  ) : (
                    <p style={{color:"rgb(117 121 124)"}}>
                      <Checkbox
                        defaultChecked
                        name="EnableMFA"
                        disabled
                        style={{padding:"0px 5px 2px 0px"}}
                      />
                      ENABLE MFA    
                      <Tooltip title="Multifactor authentication is enabled for additional security while connecting via SSH." placement="right">
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            padding: "0px 5px",
                            color: "rgb(117 121 124)"
                          }}
                        />
                      </Tooltip>
                      <Subtext>
                         For further information, please refer this {" "}
                          <StyledClickableElementForFile 
                            variant="body2"
                            onClick={(e) => fetchFilePresignedUrl(e)}
                          >
                            userguide
                          </StyledClickableElementForFile >.
                      </Subtext>
                    </p> 
                  )}
                  <Grid container style={{ padding: "30px 0px 10px 0px" }}>
                    <StyledSubmitButton
                      style={{ margin: "auto", display: "flex" }}
                      color="primary"
                      onClick={
                        isRegistered ? handleSubmitSSHUpdate : handleSubmit
                      }
                      disabled={
                        isRegistered
                          ? isEnabledUpdateButton
                          : isEnabledRegisterButton
                      }
                    >
                      {isSubmitLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress size={18} />
                        </div>
                      ) : isRegistered ? (
                        "UPDATE"
                      ) : (
                        "REGISTER"
                      )}
                    </StyledSubmitButton>
                  </Grid>
                </div>
              </Fragment>
            )}
          </StyledResourceCard>
        </Grid>
      </Grid>
      <SuccessfulOrFailedDialog
        history={history}
        isRegistration={isRegistration}
        open={openSuccessfulOrFailedDialog}
        booleanTitle={isRegistrationSuccessful}
        closeSuccessOrFailureDialog={closeSuccessOrFailureDialogAction}
      />
    </div>
  );
};

RegistrationForm.propTypes = {
  /**
   * Function that submit data for Registration Tab form
   */
  submitRegistration: PropTypes.func,
  /**
   * Boolean that confirms if the user is registered
   */
  isRegistered: PropTypes.bool,
  /**
   * Function that submit data for Access Tab form
   */
  submitSSHUpdate: PropTypes.func,
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Boolean used for check if the submit button was clicked
   */
  isSubmitLoading: PropTypes.bool,
  /**
   * Boolean that confirms if the registration of the user was successful
   */
  isRegistrationSuccessful: PropTypes.bool,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  sshConfig: PropTypes.string,
  /**
   * Function that enable mfa registration 
   * */
   enableMFA: PropTypes.func,
};

const MSTP = (state) => ({
  currentPublicKey: state.ssh.currentPublicKey,
  isLoading: state.ssh.isLoading,
  isSubmitLoading: state.ssh.isSubmitLoading,
  email: state.auth.userDetails.email,
  activeAccount: state.auth.activeAccount,
  isRegistrationSuccessful: state.ssh.isRegistrationSuccessful,
  openSuccessfulOrFailedDialog: state.ssh.openSuccessfulOrFailedDialog,
  isRegistration: state.home.isRegistration,
  isRegistrationLoading: state.home.isRegistrationLoading,
  idToken: state.auth.idToken,
  presignedUrls: state.faq.presignedUrls,
  presignedUrlIsLoading: state.faq.isLoading,
  mfaData: state.ssh.mfaData,
  eid: state.auth.userDetails.email
    ? state.auth.userDetails.email.split("@")[0]
    : "",
});

export default connect(MSTP, {
  submitRegistration: actions.submitRegistration,
  submitSSHUpdate: actions.submitSSHUpdate,
  closeSuccessOrFailureDialogAction: closeSuccessOrFailureDialog,
  saveSshPublicKeyAction: actions.saveSshPublicKey,
  fetchPresignedUrl: fetchPresignedUrl,
  enableMFA: actions.enableMFA,
})(withRouter(RegistrationForm));
