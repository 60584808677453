import { Typography } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../theme/theme";

export const Subtext = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 14px;
    color: ${theme.typography.mainColor};
    opacity: 80%;
    text-align: left;
    @media (max-width: 1700px) {
      font-size: 12px;
    }
    @media (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;

export const SubtextSolid = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 14px;
    color: ${theme.typography.mainColor};
    text-align: left;
    @media (max-width: 1700px) {
      font-size: 12px;
    }
    @media (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;

export const Subtitle5 = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 16px;
    color: ${theme.typography.mainColor};
    @media (max-width: 1700px) {
      font-size: 14px;
    }
    @media (max-width: 1500px) {
      font-size: 12px;
    }
    @media (max-width: 1350px) {
      font-size: 10px;
    }
    @media (max-width: 1100px) {
      font-size: 9px;
    }
  }
`;
export const Subtitle4 = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 16px;
    color: ${theme.typography.mainColor};
    @media (max-width: 1700px) {
      font-size: 14px;
    }
    @media (max-width: 1500px) {
      font-size: 12px;
    }
    @media (max-width: 1350px) {
      font-size: 10px;
    }
    @media (max-width: 1100px) {
      font-size: 9px;
    }
  }
`;

export const Subtitle3 = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 20px;
    color: ${theme.typography.mainColor};
    @media (max-width: 1700px) {
      font-size: 17px;
    }
    @media (max-width: 1500px) {
      font-size: 18px;
    }
    @media (max-width: 1350px) {
      font-size: 19px;
    }
    @media (max-width: 1100px) {
      font-size: 20px;
    }
  }
`;

export const Subtitle2 = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 20px;
    color: ${theme.typography.mainColor};
  }
`;

export const LargeSubtitle = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 20px;
    color: ${theme.typography.mainColor};

    @media (max-width: 1300px) {
      font-size: 20px;
    }
    @media (max-width: 1100px) {
      font-size: 14px;
    }
  }
`;

export const ExtraLargeHeader = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 60px;
    text-align: center;
    color: ${theme.typography.mainColor};
  }
`;

export const LargeNumber = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    color: ${theme.typography.mainColor};
    @media (max-width: 1100px) {
      font-size: 55px;
    }
  }
`;

export const MediumNumber = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    color: ${theme.typography.mainColor};
    @media (max-width: 1100px) {
      font-size: 25px;
    }
  }
`;

export const SmallNumber = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: ${theme.typography.mainColor};
    @media (max-width: 1100px) {
      font-size: 15px;
    }
  }
`;

export const XSmallHeader = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: medium;
    font-size: 26px;
    letter-spacing: 0.15;
    text-align: center;
    color: ${theme.typography.mainColor};
  }
`;

export const SmallHeader = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: medium;
    font-size: 34px;
    letter-spacing: 0.15;
    text-align: center;
    color: ${theme.typography.mainColor};
  }
`;

export const LargeHeader = styled(Typography)`
  &&&&& {
    font-family: Graphik;
    font-weight: normal;
    font-size: 48px;
    text-align: center;
    color: ${theme.typography.mainColor};
    @media (max-width: 1300px) {
      font-size: 36px;
    }
    @media (max-width: 1100px) {
      font-size: 32px;
    }
  }
`;

export const FieldName = styled.div`
  color: ${theme.typography.mainColor};
  padding: 5px 0px 5px 5px;
  font-family: Graphik;
  font-size: 8px;
  text-align: left;
  font-weight: 600;
  @media (min-width: 850px) {
    font-size: 10px;
    padding: 5px 0px 5px 5px;
  }
  @media (min-width: 1000px) {
    font-size: 12px;
    padding: 7px;
  }
  @media (min-width: 1200px) {
    font-size: 14px;
    padding: 10px 10px 10px 15px;
  }
  @media (min-width: 1450px) {
    font-size: 16px;
  }
  @media (min-width: 1600px) {
    font-size: 15px;
  }
  @media (min-width: 2000px) {
    font-size: 20px;
  }
`;

export const FieldValue = styled.div`
  color: ${theme.typography.mainColor};
  font-family: Graphik;
  padding: 5px 5px 5px 0px;
  font-size: 8px;
  text-align: left;
  @media (min-width: 850px) {
    font-size: 10px;
    padding: 5px 5px 5px 0px;
  }
  @media (min-width: 1000px) {
    font-size: 12px;
    padding: 7px;
  }
  @media (min-width: 1200px) {
    font-size: 14px;
    padding: 10px;
  }
  @media (min-width: 1450px) {
    font-size: 16px;
  }
  @media (min-width: 1600px) {
    font-size: 15px;
  }
  @media (min-width: 2000px) {
    font-size: 20px;
  }
`;
