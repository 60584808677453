import React, { useState } from "react";
// import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";

import { Divider } from "@material-ui/core";
import {
  StyledTooltip,
  //   TextHelperWrapper,
  //   TextHelperTypography,
  StyledParagraphBold,
} from "../FuiStyles";
import {
  longTextEndDate,
  rootExistedHint,
  //   quickConfigurationTextHelper,
} from "../Constants/LongText";
// import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
// import TextsmsIcon from "@material-ui/icons/Textsms";
import {
  checkWBSECode,
  checkBenefitsExpected,
  checkConcurrentUsers,
  checkDailyDataVolume,
  checkMonthlyTicketVolume,
} from "./CommonFunctions";
import GeneralForm from "./GeneralForm";

// import BundleOrderApps from "./BundleOrderApps";
const aSelectedInstanceFields = {
  DMSId: { type: "string", fieldName: "dmsId" },
  ClientName: { type: "string", fieldName: "clientName" },
  DeployRegion: { type: "object", fieldName: "deployRegion" },
  SAPContractID: { type: "object", fieldName: "sapContractId" },
  AaamPlatformType: { type: "object", fieldName: "aaamPlatformType" },
  //   HighAvailability: { type: "string", fieldName: "highAvailability" },
};
/**
 * Stack 360 Form
 * @author Andres McLeod
 * @version 1.0
 * @see Used in component: PlatformLicenseStepper
 */

const Stack360 = ({
  values, // the value would be changed by user's input
  updateStack360Form, //the function to update DPForm values which is from redux
  updateASelectedInstanceByField,
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  minDate,
  maxDate,
  isServiceEndDateInvalid,
  getSelectedEnvironment,
  getServiceEndDate,
  getNotesValue,
  notesValidations,
  //   configurationType,
  classes,
  history,
}) => {
  // open the dialog when user choose Yes in GDPR
  //   const config = configurationType === "quick";
  const [, setIsMonthlyTicketVolumeValid] = useState(true);

  // check whether values are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDailyDataVolumeValid, setIsDailyDataVolumeValid] = useState(true);
  const [isConcurrentUsersValid, setIsConcurrentUsersValid] = useState(true);
  const [isBenefitsExpectedValid, setIsBenefitsExpectedValid] = useState(true);

  const isGDPR = values.GDPR === "yes";
  const isStack = true;
  //   const isHichAv = values.HighAvailability === "yes";
  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";

  // change validation status
  (isWBSECodeValid ||
    isWBSECodeMDIValid ||
    isProjectNameValid ||
    isDailyDataVolumeValid ||
    isConcurrentUsersValid ||
    isBenefitsExpectedValid) &&
    values.StackApplication.length > 0 &&
    !isServiceEndDateInvalid
    ? setIsAllValid(true)
    : setIsAllValid(false);

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateStack360Form(fieldName, event);
      getServiceEndDate(event);
    } else {
      const { value } = event.target;
      event.preventDefault();
      updateStack360Form(fieldName, value);
      if (fieldName === "Environment") {
        getSelectedEnvironment(value);
      }
      if (fieldName === "WBSEcode") {
        const wbsevalue = value.toUpperCase()
        if (wbsevalue.trim() === "CBYDQ001") {
          setIsWBSECodeMDIValid(false)
        }
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "DailyDataVolume") {
        if (value[0] === "0") {
          setIsDailyDataVolumeValid(false);
        } else if (parseInt(value) > 200) {
          setIsDailyDataVolumeValid(false);
        } else {
          setIsDailyDataVolumeValid(checkDailyDataVolume(value));
        }
      }
      if (fieldName === "ConcurrentUsers") {
        setIsConcurrentUsersValid(checkConcurrentUsers(value));
      }
      if (fieldName === "BenefitsExpected") {
        if (value[0] === "0") {
          setIsBenefitsExpectedValid(false);
        } else {
          setIsBenefitsExpectedValid(checkBenefitsExpected(value));
        }
      }
      if (fieldName === "TentativeServiceEndDate") {
        getServiceEndDate(value);
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }
      if (fieldName === "TicketMonthlyVolume") {
        if (value[0] === "0") {
          setIsMonthlyTicketVolumeValid(false);
        } else {
          setIsMonthlyTicketVolumeValid(checkMonthlyTicketVolume(value));
        }
      }
      //update aSelectedInstance as other step relies on this data
      if (aSelectedInstanceFields[fieldName]) {
        const afieldDetails = aSelectedInstanceFields[fieldName];
        afieldDetails.type === "string" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value,
          });
        afieldDetails.type === "object" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value: {
              Value: value,
            },
          });
      }
    }
  };

  // Note: if "Infra360 Host Detailed - Linux/Windows" the field "Infra360 Host" has to be selected and disabled.
  const handleCheckBoxChange = (fieldName) => {
    const valueInArray =
      values.StackApplication.filter((addon) => addon === `${fieldName}`)
        .length > 0;
    if (valueInArray) {
      let removedFromArray = values.StackApplication.filter(
        (addon) => addon !== `${fieldName}`
      );
      if (
        fieldName === "acn-infra360host-addons-detailed-windows" ||
        fieldName === "acn-infra360host-addons-detailed-linux"
      ) {
        removedFromArray = removedFromArray.filter(
          (addon) => addon !== "acn-infra360host"
        );
      }
      updateStack360Form("StackApplication", removedFromArray);
    } else if (
      fieldName === "acn-infra360host-addons-detailed-windows" ||
      fieldName === "acn-infra360host-addons-detailed-linux"
    ) {
      updateStack360Form("StackApplication", [
        ...values.StackApplication,
        `${fieldName}`,
        "acn-infra360host",
      ]);
    } else {
      updateStack360Form("StackApplication", [
        ...values.StackApplication,
        `${fieldName}`,
      ]);
    }
  };

  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateStack360Form(fieldName, value);
  };
  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  /*
  const debouncedValue = useDebounce(values.DomainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);
  */

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateStack360Form(fieldName, value);
  };

  return (
    <div>
      <GeneralForm
        FormId={13}
        LeftColumnFields={
          <div>
            {PlatformFormField.EndToEndUIdPlatformField(values, handleChange)}

            {PlatformFormField.DeployRegionPlatformField(values, handleChange)}

            {PlatformFormField.ClientNamePlatformField(values, handleChange)}

            {FormField.VendorNameField(values.VendorName)}

            {PlatformFormField.SAPContractIDPlatformField(values, handleChange)}

            {/* {
              <TextHelperWrapper>
                <Avatar>
                  <TextsmsIcon />
                </Avatar>
                <TextHelperTypography>
                  {quickConfigurationTextHelper}
                </TextHelperTypography>
              </TextHelperWrapper>
            } */}
          </div>
        }
        RightColumnFields={
          <div>
            {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
            {PlatformFormField.DomainContextRootPlatformField(
              values,
              handleChangeRoot,
              isRootExist,
              rootExistedHint
            )}
            {PlatformFormField.GDPRPlatformField(
              values,
              handleChange,
              isGDPR,
              classes
            )}
            {PlatformFormField.DeploymentRegionPlatformField(
              values,
              handleChange,
              isGDPR,
              isStack
            )}
            {/* {PlatformFormField.HighAvailabilityPlaformField(
              values,
              handleChange,
              isHichAv
            )} */}

            {PlatformFormField.OrderTypeSelection(values, handleChange)}

            <StyledParagraphBold>OS Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360HostDetailedLinuxCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.Infra360HostDetailedWindowsCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.HostProcess360LinuxCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.HostProcess360WindowsCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.Infra360HostCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>DB Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.DB360OracleDBCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.DB360MSSQLServerCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Alerting</StyledParagraphBold>

            <Divider />

            {PlatformFormField.AlertCentreCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Network Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360NNMiCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Cloud Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Cloud360AWSCheckbox(
              values,
              handleCheckBoxChange
            )}

            {/* {PlatformFormField.Cloud360Addon(values, handleChange)}

            {PlatformFormField.HostProcess360LinuxAddon(values, handleChange)}

            {PlatformFormField.HostProcess360WindowsAddon(values, handleChange)}

            {PlatformFormField.HostProcess360ServiceAddon(values, handleChange)}

            {PlatformFormField.Infra360HostLinuxAddon(values, handleChange)}

            {PlatformFormField.Infra360HostWindowsAddon(values, handleChange)}

            {PlatformFormField.Infra360Addon(values, handleChange)}

            {PlatformFormField.Infra360HostMSSQLAddon(values, handleChange)}

            {PlatformFormField.Infra360HostPingMerakiAddon(
              values,
              handleChange
            )}

            {PlatformFormField.Infra360NNMIAddon(values, handleChange)}

            {PlatformFormField.Infra360NPSAddon(values, handleChange)}

            {PlatformFormField.SyntheticTransaction360Addon(
              values,
              handleChange
            )} */}

            {/* {PlatformFormField.GeographicAreaPlatformField(
              values,
              handleChange
            )} */}

            {/* {PlatformFormField.BenefitsExpectedPlatformField(
              values,
              handleChange,
              isBenefitsExpectedValid
            )} */}

            {PlatformFormField.EnvironmentPlatformField(values, handleChange)}
            {values.Environment === "Production" &&
              PlatformFormField.SplunkClusterTierPlatformField(
                values,
                handleChange,
                isStack
              )}

            <StyledTooltip
              title={longTextEndDate}
              placement="left-start"
              noValidate
            >
              <DateTextFieldCommonComponent
                tentativeServiceEndDate={values.TentativeServiceEndDate}
                selectedEnvironment={selectedEnvironment}
                isServiceEndDateInvalid={isServiceEndDateInvalid}
                minDate={minDate}
                maxDate={maxDate}
                handleChange={handleChange}
              />
            </StyledTooltip>

            {/* {PlatformFormField.DailyDataVolumePlatformField(
              values,
              handleChange,
              isConcurrentUsersValid
            )} */}

            {/* {FormField.UsersField(handleChange, values)} */}

            {/* {PlatformFormField.ConcurrentUsersPlatformField(
              values,
              handleChange,
              isConcurrentUsersValid
            )} */}

            {FormField.CustomerEnterpriseIDField(createdBy, "true")}

            {FormField.MobileNumberField(handleChangeMobileNo, values)}

            {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
            {FormField.ServiceGroupField(handleChange, values)}

            {FormField.AdminDistributionGroupField(handleChange, values)}

            {FormField.UsersDistributionGroupField(handleChange, values)}

            {PlatformFormField.RequestNotesPlatformField(
              values,
              handleChange,
              notesValidations
            )}
            {FormField.TermsAndConditionsField(
              handleChange,
              values,
              isAAAMTermsConditions
            )}
          </div>
        }
        history={history}
        NoConfig={true}
      ></GeneralForm>
    </div>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.Stack360Form,
  isRootExist: state.fui.isRootExist,
});

export default connect(MSTP, {
  updateStack360Form: fuiRedux.updateStack360Form,
  updateASelectedInstanceByField: fuiRedux.updateASelectedInstanceByField,
  checkDomainAvaliable: fuiRedux.checkRootExisted,
})(withRouter(Stack360));
