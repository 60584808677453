import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkRootExisted } from "../../../redux/fui";
import { rootExistedHint } from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import { checkWBSECode, checkTeams } from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as fuiRedux from "../../../redux/fui/index";

/**
 * Automatic Ticket Resolver Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const AutomaticTicketResolverSaaS = ({
  values, // the value would be changed by user's input
  updateATRSaaSForm, //the function to update DPForm values which is from redux
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  configurationType,
  classes,
  history,
}) => {
  // open the dialog when user choose Yes in GDPR
  const config = configurationType === "quick";
  const isTeamsRelayEnabled = values.TeamsRelayEnabled === "true";

  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isTeamsClientId, setIsTeamsClientId] = useState(false);
  const [isTeamsClientSecret, setIsTeamsClientSecret] = useState(false);
  const [isTeamsBotName, setIsTeamsBotName] = useState(false);

  // change validation status
  isTeamsRelayEnabled
    ? isTeamsClientId && isTeamsClientSecret && isTeamsBotName
      ? setIsAllValid(true)
      : setIsAllValid(false)
    : setIsAllValid(true);
  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateATRSaaSForm(fieldName, value);
  };

  // get user's input
  const handleChange = (event, fieldName) => {
    const { value } = event.target;
    event.preventDefault();
    updateATRSaaSForm(fieldName, value);
    if (fieldName === "WBSEcode") {
      const wbsevalue = value.toUpperCase()
      if (wbsevalue.trim() === "CBYDQ001") {
        setIsWBSECodeMDIValid(false)
      }
      setIsWBSECodeValid(checkWBSECode(value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3) {
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
    if (fieldName === "TeamsClientId") {
      if (value.length > 0 && checkTeams(value)) {
        setIsTeamsClientId(true);
      } else {
        setIsTeamsClientId(false);
      }
    }
    if (fieldName === "TeamsClientSecret") {
      if (value.length > 0 && checkTeams(value)) {
        setIsTeamsClientSecret(true);
      } else {
        setIsTeamsClientSecret(false);
      }
    }
    if (fieldName === "TeamsBotName") {
      if (value.length > 0 && checkTeams(value)) {
        setIsTeamsBotName(true);
      } else {
        setIsTeamsBotName(false);
      }
    }
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateATRSaaSForm("ConfigurationType", configurationType);

  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(values.DomainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  return (
    <GeneralForm
      FormId={12}
      LeftColumnFields={
        <div>
          {!config &&
            PlatformFormField.ClientNamePlatformField(values, handleChange)}
        </div>
      }
      RightColumnFields={
        <div>
          {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
          {PlatformFormField.DomainContextRootPlatformField(
            values,
            handleChangeRoot,
            isRootExist,
            rootExistedHint
          )}
          {FormField.CustomerEnterpriseIDField(createdBy, config)}

          {PlatformFormField.DeploymentRegionField(values, handleChange, false)}

          {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}

          {FormField.DryRunField(values, handleChange)}

          {FormField.TeamsRelayEnabledField(
            handleChange,
            values,
            isTeamsRelayEnabled
          )}
          {/* if user select enalble in DialogFlow, it would show DialogFlowDevToken with default value */}
          {isTeamsRelayEnabled && FormField.TeamsClientId(handleChange, values)}
          {isTeamsRelayEnabled && FormField.TeamsSecret(handleChange, values)}
          {isTeamsRelayEnabled && FormField.TeamsBotName(handleChange, values)}

          {FormField.TermsAndConditionsField(
            handleChange,
            values,
            isAAAMTermsConditions
          )}
        </div>
      }
      history={history}
      NoConfig={true}
    ></GeneralForm>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.ATRSaaSForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
});

export default connect(MSTP, {
  checkDomainAvaliable: checkRootExisted,
  updateATRSaaSForm: fuiRedux.updateATRSaaSForm,
})(withRouter(AutomaticTicketResolverSaaS));
