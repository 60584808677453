import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import { HoverTableRow } from "../ssh/SshStyles";

import {
  Subtext,
  Subtitle4,
  Subtitle3,
} from "../common/CommonStyles/CommonTypographyStyles";

import TableHead from "@material-ui/core/TableHead";

/**
 * Renders each Table row with the ec2 data.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: SSHUserManagement
 */
const AdminInnerPermissionsTable = ({ data }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Subtitle3 variant="h6" gutterBottom component="div">
          Mapped Permissions
        </Subtitle3>
      </div>
      <Table data-cy="">
        <TableHead>
          <TableRow>
            {data.length !== 0 && (
              <TableCell>
                <Subtitle4>Permission Name</Subtitle4>
              </TableCell>
            )}
            {data.length !== 0 && (
              <TableCell>
                <Subtitle4>Permission Notes</Subtitle4>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(function(innerRow, index) {
            return (
              <HoverTableRow key={index}>
                <TableCell component="th" scope="row">
                  <Subtext>{innerRow.component}</Subtext>
                </TableCell>
                <TableCell>
                  <Subtext>{innerRow.notes}</Subtext>
                </TableCell>
              </HoverTableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

AdminInnerPermissionsTable.propTypes = {};

export default AdminInnerPermissionsTable;
