import React from "react";

import { DialogTextWrapper, IconWrapper } from "../SshStyles";

import {
  Subtext,
  Subtitle3,
} from "../../common/CommonStyles/CommonTypographyStyles";
import theme from "../../../theme/theme";
import PropTypes from "prop-types";
import { DialogActions, DialogContentText } from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/Done";
import FailIcon from "@material-ui/icons/Clear";
import { AcceptButton } from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
/**
 * Dialog after user submits ssh user access request to with results of attempting to add ssh users.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AddUsersDialog
 */

const AddUsersSuccessFailDialog = ({
  response,
  open,
  innerHandleClose,
  handleClose,
  setIsLoading,
}) => {
  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  const closeDialog = () => {
    innerHandleClose();
    handleClose();
  };

  return (
    <CommonDialog
      open={open}
      onClose={closeDialog}
      title={"Adding SSH Users Results"}
      dialogType={"ssh"}
      dialogContent={
        <DialogTextWrapper>
          <DialogContentText id="alert-dialog-description">
            {response[0].map((result) => {
              return (
                <div>
                  {result.includes("success") ? (
                    <IconWrapper>
                      <SuccessIcon
                        style={{
                          color: theme.status.running,
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      />
                      <Subtitle3>{result}</Subtitle3>
                    </IconWrapper>
                  ) : (
                    <div>
                      <IconWrapper>
                        <FailIcon
                          style={{
                            color: theme.status.stopped,
                            width: "1.5em",
                            height: "1.5em",
                          }}
                        />
                        <Subtitle3>{result}</Subtitle3>
                      </IconWrapper>
                      <Subtext>
                        User does not have an SSH key registered with CAM
                      </Subtext>
                    </div>
                  )}
                </div>
              );
            })}
          </DialogContentText>
        </DialogTextWrapper>
      }
      dialogActions={
        <DialogActions>
          <AcceptButton
            onClick={closeDialog}
            label={"Ok"}
            data-cy="AddUsersOk"
            disabled={false}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

AddUsersSuccessFailDialog.propTypes = {
  /**
   * Response from submitting ssh user access for an instance, denoting any errors.
   */
  response: PropTypes.array,
  /**
   * Toggle to open/close current dialog
   */
  openAdd: PropTypes.bool,
  /**
   * Function to close current dialog
   */
  handleClose: PropTypes.func,
};

export default AddUsersSuccessFailDialog;
