import React, { useState, useEffect } from "react";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import { BoldTableCell, DetailTableWrapper } from "../FuiStyles";
import { upperFirst } from "lodash";
import { appNameProjectId } from "../Constants/ProductIds";

const InstalledAppsDetails = ({ data }) => {
  const [objectEntries, setObjectEntries] = useState([]);

  useEffect(() => {
    let obj = {
      OrderUId: data.OrderUId,
      WBSEcode: data.WBSEcode,
      CustomerMobileNo: data.CustomerMobileNo,
      ServiceGroup: data.ServiceGroup,
      AdminDistributionGroup: data.AdminDistributionGroup,
      UsersDistributionGroup: data.UsersDistributionGroup,
    };
    if (data.ProductUId === appNameProjectId.tickeyAnalysisId) {
      obj = {
        ...obj,
        TicketFeed: data.TicketFeed,
        ServiceNowUrl: data.ServiceNowUrl,
        ServiceNowUserName: data.ServiceNowUserName,
        TicketMonthlyVolume: data.TicketMonthlyVolume,
      };
    } else if (data.ProductUId === appNameProjectId.devOpsAnalyticsId) {
      obj = {
        ...obj,
        DevOpsAddonsGroup: data.DevOpsAddonsGroup,
      };
    } else if (data.ProductUId === appNameProjectId.automaticTicketResolverId) {
      obj = {
        OrderUId: data.OrderUId,
        WBSEcode: data.WBSEcode,
        DialogFlow: data.DialogFlow,
        DialogFlowClientToken: data.DialogFlowClientToken,
        DialogFlowDevToken: data.DialogFlowDevToken,
      };
    } else if (data.ProductUId === appNameProjectId.turingId) {
      obj = {
        OrderUId: data.OrderUId,
        WBSEcode: data.WBSEcode,
        DialogFlow: data.DialogFlow,
        DialogFlowClientToken: data.DialogFlowClientToken,
        DialogFlowDevToken: data.DialogFlowDevToken,
      };
    } else if (data.ProductUId === appNameProjectId.stormseerId) {
      obj = {
        OrderUId: data.OrderUId,
        WBSEcode: data.WBSEcode,
        DialogFlow: data.DialogFlow,
        DialogFlowClientToken: data.DialogFlowClientToken,
        DialogFlowDevToken: data.DialogFlowDevToken,
      };
    }
    const dataArray = Object.entries(obj);
    setObjectEntries(dataArray);
  }, [data]);

  return (
    <DetailTableWrapper>
      <Table>
        <TableBody>
          {objectEntries.map((instance) => (
            <TableRow>
              <BoldTableCell>{upperFirst(instance[0])}</BoldTableCell>
              <TableCell>{instance[1]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DetailTableWrapper>
  );
};

export default InstalledAppsDetails;
