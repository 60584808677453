// import React from "react";
// import { upperFirst } from "lodash";

import {
  //   HANDLE_CURRENT_CLOUD,
  SIDEBAR_DROPDOWN_OPTIONS,
  SIDEBAR_COLLAPSE_STATE,
  SIDEBAR_ON_HOVER_CLASSES,
  //   SAVE_NOTIFI_ARRAY_INFO,
} from "./CommonSidebarActionTypes";
// import { toast } from "react-toastify";

const initialState = {
  showSshManagementTabs: false,

  sidebarDropdownStateByClick: {
    fulfillment: {
      isOpenTab: false,
    },
    sshManagement: {
      isOpenTab: false,
    },
    accessManagement: {
      isOpenTab: false,
    },
  },
  sidebarCollapseFulfillmentState: false,
  sidebarCollapseSshManagementState: false,
  sidebarCollapseAccessManagementState: false,
  sidebarContainerOnHoverClasses: "sidebar_items_originalSize",
  sidebarTextOnHoverClass: "text",
};

function commonSidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_DROPDOWN_OPTIONS:
      if (action.type === "SIDEBAR_DROPDOWN_OPTIONS") {
        const { selectedScreenOption } = action;
        let handleSidebarDropdownStateByClick = {
          ...state.sidebarDropdownStateByClick,
        };
        if (selectedScreenOption === "fulfillment") {
          if (handleSidebarDropdownStateByClick.fulfillment.isOpenTab) {
            handleSidebarDropdownStateByClick.fulfillment.isOpenTab = false;
          } else {
            handleSidebarDropdownStateByClick.fulfillment.isOpenTab = true;
          }
        } else if (selectedScreenOption === "sshManagement") {
          if (handleSidebarDropdownStateByClick.sshManagement.isOpenTab) {
            handleSidebarDropdownStateByClick.sshManagement.isOpenTab = false;
          } else {
            handleSidebarDropdownStateByClick.sshManagement.isOpenTab = true;
          }
        } else if (selectedScreenOption === "accessManagement") {
          if (handleSidebarDropdownStateByClick.accessManagement.isOpenTab) {
            handleSidebarDropdownStateByClick.accessManagement.isOpenTab = false;
          } else {
            handleSidebarDropdownStateByClick.accessManagement.isOpenTab = true;
          }
        }
        return {
          ...state,
          sidebarDropdownStateByClick: handleSidebarDropdownStateByClick,
          sidebarCollapseFulfillmentState:
            handleSidebarDropdownStateByClick.fulfillment.isOpenTab,
          sidebarCollapseSshManagementState:
            handleSidebarDropdownStateByClick.sshManagement.isOpenTab,
          sidebarCollapseAccessManagementState:
            handleSidebarDropdownStateByClick.accessManagement.isOpenTab,
        };
      }
      return { ...state };
    case SIDEBAR_COLLAPSE_STATE:
      return {
        ...state,
        sidebarCollapseFulfillmentState: action.collapseFulfillmentState,
        sidebarCollapseSshManagementState: action.collapseSshManagementState,
        sidebarCollapseAccessManagementState: action.collapseAccessManagementState,
      };
    case SIDEBAR_ON_HOVER_CLASSES:
      return {
        ...state,
        sidebarContainerOnHoverClasses: action.sidebarContaineroOnHoverClasses,
        sidebarTextOnHoverClass: action.sidebarTextOnHoverClasses,
      };

    default:
      return {
        ...state,
      };
  }
}

export default commonSidebarReducer;
