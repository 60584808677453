import React from "react";
import { isEmpty } from "lodash";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { PlatformDetailsWrapper } from "../FuiStyles";
import InstalledAppsDetails from "./InstalledAppsDetails";
import { SecondaryButton } from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common//CommonComponents/Dialog";

/**
 * Shows the specific installed app details in a new dialog
 * @author
 * @version 1.0
 * @see Used in component: InstalledApps.js
 */

const AppDetailedDialog = ({
  open,
  handleClose,
  scroll,
  filteredApps,
  currentOrderId,
  installedApps,
  classes,
}) => {
  // create the function to find the app (orderId) installed in this instance
  const mapPayloadToADetail = (originData, orderId) => {
    if (isEmpty(originData) || !orderId) {
      return [];
    } else {
      const aOrderItem = originData.find(
        (pieceData) => orderId === pieceData.OrderUId
      );
      return aOrderItem;
    }
  };

  const installedAppsDetails = mapPayloadToADetail(
    filteredApps,
    currentOrderId
  );

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title={"Installed App Details"}
      dialogType={"fui"}
      dialogContent={
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText>
            <PlatformDetailsWrapper>
              <InstalledAppsDetails data={installedAppsDetails} />
            </PlatformDetailsWrapper>
          </DialogContentText>
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <SecondaryButton
            onClick={handleClose}
            label={"Close"}
            className={classes.FuiSecondaryButton}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

export default AppDetailedDialog;
