import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
// import schedulerReducer from "./scheduler/schedulerReducer";
import serversReducer from "./ServersReducer/ServersReducer";
import sshReducer from "./ssh";
import fuiReducer from "./fui";
import homeReducer from "./home";
import { connectRouter } from "connected-react-router";
import commonSearchReducer from "./CommonSearch/CommonSearchReducer";
import commonSidebarReducer from "./CommonSidebar/CommonSidebarReducer";
import faqReducer from "./faq/index";
import adminReducer from "./admin/index";
const rootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    // instances: schedulerReducer, ###NOT USED IN CAM APP
    servers: serversReducer,
    ssh: sshReducer,
    fui: fuiReducer,
    home: homeReducer,
    commonSearchReducer: commonSearchReducer,
    router: connectRouter(history),
    sidebarReducer: commonSidebarReducer,
    faq: faqReducer,
    adminReducer: adminReducer,
  });

export default rootReducer;
