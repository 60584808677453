import Home from "./home.jpg";
import Login from "./home2.jpg";
import TicketAnalyticsIcon from "./TicketAnalyticsIcon.png";
import DevOpsIcon from "./DevOpsIcon.png";
import ATRIcon from "./ATRIcon.svg";
import Turing from "./Turing.png";
import ATRSaaSIcon from "./ATRSaaSIcon.svg";
import SharepointIcon from "./SharepointIcon.png";
import AutomationLicense from "./AutomationLicense.svg";
import DedicatedPlatform from "./DedicatedPlatform.svg";
import UTKMPlatform from "./UTKM.png";
import EventOpsPlatform from "./EventOps.png";
import GenWizardPlatform from "./GenWizardPlatform.png";
import ADPlatform from "./ADPlatform.png";
import WindowsProvisioningPlatform from "./WindowsProvisioningPlatform.png";
import ManagedPlatform from "./ManagedPlatform.svg";
import ML from "./ML.svg";
import OnPremise from "./OnPremise.svg";
import PlainInstance from "./PlainInstance.svg";
import CAMGreen from "./logo.svg";
import CAMGreenBlack from "./CAMGreenBlack.png";
import noData from "./noData.jpg";
import AWS_Icon from "./aws_logo.png";
import Azure_Icon from "./AZURE-Logo-removebg-preview.png";
import GCP_Icon from "./TESTgcp2-removebg-preview.png";
import All_Clouds_Icon from "./cloud-3-500.png";
import AWS_Black from "./aws-black-removebg.png";
import SSHRegistrationSplashscreen from "./SSHRegistrationSplashscreen.svg";
import AAAMWiki from "./wiki.png";
import footerImage from "./footerImage.svg";
import reportExtrator from "./report-extractor.png";
import SplunkCluster from "./SplunkCluster.png";
import Stack360 from "./Stack360.png";
import StormWatch from "./StormWatch.png";
import AutomationJourney from "./AutomationJourney.png";
import AutomationPlanner from "./AutomationPlanner.png";
import IntelligentOpportunityMiner from "./IntelligentOpportunityMiner.png";
import GuidedTicketResolver from "./GuidedTicketResolver.png";
import Klewer from "./Klewer.png";
import Quasar from "./Quasar.png";
import VirtualDataScientist from "./VirtualDataScientist.png";
import StormSeer from "./StormSeer.png"

export const images = {
  HomePageImage: Home,
  LoginPageImage: Login,
  NavLogoImage: CAMGreen,
  DashboardTitle: CAMGreenBlack,
  TicketAnalyticsIcon: TicketAnalyticsIcon,
  DevOpsIcon: DevOpsIcon,
  ATRIcon: ATRIcon,
  Turing: Turing,
  ATRSaaSIcon: ATRSaaSIcon,
  AutomationLicense: AutomationLicense,
  DedicatedPlatform: DedicatedPlatform,
  UTKMPlatform: UTKMPlatform,
  EventOpsPlatform: EventOpsPlatform,
  GenWizardPlatform: GenWizardPlatform,
  ADPlatform: ADPlatform,
  WindowsProvisioningPlatform: WindowsProvisioningPlatform,
  SplunkCluster: SplunkCluster,
  Stack360: Stack360,
  StormWatch: StormWatch,
  ManagedPlatform: ManagedPlatform,
  ML: ML,
  OnPremise: OnPremise,
  PlainInstance: PlainInstance,
  noData: noData,
  SharepointIcon: SharepointIcon,
  AwsCloudIcon: AWS_Icon,
  AzureCloudIcon: Azure_Icon,
  GCPCloudIcon: GCP_Icon,
  AllCloudIcon: All_Clouds_Icon,
  AWS_Black: AWS_Black,
  SSHRegistrationSplashscreen: SSHRegistrationSplashscreen,
  AAAMWiki: AAAMWiki,
  FooterImage: footerImage,
  ReportExtrator: reportExtrator,
  AutomationJourney: AutomationJourney,
  AutomationPlanner: AutomationPlanner,
  IntelligentOpportunityMiner: IntelligentOpportunityMiner,
  GuidedTicketResolver: GuidedTicketResolver,
  Klewer: Klewer,
  Quasar: Quasar,
  VirtualDataScientist: VirtualDataScientist,
  StormSeer: StormSeer,
};
