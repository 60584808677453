import { makeStyles } from "@material-ui/core";

export const adminMakeStyles = makeStyles((theme) => ({
  createRoleButton: {
    backgroundColor: theme.palette.admin.main,
    color: "white",
  },
  createRoleButtonSecondary: {
    color: theme.palette.admin.main,
    borderColor: theme.palette.admin.main,
  },
  paddingBottom: {
    // paddingBottom: '.4em'
  },
}));
