import React from "react";
import { images } from "../../assets/index";
import { Divider } from "@material-ui/core";
export const Footer = () => {
  /**
   * Footer of every page containing mywizard aiops logo and copyright
   * @author Isabel Sprengers
   * @version 1.0
   * @see Used in component: Ec2Management.js, FulfilmentUI.js, App.js, Ssh.js
   */

  var date = new Date();

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     // marginTop: "5%",
    //     height: "10%",
    //   }}
    // >
    //   <div style={{ textAlign: "center", fontSize: "14px", color: "#000000" }}>
    //     © 2001-{date.getFullYear()} Accenture. All Rights Reserved. Powered By
    //     Accenture.
    //   </div>
    //   <img
    //     style={{
    //       width: "300px",
    //       position: "absolute",
    //       transform: "translateY(60%)",
    //     }}
    //     src={images.FooterImage}
    //     alt="Footer_not_found"
    //   />
    // </div>
    <div style={{ paddingTop: "4rem", marginTop:"5vh"}}>
      <Divider />

      <div
        style={{
          width: "100%",
          paddingBottom: "1rem"
        }}
      >

        <div style={{ padding: "2rem" }}>
          <p style={{ float: "right", fontSize: "14px", color: "#000000", marginTop: "3px" }}>
            © 2001-{date.getFullYear()} Accenture. All Rights Reserved. Powered By
            Accenture.
          </p>
          <img
            style={{
              float: "left",
              // width: "300px",
              // position: "absolute",
              // transform: "translateY(60%)",
            }}
            src={images.FooterImage}
            alt="Footer_not_found"
          />
        </div>
      </div>
    </div>
  );
};
