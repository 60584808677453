import React from "react";

import { DialogActions } from "@material-ui/core";
import { revokeUsers } from "../../../redux/ssh/index";
import { connect } from "react-redux";
import {
  AcceptButton,
  DeclineButton,
} from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";

/**
 * Renders archival page in SSH
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: Ssh
 */

const RevokeAccessDialog = ({
  email,
  epid,
  openRevoke,
  handleClose,
  revokeUsersCall,
  instanceId,
  accountType,
  accountId,
}) => {
  const handleRevoke = () => {
    revokeUsersCall(email, instanceId, [epid], accountType, accountId);
    handleClose();
  };
  return (
    <CommonDialog
      open={openRevoke}
      onClose={handleClose}
      title={"Revoking Access"}
      textContent={"Are you sure you want to revoke access?"}
      dialogType={"ssh"}
      dialogActions={
        <DialogActions>
          <AcceptButton onClick={handleRevoke} label={"Yes"} />
          <DeclineButton onClick={handleClose} label={"No"} />{" "}
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  revokeUsersCall: (email, instance_id, userepids, accountType, accountId) =>
    dispatch(revokeUsers(email, instance_id, userepids, accountType, accountId)),
});

export default connect(null, mapDispatchToProps)(RevokeAccessDialog);
