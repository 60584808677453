import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { Box, Menu, MenuItem } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
// import Box from "@material-ui/core";
import { Grid, CircularProgress } from "@material-ui/core";
import { StyledFormControl, StyledSelect } from "../fui/FuiStyles";
import { SmallHeader, Subtitle4, SmallNumber, XSmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from '../../assets';
import { connect } from "react-redux";
import base from "../../config";
import { headers } from "../../helper/api";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokenValidation } from "../../helper/customizedHooks";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import DownsizeInstance from "../ec2management/ActionItems/DownsizeInstance"
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { CSVLink } from "react-csv";
import { Radio, FormControlLabel } from "@material-ui/core";
import { StyledRadioGroup, TopRadioWrapper } from "../fui/FuiStyles";
import { DownloadButton, UpdateButton } from "../ssh/SshStyles";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend,  Title} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { Footer } from "../common/Footer";


/**
 * Approvals page
 * @author shilpa.adikeshava
 * @version 1.0
 */

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: 'auto',
  },
});

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    boxShadow: "unset",
    padding: "15px 32px 32px 32px"
  },
  count: {
    margin: '0 0 0 0.3em',
    color: '#ffffff',
    margin: '0 0 0 0.3em',
    fontSize: '1.2rem',
    background: '#7500c0',
    padding: '0rem 0.7rem 0.2rem 0.7rem',
    borderRadius: '3rem',
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));




const Approvals = (email, idToken) => {

  const [loaded, setLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [approvalRequestList, setApprovalRequestList] = useState([]);
  const [open, setOpen] = useState(-1);
  const [modalStyle] = React.useState(getModalStyle);
  const [modalData, setData] = useState();
  const [openActionModal, setOpenActionModal] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = useState(" ");
  const [notes, setNotes] = useState(" ");

  const classes = useStyles();






  useEffect(() => {
    let timer;
    if (email && idToken) {
      fetchApprovalRequestList(email);
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchApprovalRequestList(email);
        };
        apiCall();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [email, idToken]);






  const fetchApprovalRequestList = async () => {
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];
    if (customerEnterpriseId && idToken) {
      return Axios
        .get(
          `${base.urls.apis.order}` +
          `/read/all?userepid=${customerEnterpriseId}`,
          {
            headers: { ...headers, Authorization: idToken },
          }
        )
        .then((res) => {
          setIsLoading(false)
          const data = res.data;
          setApprovalRequestList(data)
        })
        .catch((err) => {
          //toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
        });
    }
  };

  const handleChange = (data, value) => {
    setData(value);
    setSelectedRowData(data);
    setOpenActionModal(true);
  };

  const handleClose = () => {
    setOpenActionModal(false);
    // setSelectedRowData(" ");
    setNotes(" ");
  };

  const handleChoosedRow = (row) => {
    // console.log("choosed row", row);
    setSelectedRowData(row);
  };

  // const handleApprovedRequest = (data) => {
  //   tokenValidation();
  //   const idToken = localStorage.getItem("token");
  //   const approvalStatus = data.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value']
  //   approvalStatus['Value'] = "Approved";
  //   const provisioningUrl = process.env.REACT_APP_PROVISION_URL;
  //   Axios.post(provisioningUrl, data, {
  //     headers: { Authorization: idToken }
  //   }).then((res) => {
  //     setLoaded(true);
  //     toast.info("Request has been approved. Thank you for actioning on it!")
  //   })
  //     .catch((err) => {
  //       toast.error("ERROR! Please try again after sometime.");
  //       console.log("Async error", err);
  //     });
  //   handleClose();
  //   setLoaded(false);
  // };

  // WITHOUT QUEUING
  const handleApprove = () => {
    tokenValidation();
    const idToken = localStorage.getItem("token");
    const objectId = selectedRowData._id;
    const approvalStatus = "Approved";
    const approver = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Approver")['Value']
    const customerEnterpriseID = selectedRowData.OrderItems[0].Config.find(item => item.Key === "CustomerEnterpriseID")['Value']
    const orderItemUId = selectedRowData.OrderItems[0].OrderItemUId
    const createdDate = selectedRowData.OrderItems[0].CreateDate ? selectedRowData.OrderItems[0].CreateDate : selectedRowData.OrderItems[0].CreatedDate
    const clientName = selectedRowData.OrderItems[0].Config.find(item => item.Key === "ClientName")['Value']
    const tier = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Tier")['Value']
    const productUId = selectedRowData.OrderItems[0].ProductUId
    const urls = `${base.urls.apis.order}/update/existing`;
    Axios.post(urls, " ", {
      headers: { Authorization: idToken },
      params: {
        ObjectId: objectId,
        ApprovalStatus: approvalStatus,
        Approver: approver,
        CustomerEnterpriseID: customerEnterpriseID,
        OrderItemUId: orderItemUId,
        ProductUId: productUId,
        CreatedDate: createdDate,
        ClientName: clientName,
        Tier: tier,
        Notes: "Approved"
      },
    }).then((res) => {
      delete selectedRowData._id;
      const provisioningUrl = process.env.REACT_APP_PROVISION_URL;
      const configArray = selectedRowData.OrderItems[0].Config
      const approvalStatus = configArray.find(item => item.Key === "ApprovalStatus");
      approvalStatus['Value'] = "Approved";
      // var date = new Date().toISOString().slice(0, 10)
      // var approvedDate = {Key: "ApprovedDate", Value: date};
      // configArray.push(approvedDate); 
      // var approvedBy = {Key: "ApprovedBy", Value: email ? email.email.split("@")[0] : ""};
      // configArray.push(approvedBy); 
      Axios.post(provisioningUrl, selectedRowData, {
        headers: { Authorization: idToken }
      }).then((res) => {
        setLoaded(true);
        toast.info("Request has been approved. Thank you for actioning on it!")
      })
        .catch((err) => {
          toast.error("ERROR! Please try again after sometime.");
          setLoaded(true);
          console.log("Async error", err);
        });
    })
      .catch((err) => {
        toast.error("ERROR! Please try again after sometime.");
        setLoaded(true);
        console.log("Async error", err);
      });
    handleClose();
    setLoaded(false);
  };

  // WITH QUEUING
  // const handleApprove = () => {
  //   tokenValidation();
  //   console.log(selectedRowData)
  //   const idToken = localStorage.getItem("token");
  //   const objectId = selectedRowData._id;
  //   const approvalStatus = "Approved";
  //   const approver = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Approver")['Value']
  //   const customerEnterpriseID = selectedRowData.OrderItems[0].Config.find(item => item.Key === "CustomerEnterpriseID")['Value']
  //   const orderItemUId = selectedRowData.OrderItems[0].OrderItemUId
  //   const createdDate = selectedRowData.OrderItems[0].CreateDate
  //   const clientName = selectedRowData.OrderItems[0].Config.find(item => item.Key === "ClientName")['Value']
  //   const tier = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Tier")['Value']
  //   const urls = `${base.urls.apis.order}/update/existing`;
  //   Axios.post(urls, " ", {
  //     headers: { Authorization: idToken },
  //     params: {
  //       // ObjectId: objectId,
  //       ApprovalStatus: approvalStatus,
  //       Approver: approver,
  //       CustomerEnterpriseID: customerEnterpriseID,
  //       OrderItemUId: orderItemUId,
  //       CreatedDate: createdDate,
  //       ClientName: clientName,
  //       Tier: tier
  //     },
  //   }).then((res) => {
  //     setLoaded(true);
  //     toast.info("Request has been approved. Thank you for actioning on it!")
  //   })
  //     .catch((err) => {
  //       toast.error("ERROR! Please try again after sometime.");
  //       console.log("Async error", err);
  //     });
  //   handleClose();
  //   setLoaded(false);
  // };

  const handleDecline = () => {
    tokenValidation();
    const idToken = localStorage.getItem("token");
    const objectId = selectedRowData._id;
    const approvalStatus = "Declined";
    const approver = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Approver")['Value']
    const customerEnterpriseID = selectedRowData.OrderItems[0].Config.find(item => item.Key === "CustomerEnterpriseID")['Value']
    const orderItemUId = selectedRowData.OrderItems[0].OrderItemUId
    const createdDate = selectedRowData.OrderItems[0].CreateDate ? selectedRowData.OrderItems[0].CreateDate : selectedRowData.OrderItems[0].CreatedDate
    const clientName = selectedRowData.OrderItems[0].Config.find(item => item.Key === "ClientName")['Value']
    const tier = selectedRowData.OrderItems[0].Config.find(item => item.Key === "Tier")['Value']
    const productUId = selectedRowData.OrderItems[0].ProductUId
    const urls = `${base.urls.apis.order}/update/existing`;

    if (notes === " " || notes === "") {
      toast.info("Please input the reason why you want to decline the request!")
    } else {
      Axios.post(urls, " ", {
        headers: { Authorization: idToken },
        params: {
          ObjectId: objectId,
          ApprovalStatus: approvalStatus,
          Approver: approver,
          CustomerEnterpriseID: customerEnterpriseID,
          OrderItemUId: orderItemUId,
          ProductUId: productUId,
          CreatedDate: createdDate,
          ClientName: clientName,
          Tier: tier,
          Notes: notes
        },
      }).then((res) => {
        setLoaded(true);
        toast.info("Request has been declined. Thank you for actioning on it!")
      })
        .catch((err) => {
          toast.error("ERROR! Please try again after sometime.");
          setLoaded(true);
          console.log("Async error", err);
        });
      handleClose();
      setLoaded(false);
    }
  };

  const handleNotesChange = (field) => (event) => {
    event.preventDefault();
    if (field === "notes") {
      setNotes(event.target.value);
    }
  };

  const sortedApprovalsData = approvalRequestList ? approvalRequestList.sort((a, b) => new Date(b.OrderItems[0].CreatedDate) - new Date(a.OrderItems[0].CreatedDate)) : undefined;

  return (
    <div>
      <div className="mainDiv">
        <DarkBackground disappear={!loaded}>
          <LoadingOverlay
            active={true}
            // spinner={<BounceLoader />}
            spinner={true}
          >
            {/* <p>Some content or children or something.</p> */}
          </LoadingOverlay>
        </DarkBackground>
        <ToastContainer autoClose={7000} />
        <Grid className="headerItems" container >
          <Grid item md={3}>
            <img
              alt=""
              src={images.NavLogoImage}
              // width="70%"
              style={{ float: "left" }}
            />
          </Grid>
          <Grid item md={9} style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <XSmallHeader className="recomHeader" style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "500", float: "right" }}> Approvals {approvalRequestList.length > 0 ? <span className={classes.count}>{approvalRequestList.length}</span> : ""}</XSmallHeader>
          </Grid>
        </Grid>
        <Divider />
        <div>
          {isLoading ?
            <div id="loader"
              style={{
                paddingTop: "10%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
            :
            <div>
              <div>
                <br></br>
                <br></br>
                {sortedApprovalsData.length > 0 ?
                  <div>
                    <Paper className={styles.root}>
                      <div className={styles.tableWrapper} style={{ maxWidth: "95% !important", maxHeight: "500px", overflow: "auto" }}>
                        <Table className={styles.table}>
                          <TableHead style={{ backgroundColor: "#7500C0" }}>
                            <TableRow >
                              <TableCell />
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Order ID
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Requested Date
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Client Name
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Product Name
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Order Type
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Request Notes
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Requester
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Approver
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Approval Status
                              </TableCell>
                              <TableCell style={{ color: "#fff", padding: "12px" }} >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {sortedApprovalsData.map((row, index) => (
                            <TableBody >
                              <TableRow style={{ backgroundColor: "#fff" }}>
                                <TableCell>
                                  <IconButton aria-label="expand row" size="small" onClick={() => setOpen(open === index ? -1 : index)}>
                                    {open === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].OrderItemUId}
                                </TableCell> 
                                <TableCell component="th" scope="row" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].CreatedDate ? row.OrderItems[0].CreatedDate.split("T")[0] : row.OrderItems[0].CreateDate.split("T")[0]}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : row.ClientName}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {
                                    row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value'].includes("myWizard AiOps")
                                      ? row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value'].replace('myWizard AiOps', '')
                                      : row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value']
                                  }
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "ConfigurationType")['Value'] === "full" ? "Client" : "Internal"}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "RequestNotes")['Value']}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "CustomerEnterpriseID")['Value']}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "Approver")['Value'].split('@')[0]}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value']}
                                </TableCell>
                                {/* <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value'] === "Pending Approval" ?
                                    <StyledSelect defaultValue="SELECT" onChange={handleChange} style={{ fontSize: "0.875rem" }}>
                                      <MenuItem disabled value="SELECT">
                                        -- SELECT --
                                      </MenuItem>
                                      <MenuItem value="Approve" data-item={row} onClick={() => handleChoosedRow(row)}>
                                        APPROVE
                                      </MenuItem>
                                      <Divider />
                                      <MenuItem value="Decline" data-item={row} onClick={() => handleChoosedRow(row)}>
                                        DECLINE
                                      </MenuItem>
                                    </StyledSelect>
                                    : "Completed"}
                                </TableCell> */}
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      style={{ background: "#48cb48", color: "#FFF" }}
                                      onClick={() => handleChange(row, "Approve")}
                                    >
                                      Approve
                                    </Button>&nbsp;&nbsp;
                                    <Button
                                      style={{ background: "#e90d0d", color: "#FFF" }}
                                      onClick={() => handleChange(row, "Decline")}
                                    >
                                      Decline
                                    </Button>
                                  </div>
                                </TableCell>

                              </TableRow>
                              <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                                    <Box style={{ padding: "1% 3% 0% 3%" }}>

                                      <Table aria-label="purchases">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Client & Contract Details </TableCell>
                                            <TableCell>Environment Details</TableCell>
                                            <TableCell>Application Configuration Details</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                          <TableCell component="th" scope="row" style={{ lineHeight: "7px" }}>
                                          <p><span style={{ fontWeight: 600 }}>SAP Contract ID:</span> {row.OrderItems[0].Config.find(item => item.Key === "SAPContractID")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>End To End UID:</span> {row.OrderItems[0].Config.find(item => item.Key === "EndToEndUId")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>WBSE Code:</span> {row.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value'] == "" ? "NA" : row.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Project Name:</span> {row.OrderItems[0].Config.find(item => item.Key === "ProjectName")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>CR/RITM Number:</span> {row.OrderItems[0].Config.find(item => item.Key === "RITM")['Value'] == "" ? "NA" : row.OrderItems[0].Config.find(item => item.Key === "RITM")['Value']}</p>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ lineHeight: "7px" }}>
                                              <p><span style={{ fontWeight: 600 }}>Domain Context Root:</span> {row.OrderItems[0].Config.find(item => item.Key === "DomainContextRoot")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Deployment Region:</span> {row.OrderItems[0].Config.find(item => item.Key === "DeploymentRegion")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Environment:</span> {row.OrderItems[0].Config.find(item => item.Key === "Environment")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Tier:</span> {row.OrderItems[0].Config.find(item => item.Key === "Tier")['Value'] == "" ? "NA" : row.OrderItems[0].Config.find(item => item.Key === "Tier")['Value']}</p>
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{ lineHeight: "7px" }}>
                                              <p><span style={{ fontWeight: 600 }}>Tentative Service End Date:</span> {row.OrderItems[0].Config.find(item => item.Key === "TentativeServiceEndDate")['Value'].split("T")[0]}</p>
                                              <p><span style={{ fontWeight: 600 }}>Enable GenAI:</span> {row.OrderItems[0].Config.find(item => item.Key === "OpenAI")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Instance Type:</span> {row.OrderItems[0].Config.find(item => item.Key === "InstanceType")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Admin Distribution Group:</span> {row.OrderItems[0].Config.find(item => item.Key === "AdminDistributionGroup")['Value']}</p>
                                              <p><span style={{ fontWeight: 600 }}>Request Notes:</span> {row.OrderItems[0].Config.find(item => item.Key === "RequestNotes")['Value']}</p>
                                            </TableCell>

                                          </TableRow>
                                        </TableBody>
                                      </Table>

                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}

                        </Table>
                      </div>
                    </Paper>
                  </div> :
                  <div style={{ alignContent: "left" }}>
                    <h3 style={{ textAlign: "left", fontFamily: "Graphik", paddingTop: "10px", fontWeight: "500" }}>There are no pending approvals for you to review!</h3>
                  </div>
                }
              </div>

            </div>
          }
        </div>
      </div >
      <div>
        <Modal open={openActionModal} onClose={handleClose}>
          <div style={modalStyle} className={classes.paper}>
            {modalData === "Approve" ?
              <div>
                <h3 style={{ color: "#7500C0" }}>Approve Request</h3>
                <Divider />
                <br></br>
                <div className={classes.root}>
                  <Alert severity="info">Monthly recovery for Infra provisioned for this order will be billed to the below mentioned WBSE.</Alert>
                </div>
                <br></br>
                <div style={{ lineHeight: "7px" }}>
                  <p><span style={{ fontWeight: 500 }}>Order ID:</span> {selectedRowData.OrderItems[0].OrderItemUId}</p>
                  <p><span style={{ fontWeight: 500 }}>Client Name:</span> {selectedRowData.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : selectedRowData.ClientName}</p>
                  <p><span style={{ fontWeight: 500 }}>WBSE Code:</span> {selectedRowData.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value'] == "" ? "NA" : selectedRowData.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value']}</p>
                </div>
                <p><br></br>Are you sure you want to <b>approve</b> this request now?</p>
                <div style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    style={{ borderColor: "#7500C0", color: "#7500C0" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>&nbsp;
                  <Button
                    style={{ background: "#7500C0", color: "#FFF" }}
                    onClick={handleApprove}
                  >
                    Yes, Approve
                  </Button>

                </div>
              </div>
              : ""}
            {modalData === "Decline" ?
              <div>
                <h3 style={{ color: "#7500C0" }}>Decline Request</h3>
                <Divider />
                <div style={{ lineHeight: "7px" }}>
                  <p><span style={{ fontWeight: 500 }}>Order ID:</span> {selectedRowData.OrderItems[0].OrderItemUId}</p>
                  <p><span style={{ fontWeight: 500 }}>Client Name:</span> {selectedRowData.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : selectedRowData.ClientName}</p>
                  <p><span style={{ fontWeight: 500 }}>WBSE Code:</span> {selectedRowData.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value'] == "" ? "NA" : selectedRowData.OrderItems[0].Config.find(item => item.Key === "WBSEcode")['Value']}</p>
                </div>
                <p><br></br>You have chose <b>"Decline"</b>. Please state the reason for it.</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  InputProps={{ rows: 4 }}
                  onChange={handleNotesChange("notes")}
                />
                <p><br></br>Are you sure you want to <b>decline</b> this request now?</p>

                <div style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    style={{ borderColor: "#7500C0", color: "#7500C0" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>&nbsp;
                  <Button
                    // variant="outlined"
                    // style={{ textTransform: 'none' }}
                    style={{ background: "#7500C0", color: "#FFF" }}
                    onClick={handleDecline}
                  >
                    Yes, Confirm
                  </Button>

                </div>
              </div>
              : ""}
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,

});


Approvals.propTypes = {

  /**

   * Data of instance

   */

  instance: PropTypes.object,

  /**

   * PrivateDNS of selected instance

   */

  privateDNS: PropTypes.string,

  /**

   * InstanceType of selected instance

   */

  instanceType: PropTypes.string,

};


export default connect(MSTP)(withRouter(withStyles(styles)(Approvals)));
