import styled from "styled-components";
import {
  Button,
  Typography,
  RadioGroup,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import theme from "../../../theme/theme";
import { SmallButton } from "../../common/CommonStyles/CommonButtons";
import {
  SubtextSolid,
  LargeSubtitle,
  Subtitle3,
  Subtitle4,
} from "../../common/CommonStyles/CommonTypographyStyles";

export const TimePickerWrapper = styled.div`
  padding-bottom: ${(props) => (props.padding ? props.padding : "0px")};
  padding-left: ${(props) => props.paddingLeft};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -6px 0 0 15px;
  width: 560px;
`;

export const SchedulerWrapper = styled.div``;

export const StyledCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: -8px 0 0 15px;
  justify-content: space-between;
  width: 200px;
`;

export const StyledButton = styled(Button)`
  &&&&& {
    width: ${(props) => (props.width ? props.width : "70px")};
    height: ${(props) => (props.height ? props.height : "35px")};
    color: ${(props) => (props.disabled ? `${grey[400]}` : "#e38c30")};
    /* border-color: ${(props) =>
    props.disabled ? `${grey[400]}` : "#e38c30"}; */
    /* margin: 10px 15px 0 0; */
  }
`;

export const SchedulerTitle = styled(Typography)`
  &&&&& {
    color: "#000000";
    font-weight: 700;
    text-align: left;
    width: 100%;
    /* margin: 10px 0 0 15px; */
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  &&&&& {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 400px;
  }
`;

export const StyledText = styled.div`
  display: flex;
  margin: 5px 0px 5px 16px;
  letter-spacing: ${(props) => props.spacing};
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10px;
  }
`;

export const StyledParagraph = styled.div`
  margin: 5px 0px 5px 16px;
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  width: 12em;
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
`;

export const PickerDialogContent = styled(DialogContent)`
  &&& {
    padding: ${(props) => props.padding};
  }
`;

export const ScheduleButton = styled(SmallButton)`
  &&&&& {
    border-color: ${theme.palette.aws.main};
    color: ${theme.palette.aws.main};
    width: 80%;
    height: 25px;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  &&&&& {
    background: ${theme.palette.aws.main};
    h6 {
      color: #fff;
    }
    display: flex;
  }
`;

export const TitleWrapper = styled.div`
  &&&&& {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const TimerMessageErrorFormat = styled.div`
  position: absolute;
  background: gray;
  color: white;
  opacity: 0.5;
  padding: 6px;
  margin: 2px;
  left: 270px;
  font-size: 12px;
  border-radius: 0px;
  margin-top: 50px;
`;

export const StyledScheduledDays = styled.span`
  margin: 0 0.2em 0 0.2em;
  color: ${(props) =>
    props.color === "aws" ? "#7500C0" : theme.typography.mainColor};
  font-weight: ${(props) => (props.color === "aws" ? "bold" : "normal")};
  font-size: 20px;
  @media (max-width: 1700px) {
    font-size: 15px;
  }
  @media (max-width: 1500px) {
    font-size: 16px;
  }
  @media (max-width: 1350px) {
    font-size: 14px;
  }
  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;

export const StyledSubtextSolid = styled(SubtextSolid)`
  &&&&& {
    font-size: 14px;
    @media (max-width: 1700px) {
      font-size: 12px;
    }
    @media (max-width: 1400px) {
      font-size: 10px;
    }
  }
`;

export const StyledLargeSubtitle = styled(LargeSubtitle)`
  &&&&& {
  }
`;

export const StyledSubtitle3 = styled(Subtitle3)`
  &&&&& {
  }
`;

export const StyledSubtitle4 = styled(Subtitle4)`
  &&&&& {
  }
`;
