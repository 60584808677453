import styled from "styled-components";
import { colours } from "../../theme/colours";
import { Link } from "react-router-dom";
import { Paper, Typography, Grid } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import theme from "../../theme/theme";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {
  MediumNumber,
  SmallNumber,
  Subtitle3,
} from "../common/CommonStyles/CommonTypographyStyles";
export const InstanceNumber = styled(SmallNumber)`
  &&&&& {
    // color: ${theme.palette.fui.main};
    margin: 0 0 0 0.3em;
    color: #7500c0;
    margin: 0 0 0 0.3em;
    font-size: 1.2rem;
    // background: #7500c0;
    padding: 0rem 0.7rem;
    border-radius: 3rem;
  }
`;

export const StyledEC2Number = styled(MediumNumber)`
  &&&&& {
    color: ${theme.palette.fui.main};
  }
`;

export const StyledEC2NumberWrapper = styled(Paper)`
  &&&&& {
    // background: #fff6eb;
    border-radius: 0px;
    text-align: center;
    align-items: center;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    height: 60px;
  }
`;

export const TypeName = styled(Subtitle3)`
  &&&&& {
  }
`;

export const StyledPageContainer = styled(Paper)`
  &&&&& {
    width: 100%;
    min-height: 7em;
    margin: 1.5em 0;
    min-width: 900px;
    margin-bottom: 4%;
  }
`;

export const ServersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

export const ServersInstanceType = styled.div`
  &&&&& {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0;
    margin-left: 5em;
    height: 100%;
  }
`;

export const WrapperDashboard = styled.div`
  padding-top: .4em;
  /* padding-top: 4%; */
  padding-left: 1%;
`;

export const StyledFirstRow = styled.div`
  display: flex;
  position: relative;
  top: -30px;
  left: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 200px;
  max-width: 200px;
`;

export const StyledEC2Header = styled.div`
  &&&&& {
    background: linear-gradient(${colours.fui.colourGradient});
    text-align: center;
    padding: ${(props) => (props.padding ? props.padding : "")};
    border-radius: 0px;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    color: white;
  }
`;

export const StyledSSHHeader = styled.div`
  &&&&& {
    background: linear-gradient(${colours.fui.colourGradient});
    text-align: center;
    padding: 0 0 20% 0;
    border-radius: 0px;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    height: 23px;
    color: white;
  }
`;

export const StyledFUIHeader = styled.div`
  &&&&& {
    background: linear-gradient(${colours.fui.colourGradient});
    text-align: center;
    padding: 0 0 20% 0;
    border-radius: 0px;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    height: 23px;
    color: white;
  }
`;

export const StyledHeaderTitle = styled.h2`
  color: #fff;
  font-size: 20px;
  height: 1em;
`;

export const StyledFUINumberWrapper = styled(Paper)`
  &&&&& {
    background: #f7faff;
    border-radius: 0px;
    text-align: center;
    align-items: center;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    height: 60px;
  }
`;

export const StyledSSHNumberWrapper = styled(Paper)`
  &&&&& {
    background: #f7fffa;
    border-radius: 0px;
    text-align: center;
    align-items: center;
    box-shadow: "unset",
    // box-shadow: 4px 4px 4px rgba(179, 179, 179, 0.58);
    width: 220px;
    height: 60px;
  }
`;

export const StyledFUINumber = styled(MediumNumber)`
  &&&&& {
    color: ${theme.palette.fui.main};
  }
`;

export const StyledSSHNumber = styled(MediumNumber)`
  &&&&& {
    color: ${theme.palette.ssh.main};
  }
`;

export const StyledLogo = styled.img`
  height: 50px;
  width: 400px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 3em 0;
`;

export const TitleTypography = styled(Typography)`
  &&&&& {
    font-weight: bold;
    color: "#000000";
    font-size: 45px;
    margin: 0 0 0 12px;
  }
`;

export const TitleColumnWrapperWithStyles = styled(Link)`
  &&& {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      /* background: #f2f2f2; */
    }
    text-decoration: none;
  }
`;

export const TitleColumnWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
    /* background: #f2f2f2; */
  }
  text-decoration: none;
`;

export const SshInstanceType = styled(Link)`
  &&&&& {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: -1em 2em 0 0;
    // p {
    //   &:hover {
    //     cursor: pointer;
    //     color: "#000000";
    //     font-weight: 900;
    //   }
    //   > svg {
    //     &:hover {
    //       cursor: pointer;
    //       color: "#000000";
    //       font-weight: 900;
    //     }
    //   }
    // }
    text-decoration: none;
  }
`;

export const FuiWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2.5em 1em 1em 5em;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2.5em 1em 1em 5em;
`;

export const FuiInstanceType = styled.div`
  &&&&& {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: -1em 3em 0 0;
  }
`;

export const SSHNumber = styled(Typography)`
  &&&&& {
    color: ${theme.palette.ssh.main};
    margin: 0 0 0 0.3em;
    color: #7500c0;
    margin: 0 0 0 0.3em;
    font-size: 1.2rem;
    font-weight: bold;
    // background: #7500c0;
    padding: 0rem 0.7rem;
    border-radius: 3rem;
  }
`;

export const SSHRegistration = styled(Typography)`
  margin: 0px 11px 0 0.5em;
  &:hover {
    cursor: pointer;
  }
  text-decoration: none;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  &&&&& {
    color: ${(props) =>
    props.color === "ssh" && props.hover
      ? "#9CD565"
      : props.color === "ssh" && props.hover === false && "#68AB33"};
    font-size: 30px;
    margin: 0px 11px 0 0.5em;
    font-weight: bold;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  &&&&& {
    color: ${(props) =>
    props.color === "ssh" && props.hover
      ? "#9CD565"
      : props.color === "ssh" && props.hover === false && "#68AB33"};
    font-size: 30px;
    margin: 0px 0 0 0.5em;
    font-weight: bold;
  }
`;

export const RowContentWrapper = styled.div`
  width: 100%;
  margin: ${props => props.loading ? '2.5em 1em 1em 5em' : '0'};
`;

export const Title = styled(Typography)`
  &&&&& {
    color: "#000000";
    font-weight: bold;
    text-align: left;
    @media (max-width: 590px) {
      font-size: 10px;
    }
    padding: 0.4em 0 0 1em;
  }
`;

export const CarlosTest = styled.div`
  width: 100%;
`;

export const WrapperPieChart = styled.div`
  max-width: 40%;
`;
export const StyledGridTitle = styled(Grid)`
  &&& {
    display: flex;
  }
`;

export const StyledGridItem = styled(Grid)`
  &&& {
    width: 100%;
  }
`;

export const StyledFulfillmentContent = styled(Grid)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PieChartPaper = styled(Paper)`
  &&& {
    background: #f2f2f2;
  }
`;

export const StyledMdGrid = styled(Grid)`
  &&& {
    display: ${(props) => (props.justifyflex ? "flex" : undefined)};
    justify-content: ${(props) => (props.justifyflex === 'true' ? "center" : undefined)};
  }
`;

export const StyledJustifyGridContent = styled(Grid)`
  &&& {
    display: flex;
    justify-content: center;
    background: "#f2f2f2";
  }
`;

export const Container = styled.div`
  margin: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const OnePieChartWrapper = styled.div`
  width: 320px;
  margin: 1em 0;
`;

export const PieChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 0 1.5em;
`;
