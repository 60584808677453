import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Radio, FormControlLabel } from "@material-ui/core";
import {
  StyledRadioGroup,
  TopRadioWrapper,
  ConfigurationFormLabel,
} from "./FuiStyles";
import { quickConfiguration, fullConfiguration } from "./Constants/LongText";
import * as fuiRedux from "../../redux/fui/index";
import {
  ATRFormModel,
  EventOpsFormModel,
  DOAFormModel,
  TAFormModel,
  DPFormModel,
  GWLFormModel,
  ADFormModel,
  WPFormModel,
  MPFormModel,
  PIFormModel,
  SPFormModel,
  REFormModel,
  PAMFormModel,
  UTKMFormModel,
  SplunkClusterFormModel,
  Stack360FormModel,
  StormWatchFormModel,
  StormSeerFormModel,
  OPSFormModel,
  TuringFormModel,
} from "../../constants/formModels";
import PropTypes from "prop-types";
/**
 * Handles the configuration type of a form
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: AppStepper and all forms.
 */
const ConfigurationType = ({
  setConfigurationType,
  configurationType,
  clearDPForm,
  clearGWLForm,
  clearADForm,
  clearWPForm,
  clearMPForm,
  clearPIForm,
  clearTAForm,
  clearDOAForm,
  clearATRForm,
  clearEOForm,
  clearOPSForm,
  clearSPForm,
  clearREForm,
  clearPAMForm,
  clearUTKMForm,
  clearSPLUCLUForm,
  clearStack360Form,
  clearStormWatchForm,
  clearStormSeerForm,
  clearLocalComponentData,
  clearIsRootExisted,
  clearExternalClientData,
  clearTuringForm,
}) => {
  const [isQuickConfiguration, setIsQuickConfiguration] = useState(false);
  useEffect(() => {
    if (configurationType.length > 0) {
      let configurationTypeValue = configurationType === "quick" ? true : false;
      setIsQuickConfiguration(configurationTypeValue);
    }
  }, [configurationType]);

  const clearForm = (e, configurationType) => {
    setConfigurationType(e, configurationType);
    clearDPForm(DPFormModel);
    clearGWLForm(GWLFormModel);
    clearADForm(ADFormModel);
    clearWPForm(WPFormModel);
    clearMPForm(MPFormModel);
    clearPIForm(PIFormModel);
    clearTAForm(TAFormModel);
    clearDOAForm(DOAFormModel);
    clearATRForm(ATRFormModel);
    clearEOForm(EventOpsFormModel);
    clearTuringForm(TuringFormModel);
    clearOPSForm(OPSFormModel);
    clearSPForm(SPFormModel);
    clearREForm(REFormModel);
    clearPAMForm(PAMFormModel);
    clearUTKMForm(UTKMFormModel);
    clearSPLUCLUForm(SplunkClusterFormModel);
    clearStack360Form(Stack360FormModel);
    clearStormWatchForm(StormWatchFormModel);
    clearStormSeerForm(StormSeerFormModel)
    clearLocalComponentData();
    clearIsRootExisted();
    clearExternalClientData();
  };

  return (
    <div>
      <TopRadioWrapper>
        <ConfigurationFormLabel component="legend">
          ORDER TYPE
        </ConfigurationFormLabel>

        <StyledRadioGroup
          aria-label="configurationType"
          name="configurationType"
          value={configurationType}
          onChange={(e) => clearForm(e, "configurationType")}
          row
        >
          <FormControlLabel
            checked={isQuickConfiguration === false}
            value="full"
            control={<Radio color="primary" />}
            // label={`CLIENT (${fullConfiguration})`}
            label={`CLIENT`}
          />
          <div>
            <FormControlLabel
              checked={isQuickConfiguration}
              value="quick"
              control={<Radio color="primary" />}
              // label={`INTERNAL (${quickConfiguration})`}
              label={`INTERNAL USER ( For developing & testing )`}
            />

          </div>
        </StyledRadioGroup>
      </TopRadioWrapper>
      {configurationType == "quick" ?
        <div>
          <em style={{ fontSize: "small", color: " #0041F0" }}>“I acknowledge that my cost owner is accountable for the cost of this internal/testing instance and will be subject to regular auditing"</em>
        </div> :
        <div>
          <em style={{ fontSize: "small", color: " #0041F0" }}>“Please keep valid/active sap contract id and end to end uid respective to the client you select"</em>
        </div>
      }
    </div>
  );
};

const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
});

ConfigurationType.propTypes = {
  /**
   * Redux function to set the configuration type of a form
   */
  setConfigurationType: PropTypes.func,
  /**
   * Allows you to clear the dedicated platform redux state
   */
  clearDPForm: PropTypes.func,
  /**
   * Allows you to clear the windows provisioning platform redux state
   */
  clearWPForm: PropTypes.func,
  /**
   *
   */
  clearLocalComponentData: PropTypes.func,
  /**
   *
   */
  clearIsRootExisted: PropTypes.func,
};

export default connect(MSTP, {
  setConfigurationType: fuiRedux.setConfigurationType,
  clearDPForm: fuiRedux.clearDPForm,
  clearGWLForm: fuiRedux.clearGWLForm,
  clearADForm: fuiRedux.clearADForm,
  clearWPForm: fuiRedux.clearWPForm,
  clearMPForm: fuiRedux.clearMPForm,
  clearPIForm: fuiRedux.clearPIForm,
  clearTAForm: fuiRedux.clearTAForm,
  clearDOAForm: fuiRedux.clearDOAForm,
  clearATRForm: fuiRedux.clearATRForm,
  clearEOForm: fuiRedux.clearEOForm,
  clearTuringForm: fuiRedux.clearTuringForm,
  clearOPSForm: fuiRedux.clearOPSForm,
  clearSPForm: fuiRedux.clearSPForm,
  clearREForm: fuiRedux.clearREForm,
  clearPAMForm: fuiRedux.clearPAMForm,
  clearUTKMForm: fuiRedux.clearUTKMForm,
  clearSPLUCLUForm: fuiRedux.clearSPLUCLUForm,
  clearStack360Form: fuiRedux.clearStack360Form,
  clearStormWatchForm: fuiRedux.clearStormWatchForm,
  clearStormSeerForm: fuiRedux.clearStormSeerForm,
  clearLocalComponentData: fuiRedux.clearLocalComponentData,
  clearIsRootExisted: fuiRedux.clearIsRootExisted,
  clearExternalClientData: fuiRedux.clearExternalClientData,
})(ConfigurationType);
