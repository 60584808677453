import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  StyledSearchBarPlatforms,
  PlatformWrapper,
  PlatformTitleWrapper,
  TableFirstRow,
  StyledSearchIcon,
  StyledPaper,
  HoverTableRow,
  ProgressWrapper,
  StatusWrapper,
  LicensesStatusWrapper,
  FinalWrapperFromSsh,
  ToAccessTypography,
  SuccessWrapper,
  StyledAvatar,
  ButtonWrapperFromSsh,
  TableWrapper,
} from "../FuiStyles";
// import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Fuse from "fuse.js";
import * as fuiRedux from "../../../redux/fui/index";
import StatusIcon from "../StatusIcon";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import { appNameProjectId } from "../Constants/ProductIds";
import {
  PrimaryButton,
  BorderlessButton,
} from "../../common/CommonComponents/Buttons";
import DetailsDialog from "../Dialogs/DetailsDialogs";
import { SmallHeader } from "../../common/CommonStyles/CommonTypographyStyles";
import { Footer } from "../../common/Footer";

// show instance name instead of productUId
const showInstanceName = (licenseId) => {
  switch (licenseId) {
    case appNameProjectId.onPremiseServiceId:
      return "GenWizard On-Premise Service";
    case appNameProjectId.automationAnywhereLicensesId:
      return "Automation Anywhere Licenses";
    default:
      return "Unknown Instance";
  }
};

const mapPayloadToState = (originData) =>
  originData.map((originRow) => ({
    orderId: originRow.OrderUId,
    clientName: originRow.ClientName,
    productName: showInstanceName(originRow.ProductUId),
    status: originRow.Status,
    domainContextRoot: originRow.DomainContextRoot,
  }));
/**
 * license list that shows the details of a users licenses
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: FulfilmentUI
 */
const LicenseList = ({
  isLoading,
  rowsReal,
  fetchInstanceData,
  history,
  email,
  classes,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [scrollDetails, setScrollDetails] = useState("paper");
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => email && fetchInstanceData(email), [email]);

  //refresh the table per 30s
  useEffect(() => {
    let timer;
    if (email) {
      timer = setInterval(() => {
        const apiCall = () => {
          fetchInstanceData(email);
        };
        apiCall();
      }, 30000);
      return () => clearInterval(timer);
    } else {
      return () => clearInterval(timer);
    }
  }, [email]);

  const mapPlacedToProcessing = (data) => {
    return data.filter((item) => {
      return item.status === "Placed"
        ? (item.status = "Processing")
        : item.status;
    });
  };

  const showCorrectPlatform = (rows) => {
    return filterNeedShow(rows);
  };

  const filterNeedShow = (data) =>
    data.filter(
      (row) =>
        row.ProductUId === appNameProjectId.onPremiseServiceId ||
        row.ProductUId === appNameProjectId.automationAnywhereLicensesId
    );

  const tableRows = mapPlacedToProcessing(
    mapPayloadToState(showCorrectPlatform(rowsReal))
  );

  const fuse = new Fuse(tableRows, {
    findAllMatches: true,
    minMatchCharLength: 1,
    maxPatternLength: 32,
    keys: ["orderId", "clientName", "productName", "status", "details"],
  });

  const handleClickOpenDetails = (scrollType, orderId) => () => {
    setOpenDetails(true);
    setScrollDetails(scrollDetails);
    setCurrentOrderId(orderId);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const filteredInstances = () => {
    return searchQuery
      ? searchQuery === "*"
        ? tableRows
        : fuse.search(searchQuery)
      : tableRows;
  };

  return (
    <>
      <div style={{ height: "90%", overflowY: "scroll" }}>
        <TableWrapper style={{ height: "100%" }}>
          <PlatformWrapper style={{ height: "10%", maxHeight: "10%" }}>
            <PlatformTitleWrapper>
              <SmallHeader>License List</SmallHeader>
            </PlatformTitleWrapper>
            <StyledSearchBarPlatforms
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              id="outlined-search"
              type="search"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledSearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </PlatformWrapper>
          {isLoading ? (
            <div style={{ height: "90%", maxHeight: "90%" }}>
              <ProgressWrapper
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </ProgressWrapper>
            </div>
          ) : isEmpty(tableRows) ? (
            <FinalWrapperFromSsh
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SuccessWrapper>
                <StyledAvatar>
                  <RemoveIcon />
                </StyledAvatar>
                <ToAccessTypography>
                  You haven't ordered any license!
                </ToAccessTypography>
              </SuccessWrapper>
              <ButtonWrapperFromSsh>
                <PrimaryButton
                  data-cy="goToOrderAnLicence"
                  onClick={() => history.push("/fui/order")}
                  label={"GO TO ORDER A LICENSE"}
                  className={classes.FuiPrimaryButton}
                />
              </ButtonWrapperFromSsh>
            </FinalWrapperFromSsh>
          ) : (
            <StyledPaper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableFirstRow align="center">ORDER ID</TableFirstRow>
                    <TableFirstRow align="center">CLIENT NAME</TableFirstRow>
                    <TableFirstRow align="center">PRODUCT NAME</TableFirstRow>
                    <TableFirstRow align="left">
                      <LicensesStatusWrapper>STATUS</LicensesStatusWrapper>
                    </TableFirstRow>
                    <TableFirstRow align="center">DETAILS</TableFirstRow>
                  </TableRow>
                </TableHead>
                {!isLoading && (
                  <TableBody>
                    {filteredInstances().map((row) => (
                      <HoverTableRow key={row.orderId}>
                        <TableCell align="center">{row.orderId}</TableCell>
                        <TableCell align="center">{row.clientName === "myWizard AiOps" ? "GenWizard AiOps" : row.clientName}</TableCell>
                        <TableCell align="center">{row.productName}</TableCell>
                        <TableCell align="center">
                          <StatusWrapper>
                            <StatusIcon status={row.status} />
                            <div>{row.status}</div>
                          </StatusWrapper>
                        </TableCell>
                        <TableCell align="center">
                          <BorderlessButton
                            data-cy="licenceDetails"
                            onClick={handleClickOpenDetails("paper", row.orderId)}
                            label={"Details"}
                            className={classes.FuiBorderlessButton}
                          />
                        </TableCell>
                      </HoverTableRow>
                    ))}
                  </TableBody>
                )}
              </Table>

              <DetailsDialog
                open={openDetails}
                handleClose={handleCloseDetails}
                scroll={scrollDetails}
                rowsReal={rowsReal}
                currentOrderId={currentOrderId}
                classes={classes}
                dialogName={"platform"}
              />
            </StyledPaper>
          )}
        </TableWrapper>
      </div>
      <div style={{
        left: 96,
        bottom: 0,
        right: 26,
      }}>
        <Footer />
      </div>
    </>
  );
};

LicenseList.propTypes = {
  /**
   * History of route library
   */
  history: PropTypes.object,
  /**
   * Is the page currently loading
   */
  isLoading: PropTypes.bool,
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * Information of each platform
   */
  rowsReal: PropTypes.array,
  /**
   * Redux function to fetch the instance data using an email.
   */
  fetchInstanceData: PropTypes.func,
};

const MSTP = (state) => ({
  isLoading: state.fui.isLoading,
  rowsReal: state.fui.instanceData,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, { fetchInstanceData: fuiRedux.fetchInstanceData })(
  withRouter(LicenseList)
);
