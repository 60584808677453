import React from "react";
import { withRouter } from "react-router-dom";
import { BackWrapper, BackButton, StyledArrowBackIosIcon } from "./FuiStyles";
import * as fuiRedux from "../../redux/fui/index";
import { connect } from "react-redux";
import PropTypes from "prop-types";
/**
 * Back button to leave the form stepper
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: AppStepper, all licenses and platform forms
 */
const BackToStandardList = ({ history, clearLocalComponentData }) => {
  const handleBackToList = () => {
    clearLocalComponentData();
    history.push("/fui/order");
  };

  return (
    <BackWrapper>
      <BackButton onClick={handleBackToList}>
        <StyledArrowBackIosIcon />
        Back
      </BackButton>
    </BackWrapper>
  );
};
BackToStandardList.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   *
   */
  clearLocalComponentData: PropTypes.func,
};
export default connect(null, {
  clearLocalComponentData: fuiRedux.clearLocalComponentData,
})(withRouter(BackToStandardList));
