import React, { useState, useEffect } from "react";
import { DialogActions } from "@material-ui/core";
import {
  Flex,
  Pharagraph,
  ButtonWrapper,
  PharagraphWrapper,
  StyledTextFieldForm,
} from "../ServerList/styledServerList";
import Axios from "axios";
import PropTypes from "prop-types";
import { tokenValidation } from "../../../helper/customizedHooks";
import base from "../../../config";
import { MenuItem, InputLabel } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import {
  checkWBSECode,
  isEidValid,
  checkNotesValue,
  checkProjectValue,
  checkSecondaryCustomerID,
} from "../../fui/forms/CommonFunctions";
import {
  StyledFormControl,
  StyledSelect
} from "../../fui/FuiStyles";
import {
  AcceptButton,
  DeclineButton,
} from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
/**
 * The EditTags component has the function of changing the tags that are editable for a selected instance
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: ServerList.js
 */

export const EditTags = (props) => {
  const {
    item,
    open,
    email,
    idToken,
    editTags,
    currentId,
    serverList,
    handleClose,
    handleApiCall,
    setHandleApiCall,
    initiative,
  } = props;

  const [listener, setListener] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [formValueCollector, setFormValueCollector] = useState([]);
  const [newFormValueCollector, setNewFormValueCollector] = useState([]);
  const [staticFormObject, setStaticFormObject] = useState({});
  const [staticFormArray, setStaticFormArray] = useState([]);
  const [currentServiceEndDate, setCurrentServiceEndDate] = useState("");
  const [currentEnvironment, setCurrentEnvironment] = useState("");
  const [currentInitiative, setCurrentInitiative] = useState("");
  const [informationToSend, setInformationToSend] = useState([]);
  const [editedObject, setEditedObject] = useState({});
  const [customerEnterPriseIdValid, setCustomerEnterpriseIdValid] = useState(
    false
  );
  const [secondaryCustomerIdValid, setSecondaryCustomerIdValid] = useState(false);
  const [wbseCodeValid, setWbseCodeValid] = useState(false);
  const [
    serviceEndDateByEnvironment,
    setServiceEndDateByEnvironment,
  ] = useState("");
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [validationSED, setValidationSED] = useState(false);
  const [serviceEndDateNoValid, setServiceEndDateNoValid] = useState(false);
  const [currentNotesValue, setCurrentNotesValue] = useState(undefined);
  const [currentProjectValue, setCurrentProjectValue] = useState(undefined);
  const [notesLenght, setNotesLenght] = useState(undefined);
  const [projectLenght, setProjectLenght] = useState(undefined);
  const [firstSpace, setFirstSpace] = useState(false);
  const [firstSpaceForProject, setFirstSpaceForProject] = useState(false);

  const [, setServiceEndDate] = useState();

  useEffect(() => {
    if (("notes" in item)==false){
      setCurrentNotesValue("");
      setNotesLenght("0");
    } else {
      setCurrentNotesValue(item.notes);
      setNotesLenght(item.notes.length);
    }
  }, [handleApiCall]);

  useEffect(() => {
    if (("projectname" in item)==false){
      setCurrentProjectValue("");
      setProjectLenght("0");
    } else {
      setCurrentProjectValue(item.projectname);
      setProjectLenght(item.projectname.length);
    }
  }, [handleApiCall]);

  let getMaxDate = (date) => {
    const maxDateEnv = moment()
      .add(date, "days")
      .format("YYYY-MM-DD");
    return maxDateEnv;
  };

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    const environmentArray = ["Dev", "Development", "dev", "development", "Test", "test", "sit", "SIT", "Sit", "Non-Prod", "Sales", "sales", "Demo", "demo", "Production", "production", "prod", "Prod"];
    setMinDate(currentDate);
    if (currentEnvironment) {
      setServiceEndDateByEnvironment(currentEnvironment);
    } else {
      let environmentUppercase = "";
      //if (item.environment !== undefined) {
      if (environmentArray.includes(item.environment) === true) {
        environmentUppercase =
          item.environment.charAt(0).toUpperCase() + item.environment.slice(1);
      } else {
        environmentUppercase = "N/A";
      }
      setServiceEndDateByEnvironment(environmentUppercase);
    }
    if (serviceEndDateByEnvironment) {
      if (serviceEndDateByEnvironment === "Dev" || serviceEndDateByEnvironment === "Development" || serviceEndDateByEnvironment === "dev" || serviceEndDateByEnvironment === "development") {
        const endDate = getMaxDate(28);
        setCurrentServiceEndDate("");
        setValidationSED(!validationSED);
        setMaxDate(endDate);
        informationToSend.forEach((element) => {
          if (element.Key === "service_end_date" || element.Key === "ServiceEndDate" ) {
            if(("serviceEndDate" in item)==false){
              element.Value = currentDate;
            } else {
              element.Value = item.serviceEndDate;
            }
          }
        });
      } else if (serviceEndDateByEnvironment === "Test" || serviceEndDateByEnvironment === "test" || serviceEndDateByEnvironment === "sit"|| serviceEndDateByEnvironment === "SIT"|| serviceEndDateByEnvironment === "Sit"|| serviceEndDateByEnvironment === "Non-Prod") {
        const endDate = getMaxDate(15);
        setCurrentServiceEndDate("");
        setValidationSED(!validationSED);
        setMaxDate(endDate);
        informationToSend.forEach((element) => {
          if (element.Key === "service_end_date" || element.Key === "ServiceEndDate") {
            if(("serviceEndDate" in item)==false){
              element.Value = currentDate;
            } else {
              element.Value = item.serviceEndDate;
            }
          }
        });
      } else if (serviceEndDateByEnvironment === "Sales" || serviceEndDateByEnvironment === "sales"|| serviceEndDateByEnvironment === "Demo"|| serviceEndDateByEnvironment === "demo") {
        const endDate = getMaxDate(60);
        setCurrentServiceEndDate("");
        setValidationSED(!validationSED);
        setMaxDate(endDate);
        informationToSend.forEach((element) => {
          if (element.Key === "service_end_date" || element.Key === "ServiceEndDate") {
            if(("serviceEndDate" in item)==false){
              element.Value = currentDate;
            } else {
              element.Value = item.serviceEndDate;
            }
          }
        });
      } else if (serviceEndDateByEnvironment === "N/A" || serviceEndDateByEnvironment === "Others") {
        const endDate = getMaxDate(15);
        setCurrentServiceEndDate("");
        setValidationSED(!validationSED);
        setMaxDate(endDate);
        informationToSend.forEach((element) => {
          if (element.Key === "service_end_date" || element.Key === "ServiceEndDate") {
            if(("serviceEndDate" in item)==false){
              element.Value = currentDate;
            } else {
              element.Value = item.serviceEndDate;
            }
          }
        });
      } else if (serviceEndDateByEnvironment === "Production" || serviceEndDateByEnvironment === "production"|| serviceEndDateByEnvironment === "prod"|| serviceEndDateByEnvironment === "Prod") {
        // let currentYear = moment().year();

        //const endDate = moment(`2025-12-25`, "YYYY-MM-DD").format("YYYY-MM-DD");
        const endDate = getMaxDate(60);
        setCurrentServiceEndDate("");
        setValidationSED(!validationSED);
        setMaxDate(endDate);
        informationToSend.forEach((element) => {
          if (element.Key === "service_end_date" || element.Key === "ServiceEndDate") {
            if(("serviceEndDate" in item)==false){
              element.Value = currentDate;
            } else {
              element.Value = item.serviceEndDate;
            }
          }
        });
      }
    }
  }, [
    serverList,
    item,
    currentEnvironment,
    serviceEndDateByEnvironment,
    listener,
  ]);

   useEffect(() => {
    if (newFormValueCollector && newFormValueCollector.length > 0) {
      if (
        (newFormValueCollector &&
          newFormValueCollector[0] &&
          newFormValueCollector[0].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[1] &&
          newFormValueCollector[1].Value === "undefined/undefined/") ||
        (newFormValueCollector &&
          newFormValueCollector[2] &&
          newFormValueCollector[2].Value === "undefined/undefined/") ||
        (newFormValueCollector &&
          newFormValueCollector[3] &&
          newFormValueCollector[3].Value === "undefined/undefined/") ||
        (newFormValueCollector &&
          newFormValueCollector[4] &&
          newFormValueCollector[4].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[5] &&
          newFormValueCollector[5].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[6] &&
          newFormValueCollector[6].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[7] &&
          newFormValueCollector[7].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[8] &&
          newFormValueCollector[8].Value === "") ||
        (newFormValueCollector &&
          newFormValueCollector[9] &&
          newFormValueCollector[9].Value === "undefined/undefined/") ||
        (newFormValueCollector &&
          newFormValueCollector[9] &&
          newFormValueCollector[9].Value === "undefined/undefined/")
      ) {
        setIsFormValid(true);
      } else if (
        newFormValueCollector &&
        newFormValueCollector[0] &&
        newFormValueCollector[0].Value === staticFormObject.CustomerEnterpriseID &&
        newFormValueCollector &&
        newFormValueCollector[1] &&
        newFormValueCollector[1].Value === staticFormObject.Environment &&
        newFormValueCollector &&
        newFormValueCollector[2] &&
        newFormValueCollector[2].Value === staticFormObject.Initiative &&
        newFormValueCollector &&
        newFormValueCollector[3] &&
        newFormValueCollector[3].Value === staticFormObject.SecondaryPOC &&
        newFormValueCollector &&
        newFormValueCollector[4] &&
        newFormValueCollector[4].Value === staticFormObject.WBSEcode &&
        newFormValueCollector &&
        newFormValueCollector[5] &&
        newFormValueCollector[5].Value === staticFormObject.clientName &&
        newFormValueCollector &&
        newFormValueCollector[6] &&
        newFormValueCollector[6].Value === staticFormObject.notes &&
        newFormValueCollector &&
        newFormValueCollector[7] &&
        newFormValueCollector[7].Value === staticFormObject.ProjectName &&
        newFormValueCollector &&
        newFormValueCollector[8] &&
        newFormValueCollector[8].Value === staticFormObject.service_end_date &&
        newFormValueCollector &&
        newFormValueCollector[8] &&
        newFormValueCollector[8].Value !== "undefined/undefined/"
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
      let objectToRedux = {};
      let infoToSendApiCall = [];
      for (const [index, iterator] of newFormValueCollector.entries()) {
        if (staticFormArray.length > 0) {
          if (iterator.Key === staticFormArray[index].Key) {
            if (
              iterator.Value === staticFormArray[index].Value ||
              iterator.Value === "" ||
              iterator.Value === "undefined/undefined/"
            ) {
            } else {
              objectToRedux = {
                ...objectToRedux,
                [iterator.Key]: iterator.Value,
              };
              infoToSendApiCall = [
                ...infoToSendApiCall,
                { Key: iterator.Key, Value: iterator.Value },
              ];
            }
          }
        }
        setEditedObject(objectToRedux);
        setInformationToSend(infoToSendApiCall);
      }
    }
  }, [newFormValueCollector, listener, staticFormArray, staticFormObject]);

  useEffect(() => {
    serverList.forEach((iterator) => {
      if (iterator.instanceId === item.instanceId) {
        if (("serviceEndDate" in item)==false){
          const currentDate = moment().format("DD-MM-YYYY");
          let [day, month, year] = currentDate.split("-");
          if (day && month && year) {
            setCurrentServiceEndDate(`${year}-${month}-${day}`);
          }
        } else {
          let [day, month, year] = iterator.serviceEndDate.split("/");
          if (day && month && year) {
            setCurrentServiceEndDate(`${year}-${month}-${day}`);
          }
        }
      }
    });
    if (item.Initiative === "mywizard.techarch.core" || item.Initiative === "mywizard.techarch.iemp"){
      if (handleApiCall === false && newFormValueCollector.length === 0) {
        setNewFormValueCollector([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSECode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "Notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "ServiceEndDate", Value: item.serviceEndDate },
        ]);
      }
      if (handleApiCall === false && formValueCollector.length === 0) {
        setFormValueCollector([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSECode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "Notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "ServiceEndDate", Value: item.serviceEndDate },
        ]);
      }
      if (handleApiCall === false && Object.keys(staticFormObject).length === 0) {
        setStaticFormObject({
          CustomerEnterpriseID: item.customerEnterpriseID,
          Environment: item.environment,
          Initiative: item.initiative,
          SecondaryPOC: item.secondarypoc,
          WBSECode: item.wbseCode,
          clientName: item.clientname,
          Notes: item.notes,
          ProjectName: item.projectname,
          ServiceEndDate: item.serviceEndDate,
        });
      }
      if (handleApiCall === false && staticFormArray.length === 0) {
        setStaticFormArray([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSECode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "Notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "ServiceEndDate", Value: item.serviceEndDate },
        ]);
      }

    } else {
      if (handleApiCall === false && newFormValueCollector.length === 0) {
        setNewFormValueCollector([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSEcode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "service_end_date", Value: item.serviceEndDate },
        ]);
      }
      if (handleApiCall === false && formValueCollector.length === 0) {
        setFormValueCollector([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSEcode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "service_end_date", Value: item.serviceEndDate },
        ]);
      }
      if (handleApiCall === false && Object.keys(staticFormObject).length === 0) {
        setStaticFormObject({
          CustomerEnterpriseID: item.customerEnterpriseID,
          Environment: item.environment,
          Initiative: item.initiative,
          SecondaryPOC: item.secondarypoc,
          WBSEcode: item.wbseCode,
          clientName: item.clientname,
          notes: item.notes,
          ProjectName: item.projectname,
          service_end_date: item.serviceEndDate,
        });
      }
      if (handleApiCall === false && staticFormArray.length === 0) {
        setStaticFormArray([
          { Key: "CustomerEnterpriseID", Value: item.customerEnterpriseID },
          { Key: "Environment", Value: item.environment },
          { Key: "Initiative", Value: item.initiative },
          { Key: "SecondaryPOC", Value: item.secondarypoc },
          { Key: "WBSEcode", Value: item.wbseCode },
          { Key: "clientName", Value: item.clientname },
          { Key: "notes", Value: item.notes },
          { Key: "ProjectName", Value: item.projectname },
          { Key: "service_end_date", Value: item.serviceEndDate },
        ]);
      }
    }
  }, [handleApiCall]);


  const handleEditKeyValue = (e, name) => {
    const { value } = e.target;
    let trimValue = value.trim();
    if (formValueCollector.find((element) => element.Key === name)) {

      setListener(!listener);
      formValueCollector.forEach((element, index) => {
        if (element.Key === name) {
          if (element.Key === "CustomerEnterpriseID") {
            let iEidValid = isEidValid(value);
            if (iEidValid) {
              newFormValueCollector[index].Value = trimValue;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setCustomerEnterpriseIdValid(false);
            } else {
              newFormValueCollector[index].Value = "";
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setCustomerEnterpriseIdValid(true);
            }
          } else if (element.Key === "Environment") {
            setCurrentEnvironment(value);
            newFormValueCollector[index].Value = trimValue;
            let newTagsArr = newFormValueCollector;
            setNewFormValueCollector([]);
            setNewFormValueCollector(newTagsArr);
            setListener(!listener);
          } else if (element.Key === "Initiative") {
            setCurrentInitiative(value);
            newFormValueCollector[index].Value = trimValue;
            let newTagsArr = newFormValueCollector;
            setNewFormValueCollector([]);
            setNewFormValueCollector(newTagsArr);
            setListener(!listener);
          }
          else if (element.Key === "SecondaryPOC") {
            let isSecondaryCustomerIdValid = checkSecondaryCustomerID(value);
            if (isSecondaryCustomerIdValid) {
              newFormValueCollector[index].Value = trimValue;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setSecondaryCustomerIdValid(false);
            } else {
              newFormValueCollector[index].Value = "";
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setSecondaryCustomerIdValid(true);
            }
          } else if (element.Key === "WBSEcode" || element.Key === "WBSECode") {
            let isWBSEValid = checkWBSECode(value);
            if (isWBSEValid) {
              newFormValueCollector[index].Value = trimValue;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setWbseCodeValid(false);
            } else {
              newFormValueCollector[index].Value = "";
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setWbseCodeValid(true);
            }
          } else if (element.Key === "clientName") {
            if (trimValue) {
              newFormValueCollector[index].Value = trimValue;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
            } else {
              newFormValueCollector[index].Value = "";
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
            }
          } else if (element.Key === "notes" || element.Key === "Notes") {
            let isNotesValid = checkNotesValue(value);
            if (
              isNotesValid &&
              e.target.value.length > 0 &&
              e.target.value.length <= 247
            ) {
              newFormValueCollector[index].Value = e.target.value;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setCurrentNotesValue(e.target.value);
              setNotesLenght(e.target.value.length);
              setFirstSpace(false);
            } else {
              if (e.target.value === "") {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentNotesValue(e.target.value);
                setNotesLenght(0);
                setFirstSpace(true);
              } else if (e.target.value === " ") {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentNotesValue(e.target.value);
                setNotesLenght(e.target.value);
              } else if (e.target.value.length > 247) {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentNotesValue(e.target.value);
                setNotesLenght(e.target.value.length);
              }
            }
          } else if (element.Key === "ProjectName") {
            let isProjectValid = checkProjectValue(value);
            if (
              isProjectValid &&
              e.target.value.length > 0 &&
              e.target.value.length <= 32
            ) {
              newFormValueCollector[index].Value = e.target.value;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setCurrentProjectValue(e.target.value);
              setProjectLenght(e.target.value.length);
              setFirstSpaceForProject(false);
            } else {
              if (e.target.value === "") {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentProjectValue(e.target.value);
                setProjectLenght(0);
                setFirstSpaceForProject(false);
              } else if (e.target.value === " ") {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentProjectValue(e.target.value);
                setProjectLenght(e.target.value);
              } else if (e.target.value.length > 32) {
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentProjectValue(e.target.value);
                setProjectLenght(e.target.value.length);
              }
            }
          } else if (element.Key === "service_end_date" || element.Key === "ServiceEndDate") {
            const [year, month, day] = trimValue.split("-");
            const [maxYear, maxMonth, maxDay] = maxDate.split("-");
            const [minYear, minMonth, minDay] = minDate.split("-");

            let dateFormat = `${day}/${month}/${year}`;
            setServiceEndDate(dateFormat);
            if (staticFormObject.service_end_date !== dateFormat) {
              let maxDateValidation = new Date(maxYear, maxMonth - 1, maxDay);
              let minDateValidation = new Date(minYear, minMonth - 1, minDay);
              let dateFormatValidation = new Date(year, month - 1, day);
              if (dateFormat === "undefined/undefined/") {
                newFormValueCollector[index].Value = dateFormat;
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentServiceEndDate("");
                setServiceEndDateNoValid(true);
              } else if (
                dateFormatValidation >= minDateValidation &&
                dateFormatValidation <= maxDateValidation
              ) {
                newFormValueCollector[index].Value = dateFormat;
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentServiceEndDate(trimValue);
                setServiceEndDateNoValid(false);
              } else {
                setServiceEndDateNoValid(true);
                newFormValueCollector[index].Value = "";
                let newTagsArr = newFormValueCollector;
                setNewFormValueCollector([]);
                setNewFormValueCollector(newTagsArr);
                setListener(!listener);
                setCurrentServiceEndDate(trimValue);
              }
            } else {
              newFormValueCollector[index].Value = dateFormat;
              let newTagsArr = newFormValueCollector;
              setNewFormValueCollector([]);
              setNewFormValueCollector(newTagsArr);
              setListener(!listener);
              setCurrentServiceEndDate(trimValue);
              setServiceEndDateNoValid(false);
            }
          }
        }
      });
    } else {
      setListener(!listener);
      setNewFormValueCollector([
        ...newFormValueCollector,
        { Key: name, Value: trimValue },
      ]);
    }
    setListener(!listener);
  };

  const handleOnSubmit = (e, accountId, accountType, privateDNS, inititive) => {
    e.preventDefault();
    editTags(editedObject, currentId);
    handleClose(false);
    tokenValidation();
    setHandleApiCall(true);
    setListener(false);
    setIsFormValid(true);
    setFormValueCollector([]);
    setNewFormValueCollector([]);
    setStaticFormObject({});
    setStaticFormArray([]);
    setCurrentServiceEndDate();
    setCurrentEnvironment("");
    setCurrentInitiative("");
    setInformationToSend([]);
    setEditedObject({});
    setCustomerEnterpriseIdValid(false);
    setSecondaryCustomerIdValid(false);
    setWbseCodeValid(false);
    setServiceEndDateByEnvironment("");
    setMinDate(undefined);
    setMaxDate(undefined);
    setValidationSED(false);
    setServiceEndDateNoValid(false);
    setCurrentNotesValue(undefined);
    setCurrentProjectValue(undefined);
    setNotesLenght(undefined);
    setProjectLenght(undefined);
    let url = `${base.urls.apis.ec2}/${currentId}/modify_tags?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}`;
    Axios.post(url, informationToSend, {
      headers: { Authorization: idToken },
    });
  };

  const handleCancel = () => {
    handleClose(false);
    tokenValidation();
    setHandleApiCall(true);
    setListener(false);
    setIsFormValid(true);
    setFormValueCollector([]);
    setNewFormValueCollector([]);
    setStaticFormObject({});
    setStaticFormArray([]);
    setCurrentServiceEndDate();
    setCurrentEnvironment("");
    setCurrentInitiative("");
    setInformationToSend([]);
    setEditedObject({});
    setCustomerEnterpriseIdValid(false);
    setSecondaryCustomerIdValid(false);
    setWbseCodeValid(false);
    setServiceEndDateByEnvironment("");
    setMinDate(undefined);
    setMaxDate(undefined);
    setValidationSED(false);
    setServiceEndDateNoValid(false);
    setCurrentNotesValue(undefined);
    setCurrentProjectValue(undefined);
    setNotesLenght(undefined);
    setProjectLenght(undefined);
  };

  const onKeyUpFalse = (e) => {
    e.preventDefault();
    return false;
  };

  const onKeyDownFalse = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <CommonDialog
      open={open}
      onClose={() => handleCancel()}
      title={"EDIT"}
      dialogType={"servers"}
      dialogContent={
        item && (
          <div>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Customer Enterprise ID:</Pharagraph>
              </PharagraphWrapper>
              <StyledTextFieldForm
                width={"100%"}
                customerenterpriseidcy="CustomerEnterpriseIdCy"
                defaultValue={item.customerEnterpriseID}
                margin="dense"
                variant="outlined"
                onChange={(e) => handleEditKeyValue(e, "CustomerEnterpriseID")}
                error={customerEnterPriseIdValid}
                helperText={
                  customerEnterPriseIdValid &&
                  "Enter a valid Customer Enterprise ID"
                }
              />
            </Flex>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Secondary POC:</Pharagraph>
              </PharagraphWrapper>
              <StyledTextFieldForm
                defaultValue={item.secondarypoc}
                margin="dense"
                variant="outlined"
                onChange={(e) => handleEditKeyValue(e, "SecondaryPOC")}
                error={secondaryCustomerIdValid}
                helperText={
                  secondaryCustomerIdValid && "Please provide a valid Secondary POC."
                }
              />
            </Flex>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Environment:</Pharagraph>
              </PharagraphWrapper>
              <StyledFormControl variant="outlined" margin="dense" style={{marginLeft:"25%"}}>
                  <InputLabel htmlFor="outlined-service-group">Environment</InputLabel>
                  <StyledSelect
                    defaultValue={item.environment}
                    onChange={(e) => handleEditKeyValue(e, "Environment")}
                    label="Environment"
                    margin="dense"
                    variant="outlined"
                  >
                  <MenuItem value="">
                      <em>--Select environment--</em>
                  </MenuItem>
                  { item.environment === "Production" ? 
                    <MenuItem value="Production">Production</MenuItem> :
                    <MenuItem value="production">Production</MenuItem>
                  } 
                  <MenuItem value="dev">Dev</MenuItem>
                  <MenuItem value="uat">Uat</MenuItem>
                  <MenuItem value="sales">Sales</MenuItem>
                  <MenuItem value="test">Test</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            </Flex>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Initiative:</Pharagraph>
              </PharagraphWrapper>
              <StyledFormControl variant="outlined" margin="dense" style={{marginLeft:"25%"}}>
                  <InputLabel htmlFor="outlined-service-group">Initiative</InputLabel>
                  <StyledSelect
                    defaultValue={item.initiative}
                    onChange={(e) => handleEditKeyValue(e, "Initiative")}
                    label="Initiative"
                    margin="dense"
                    variant="outlined"
                  >
                  <MenuItem value="">
                      <em>--Select initiative--</em>
                  </MenuItem>
                  <MenuItem value="Agile Capability">Agile Capability</MenuItem>
                  <MenuItem value="Change Enablement">Change Enablement</MenuItem>
                  <MenuItem value="FORM, Methods & Estimators">FORM, Methods & Estimators</MenuItem>
                  <MenuItem value="Language Translation Services">Language Translation Services</MenuItem>
                  <MenuItem value="Living Systems Advisory">Living Systems Advisory</MenuItem>
                  <MenuItem value="1AAA">1AAA</MenuItem>
                  <MenuItem value="DevOps Capability">DevOps Capability</MenuItem>
                  <MenuItem value="ICS">ICS</MenuItem>
                  <MenuItem value="IEMP">IEMP</MenuItem>
                  <MenuItem value="myDiagnostic">myDiagnostic</MenuItem>
                  <MenuItem value="myIndustry">myIndustry</MenuItem>
                  <MenuItem value="myMDI">myMDI</MenuItem>
                  <MenuItem value="myNav">myNav</MenuItem>
                  <MenuItem value="mySP">mySP</MenuItem>
                  <MenuItem value="myWizard Apps">myWizard - Other Apps</MenuItem>
                  <MenuItem value="myWizard AI Apps">myWizard AI Apps</MenuItem>
                  <MenuItem value="myWizard_AiOps">Mywizard AiOps</MenuItem>
                  <MenuItem value="myWizard Governance">Mywizard Governance</MenuItem>
                  <MenuItem value="myWizard Platform Mgt">Mywizard Platform Mgt</MenuItem>
                  <MenuItem value="myWizard SaaS">myWizard SaaS</MenuItem>
                  <MenuItem value="myWizard SI">myWizard SI</MenuItem>
                  <MenuItem value="Technical architecture">Technical architecture</MenuItem>
                  <MenuItem value="Transformation Office">Transformation Office</MenuItem>
                  <MenuItem value="UX">UX</MenuItem>
                  <MenuItem value="Automation Deployment">Automation Deployment</MenuItem>
                  <MenuItem value="Sales & Solutions">Automation Sales & Solution</MenuItem>
                  <MenuItem value="PMO">PMO</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            </Flex>
           
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>WBSE Code:</Pharagraph>
              </PharagraphWrapper>
              {(item.Initiative === "mywizard.techarch.core" || item.Initiative === "mywizard.techarch.iemp") ? (
                <StyledTextFieldForm
                  defaultValue={item.wbseCode}
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => handleEditKeyValue(e, "WBSECode")}
                  error={wbseCodeValid}
                  helperText={wbseCodeValid && "Please provide a valid WBSE Code."}
                />):(
                <StyledTextFieldForm
                  defaultValue={item.wbseCode}
                  margin="dense"
                  variant="outlined"
                  onChange={(e) => handleEditKeyValue(e, "WBSEcode")}
                  error={wbseCodeValid}
                  helperText={wbseCodeValid && "Please provide a valid WBSE Code."}
                />
              )} 
            </Flex>

            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Service End Date:</Pharagraph>
              </PharagraphWrapper>
              {(item.Initiative === "mywizard.techarch.core" || item.Initiative === "mywizard.techarch.iemp") ? (
              <StyledTextFieldForm
                defaultValue={currentServiceEndDate}
                type="date"
                margin="dense"
                variant="outlined"
                error={serviceEndDateNoValid}
                helperText={
                  serviceEndDateNoValid && "Please select a Valid Date"
                }
                onChange={(e) => handleEditKeyValue(e, "ServiceEndDate")}
                inputProps={{
                  min: minDate,
                  max: maxDate,
                  onKeyDown: onKeyDownFalse,
                  onKeyUp: onKeyUpFalse,
                }}
              />):(
                <StyledTextFieldForm
                defaultValue={currentServiceEndDate}
                type="date"
                margin="dense"
                variant="outlined"
                error={serviceEndDateNoValid}
                helperText={
                  serviceEndDateNoValid && "Please select a Valid Date"
                }
                onChange={(e) => handleEditKeyValue(e, "service_end_date")}
                inputProps={{
                  min: minDate,
                  max: maxDate,
                  onKeyDown: onKeyDownFalse,
                  onKeyUp: onKeyUpFalse,
                }}
              />
              )}
            </Flex>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Project Name:</Pharagraph>
              </PharagraphWrapper>
              <StyledTextFieldForm
                value={currentProjectValue}
                margin="dense"
                multiline
                rows={1}
                rowsMax={2}
                error={
                  projectLenght === 0 || projectLenght > 32 || firstSpaceForProject || projectLenght === 1 || projectLenght === 2 
                    ? true
                    : false
                }
                helperText={
                  projectLenght === 0 || projectLenght === 1 || projectLenght === 2 
                    ? "Please enter at least 3 character"
                    : firstSpaceForProject
                    ? "The First value cannot be a blank space"
                    : projectLenght > 32
                    ? `Maximum characters allowed 32 and you typed ${projectLenght}`
                    : `${projectLenght}/32`
                }
                variant="outlined"
                onChange={(e) => handleEditKeyValue(e, "ProjectName")}
              />
            </Flex>
            <Flex>
              <PharagraphWrapper>
                <Pharagraph>Notes:</Pharagraph>
              </PharagraphWrapper>
              {(item.Initiative === "mywizard.techarch.core" || item.Initiative === "mywizard.techarch.iemp") ? (
              <StyledTextFieldForm
                value={currentNotesValue}
                margin="dense"
                multiline
                rows={1}
                rowsMax={2}
                error={
                  notesLenght === 0 || notesLenght > 247 || firstSpace
                    ? true
                    : false
                }
                helperText={
                  notesLenght === 0
                    ? "Please enter at least 1 character"
                    : firstSpace
                    ? "The First value cannot be a blank space"
                    : notesLenght > 247
                    ? `Maximum characters allowed 247 and you typed ${notesLenght}`
                    : `${notesLenght}/247`
                }
                variant="outlined"
                onChange={(e) => handleEditKeyValue(e, "Notes")}
              /> ):(
                <StyledTextFieldForm
                value={currentNotesValue}
                margin="dense"
                multiline
                rows={1}
                rowsMax={2}
                error={
                  notesLenght === 0 || notesLenght > 247 || firstSpace
                    ? true
                    : false
                }
                helperText={
                  notesLenght === 0
                    ? "Please enter at least 1 character"
                    : firstSpace
                    ? "The First value cannot be a blank space"
                    : notesLenght > 247
                    ? `Maximum characters allowed 247 and you typed ${notesLenght}`
                    : `${notesLenght}/247`
                }
                variant="outlined"
                onChange={(e) => handleEditKeyValue(e, "notes")}
              />
              )}  
            </Flex>
          </div>
        )
      }
      dialogActions={
        <DialogActions>
          <ButtonWrapper>
            <DeclineButton
              onClick={() => handleCancel()}
              canceledittagsbuttoncy="cancelEditTagsButtonCy"
              label={"Cancel"}
            />
            <AcceptButton
              disabled={isFormValid ? true : false}
              saveedittagsbuttoncy="saveEditTagsButtonCy"
              label={"Save"}
              onClick={(e) =>
                handleOnSubmit(
                  e,
                  item.accountId,
                  item.accountType,
                  item.privateDNSName,
                  item.initiative
                )
              }
            />
          </ButtonWrapper>
        </DialogActions>
      }
    ></CommonDialog>
  );
};

EditTags.propTypes = {
  /**
   * Selected instance to which the tags will be edited.
   */
  item: PropTypes.object,
  /**
   * Boolean that opens or closes a dialog.
   */
  open: PropTypes.bool,
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Action, change the tags in the redux state
   */
  editTags: PropTypes.func,
  /**
   * Current instance Id.
   */
  currentId: PropTypes.string,
  /**
   * Funcion that close an Dialog
   */
  handleClose: PropTypes.func,
  /**
   * Boolean which causes API calls to be redone on the setTimeOut in Ec2ManagementComponent
   */
  setHandleApiCall: PropTypes.func,
  /**
   * Property used in a useEffect to update the state of the component
   */
  serverList: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    instancesNewTags: state.servers.instancesNewTags,
    serverList: state.servers.instancesNewTags,
    activeAccount: state.auth.activeAccount,
  };
};

// export default connect(mapStateToProps)(EditTags);

export default connect(mapStateToProps, null)(EditTags);
