import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import {
  AddUserButton,
  RevokeAccessButton,
  StyledIconButton,
  HoverTableRow,
  //   RevokeButtonSshManagement,
} from "../SshStyles";

import { Subtext } from "../../common/CommonStyles/CommonTypographyStyles";
import theme from "../../../theme/theme";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import TableHead from "@material-ui/core/TableHead";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { FormHelperText } from "@material-ui/core";
import RevokeAccessDialog from "./RevokeAccessDialog";
import AddUsersDialog from "./AddUsersDialog";

/**
 * Renders each Table row with the ec2 data.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: SSHUserManagement
 */

const SSHUserManagementTable = ({ ec2Data, email, idToken }) => {
  const [open, setOpen] = React.useState(false);
  const [openRevokeUser, setOpenRevokeUser] = useState(false);
  const [openAddUsers, setOpenAddUsers] = useState(false);
  const [user, setUser] = useState("");

  var row = ec2Data["ssh_user"];

  //Handles closing of both AddUsersDialog and RevokeAccessDialog
  const handleClose = () => {
    setOpenRevokeUser(false);
    setOpenAddUsers(false);
  };

  const handleOpenDialog = (user) => {
    setOpenRevokeUser(true);
    setUser(user);
  };

  //Gets the corresponding color of the instance status.
  const getStatusColor = (ele) => {
    return ele.instanceState === "running"
      ? theme.status.running
      : ele.instanceState === "stopped"
      ? theme.status.stopped
      : theme.status.pending;
  };

  return (
    <React.Fragment>
      <HoverTableRow key={ec2Data.orderid}>
        <TableCell
          style={{
            borderLeft: "10px solid" + getStatusColor(ec2Data),
          }}
          align="center"
        >
          <Subtext>{ec2Data.clusterId}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext>{ec2Data.privateDNSName}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext>{ec2Data.serviceEndDate}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext>{ec2Data.clientname === "myWizard AiOps" ? "GenWizard AiOps" : ec2Data.clientname}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext style={{ color: getStatusColor(ec2Data) }}>
            {ec2Data.instanceState}
          </Subtext>
        </TableCell>
        <TableCell key={ec2Data.orderId}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon data-cy="keyboardArrowUpIcon" />
            ) : (
              <KeyboardArrowDownIcon data-cy="keyboardArrowDownIcon" />
            )}
          </IconButton>
        </TableCell>
      </HoverTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6" gutterBottom component="div">
                    SSH Users
                  </Typography>
                  <AddUserButton
                    variant="outlined"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    onClick={() => setOpenAddUsers(true)}
                    disabled={ec2Data.instanceState === "stopped"}
                  >
                    Add SSH User
                  </AddUserButton>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {ec2Data.instanceState === "stopped" && (
                    <FormHelperText error={true}>
                      <div>You cannot add or revoke users </div>
                      <div>if instance is in stopped state</div>
                    </FormHelperText>
                  )}
                </div>
              </div>
              {/* Inner table behind the collapsable icon for each instance */}
              <Table size="small" aria-label="purchases" data-cy="sshUserTable">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {row.length !== 0 && <TableCell>User Name</TableCell>}
                    {row.length !== 0 && <TableCell>User Email</TableCell>}
                    <TableCell>
                      <AddUsersDialog
                        ec2Data={ec2Data}
                        openAdd={openAddUsers}
                        handleClose={handleClose}
                        email={email}
                        users={row[0]}
                        idToken={idToken}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.length > 0 &&
                    row[0].map(
                      (innerRow) =>
                        innerRow && (
                          <HoverTableRow key={ec2Data.orderid + innerRow}>
                            <TableCell component="th" scope="row">
                              <StyledIconButton
                                // borderradius="0"
                                margin="12px"
                                onClick={() => handleOpenDialog(innerRow)}
                                disabled={ec2Data.instanceState === "stopped"}
                              >
                                <RevokeAccessButton
                                  color={"revoke"}
                                  variant="outlined"
                                  disabled={ec2Data.instanceState === "stopped"}
                                >
                                  Revoke
                                </RevokeAccessButton>
                              </StyledIconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {innerRow.split(".").join(" ")}
                            </TableCell>
                            <TableCell>{innerRow + "@accenture.com"}</TableCell>
                            <TableCell>
                              <RevokeAccessDialog
                                email={email}
                                epid={user}
                                openRevoke={openRevokeUser}
                                handleClose={handleClose}
                                instanceId={ec2Data.instanceId}
                                accountType={ec2Data.accountType}
                                accountId={ec2Data.accountId}
                              ></RevokeAccessDialog>
                            </TableCell>
                          </HoverTableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

SSHUserManagementTable.propTypes = {
  /**
   * An instance owned by the user
   */
  ec2Data: PropTypes.object,
  /**
   * Current users email
   */
  email: PropTypes.string,
};

export default SSHUserManagementTable;
