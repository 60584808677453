import axios from "axios";
import { headers } from "../../helper/api";
import * as authTypes from "./authTypes";
import config from "../../config";

export const getUserDetails = (idToken) => (dispatch, getState) => {
  const activeAccount = getState().auth.activeAccount;
  let getUserDetailsUrl = " ";
  if (window.location.pathname.includes("auth1") || window.location.pathname.includes("recommendations")) {
    getUserDetailsUrl = config.urls.apis.recomAuth;
  } else if (window.location.pathname.includes("auth2") || window.location.pathname.includes("approvals")) {
    getUserDetailsUrl = config.urls.apis.approvalsAuth;
  } else if (window.location.pathname.includes("auth3") || window.location.pathname.includes("serverReview")) {
    getUserDetailsUrl = config.urls.apis.serverReviewAuth;
  } else {
    getUserDetailsUrl = config.urls.apis.auth;
  }
  return axios
    .get(getUserDetailsUrl, {
      headers: {
        ...headers,
        Authorization: idToken,
      },
      params: { inititive: activeAccount },
    })
    .then((res) => {
      res.data.profile = res.data.profile === undefined ? "" : res.data.profile;
      var copyData = res.data.profile;

      let array;
      if (copyData[0] === "[") {
        array = res.data.profile
          .slice(1, -1)
          .replace(" ", "")
          .split(",");
      } else {
        array = res.data.profile.replace(" ", "").split(",");
      }

      for (var i = 0; i < array.length; i++) {
        var x = array[i].replace(/['"/@/&/#//]+/g, "").trim();
        array[i] = x;
      }
      res.data.profile = array;

      let { exp } = res.data;
      let expirationDate_number = parseInt(exp, 10);
      const currentTime = new Date().getTime() / 1000;
      let currenTime_number = parseInt(currentTime, 10);

      let isAuthenticated = false;
      if (window.location.pathname.includes("auth1") || window.location.pathname.includes("recommendations")) {
        if (currenTime_number < expirationDate_number) {
          isAuthenticated = true;
        } else if (currenTime_number > expirationDate_number) {
          window.location.href = process.env.REACT_APP_RECOMMENDATIONS_URL;
          localStorage.clear();
          return false;
        } else {
          window.location.href = process.env.REACT_APP_RECOMMENDATIONS_URL;
          localStorage.clear();
          return false;
        }
      } else if (window.location.pathname.includes("auth2") || window.location.pathname.includes("approvals")) {
        if (currenTime_number < expirationDate_number) {
          isAuthenticated = true;
        } else if (currenTime_number > expirationDate_number) {
          window.location.href = process.env.REACT_APP_APPROVALS_URL;
          localStorage.clear();
          return false;
        } else {
          window.location.href = process.env.REACT_APP_APPROVALS_URL;
          localStorage.clear();
          return false;
        }
      } else if (window.location.pathname.includes("auth3") || window.location.pathname.includes("serverReview")) {
        if (currenTime_number < expirationDate_number) {
          isAuthenticated = true;
        } else if (currenTime_number > expirationDate_number) {
          window.location.href = process.env.REACT_APP_SERVER_REVIEW_URL;
          localStorage.clear();
          return false;
        } else {
          window.location.href = process.env.REACT_APP_SERVER_REVIEW_URL1;
          localStorage.clear();
          return false;
        }
      } else {
        if (currenTime_number < expirationDate_number) {
          isAuthenticated = true;
        } else if (currenTime_number > expirationDate_number) {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
          localStorage.clear();
          return false;
        } else {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
          localStorage.clear();
          return false;
        }
      }
      dispatch({
        type: authTypes.inititive,
        data: res.data.profile,
      });

      dispatch({
        type: authTypes.SAVE_USER_DETAILS,
        data: res.data,
        isAuthenticated,
      });
    })
    .catch((err) => {
      if (window.location.pathname.includes("auth1") || window.location.pathname.includes("recommendations")) {
        window.location.href = process.env.REACT_APP_RECOMMENDATIONS_URL;
        localStorage.clear();
        console.log(err);
      } else if (window.location.pathname.includes("auth2") || window.location.pathname.includes("approvals")) {
        window.location.href = process.env.REACT_APP_APPROVALS_URL;
        localStorage.clear();
        console.log(err);
      } else if (window.location.pathname.includes("auth3") || window.location.pathname.includes("serverReview")) {
        window.location.href = process.env.REACT_APP_SERVER_REVIEW_URL;
        localStorage.clear();
        console.log(err);
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
        localStorage.clear();
        console.log(err);
      }
    });
};

export const signOut = () => ({
  type: authTypes.SIGNIN_SUCCESS,
});

export const saveTokens = (tokenObj) => ({
  type: authTypes.SAVE_TOKENS,
  data: tokenObj,
});

export const getAuthorizationPermissions = (email, adRole, idToken) => (
  dispatch,
  getState
) => {
  //const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const newAdRole = Array.isArray(adRole) ? adRole : [adRole];
  return axios
    .post(
      config.urls.apis.rbac +
      `?customer_enterprise_id=${eId}&inititive=${activeAccount}`,
      newAdRole,
      {
        headers: { ...headers, Authorization: `Bearer ${idToken}` },
      }
    )
    .then((res) => {
      localStorage.setItem(
        "userRoleBasedAccessControl",
        JSON.stringify({ data: res.data })
      );
      dispatch({
        type: authTypes.CAM_PERMISSIONS,
        data: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
