/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  SuccessWrapper,
  StyledAvatar,
  ToAccessTypography,
  ProgressWrapper,
  PendingApprovalWrapper,
  FinalWrapper,
} from "../SshStyles";
import RemoveIcon from "@material-ui/icons/Remove";

import { CircularProgress, Grid, TextField } from "@material-ui/core";
import { tokenValidation } from "../../../helper/customizedHooks";
import base from "../../../config";
import SSHCards from "../SshCommon/SSHCards";
import { getOptions } from "../../../helper/api";
import PropTypes from "prop-types";
import {
  AcceptButton,
  DeclineButton,
} from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
import theme from "../../../theme/theme";
import { useSSHMakeStyles } from "../SSHMakeStyles";
/**
 * Renders the pending cards and handles approvals/declines
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: Ssh.js
 */

const PendingSshApprovals = ({
  idToken,
  data,
  loading,
  reload,
  activeAccount,
}) => {
  const classes = useSSHMakeStyles();

  const [checkedItems, setCheckedItems] = useState({});
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [approveDeclineNotes, setApproveDeclineNotes] = useState("");
  const [approveAccountId, setApprovedAccountId] = useState("");
  const [declineAccountId, setDeclinedAccountId] = useState("");

  //headers for axios calls
  getOptions.headers["Authorization"] = idToken;

  const url = `${base.urls.apis.sshNew}requests`;

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const declineAction = (element, accountId) => {
    setOpen(true);
    setCheckedItems(element);
    setDeclinedAccountId(accountId);
  };

  const approveAction = (element, accountId) => {
    setOpen2(true);
    setCheckedItems(element);
    setApprovedAccountId(accountId);
  };

  const acceptDeclineButton = () => {
    tokenValidation();
    let reqId = checkedItems.request_id;
    let accountId = declineAccountId;
    const url3 = `${url}/${reqId}/decline?notes=${
      approveDeclineNotes ? approveDeclineNotes : ""
    }&inititive=${activeAccount}&accountId=${accountId}`;

    axios.get(url3, getOptions).catch((error) => {
      console.log(error);
    });

    setCheckedItems({});
    setOpen(false);
    reload(true);
    setApproveDeclineNotes("");
  };

  const handleApproveClick = () => {
    tokenValidation();

    let reqId = checkedItems.request_id;
    let accountId = approveAccountId;
    const url3 = `${url}/${reqId}/approve?notes=${
      approveDeclineNotes ? approveDeclineNotes : ""
    }&inititive=${activeAccount}&accountId=${accountId}`;

    axios.get(url3, getOptions).catch((error) => {
      console.log(error);
    });

    setCheckedItems({});
    setOpen2(false);
    reload(true);
    setApproveDeclineNotes("");
  };

  return (
    <PendingApprovalWrapper>
      {loading ? (
        <ProgressWrapper>
          <CircularProgress style={{ color: `${theme.palette.ssh.main}` }} />
        </ProgressWrapper>
      ) : data && data.length === 0 ? (
        <FinalWrapper noPendingcy="nopending">
          <SuccessWrapper>
            <StyledAvatar>
              <RemoveIcon />
            </StyledAvatar>
            <ToAccessTypography>
              There is no pending approvals!
            </ToAccessTypography>
          </SuccessWrapper>
        </FinalWrapper>
      ) : (
        <div>
          <Grid container>
            {data.map((element, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  style={{ padding: "10px 20px 10px 20px" }}
                  key={i}
                >
                  <SSHCards
                    cardData={element}
                    cardType={"PendingApprovals"}
                    approvalButton={
                      <AcceptButton
                        value={data[i].account_id}
                        onClick={() => approveAction(element, data[i].account_id)}
                        label={"Approve"}
                        className={classes.PendingApprovalsApproveButton}
                        disabled={false}
                      />
                    }
                    declineButton={
                      <DeclineButton
                        value={data[i].account_id}
                        onClick={() => declineAction(element, data[i].account_id)}
                        label={"Decline"}
                        className={classes.PendingApprovalsDeclineButton}
                      />
                    }
                  />
                </Grid>
              );
            })}
            <CommonDialog
              open={open2}
              onClose={handleClose}
              title={"Request Submit Successful"}
              textContent={"Your request will take up 3 minutes."}
              dialogType={"ssh"}
              dialogActions={
                <div style={{ padding: "0 24px 8px 24px" }}>
                  <div style={{ width: "100%", padding: "0 0 15px 0" }}>
                    <TextField
                      required
                      label="Notes"
                      variant="outlined"
                      placeholder={"Notes"}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setApproveDeclineNotes(e.target.value.trim())
                      }
                      error={approveDeclineNotes.length > 50 ? true : false}
                      helperText={
                        approveDeclineNotes.length > 50
                          ? `Maximum characters allowed 50 and you typed ${approveDeclineNotes.length}`
                          : `${approveDeclineNotes.length}/50`
                      }
                      // inputProps={{
                      //   className: classes.TextFieldNotes
                      // }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <AcceptButton
                      disabled={
                        approveDeclineNotes.length > 0 &&
                        approveDeclineNotes.length < 51
                          ? false
                          : true
                      }
                      onClick={handleApproveClick}
                      label={"Ok"}
                    />
                    <DeclineButton
                      onClick={handleClose}
                      label={"Close"}
                      marginLeftOn={".5em"}
                    />
                  </div>
                </div>
              }
            />
            <CommonDialog
              open={open}
              onClose={handleClose}
              title={"Confirm"}
              textContent={"Are you sure you want to decline?"}
              dialogType={"ssh"}
              dialogActions={
                <div style={{ padding: "0 24px 8px 24px" }}>
                  <div style={{ width: "100%", padding: "0 0 15px 0" }}>
                    <TextField
                      required
                      label="Notes"
                      variant="outlined"
                      placeholder={"Notes"}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setApproveDeclineNotes(e.target.value.trim())
                      }
                      error={approveDeclineNotes.length > 50 ? true : false}
                      helperText={
                        approveDeclineNotes.length > 50
                          ? `Maximum characters allowed 50 and you typed ${approveDeclineNotes.length}`
                          : `${approveDeclineNotes.length}/50`
                      }
                      // inputProps={{
                      //   className: classes.TextFieldNotes
                      // }}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <AcceptButton
                      disabled={
                        approveDeclineNotes.length > 0 &&
                        approveDeclineNotes.length < 51
                          ? false
                          : true
                      }
                      onClick={acceptDeclineButton}
                      label={"Agree"}
                    />
                    <DeclineButton
                      onClick={handleClose}
                      label={"Close"}
                      marginLeftOn={".5em"}
                    />
                  </div>
                </div>
              }
            />
          </Grid>
        </div>
      )}
    </PendingApprovalWrapper>
  );
};

PendingSshApprovals.propTypes = {
  /**
   * Boolean that confirms is the page is loading
   */
  loading: PropTypes.bool,
  /**
   * The complete data containing all requests of a user.
   */
  data: PropTypes.array,
  /**
   * Identification Token use for API access
   */
  idToken: PropTypes.string,
  /**
   * Passed into ssh to trigger a reload a few seconds after the approve/decline button.
   */
  reload: PropTypes.func,
};

const mapStateToProps = (state) => ({
  idToken: state.auth.idToken,
  isPendingLoading: state.ssh.isPendingLoading,
  activeAccount: state.auth.activeAccount,
});

export default connect(mapStateToProps, null)(PendingSshApprovals);
