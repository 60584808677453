import React, { useState, useEffect } from "react";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import { BoldTableCell, DetailTableWrapper } from "../FuiStyles";
import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import { appNameProjectId } from "../Constants/ProductIds";

/**
 * Details of each platform row
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: PlatformList
 */
const PlatformDetails = ({ data }) => {
  const [objectEntries, setObjectEntries] = useState([]);

  useEffect(() => {
    let splitTSED =
      data.TentativeServiceEndDate && data.TentativeServiceEndDate.split("T");
    let obj = {
      OrderUId: data.OrderItemUId,
      DomainContextRoot: data.DomainContextRoot,
      CustomerEnterpriseID: data.CustomerEnterpriseID,
      Environment: data.Environment,
      TentativeServiceEndDate: `${
        splitTSED ? `${splitTSED[0]}` : data.TentativeServiceEndDate
      } `,
      WBSEcode: data.WBSEcode,
      Status: data.Status,
      SAPContractID: data.SAPContractID,
      AccessLinkURL: data.AccessLinkURL,
      DeploymentRegion: data.DeploymentRegion,
      ClientName: data.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : data.ClientName,
      CustomerMobileNo: data.CustomerMobileNo,
      Notes: data.RequestNotes,
      GDPR: data.GDPR,
      EndToEndUId: data.EndToEndUId === "" ? data.DMSId : data.EndToEndUId,
      // I made this change to remove this tag for the frontEnd when the ProductUid === ATR
      ...(data.ProductUId !== appNameProjectId.automaticTicketResolverId && {
        ServiceGroup: data.ServiceGroup,
      }),
      // I made this change to remove this tag for the frontEnd when the ProductUid === ATR
      ...(data.ProductUId !== appNameProjectId.automaticTicketResolverId && {
        UsersDistributionGroup: data.UsersDistributionGroup,
      }),
      // I made this change to remove this tag for the frontEnd when the ProductUid === ATR
      ...(data.ProductUId !== appNameProjectId.automaticTicketResolverId && {
        AdminDistributionGroup: data.AdminDistributionGroup,
      }),
      // I made this change to remove this tag for the frontEnd when the ProductUid === StormSeer
      ...(data.ProductUId !== appNameProjectId.stormseerId && {
        ServiceGroup: data.ServiceGroup,
      }),
      // I made this change to remove this tag for the frontEnd when the ProductUid === StormSeer
      ...(data.ProductUId !== appNameProjectId.stormseerId && {
        UsersDistributionGroup: data.UsersDistributionGroup,
      }),
      // I made this change to remove this tag for the frontEnd when the ProductUid === StormSeer
      ...(data.ProductUId !== appNameProjectId.stormseerId && {
        AdminDistributionGroup: data.AdminDistributionGroup,
      }),
    };
    const dataArray = Object.entries(obj);
    setObjectEntries(dataArray);
  }, [data]);

  return (
    <DetailTableWrapper>
      <Table>
        <TableBody>
          {objectEntries.map((instance, key) => (
            // show the Key in first column and the Value in the second column
            <TableRow key={key}>
              <BoldTableCell>{upperFirst(instance[0])}</BoldTableCell>
              <TableCell>{instance[1]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DetailTableWrapper>
  );
};
PlatformDetails.propTypes = {
  /**
   * Data of platform
   */
  data: PropTypes.array,
};
export default PlatformDetails;
