import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledDatePicker, StyledDefaultTextField } from "../fui/FuiStyles";
import { Radio } from "@material-ui/core";
import moment from "moment";
import {
    Box,
    Typography,
    Stepper,
    Step,
    StepButton,
    Button,
    InputLabel,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
    DialogContent,
    DialogContentText,
    CircularProgress,
    InputAdornment,
    FormControl,
    RadioGroup
} from "@material-ui/core";
import { AcceptButton } from "../common/CommonComponents/Buttons";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import base from "../../config";
import { connect } from "react-redux";
import "./custom.css";
import { StyledFormControl, StyledSelect, StyledTextField } from "../fui/FuiStyles";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { StyledClickableElementForFile } from "../ec2management/ServerList/styledServerList";
import { checkWBSECode } from "../fui/forms/CommonFunctions";

/**
   * @author shilpa.adikeshava
   * @version 1.0
 */



const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",
        width: 720,
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        boxShadow: "unset",
        padding: "15px 32px 32px 32px"
    },
    requestOpenAIButton: {
        backgroundColor: theme.palette.admin.main,
        color: "white",
        float: "right"
    },
    actionButton: {
        border: "2px solid  #0041F0 !important",
        color: " #0041F0 !important",
        background: "white"
    },
    backButton: {
        borderColor: "rgb(158,158,158) !important",
        color: "rgb(158,158,158) !important"
    }
}));

const provisioningURL = process.env.REACT_APP_PROVISION_URL;

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const RequestForm = (props) => {
    const {
        email
    } = props;
    const classes = useStyles();
    const idToken = localStorage.getItem("token");


    const [open, setOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [file, setFile] = useState("");
    const [pptName, setPptName] = useState("");
    const [pptFile, setPPTFile] = useState(null);
    const [pptBase64URL, setPptBase64URL] = useState("");
    const [manager, setManager] = useState("");
    const [usecase, setUsecase] = useState("");
    const [requestType, setRequestType] = useState("");
    const [configurationType, setConfigurationType] = useState("client");
    const [wbseCode, setWbseCode] = useState("");
    const [sapContractID, setSAPContractID] = useState("");
    const [clientname, setClientname] = useState("myWizard AiOps");
    const [secondaryPoc, setSecondaryPoc] = useState("");
    const [atrURL, setAtrURL] = useState("");
    const [assetName, setAssetName] = useState("");

    const [loading, setLoading] = useState(false);
    const [finalSubmitLoading, setFinalSubmitLoading] = useState(true);
    const [finalSubmitError, setFinalSubmitError] = useState(true);


    const [isAllInputsCorrect, setIsAllInputsCorrect] = useState(false);
    const [isPPTValid, setIsPPTValid] = useState(false);
    const [isRequestTypeValid, setIsRequestTypeValid] = useState(false);
    const [isAtrURLValid, setIsAtrURLValid] = useState(false);
    const [isAssetnameValid, setIsAssetnameValid] = useState(false);
    const [isUsecaseValid, setIsUsecaseValid] = useState(false);
    const [isManagerValid, setIsManagerValid] = useState(false);
    const [isTCAgreed, setIsTCAgreed] = useState(false);
    const [isConfigurationTypeValid, setIsConfigurationTypeValid] = useState(false);
    const [isSAPContractIDValid, setIsSAPContractIDValid] = useState(true);
    const [isClientNameValid, setIsClientNameValid] = useState(false);

    const [atrVersion, setAtrVersion] = useState("");
    const [instanceState, setInstanceState] = useState("");
    const [instanceOwner, setInstanceOwner] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
    const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
    const [sapContractIDLoading, setSapContractIDLoading] = useState(false);

    const handleClose = () => {
        props.close()
    };

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = (step) => {
        if (step + 1 == "1") {
            if (requestType === "Other" && configurationType === "client") {
                if (isAssetnameValid && isWBSECodeValid && isSAPContractIDValid && wbseCode !== "" && sapContractID !== "" && secondaryPoc !== "") {
                    axiosCall();
                } else {
                    toast.error("Please input valid details in all the fields to continue.")
                    setIsAllInputsCorrect(false);
                }
            } else if (requestType === "Other" && configurationType === "internal") {
                if (isAssetnameValid && isWBSECodeValid && wbseCode !== "" && secondaryPoc !== "") {
                    axiosCall();
                } else {
                    toast.error("Please input valid details in all the fields to continue.")
                    setIsAllInputsCorrect(false);
                }
            } else if (requestType === "ATR") {
                if (isAtrURLValid) {
                    setLoading(true)
                    checkATRVersion(atrURL);
                    // axiosCall();
                } else {
                    toast.error("Please input ATR URL to proceed further.")
                    setIsAllInputsCorrect(false);
                }
            } else {
                toast.error("Please select request type to continue.")
                setIsAllInputsCorrect(false);
            }
        } else if (step + 1 == "4") {
            if (requestType === "Other") {
                if (isManagerValid && isUsecaseValid && isAssetnameValid && isPPTValid) {
                    axiosCall();
                } else {
                    toast.error("Please input all the fields!")
                    setIsAllInputsCorrect(false);
                }
            } else {
                if (isManagerValid && isUsecaseValid && isAtrURLValid && isPPTValid) {
                    axiosCall();
                } else {
                    toast.error("Please input all the fields!")
                    setIsAllInputsCorrect(false);
                }
            }
        } else {
            axiosCall();
        }


    };

    const handleComplete = () => {
        if (requestType === "ATR") {
            if (atrURL !== "") {
                checkATRVersion(atrURL);
            } else {
                toast.error("Please input ATR URL to proceed further.")
            }
        } else {
            handleCompleteOtherAppRequest();
        }
    }

    const handleCompleteATRRequest = () => {
        setFinalSubmitLoading(true)
        setFinalSubmitError(false)
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted({ 0: true, 1: true, 2: true, 3: true, 4: true });
        const formData = {
            OtherApp: "ATR",
            ATRurl: atrURL,
            CustomerEnterpriseID: email,
            // useCase: usecase,
        }
        const url = provisioningURL;

        if (isAtrURLValid) {
            setIsAllInputsCorrect(true);
            handleNext();

            //API call to store other details in DynamoDB
            Axios.post(url, formData, {
                headers: { "Content-Type": "application/json", Authorization: idToken },
                params: {
                    "CustomerEnterpriseID": email,
                }
            }).then((res) => {
                // console.log(res.data)
                setFinalSubmitLoading(false)
                setFinalSubmitError(false)
            }).catch((err) => {
                console.log("Async error", err);
                setFinalSubmitError(true)
            });
        } else {
            toast.error("Please read & agree to the terms & conditions below to proceed!")
            setIsAllInputsCorrect(false);
        }
    };

    const handleCompleteOtherAppRequest = () => {
        setFinalSubmitLoading(true)
        setFinalSubmitError(false)
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        const formData = new FormData();
        const url = `${base.urls.apis.azure}/openai-access`;
        const bucket = "openai-access-certificates"
        const pptToString = JSON.stringify(pptFile)
        const selectedFiles = { "pptfile": pptToString }
        if (isAssetnameValid && isUsecaseValid && isManagerValid && isTCAgreed && isPPTValid) {
            setIsAllInputsCorrect(true);
            handleNext();
            //API call to upload image to S3
            Axios.post(selectedFiles, {
                headers: { Authorization: idToken },
                params: {
                    "inititive": "na"
                }
            }).then((res) => {
                // console.log(res.data)
                setFinalSubmitLoading(false)
            }).catch((err) => {
                console.log("Async error", err);
            });
            //API call to store other details in DynamoDB
            Axios.post(url, formData, {
                headers: { Authorization: idToken },
                params: {
                    "assetName": assetName,
                    "managerName": manager,
                    "step": activeStep + 1,
                    "username": email,
                    "usecase": usecase,
                    "SAPContractID": sapContractID,
                    "WBSEcode": wbseCode,
                    "ClientName": clientname,
                    "SecondaryPOC": secondaryPoc,
                    "TentativeServiceEndDate": selectedDate,
                }
            }).then((res) => {
                // console.log(res.data)
                setFinalSubmitLoading(false)
                setFinalSubmitError(false)
            }).catch((err) => {
                console.log("Async error", err);
                setFinalSubmitError(true)
            });
        } else {
            toast.error("Please read & agree to the terms & conditions below to proceed!")
            setIsAllInputsCorrect(false);
        }

    };

    const axiosCall = () => {
        const formData = new FormData();
        const url = `${base.urls.apis.azure}/openai-access`;
        const bucket = "azureopenaiaccess"
        const ppturl = `${base.urls.apis.azure}/${bucket}`;
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
        const pptToString = JSON.stringify(pptFile)
        const selectedFiles = { "pptfile": pptToString }
        // //API call to upload image to S3
        Axios.post(ppturl, selectedFiles, {
            headers: { Authorization: idToken },
            params: {
                "inititive": "na"
            }
        }).then((res) => {
            // console.log(res.data)
            setFinalSubmitLoading(false)
        }).catch((err) => {
            console.log("Async error", err);
        });
        //API call to store other details in DynamoDB
        Axios.post(url, formData, {
            headers: { "Content-Type": "multipart/form-data", Authorization: idToken },
            params: {
                "assetName": assetName,
                "managerName": manager,
                "step": activeStep + 1,
                "usecase": usecase,
                "SAPContractID": sapContractID,
                "WBSEcode": wbseCode,
                "ClientName": clientname,
                "SecondaryPOC": secondaryPoc,
                "TentativeServiceEndDate": selectedDate,

            }
        }).then((res) => {
            // console.log(res.data)
        }).catch((err) => {
            console.log("Async error", err);
        });
    }

    const checkATRVersion = (url) => {
        setLoading(true);
        const urls = `${base.urls.apis.azure}/checkatrversion?ATRurl=${url}`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setAtrVersion(data.ATRversion)
            setInstanceState(data.InstanceState)
            setInstanceOwner(data.InstanceOwner)
            checkATRDetails(data);
            setLoading(false)
        }).catch((error) => {
            console.log('Error', error)
            setLoading(false)
        })
    };

    const checkATRDetails = (data) => {
        const version = data[0] ? parseInt(data[0].Atrversion) : ""
        if (data.message === "Not an Owner") {
            setErrorMsg("Oops! You don't have ownership rights for this ATR. For further assistance, kindly reach out to the instance owner or enter the ATR URL which you own.")
        } else if (data.message === "stopped") {
            setErrorMsg("Oops! This ATR is currently not running. Please start the instance and proceed with your request.")
        } else if (data.message === "Invalid URL") {
            setErrorMsg("Oops! You have entered an invalid ATR URL. Please enter a valid URL to proceed with your request.")
        } else if (data == null) {
            setErrorMsg("Oops! You have entered an invalid ATR URL. Please enter a valid URL to proceed with your request.")
        } else if (version >= 4) {
            setIsAtrURLValid(true)
            handleCompleteATRRequest();
        } else if (version < 4) {
            setErrorMsg("Oops! This ATR version is not compatible with OpenAI. Please upgrade by raising L3 support ticket. Thank you!")
        } else if (data[0].Atrversion === "N/A") {
            setErrorMsg("Oops! We are facing heavy traffic in our system, please try again later.")
        } else {
            setIsAtrURLValid(false)
            setErrorMsg("Oops! We are facing heavy traffic in our system, please try again later.")
        }
    };


    let list = "divya.rahangdale,other,allen.shi,alvin.sulendra,anjali.prakash,brian.lewkowicz,c.angsuwittaya,chloe.zhou,clement.bramy,dann.mendis,diego.sanches,dipankar.raybarman,eng.moorthy,j.a.shaw,jiayi.chen,k.gopalakrishna,karthik.kk,katherine.ann.shaw,kim.b.harris,leon.whine,lily.a.liu,luke.higgins,m.walmsley,manish.tejas,naveen.kalasamudram,niyaz.shaffi,pascal.mias,pavan.kumar.sure,pranav.x.chamoli,prashant.chaudhari,prathmesh.palvankar,qian.jin,rakesh.d.patel,saravana.babu.raman,sean.richer,sebastian.allocca,shri.kant.tyagi,srinivasa.kuruganti,subuu.konar,takashi.toda,vaibhav.suneja,waqas.ahmad,geraldo.c.filho,karen.mayr,marcio.montanheiro,roberto.t.rodrigues,eric.cong.wang,libo.lin,xinghua.zhou,yuanhui.sun,arun.vidyadharan,avinash.samal,badrinath.parameswar,bikram.a.chakraborty,deepak.x.kumar,glory.kaliappan,harsh.d.srivastava,jagrati.gupta,k.gopinath,krishnakumar.eledath,mohiuddin.khan,nitin.harale,raghavendra.ashok,ramakantha.ks,sairam.venkata,santhosh.narasipuram,senthil.k.c,shahid.ali,sherin.s.varghese,shikha.kumari,sindhu.bhaskaran,srinivas.boga,sriram.l.krishna,suresh.kakkalmeli,suresh.murapaka,vijaya.sharada.yara,vineet.a.singh,vinoth.sasikanthan,carlos.gomez.puig,emmy.godoy.reyes,oslin.romero,ricardo.garcia.cerda,christian.j.r.robles,edward.ryan.t.gaw,emmanuel.b.antonio,michelle.n.latoza,neo.y.bumanlag,princess.d.duran,riyadh.m.omar,rolando.d.de.guzman,dawid.dabrus,iraden.n.ramkhelawon,jean.pierre.haumey,vishal.siriduth,arinobu.shiobe,kumiko.tonegawa,masataka.suzaki,shogo.tohyama,takahiro.a.kobayashi,tingni.du,yasuhiro.takenaka,yuki.yonemori,melanie.di.iorio,samuel.gleeson,piyush.a.mahajan,ranjit.kavil,faith.k.montojo,yukio.suzuki"
    const managersList = list.split(",");
    const sortManagersList = managersList.sort();

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: idToken,
        },
    };

    const fetchFilePresignedUrl = (event) => {
        event.preventDefault();
        const urls = `${base.urls.apis.faq}/aiops_genai_workshop.pdf?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            window.open(data[0], "_blank");
        }).catch((error) => {
            console.log('Error', error)
        })
    };



    function checkRequestType(input) {
        if (!input || input === "") {
            toast.error('Please select request type.');
            setIsRequestTypeValid(false);
        } else {
            setIsRequestTypeValid(true);
            setRequestType(input)
        }
    }

    function checkAtrURL(input) {
        if (!input || input === "") {
            setIsAtrURLValid(false);
        } else {
            setIsAtrURLValid(true);
            setAtrURL(input)
        }
    }

    function checkManager(input) {
        if (!input || input === "") {
            setIsManagerValid(false);
        } else {
            setIsManagerValid(true);
            setManager(input)
        }
    }

    function checkUseCase(input) {
        if (!input || input === "") {
            setIsUsecaseValid(false);
        } else {
            setIsUsecaseValid(true);
            setUsecase(input)
        }
    }

    function checkAssetName(input) {
        if (!input || input === "") {
            setIsAssetnameValid(false);
        } else {
            setIsAssetnameValid(true);
            setAssetName(input)
        }
    }

    function checkConfigurationType(input) {
        if (!input || input === "") {
            setIsConfigurationTypeValid(false);
        } else {
            setIsConfigurationTypeValid(true);
            setConfigurationType(input)
        }
    }

    function checkSAPContractID(input) {
        document.getElementById("clientDetails").style.display = "none";
        if (!input || input === "") {
            setIsSAPContractIDValid(false);
        } else {
            setSAPContractID(input)
            if (input.length == 10) {
                const fetchFuiDetailsUrl = `${base.urls.apis.fulfillment}/client-details?contract_code=${input}`;
                setSapContractIDLoading(true)
                Axios.get(fetchFuiDetailsUrl, options).then((res) => {
                    const data = res.data
                    if (data[0] == "N/A") {
                        setSapContractIDLoading(false)
                        setIsSAPContractIDValid(false);
                        document.getElementById("clientDetails").style.display = "none";
                    } else {
                        setSapContractIDLoading(false)
                        setIsSAPContractIDValid(true);
                        setClientname(data[0])
                        document.getElementById("clientDetails").style.display = "block";
                    }
                }).catch((error) => {
                    console.log('Error', error)
                    setSapContractIDLoading(false)
                    setIsSAPContractIDValid(true);
                })
            } else {
                setIsSAPContractIDValid(false);
            }
            // setIsSAPContractIDValid(true);
            // setSAPContractID(input)
        }
    }

    function checkClientName(input) {
        if (!input || input === "") {
            setIsClientNameValid(false);
        } else {
            setIsClientNameValid(true);
            setClientname(input)
        }
    }

    function checkPPT(input) {
        if (!input || input === "") {
            setIsPPTValid(false);
        }
        else if (!input.name.match(/\.(ppt|pptx)$/)) {
            toast.error('Only PPT & PPTX formats are allowed');
            setIsPPTValid(false);
        } else {
            setIsPPTValid(true);
            setFile(URL.createObjectURL(input));
        }
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleUploadPPTFileInputChange = e => {
        let file = pptFile;

        checkPPT(e.target.files[0])

        file = e.target.files[0];
        setPptName(file.name)

        getBase64(file)
            .then(result => {
                setPptBase64URL(result);
                setPPTFile(result);
            })
            .catch(err => {
                console.log(err);
            });

    };

    function handleChange(e, fieldName) {
        if (fieldName === "TentativeServiceEndDate") {
            setSelectedDate(e)
        } else {
            const value = e.target.value;
            e.preventDefault();
            if (fieldName === "requestType") {
                checkRequestType(value)
                setIsTCAgreed(false);
                setErrorMsg("");
            }
            if (fieldName === "atrURL") {
                checkAtrURL(value)
                setIsTCAgreed(false);
                setErrorMsg("");
            }
            if (fieldName === "assetName") {
                checkAssetName(value)
                setIsTCAgreed(false);
            }
            if (fieldName === "usecase") {
                checkUseCase(value)
                setIsTCAgreed(false);
            }
            if (fieldName === "manager") {
                checkManager(value)
                setIsTCAgreed(false);
            }
            if (fieldName === "tc") {
                setIsTCAgreed(e.target.checked)
            }
            if (fieldName === "configurationType") {
                setWbseCode("");
                setSecondaryPoc("");
                setClientname("");
                setSAPContractID("");
                setAssetName("");
                checkConfigurationType(value)
                setIsTCAgreed(false);
            }
            if (fieldName === "WBSEcode") {
                setWbseCode(value)
                setIsWBSECodeValid(checkWBSECode(value));
            }
            if (fieldName === "SAPContractID") {
                checkSAPContractID(value)
                setIsTCAgreed(false);
            }
            if (fieldName === "SecondaryPOC") {
                setIsTCAgreed(false);
                setSecondaryPoc(value);
            }
            if (fieldName === "ClientName") {
                checkClientName(value)
                setIsTCAgreed(false);
            }

        }

    }

    const l3Url =
        "https://mywizard-aiops.accenture.com/resources/support-requests/";

    return (
        <div className={classes.root}>
            <DialogContent>
                <DialogContentText id="alert-dialog-descreption">
                    <Typography sx={{ mt: 2, mb: 1 }} style={{ textAlign: "center" }}>
                        <p style={{ fontSize: "1.4rem", color: " #0041F0 !important" }}><b>This feature is temporarily unavailable</b>&#x1F61E;</p>
                        <p>If you require OpenAI, please raise a service request using this{" "}
                            <a href={l3Url} target="_blank" rel="noopener noreferrer">
                                link
                            </a>.
                            Thank you for your understanding.
                        </p>
                        <br></br>
                        <div style={{ float: "right" }}>
                            <PrimaryButton
                                data-cy="dialogOkButton"
                                variant="outlined"
                                onClick={handleClose}
                                label={"Close"}
                                className={classes.requestOpenAIButton}
                            />
                        </div>

                    </Typography>
                </DialogContentText>
            </DialogContent>

        </div>
        // <div className={classes.root}>
        //     <DialogContent>
        //         <DialogContentText id="alert-dialog-descreption">
        //             <Box sx={{ width: '100%' }}>
        //                 {/* <Stepper nonLinear activeStep={activeStep}>
        //                     {steps.map((label, index) => (
        //                         <Step key={label} completed={completed[index]}>
        //                             <StepButton color="inherit">
        //                                 {label}
        //                             </StepButton>
        //                         </Step>
        //                     ))}
        //                 </Stepper> */}
        //                 <div>
        //                     {allStepsCompleted() && isAllInputsCorrect ? (
        //                         <React.Fragment>
        //                             <br></br>
        //                             <br></br>
        //                             {finalSubmitLoading ? (
        //                                 <div
        //                                     style={{
        //                                         display: "flex",
        //                                         justifyContent: "center",
        //                                         alignItems: "center",
        //                                     }}
        //                                 >
        //                                     <CircularProgress size={40} />
        //                                 </div>
        //                             ) : (
        //                                 !finalSubmitError ?
        //                                     <Typography sx={{ mt: 2, mb: 1 }} style={{ textAlign: "center" }}>
        //                                         <p style={{ fontSize: "1.1rem", color: " #0041F0 !important" }}><b>Thank you for submitting your request for OpenAI access!</b></p>
        //                                         <p>Once your request has been reviewed & actioned, you will receive a notification via email.</p>
        //                                         <br></br>
        //                                         <div style={{ float: "right" }}>
        //                                             <PrimaryButton
        //                                                 data-cy="dialogOkButton"
        //                                                 variant="outlined"
        //                                                 onClick={handleClose}
        //                                                 label={"Close"}
        //                                                 className={classes.requestOpenAIButton}
        //                                             />
        //                                         </div>

        //                                     </Typography>
        //                                     : <Typography sx={{ mt: 2, mb: 1 }} style={{ textAlign: "center" }}>
        //                                         <p style={{ fontSize: "1.1rem", color: " #0041F0 !important" }}><b>Oops! Something went wrong.</b></p>
        //                                         <p>We're sorry, but your request couldn't be processed at the moment due to heavy traffic. Please try again after some time. We apologize for any inconvenience caused. Thank you for your understanding.
        //                                         </p>
        //                                         <br></br>
        //                                         <div style={{ float: "right" }}>
        //                                             <PrimaryButton
        //                                                 data-cy="dialogOkButton"
        //                                                 variant="outlined"
        //                                                 onClick={handleClose}
        //                                                 label={"Close"}
        //                                                 className={classes.requestOpenAIButton}
        //                                             />
        //                                         </div>

        //                                     </Typography>
        //                             )}
        //                             <br></br>
        //                         </React.Fragment>
        //                     ) : (
        //                         <React.Fragment>
        //                             <Typography sx={{ mt: 2, mb: 1, py: 1 }} style={{ paddingBottom: "5%" }}>
        //                                 {
        //                                     (() => {
        //                                         if (activeStep + 1 == 1)
        //                                             return (
        //                                                 <div>
        //                                                     <p>We are excited to enable your access to Azure OpenAI services and resources. Before we proceed, we kindly request you to complete the mandatory data security training program. This training is essential to ensure that you are well-prepared and equipped to handle our data securely, maintaining its confidentiality, integrity, and availability.</p>
        //                                                     <p>
        //                                                         <a href="https://mylearning.accenture.com/mylearningui/learner/coursedetail/1826391" target="_blank" rel="noopener noreferrer">
        //                                                             Link to Training
        //                                                         </a>
        //                                                     </p>
        //                                                     <br></br>
        //                                                     <p><b>Are you raising Open AI Integration request for an existing ATR application or for other application? Please select the type of request below.</b></p>
        //                                                     <StyledFormControl variant="outlined" margin="dense" fullWidth>
        //                                                         <InputLabel htmlFor="outlined-requesttype">Request Type</InputLabel>
        //                                                         <StyledSelect
        //                                                             onChange={(e) => handleChange(e, "requestType")} label="Request Type" value={requestType}
        //                                                         >
        //                                                             <MenuItem value="">
        //                                                                 <em>--Select Request Type--</em>
        //                                                             </MenuItem>
        //                                                             <MenuItem value="ATR"> Existing myWizard ATR </MenuItem>
        //                                                             {/* <MenuItem value="NewATR"> New ATR </MenuItem> */}
        //                                                             <MenuItem value="Other"> Other Applications </MenuItem>
        //                                                         </StyledSelect>
        //                                                     </StyledFormControl>
        //                                                     {requestType === "ATR" ?
        //                                                         <div>
        //                                                             <StyledFormControl variant="outlined" margin="dense">
        //                                                                 <InputLabel htmlFor="outlined-plan-and-scope-select">
        //                                                                     Chat GPT
        //                                                                 </InputLabel>
        //                                                                 <StyledSelect
        //                                                                     defaultValue="4"
        //                                                                     // onChange={(e) => handleDevOpsAddon(e)}
        //                                                                     label="Chat GPT"
        //                                                                     fullWidth
        //                                                                 >
        //                                                                     <MenuItem value="">
        //                                                                         <em>--Select version--</em>
        //                                                                     </MenuItem>
        //                                                                     <MenuItem value="4">4</MenuItem>
        //                                                                 </StyledSelect>
        //                                                             </StyledFormControl>
        //                                                             <StyledFormControl>
        //                                                                 <TextField
        //                                                                     margin="dense"
        //                                                                     variant="outlined"
        //                                                                     placeholder="e.g. https://[regioncode]-[domaincontextroot].atrmywizard-aiops.com/atr/signin"
        //                                                                     onChange={(e) => handleChange(e, "atrURL")}
        //                                                                     label="ATR URL"
        //                                                                     InputProps={{
        //                                                                         endAdornment: (
        //                                                                             <InputAdornment position="end">
        //                                                                                 {loading ?
        //                                                                                     <CircularProgress size={20} />
        //                                                                                     :
        //                                                                                     " "}
        //                                                                             </InputAdornment>
        //                                                                         ),
        //                                                                     }}
        //                                                                 />
        //                                                             </StyledFormControl>
        //                                                         </div> : ""
        //                                                     }

        //                                                     <div style={{ background: "red", display: errorMsg ? "block" : "none" }}>
        //                                                         <p style={{ color: "white", padding: "1%" }} ><b>{errorMsg}</b></p>
        //                                                     </div>

        //                                                     {requestType === "Other" ?
        //                                                         <div>
        //                                                             <p><b>To better assist you, could you please let us know if you are a client or an internal user?</b></p>
        //                                                             <FormControl variant="outlined" style={{ marginTop: "-1%" }}>
        //                                                                 <RadioGroup
        //                                                                     aria-label="configurationType"
        //                                                                     name="configurationType"
        //                                                                     value={configurationType}
        //                                                                     onChange={(e) => handleChange(e, "configurationType")}
        //                                                                     row
        //                                                                 >
        //                                                                     <FormControlLabel
        //                                                                         value="client"
        //                                                                         control={<Radio color="primary" />}
        //                                                                         label={`CLIENT`}
        //                                                                     />
        //                                                                     <FormControlLabel
        //                                                                         value="internal"
        //                                                                         control={<Radio color="primary" />}
        //                                                                         label={`INTERNAL USER`}
        //                                                                     />
        //                                                                 </RadioGroup>
        //                                                             </FormControl>
        //                                                             <StyledFormControl variant="outlined" margin="dense" fullWidth>
        //                                                                 <InputLabel htmlFor="outlined-assetname">Asset name</InputLabel>
        //                                                                 <StyledSelect
        //                                                                     onChange={(e) => handleChange(e, "assetName")} label="Asset Name" value={assetName}
        //                                                                 >
        //                                                                     <MenuItem value="">
        //                                                                         <em>--Select Asset Name--</em>
        //                                                                     </MenuItem>
        //                                                                     <MenuItem value="Ticket Analyser">Ticket Analyser</MenuItem>
        //                                                                     <MenuItem value="Stack Monitoring Suite">Stack Monitoring Suite</MenuItem>
        //                                                                     <MenuItem value="DevOps Analytics">DevOps Analytics</MenuItem>
        //                                                                     <MenuItem value="myWizard AiOps Sharepoint Addon">myWizard AiOps Sharepoint Addon</MenuItem>
        //                                                                     <MenuItem value="Cloud Security and Compliance Checker">Cloud Security and Compliance Checker</MenuItem>
        //                                                                     <MenuItem value="Report Extractor">Report Extractor</MenuItem>
        //                                                                     <MenuItem value="Automatic Ticket Assignment">Automatic Ticket Assignment</MenuItem>
        //                                                                     <MenuItem value="myWizard AiOps Batch">myWizard AiOps Batch</MenuItem>
        //                                                                     <MenuItem value="myWizard AiOps SAP360">myWizard AiOps SAP360</MenuItem>
        //                                                                     <MenuItem value="Cloud Chaser">Cloud Chaser</MenuItem>
        //                                                                     <MenuItem value="myWizard AiOps RPA">myWizard AiOps RPA</MenuItem>
        //                                                                     <MenuItem value="StormWatch">StormWatch</MenuItem>
        //                                                                     <MenuItem value="RemedyForce connector">RemedyForce connector</MenuItem>
        //                                                                     <MenuItem value="Cloud Chargeback">Cloud Chargeback</MenuItem>
        //                                                                     <MenuItem value="Oracle360">Oracle360</MenuItem>
        //                                                                     <MenuItem value="Other">Other</MenuItem>
        //                                                                 </StyledSelect>
        //                                                                 <StyledFormControl variant="outlined" margin="dense" fullWidth>
        //                                                                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
        //                                                                         <StyledDatePicker
        //                                                                             autoOk
        //                                                                             label="Tentative Service End Date"
        //                                                                             id="TentativeServiceEndDate"
        //                                                                             value={selectedDate}
        //                                                                             onChange={(e) => {
        //                                                                                 setSelectedDate(e);
        //                                                                                 return handleChange(
        //                                                                                     moment(e).format("YYYY-MM-DD"),
        //                                                                                     "TentativeServiceEndDate"
        //                                                                                 );
        //                                                                             }}
        //                                                                             variant="inline"
        //                                                                             inputVariant="outlined"
        //                                                                             format="MM/dd/yyyy"
        //                                                                             disableCloseOnSelect={true}
        //                                                                             disablePast={true}
        //                                                                         />
        //                                                                     </MuiPickersUtilsProvider>
        //                                                                 </StyledFormControl>
        //                                                                 <StyledFormControl>
        //                                                                     <TextField
        //                                                                         label="WBSE Code"
        //                                                                         id="WBSEcode"
        //                                                                         margin="dense"
        //                                                                         variant="outlined"
        //                                                                         value={wbseCode}
        //                                                                         onChange={(e) => handleChange(e, "WBSEcode")}
        //                                                                         helperText={
        //                                                                             !isWBSECodeValid &&
        //                                                                             "Please provide a valid WBSE Code."
        //                                                                         }
        //                                                                         error={!isWBSECodeValid}
        //                                                                     />
        //                                                                 </StyledFormControl>
        //                                                                 <StyledFormControl>
        //                                                                     <TextField
        //                                                                         variant="outlined"
        //                                                                         placeholder="Please provide the Secondary POC"
        //                                                                         onChange={(e) => handleChange(e, "SecondaryPOC")}
        //                                                                         label="Secondary POC"
        //                                                                         margin="dense"
        //                                                                         value={secondaryPoc}
        //                                                                     />
        //                                                                 </StyledFormControl>
        //                                                             </StyledFormControl>
        //                                                         </div>
        //                                                         : ""
        //                                                     }
        //                                                     {
        //                                                         configurationType === "client" && requestType === "Other" ?
        //                                                             <div>
        //                                                                 <StyledFormControl>
        //                                                                     <TextField
        //                                                                         variant="outlined"
        //                                                                         placeholder="Please provide the SAPContractID"
        //                                                                         onChange={(e) => handleChange(e, "SAPContractID")}
        //                                                                         label="SAP Contract ID"
        //                                                                         margin="dense"
        //                                                                         value={sapContractID}
        //                                                                         helperText={
        //                                                                             !isSAPContractIDValid &&
        //                                                                             "Please provide a valid SAP Contract ID."
        //                                                                         }
        //                                                                         error={!isSAPContractIDValid}
        //                                                                         InputProps={{
        //                                                                             endAdornment: (
        //                                                                                 <InputAdornment position="end">
        //                                                                                     {/*Validation added to show a circular progress when API call is fetching */}
        //                                                                                     {sapContractIDLoading ?
        //                                                                                         <CircularProgress size={20} />
        //                                                                                         : " "}
        //                                                                                 </InputAdornment>
        //                                                                             ),
        //                                                                         }}
        //                                                                     />
        //                                                                 </StyledFormControl>
        //                                                                 <div id="clientDetails" style={{ display: "none" }}>
        //                                                                     <StyledFormControl>
        //                                                                         <StyledDefaultTextField
        //                                                                             label="Client Name"
        //                                                                             id="ClientName"
        //                                                                             margin="dense"
        //                                                                             variant="outlined"
        //                                                                             value={clientname}
        //                                                                             onChange={(e) => handleChange(e, "Clientname")}
        //                                                                             defaultValue={clientname}
        //                                                                             InputProps={{
        //                                                                                 readOnly: true,
        //                                                                             }}
        //                                                                         />

        //                                                                     </StyledFormControl>
        //                                                                 </div>
        //                                                             </div> : ""
        //                                                     }
        //                                                 </div>
        //                                             )
        //                                         if (requestType === "Other") {
        //                                             if (activeStep + 1 == 2) {
        //                                                 return (
        //                                                     <div>
        //                                                         <p>As OpenAI handles vast amounts of data, including proprietary information, personal data, and potentially sensitive content, it is crucial that all users have a solid understanding of data security principles and best practices. Please refer to the Accenture's HIPPA guidelines help you handle Personal data responsibly.</p>
        //                                                         <div style={{ textAlign: "center" }}>
        //                                                             <iframe src={props.pptFileUrl} width="700px" height="400px" frameborder="0"></iframe>
        //                                                             {/* <iframe src="https://myoffice.accenture.com/personal/shilpa_adikeshava_accenture_com/_layouts/15/Doc.aspx?sourcedoc={5a658d46-4d63-46c6-b2ec-9ab099aea4d8}&amp;action=embedview&amp;wdAr=1.3333333333333333" width="700px" height="400px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe> */}
        //                                                         </div>
        //                                                         <p>You can also download the PowerPoint presentation (PPT) associated with the training through this {" "}
        //                                                             <a href="https://ts.accenture.com/sites/Information_Security2/Protecting%20Accenture/Shared%20Documents/Client%20Data%20Protection%20WordPress%20Site/Homepage-Right%20Side/HIPAA%20Awareness%20Training.zip" target="_blank" rel="noopener noreferrer">
        //                                                                 link
        //                                                             </a> if you have trouble viewing content.</p>
        //                                                     </div>
        //                                                 )
        //                                             } else if (activeStep + 1 == 3) {
        //                                                 return (
        //                                                     <div>
        //                                                         <p>As we continue to leverage OpenAI in our projects, I want to emphasize the importance of adhering to myWizard AiOps guidelines and best practices. These guidelines are designed to ensure efficient, secure, and responsible usage of OpenAI services. It is crucial that we all refer to these guidelines to maintain consistency across our assets and mitigate any potential risks.</p>
        //                                                         <div style={{ textAlign: "center" }}>
        //                                                             <iframe src={props.pdfFileUrl} width="700px" height="400px" frameborder="0"></iframe>
        //                                                         </div>
        //                                                         <p>You can also download the PDF associated with the training through this {" "}
        //                                                             <StyledClickableElementForFile
        //                                                                 variant="body2"
        //                                                                 onClick={(e) => fetchFilePresignedUrl(e)}
        //                                                             >
        //                                                                 link
        //                                                             </StyledClickableElementForFile >.</p>

        //                                                     </div>
        //                                                 )
        //                                             } else if (activeStep + 1 == 4) {
        //                                                 return (
        //                                                     <div>
        //                                                         <p>To ensure a smooth approval process from your manager, please make sure to submit all the fields listed below:</p>

        //                                                         <StyledFormControl variant="outlined" margin="dense" fullWidth>
        //                                                             <InputLabel htmlFor="outlined-manager">Manager</InputLabel>
        //                                                             <StyledSelect
        //                                                                 // value={values.ServiceGroup}
        //                                                                 onChange={(e) => handleChange(e, "manager")} label="Manager" value={manager}
        //                                                             >
        //                                                                 <MenuItem value="">
        //                                                                     <em>--Select Manager--</em>
        //                                                                 </MenuItem>
        //                                                                 {sortManagersList.map(item => (
        //                                                                     <MenuItem key={item} value={item}>{item}</MenuItem>
        //                                                                 ))}
        //                                                             </StyledSelect>
        //                                                         </StyledFormControl>
        //                                                         <StyledFormControl variant="outlined" margin="dense" fullWidth>
        //                                                             <TextField
        //                                                                 variant="outlined"
        //                                                                 placeholder="Please input the usecases you are planning to use the Open AI for."
        //                                                                 multiline
        //                                                                 rows={5}
        //                                                                 inputProps={{
        //                                                                     maxLength: 300,
        //                                                                 }}
        //                                                                 onChange={(e) => handleChange(e, "usecase")}
        //                                                                 label="Purpose of using OpenAI"
        //                                                                 value={usecase} />
        //                                                         </StyledFormControl>

        //                                                         <div>
        //                                                             <br></br>
        //                                                             <p>To ensure we have a comprehensive understanding of your ideas and plans, we kindly request you to upload a PPT presentation that outlines how you envision the integration of OpenAI in your application.</p>
        //                                                             <label htmlFor="upload-ppt">
        //                                                                 <input
        //                                                                     style={{ display: "none" }}
        //                                                                     id="upload-ppt"
        //                                                                     name="upload-ppt"
        //                                                                     type="file"
        //                                                                     accept=".ppt, .pptx"
        //                                                                     onChange={handleUploadPPTFileInputChange}
        //                                                                 />

        //                                                                 <Button color="primary" variant="contained" component="span">
        //                                                                     <AddIcon />Upload PPT
        //                                                                 </Button>{" "}&nbsp;&nbsp;{pptName}
        //                                                             </label>
        //                                                         </div>

        //                                                     </div>
        //                                                 )
        //                                             } else if (activeStep + 1 == 5) {
        //                                                 return (
        //                                                     <div>
        //                                                         <p>Please take the time to carefully review all the terms and conditions before checking the checkbox. </p>
        //                                                         <ul>
        //                                                             <li>As an employee of Accenture Ltd, I understand and acknowledge the <b>significance of responsible use of OpenAI's model</b>. I commit to utilizing the model in accordance with the guidelines and policies set forth by the company.
        //                                                             </li>
        //                                                             <li>I acknowledge that using <b>OpenAI's model requires adherence to ethical and legal standards</b>. I will use the model responsibly, ensuring that generated content aligns with our company values and complies with relevant laws and regulations.</li>
        //                                                             <li>By signing below, I affirm my commitment to using OpenAI's model effectively and responsibly. I will exercise caution when <b>generating content and make informed decisions to avoid biased or misleading outputs</b>.
        //                                                             </li>
        //                                                             <li>I recognize that responsible use of OpenAI's model is essential for maintaining <b>trust and integrity</b>. I pledge to validate and review the generated content to ensure accuracy, fairness, and appropriateness before sharing or utilizing it.
        //                                                             </li>
        //                                                             <li>I understand that using OpenAI's model requires considering potential risks and limitations. <b>I will actively seek to enhance my understanding of the model's capabilities and constraints</b> to make informed decisions while leveraging its capabilities.
        //                                                             </li>
        //                                                             <li>By agreeing to this statement, I affirm my commitment to following best practices when using OpenAI's model. <b>I will critically evaluate and verify the generated content, taking responsibility for its accuracy, relevance, and compliance</b>.
        //                                                             </li>
        //                                                             <li>I acknowledge that training and education are crucial for safe and effective use of OpenAI's model. I will actively participate in relevant training programs and stay updated on best practices to <b>maximize the benefits while mitigating any potential risks</b>.
        //                                                             </li>
        //                                                             <li>I understand that using OpenAI's model requires protecting the privacy and confidentiality of data. <b>I will handle any sensitive or confidential information in accordance with data privacy policies and security protocols.</b></li>
        //                                                             <li>By acknowledging this statement, I confirm that I will use OpenAI's model responsibly and respect the intellectual property rights of others. <b>I will avoid generating or sharing content that may infringe upon copyrights or violate any legal obligations.</b>
        //                                                             </li>
        //                                                             <li><b>I hereby acknowledge that the responsible use of OpenAI's model is a shared responsibility. I will actively contribute to fostering a culture of ethical AI usage and collaborate with my colleagues to ensure its effective and beneficial deployment.</b></li>
        //                                                             <li><b>I hereby acknowledge that I have completed the AiOps recommended trainings before raising this request.</b>
        //                                                             </li>

        //                                                         </ul>
        //                                                         <FormControlLabel onChange={(e) => handleChange(e, "tc")} control={<Checkbox />} label="I agree to the above terms & conditions" />
        //                                                     </div>
        //                                                 )
        //                                             }
        //                                         }
        //                                     })()
        //                                 }
        //                             </Typography>
        //                             <div>
        //                                 <div style={{ float: "left" }}>
        //                                     <AcceptButton
        //                                         color="inherit"
        //                                         disabled={activeStep === 0}
        //                                         onClick={handleBack}
        //                                         sx={{ mr: 1 }}
        //                                         label={"Back"}
        //                                         className={activeStep === 0 ? classes.backButton : classes.actionButton}
        //                                     ></AcceptButton>
        //                                 </div>
        //                                 {requestType === "ATR" ?
        //                                     <div style={{ float: "right" }}>
        //                                         {
        //                                             <AcceptButton className={classes.actionButton} onClick={handleComplete} disabled={isAllInputsCorrect} label="Submit" />
        //                                         }
        //                                     </div>
        //                                     :
        //                                     <div style={{ float: "right" }}>
        //                                         {
        //                                             (activeStep + 1) === steps.length ? (
        //                                                 <AcceptButton className={classes.actionButton} onClick={handleComplete} disabled={isAllInputsCorrect} label="Submit" />
        //                                             ) : (
        //                                                 <AcceptButton onClick={() => {
        //                                                     const newCompleted = completed;
        //                                                     newCompleted[activeStep] = true;
        //                                                     setCompleted(newCompleted);
        //                                                     // handleNext();
        //                                                     handleSave(activeStep);
        //                                                 }} className={classes.actionButton} disabled={isAllInputsCorrect || loading} label='Next' />
        //                                             )
        //                                         }
        //                                     </div>
        //                                 }
        //                             </div>
        //                         </React.Fragment>
        //                     )}
        //                 </div>
        //             </Box>
        //         </DialogContentText>
        //         <br></br>
        //         <br></br>
        //         <br></br>
        //         <br></br>
        //     </DialogContent>
        // </div>
    );
};

const mapStateToProps = (state) => {
    return {
        idToken: state.auth.idToken,
        email: state.auth.userDetails.email
            ? state.auth.userDetails.email.split("@")[0]
            : "",
    };
};


export default connect(mapStateToProps, {})(RequestForm);