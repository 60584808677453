import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import {
  PlatformWrapper,
  PlatformTitleWrapper,
  StyledPaper,
  TableWrapper,
} from "../fui/FuiStyles";
import { StyledTableHead, TableFirstRow } from "../ssh/SshStyles";

import {
  SmallHeader,
  Subtitle4,
} from "../common/CommonStyles/CommonTypographyStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdminRolesTable from "./AdminRolesTable";
import AdminGroupsTable from "./AdminGroupsTable";
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import { adminMakeStyles } from "./AdminMakeStyles";
import CreateEditRoleDialog from "./CreateEditRoleDialog";
import {
  fetchRoles,
  fetchGroups,
  fetchPermissions,
  deleteRole,
} from "../../redux/admin/index";
import { Footer } from "../common/Footer";
/**
 * User Management page in SSH to add/revoke user ssh access in each instance.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: Ssh
 */

const AdminPage = ({
  email,
  fetchExistingRoles,
  isFetchRolesLoading,
  rolesData,
  fetchExistingGroups,
  isFetchGroupsLoading,
  groupsData,
  permissionsData,
  fetchExistingPermissions,
  submitDeleteRole,
}) => {
  let classes = adminMakeStyles();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let myTimer;

    if (email) {
      fetchExistingPermissions(email);
      fetchExistingRoles(email);
      fetchExistingGroups(email);
      myTimer = setInterval(() => {
        fetchExistingRoles(email);
        fetchExistingGroups(email);
      }, 5000);
      return () => clearInterval(myTimer);
    }
  }, [email, openDialog]);

  const [switchState, setSwitchState] = React.useState({
    rolesOrGroups: false,
  });

  const handleChange = (event) => {
    setSwitchState({
      ...switchState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <TableWrapper>
        <PlatformWrapper>
          <PlatformTitleWrapper>
            <SmallHeader style={{ textAlign: "left" }} data-cy="adminHeader">
              {switchState["rolesOrGroups"] ? "Groups" : "Roles"}
            </SmallHeader>
          </PlatformTitleWrapper>

          <FormControlLabel
            control={
              <Switch
                checked={switchState.rolesOrGroups}
                onChange={handleChange}
                name="rolesOrGroups"
                color="default"
              />
            }
            label={switchState["rolesOrGroups"] ? "See Roles" : "See Groups"}
          />
        </PlatformWrapper>
        {switchState["rolesOrGroups"] ? (
          <StyledPaper style={{ maxHeight: "45em", overflow: "auto" }}>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableFirstRow>
                    <Subtitle4>AD Group Name</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Role Count</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Aggregate Permissions Count</Subtitle4>
                  </TableFirstRow>
                </TableRow>
              </StyledTableHead>
              {!isFetchGroupsLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              ) : (
                <TableBody data-cy="instanceTable">
                  {groupsData.map((row, index) => (
                    <AdminGroupsTable
                      data={row}
                      classes={classes}
                      roles={rolesData}
                      permissions={permissionsData}
                      key={index}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </StyledPaper>
        ) : (
          <StyledPaper style={{ maxHeight: "45em", overflow: "auto" }}>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableFirstRow>
                    <Subtitle4>Name</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Permissions Count</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Notes</Subtitle4>
                  </TableFirstRow>
                </TableRow>
              </StyledTableHead>
              {!isFetchRolesLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              ) : (
                <TableBody data-cy="instanceTable">
                  {rolesData.map((row, index) => (
                    <AdminRolesTable
                      data={row}
                      collapse={true}
                      permissions={permissionsData}
                      key={index}
                      classes={classes}
                      deleteRole={submitDeleteRole}
                      email={email}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </StyledPaper>
        )}
        <div style={{ float: "right", padding: "10px 0px" }}>
          <PrimaryButton
            label={
              switchState["rolesOrGroups"] ? "+ Add AD Group" : "+ Create Role"
            }
            onClick={() => setOpenDialog(true)}
            className={classes.createRoleButton}
          />
          {openDialog && (
            <CreateEditRoleDialog
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              permissions={permissionsData}
              dialogType={
                switchState["rolesOrGroups"] ? "add_ad_group" : "create"
              }
            />
          )}
        </div>
      </TableWrapper>
      <div style={{
        left: 96,
        bottom: 0,
        right: 26,
      }}>
        <Footer />
      </div>
    </React.Fragment>
  );
};

AdminPage.propTypes = {
  /**
   * Current users email
   */
  email: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    email: state.auth.userDetails.email,
    isFetchRolesLoading: state.adminReducer.fetchRolesIsLoading,
    rolesData: state.adminReducer.roles,
    isFetchGroupsLoading: state.adminReducer.fetchGroupsIsLoading,
    groupsData: state.adminReducer.groups,
    permissionsData: state.adminReducer.permissions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchExistingRoles: (email) => dispatch(fetchRoles(email)),
  fetchExistingGroups: (email) => dispatch(fetchGroups(email)),
  fetchExistingPermissions: (email) => dispatch(fetchPermissions(email)),
  submitDeleteRole: (email, data) => dispatch(deleteRole(email, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
