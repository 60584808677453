import React, { useState, useEffect } from "react";

import { DialogTextWrapper, StyledTextField } from "./AdminStyles";
import { DialogActions, DialogContentText } from "@material-ui/core";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { connect } from "react-redux";
import * as adminRedux from "../../redux/admin/index";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
} from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import {
  submitNewRole,
  submitUpdateRole,
  submitNewAdGroup,
} from "../../redux/admin/index";
import AdminSuccessFailDialog from "./AdminSuccessFailDialog";
/**
 * Dialog after user submits ssh user access request to with results of attempting to add ssh users.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AddUsersDialog
 */

const CreateEditRoleDialog = ({
  openDialog,
  setOpenDialog,
  updateRole,
  permissions,
  createRoleData,
  roleData,
  submitRole,
  email,
  clearRole,
  createSuccess,
  createResults,
  createRoleLoading,
  dialogType,
  currentRoleState,
  submitUpdateRolePermissions,
  updateGroup,
  clearGroup,
  createAdGroupData,
  submitAdGroup,
  createGroupLoading,
  createGroupResults,
  createGroupSuccess,
  groupData,
}) => {
  var permCheckboxes = {};
  dialogType !== "add_ad_group" &&
    (dialogType === "create"
      ? permissions.forEach((perm) => {
          permCheckboxes[perm.component] = false;
        })
      : permissions.forEach((perm) => {
          permCheckboxes[perm.component] = currentRoleState[
            "permissions"
          ].includes(perm.component);
        }));
  const [checkboxState, setCheckboxState] = React.useState(permCheckboxes);
  const [isRoleNameValid, setIsRoleNameValid] = useState(true);
  const [isAdGroupNameValid, setIsAdGroupNameValid] = useState(true);

  const [isRoleNotesValid, setIsRoleNotesValid] = useState(true);
  const [errorMessageRoleName, setErrorMessageRoleName] = useState("");
  const [createRoleSubmitToggle, setCreateRoleSubmitToggle] = useState(false);
  const [createAdGroupSubmitToggle, setCreateAdGroupSubmitToggle] = useState(
    false
  );

  const [openInner, setOpenInner] = useState(false);

  //Toggles the inner dialog to display the results of adding users to instance.
  useEffect(() => {
    if (
      (createSuccess && createRoleSubmitToggle) ||
      (createAdGroupSubmitToggle && createGroupSuccess)
    ) {
      setOpenInner(true);
    }
  }, [createResults, createGroupResults]);

  const handleCheckboxChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });

    updateRole("permissions", [
      ...createRoleData.permissions,
      event.target.name,
    ]);
  };

  const closeDialog = () => {
    handleClose();
  };

  const handleClose = () => {
    clearRole();
    setOpenDialog(false);
  };

  const handleInnerClose = () => {
    setOpenInner(false);
  };

  const handleSubmit = () => {
    if (dialogType === "create") {
      updateRole("permissionCount", createRoleData.permissions.length);
      checkIfNameValid(createRoleData.roleName);
      checkIfNotesValid(createRoleData.roleNotes);

      submitRole(email, createRoleData);
      setCreateRoleSubmitToggle(true);
    } else if (dialogType === "add_ad_group") {
      setCreateAdGroupSubmitToggle(true);
      checkifAdGroupValid(createAdGroupData.adGroupName);
      submitAdGroup(email, createAdGroupData);
    } else {
      var rolePermissions = [];
      for (var key in checkboxState) {
        if (checkboxState.hasOwnProperty(key) && checkboxState[key]) {
          rolePermissions.push(key);
        }
      }

      submitUpdateRolePermissions(
        email,
        rolePermissions,
        currentRoleState.roleName
      );
      handleClose();
    }
  };

  const searchArray = (nameKey, myArray, searchValue) => {
    for (var i = 0; i < myArray.length; i++) {
      if (searchValue === "roleName") {
        if (myArray[i].roleName === nameKey) {
          return true;
        }
      } else {
        if (myArray[i].ad_group_name === nameKey) {
          return true;
        }
      }
    }
    return false;
  };

  const checkIfNameValid = (value) => {
    if (!(value !== "" && value.length <= 50)) {
      setErrorMessageRoleName(
        "Role Name must have more than 0 characters and less than 50 characters"
      );
      setIsRoleNameValid(false);
    } else if (searchArray(value, roleData, "roleName")) {
      setIsRoleNameValid(false);
      setErrorMessageRoleName("This role already exists");
    } else {
      setIsRoleNameValid(true);
    }
  };

  const checkifAdGroupValid = (value) => {
    if (searchArray(value, groupData, "ad_group_name")) {
      setIsAdGroupNameValid(false);
    } else {
      setIsAdGroupNameValid(true);
    }
  };

  const checkIfNotesValid = (value) => {
    setIsRoleNotesValid(value !== "" && value.length <= 256);
  };

  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();

    updateRole(fieldName, value);
    if (fieldName === "roleName") {
      checkIfNameValid(value);
    }
    if (fieldName === "roleNotes") {
      checkIfNotesValid(value);
    }
  };
  const handleAdGroupChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    checkifAdGroupValid(value);
    updateGroup(fieldName, value);
  };

  return (
    <CommonDialog
      open={openDialog}
      onClose={closeDialog}
      title={
        dialogType === "create"
          ? "Create Role"
          : "add_ad_group"
          ? "Add AD Group"
          : "Edit Role Permissions"
      }
      dialogType={"admin"}
      textContent={
        "Please fill out the form below to" +
        (dialogType === "create"
          ? " create a Role"
          : "add_ad_group"
          ? " add an AD Group"
          : " edit Role permissions")
      }
      dialogContent={
        <DialogTextWrapper>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          {dialogType === "add_ad_group" && (
            <div>
              <StyledTextField
                key="AdGroupName"
                label="AD Group Name"
                id="AdGroupName"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleAdGroupChange(e, "adGroupName")}
                error={!isAdGroupNameValid}
                helperText={
                  !isAdGroupNameValid && "This name already exists in CAM"
                }
              ></StyledTextField>
            </div>
          )}
          {dialogType === "create" && (
            <div>
              <StyledTextField
                key="RoleName"
                label="Role Name"
                id="RoleName"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleChange(e, "roleName")}
                error={!isRoleNameValid}
                helperText={!isRoleNameValid && errorMessageRoleName}
              ></StyledTextField>
              <StyledTextField
                key="RoleNotes"
                label="Role Notes"
                id="RoleNotes"
                margin="dense"
                variant="outlined"
                onChange={(e) => handleChange(e, "roleNotes")}
                rows={6}
                multiline
                error={!isRoleNotesValid}
                helperText={
                  !isRoleNotesValid &&
                  "Role Notes must have more than 0 characters and less than 256 characters"
                }
              ></StyledTextField>
            </div>
          )}
          {dialogType !== "add_ad_group" && (
            <FormControl component="fieldset" style={{ padding: "10px 0px" }}>
              <FormLabel
                component="legend"
                style={{ padding: "10px 0px 0px 0px" }}
              >
                Permissions
              </FormLabel>
              <FormGroup>
                {permissions.map((perm) => {
                  return (
                    <div>
                      <FormControlLabel
                        data-cy="permissionCheckboxes"
                        control={
                          <Checkbox
                            checked={checkboxState[perm.component]}
                            onChange={handleCheckboxChange}
                            name={perm.component}
                          />
                        }
                        label={perm.component}
                      />
                      <FormHelperText style={{ marginTop: "0px" }}>
                        {perm.notes}
                      </FormHelperText>
                    </div>
                  );
                })}
              </FormGroup>
            </FormControl>
          )}
          {openInner && (
            <AdminSuccessFailDialog
              response={
                dialogType === "create" ? createResults : createGroupResults
              }
              open={openInner}
              innerHandleClose={handleInnerClose}
              handleClose={closeDialog}
              isLoading={
                dialogType === "create" ? createRoleLoading : createGroupLoading
              }
              title={
                dialogType === "create"
                  ? "Created Role Results"
                  : "Created AD Group Results"
              }
            />
          )}
        </DialogTextWrapper>
      }
      dialogActions={
        <DialogActions data-cy="createRoleSubmit">
          <DeclineButton
            onClick={handleClose}
            label={"Cancel"}
            disabled={false}
          />
          <AcceptButton
            onClick={handleSubmit}
            label={"Submit"}
            disabled={
              (dialogType === "create" &&
                (!isRoleNameValid ||
                  !isRoleNotesValid ||
                  createRoleData.roleName === "" ||
                  createRoleData.roleNotes === "")) ||
              (dialogType === "add_ad_group" &&
                (!isAdGroupNameValid || createAdGroupData.adGroupName === ""))
            }
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const MSTP = (state, ownProps) => ({
  email: state.auth.userDetails.email,
  createRoleData: state.adminReducer.createRole,
  createAdGroupData: state.adminReducer.createAdGroup,
  roleData: state.adminReducer.roles,
  groupData: state.adminReducer.groups,
  createSuccess: state.adminReducer.createRoleSuccess,
  createResults: state.adminReducer.createRoleResults,
  createRoleLoading: state.adminReducer.createRoleIsLoading,
  createGroupSuccess: state.adminReducer.createAdGroupSuccess,
  createGroupResults: state.adminReducer.createAdGroupResults,
  createGroupLoading: state.adminReducer.createAdGroupIsLoading,
});

export default connect(MSTP, {
  updateRole: adminRedux.updateRoleForm,
  clearRole: adminRedux.clearRoleForm,
  updateGroup: adminRedux.updateAdGroupForm,
  clearGroup: adminRedux.clearAdGroupForm,
  submitUpdateRolePermissions: submitUpdateRole,
  submitRole: submitNewRole,
  submitAdGroup: submitNewAdGroup,
})(CreateEditRoleDialog);
