import React, { useState } from "react";
import { TableCell, TableRow, DialogActions } from "@material-ui/core";

import { HoverTableRow } from "../ssh/SshStyles";

import { Subtext } from "../common/CommonStyles/CommonTypographyStyles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import AdminInnerPermissionsTable from "./AdminInnerPermissionsTable";
import CreateEditRoleDialog from "./CreateEditRoleDialog";
import {
  SecondaryButton,
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { CommonDialog } from "../common/CommonComponents/Dialog";

/**
 * Renders each Table row with the ec2 data.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: SSHUserManagement
 */

const AdminRolesTable = ({
  email,
  data,
  collapse,
  permissions,
  classes,
  deleteRole,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  var dataPermissions = [];
  data["permissions"].forEach((perm) => {
    dataPermissions.push(findPermission(permissions, perm)[0]);
  });

  function findPermission(arr, searchKey) {
    return arr.filter((obj) =>
      Object.keys(obj).some((key) => obj[key].includes(searchKey))
    );
  }

  const handleDeleteSubmit = () => {
    deleteRole(email, { roleName: data.roleName });
    setOpenDeleteDialog(false);
  };

  return (
    <React.Fragment>
      {collapse && (
        <HoverTableRow key={""}>
          <TableCell align="center">
            <Subtext>{data.roleName}</Subtext>
          </TableCell>
          <TableCell align="center">
            <Subtext>{data.permissionCount}</Subtext>
          </TableCell>
          <TableCell align="center">
            <Subtext>{data.roleNotes}</Subtext>
          </TableCell>
          <TableCell key={""}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon data-cy="keyboardArrowUpIcon" />
              ) : (
                <KeyboardArrowDownIcon data-cy="keyboardArrowDownIcon" />
              )}
            </IconButton>
          </TableCell>
        </HoverTableRow>
      )}
      <TableRow>
        {collapse && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div style={{ float: "right", padding: "10px 0px" }}>
                <SecondaryButton
                  label="Delete Role"
                  onClick={() => setOpenDeleteDialog(true)}
                  className={classes.createRoleButtonSecondary}
                  disabled={data.roleName === "admin"}
                />
                <SecondaryButton
                  label="Edit Role"
                  onClick={() => setOpenDialog(true)}
                  className={classes.createRoleButtonSecondary}
                  disabled={data.roleName === "admin"}
                />
                {openDialog && (
                  <CreateEditRoleDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    permissions={permissions}
                    dialogType={"edit"}
                    currentRoleState={data}
                  />
                )}
                {openDeleteDialog && (
                  <CommonDialog
                    open={openDeleteDialog}
                    onClose={() => setOpenDeleteDialog(false)}
                    title={"Delete Role: " + data.roleName}
                    dialogType={"admin"}
                    textContent={"Are you sure you want to delete this role?"}
                    dialogActions={
                      <DialogActions data-cy="createRoleSubmit">
                        <DeclineButton
                          onClick={() => setOpenDeleteDialog(false)}
                          label={"Cancel"}
                          disabled={false}
                        />
                        <AcceptButton
                          onClick={() => handleDeleteSubmit()}
                          label={"Yes"}
                        />
                      </DialogActions>
                    }
                  ></CommonDialog>
                )}
              </div>
              <Box margin={1}>
                <AdminInnerPermissionsTable data={dataPermissions} />
              </Box>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

AdminRolesTable.propTypes = {};

export default AdminRolesTable;
