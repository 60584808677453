import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const DialogTextWrapper = styled.div`
  margin: 0em 1.5em 1.5em 1.5em;
`;
