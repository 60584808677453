import {
  Button,
  Collapse,
  Typography,
  CircularProgress,
  TextField,
  FormControl,
  Card,
} from "@material-ui/core";
import styled from "styled-components";
import { green, grey, orange } from "@material-ui/core/colors";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import theme from "../../../theme/theme";
import {
  LargeSubtitle,
  FieldName,
  FieldValue,
} from "../../common/CommonStyles/CommonTypographyStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export const StyledFileCopyIcon = styled(FileCopyIcon)`
  color: "#000000";
  font-weight: 600;
`;

export const StyledTitle = styled.div`
  display: flex;
  padding-left: 14px;
  padding-top: 0;
  min-height: 26px;
  max-height: 26px;
  height: 26px;
  width: -webkit-fill-available;
`;

export const StyledPaper = styled.div`
  padding: 5px;
  display: flex;
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 100px;
    font-size: 9px;
    margin: 12px 0 0 12px;
    height: 35px;
  }
`;

export const StyledCoppalse = styled(Collapse)`
  border-bottom-left-radius: ${(props) => props.bottomradius};
`;

export const Flex = styled.div`
  display: flex;
  margin: 0 2em;
`;

export const Pharagraph = styled(Typography)`
  &&&&& {
    color: "#000000";
    font-weight: 600;
    text-align: left;
    margin: 20px 0 0 0;
    font-size: 16px;
  }
`;

export const PharagraphWrapper = styled.div`
  width: 50%;
`;

export const GreenCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: ${green[600]};
  }
`;
export const StatusIconWrapper = styled.div`
  margin-right: 5px;
  padding-top: 8px;
`;

export const OrangeCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: ${orange[900]};
  }
`;

export const PlacedCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: "#000000";
    font-weight: 500;
  }
`;

export const FirstLineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const IconWrapper = styled.div`
  margin-left: 38%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  width: 50%;
`;

export const StyledTextFieldForm = styled(TextField)`
  &&& {
    width: 50%;
  }
`;

export const StyledServerCard = styled(Card)`
  &&&&& {
    height: 203px;
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
    border-radius: 0px;
  }
`;

export const ServerStatusSideBar = styled.div`
  writing-mode: vertical-lr;
  width: 100%;
  text-align: center;
  transform: rotate(180deg);
  height: 203px;
  border-left-style: solid;
  border-color: ${theme.backgroundBorderGreys.border};
  border-width: 1px;
  @media (min-width: 1300px) {
    height: 230px;
  }
  @media (min-width: 1100px) {
    height: 203px;
  }
`;

export const ServerStatusSideBarText = styled(LargeSubtitle)`
  &&&&& {
    font-weight: normal;
    font-size: 15px;
    color: white;
    @media (min-width: 1200px) {
      font-size: 16px;
    }
    @media (min-width: 1500px) {
      font-size: 20px;
    }
    @media (min-width: 2000px) {
      font-size: 28px;
    }
    @media (min-width: 2300px) {
      font-size: 32px;
    }
    @media (min-width: 2600px) {
      font-size: 36px;
    }
  }
`;

export const ServerFieldName = styled(FieldName)`
  &&&&& {
    padding: 14px 0px;
    font-size: 8px;
    @media (min-width: 1200px) {
      font-size: 10px;
      padding: 10px;
    }
    @media (min-width: 1400px) {
      font-size: 12px;
      padding: 10px;
    }
    @media (min-width: 1600px) {
      font-size: 14px;
      padding: 10px;
    }
    @media (min-width: 1800px) {
      font-size: 16px;
      padding: 10px;
    }
    @media (min-width: 2000px) {
      font-size: 15px;
      padding: 10px;
    }
  }
`;

export const ServerFieldValue = styled(FieldValue)`
  &&&&& {
    padding: 14px 0px;
    font-size: 8px;
    @media (min-width: 1200px) {
      font-size: 10px;
      padding: 10px;
    }
    @media (min-width: 1400px) {
      font-size: 12px;
      padding: 10px;
    }
    @media (min-width: 1600px) {
      font-size: 14px;
      padding: 10px;
    }
    @media (min-width: 1800px) {
      font-size: 16px;
      padding: 10px;
    }
    @media (min-width: 2000px) {
      font-size: 15px;
      padding: 10px;
    }
  }
`;

export const StyledActionButton = styled(Button)`
  &&& {
    border-color: ${theme.palette.aws.main};
    color: ${theme.palette.aws.main};
    height: 35px;
    width: 100px;
    border-width: 2px;
    border-radius: 0px;
    font-size: 15px;
    @media (max-width: 1300px) {
      font-size: 14px;
      width: 75px;
    }
    @media (max-width: 1100px) {
      font-size: 12px;
      width: 60px;
    }
    @media (max-width: 900px) {
      font-size: 10px;
      width: 40px;
    }
  }
`;

export const StyledClickableElement = styled.div`
  cursor: pointer;
  color: #0041F0;
  text-decoration: underline;
`;

export const StyledClickableElementForFile = styled.a`
  cursor: pointer;
  color: #0041F0;
  text-decoration: underline;
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  &&& {
    width: 3em;
    height: 3em;
    @media (max-width: 1300px) {
      width: 2em;
      height: 2em;
      padding-top: 15%;
    }
  }
`;

export const StyledExpandLessIcon = styled(ExpandLessIcon)`
  &&& {
    width: 3em;
    height: 3em;
    @media (max-width: 1300px) {
      width: 2em;
      height: 2em;
      padding-top: 15%;
    }
  }
`;
