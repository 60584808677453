import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { StyledTextField } from "../components/fui/FuiStyles";

export const sortByDate = (a, b) => {
  const firstDate = new Date(a.CreatedDate);
  const secondDate = new Date(b.CreatedDate);
  return firstDate > secondDate ? -1 : firstDate < secondDate ? 1 : 0;
};

export const noMatchRegex = /(?!)/;

export const RegexStyledTextField = ({ regex, onChange, ...props }) => {
  const handleChange = useCallback(
    (e) => {
      e.currentTarget.value = e.currentTarget.value.replace(regex, "");
      onChange(e);
    },
    [onChange, regex]
  );

  return <StyledTextField onChange={handleChange} {...props} />;
};

export default React.memo(RegexStyledTextField);

RegexStyledTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  regex: PropTypes.instanceOf(RegExp),
};

RegexStyledTextField.defaultProps = {
  regex: noMatchRegex,
};
