import React, { useState, Fragment, useEffect } from "react";
import Axios from "axios";
import base from "../../../../config";
// material ui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {
  IconWrapper,
  StyledCoppalse,
  StyledServerCard,
  ServerStatusSideBar,
  ServerStatusSideBarText,
  ServerFieldName,
  ServerFieldValue,
  StyledExpandLessIcon,
  StyledExpandMoreIcon,
} from "../styledServerList";
import theme from "../../../../theme/theme";
import { connect } from "react-redux";
import { CommonDialog } from "../../../common/CommonComponents/Dialog";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Input,
  Typography,
} from "@material-ui/core/";
import {
  LargeSubtitle,
  Subtitle3,
} from "../../../common/CommonStyles/CommonTypographyStyles";
import { TitleBar } from "../../../ssh/SshCommon/SSHCardsStyles";
// Components
import PropTypes from "prop-types";
import EditTags from "../../EditTagsForm/editTags";
import ActionItems from "../../ActionItems/ActionItems";
import InstanceDetailsCard from "../../InstanceDetailCard/InstanceDetailsCard";
import { images } from "../../../../assets";
import { TimePickerInformation } from "../../TimePicker/TimePickerInformation";
import SetSchedule from "../../TimePicker/SetSchedule/SetSchedule";
import EditSchedule from "../../TimePicker/EditSchedule/EditSchedule";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  openMoreInformationAction,
  editTagsAction,
} from "../../../../redux/ServersReducer/ServersActions";
import { Button } from "@material-ui/core";
import { PrimaryButton } from "../../../common/CommonComponents/Buttons";

/**
 * The ActionItems EditTags and TimePicker components are loaded in this component.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: Ec2ManagementComponent.js
 */

const ServersCard = (props) => {
  const {
    page,
    email,
    idToken,
    history,
    classes,
    rowsPerPage,
    instancesNewTags,
    openMoreInformationAction,
    editTagsAction,
    handleApiCall,
    setHandleApiCall,
  } = props;

  // this variable opens the EditTags component
  const [open, setOpen] = useState(false);

  const [currentItem, setCurrentItem] = useState();
  const [instanceEditTags, setInstanceEditTags] = useState();
  const [currentInstanceId, setCurrentInstanceId] = useState();
  const [terminatedInstances, setTerminatingIntances] = useState([]);

  const [, setOpenSplunkDialog] = useState({
    openDialog: false,
    instanceId: "",
  });

  // variable used to save the instanceId in order to open the Schedule Dialog
  const [scheduleInstanceId, setScheduleInstanceId] = useState("");

  // variable used to save the instanceId in order to open the Schedule Dialog
  const [editScheduleInstanceId, setEditScheduleInstanceId] = useState("");

  // this variables handles the state ot the ScheduleDialog
  const [openNewSchedule, setOpenNewSchedule] = useState(false);
  const [openEditSchedule, setOpenEditSchedule] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const [showOpenAiDetails, setShowOpenAiDetails] = useState(false);
  const [closeOpenAiDetails, setCloseOpenAiDetails] = useState(false);
  const [displayDetail, setDisplayDetail] = useState("");

  const [values, setValues] = React.useState({
    password: "",
    showKey: false,
  })

  const handleKeyChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowKey = () => {
    setValues({ ...values, showKey: !values.showKey });
  };

  const handleMouseDownKey = (event) => {
    event.preventDefault();
  };

  const changeOpenState = (id) => {
    setCurrentInstanceId(id);
    openMoreInformationAction(id);
  };

  // with this function we can open the Edit Tags dialog.
  // we also are saving an specific instance id
  // and we are saving an entire instance object
  const handleClickOpen = (item) => {
    setHandleApiCall(false);
    setCurrentItem(item);
    setOpen(true);
    setInstanceEditTags(item.instanceId);
  };

  // this function is used to open a new Schedule Dialog
  const handleSetSchedule = (instanceId) => {
    setOpenNewSchedule(true);
    setHandleApiCall(false);
    setScheduleInstanceId(instanceId);
  };

  // this function is used to open a Schedule dialog to edit the current schedule info
  const handleEditSchedule = (instanceId) => {
    setOpenEditSchedule(true);
    setHandleApiCall(false);
    setEditScheduleInstanceId(instanceId);
  };

  const handleClose = (close) => {
    setHandleApiCall(true);
    setOpen(close);
  };

  useEffect(() => {
    getOpenAIAccessData();
    const interval = setInterval(() => {
      getOpenAIAccessData();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const getOpenAIAccessData = () => {
    const urls = `${base.urls.apis.azure}/openai-access/read/all?inititive=na`;
    Axios.get(urls, options).then((res) => {
      const data = res.data
      setAccessData(data)
    }).catch((error) => {
      console.log('Error', error)
    })
  }

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const instanceInfoStringBuilder = (instance) => {
    var initiative = instance.initiative
    var result = ""
    if (initiative === "myWizard_AiOps") {
      result = "GenWizard AiOps" + " "
    } else {
      result = ""
    }
    result += instance.accountType + " ";
    result += instance.environment
      ? instance.environment === "Production"
        ? "Prod"
        : instance.environment
      : "";
    return result;
  };

  const finalInstances = instancesNewTags.filter((instance) => {
    let terminated = false;

    terminated = !!terminatedInstances.find(
      (tIntance) => instance.instanceId === tIntance
    );
    return !terminated;
  });

  const isOpenAiEnabled = (instanceId) => {
    const data = accessData ? accessData.find(item => item.InstanceId === instanceId) : null
    // setSelectedOpenAI(data);
    if (data && data.InstanceId) {
      return true;
    }
    return false;
  }

  const handleShowOpenaiDetails = (instanceId) => {
    setShowOpenAiDetails(true);
    setDisplayDetail(instanceId);
  }

  const handleCloseOpenAiDetails = () => {
    setShowOpenAiDetails(false);
    setCloseOpenAiDetails(true);
  }

  return (
    <>
      <Grid container>
        {finalInstances &&
          finalInstances.length > 0 &&
          finalInstances
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <Fragment key={index}>
                {/* component to set a new schedule */}
                {item.instanceId === scheduleInstanceId && (
                  <SetSchedule
                    item={item}
                    email={email}
                    idToken={idToken}
                    openNewSchedule={openNewSchedule}
                    setHandleApiCall={setHandleApiCall}
                    currentInstanceState={item.instanceState}
                    setOpenNewSchedule={setOpenNewSchedule}
                    initiative={item.Initiative}
                  />
                )}
                {/* component to set a edit schedule */}
                {item.instanceId === editScheduleInstanceId && (
                  <EditSchedule
                    item={item}
                    email={email}
                    idToken={idToken}
                    openEditSchedule={openEditSchedule}
                    setHandleApiCall={setHandleApiCall}
                    currentInstanceState={item.instanceState}
                    setOpenEditSchedule={setOpenEditSchedule}
                    initiative={item.Initiative}
                  />
                )}

                <StyledServerCard style={{ width: "95%", marginBottom: "1%" }}>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      style={{
                        background:
                          item.instanceState === "running"
                            ? theme.status.running
                            : item.instanceState === "stopped"
                              ? theme.status.stopped
                              : theme.status.pending,
                        maxWidth: "2.2%",
                        flexBasis: "2.2%",
                      }}
                    >
                      <ServerStatusSideBar>
                        <div>
                          <ServerStatusSideBarText data-cy="instanceState-cy">
                            {item.InstanceType === "Container" || item.InstanceType === "EventOps" ? "NA" : item.instanceState.substr(0, 1).toUpperCase() +
                              item.instanceState.substr(1)}
                          </ServerStatusSideBarText>
                        </div>
                      </ServerStatusSideBar>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      style={{
                        maxWidth: "97.5%",
                        flexBasis: "97.5%",
                      }}
                    >
                      <Grid container style={{ height: "100%" }}>
                        <Grid
                          item
                          xs={2}
                          md={2}
                          style={{
                            borderRightStyle: "solid",
                            borderColor: theme.backgroundBorderGreys.border,
                            borderWidth: "1px",
                          }}
                        >
                          {/* this components shows the schedule information */}
                          <TimePickerInformation
                            classes={classes}
                            item={item}
                            email={email}
                            idToken={idToken}
                            loadingIconStatusBool={item.isDisabled}
                            currentInstanceState={item.instanceState}
                            instanceId={item.instanceId}
                            setHandleApiCall={setHandleApiCall}
                            handleSetSchedule={handleSetSchedule}
                            handleEditSchedule={handleEditSchedule}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Grid item xs={12}>
                            <TitleBar>
                              <Grid
                                container
                                style={{
                                  height: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={1} style={{ marginTop: ".4em" }}>
                                  {/*
                                  here we have to handle the conditionals to show different cloud images.
                                  In order to see more images, see the "images" object
                                */}
                                  <img
                                    src={images.AWS_Black}
                                    width="50px"
                                    style={{ marginRight: "25%" }}
                                    alt="AWS_Black_not_found"
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  {item.openaienabled === "OpenAI+Quasar" ?
                                    <LargeSubtitle>
                                      {item.clusterId}
                                      <span style={{ backgroundColor: "#378171", fontSize: "18px", padding: "3px 7px", borderRadius: "10px", color: "white" }}>
                                        ATR-Q++ Container Bundle
                                      </span>
                                    </LargeSubtitle>
                                    :
                                    item.InstanceType === "Container" && item.platformtype === "myWizard AiOps ATR Caas" ?
                                      <LargeSubtitle>
                                        {item.clusterId}
                                        <span style={{ backgroundColor: "#378171", fontSize: "18px", padding: "3px 7px", borderRadius: "10px", color: "white" }}>
                                          ATR Container
                                        </span>
                                      </LargeSubtitle>
                                      : item.InstanceType === "Container" && item.platformtype === "myWizard AiOps Quasar Caas" ?
                                        <LargeSubtitle>
                                          {item.clusterId}
                                          <span style={{ backgroundColor: "#378171", fontSize: "18px", padding: "3px 7px", borderRadius: "10px", color: "white" }}>
                                            Q++ Container
                                          </span>
                                        </LargeSubtitle>
                                        :

                                        <>
                                          <LargeSubtitle>
                                            {item.clusterId}
                                            {/* {isOpenAiEnabled(item.instanceId) ?
                                          <PrimaryButton
                                            data-cy="dialogOkButton"
                                            variant="outlined"
                                            label={"OpenAI Enabled"}
                                            onClick={() => handleShowOpenaiDetails(item.instanceId)}
                                          />
                                          : ""} */}
                                          </LargeSubtitle>
                                          {/* <p></p> */}

                                        </>
                                  }
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    height: "100%",
                                  }}
                                >
                                  <Grid
                                    container
                                    style={{
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      style={{
                                        borderLeftStyle: "solid",
                                        borderColor:
                                          theme.backgroundBorderGreys.border,
                                        borderWidth: "1px",
                                        padding: "2%",
                                        maxWidth: "inherit",
                                        height: "100%",
                                        backgroundColor: "white",
                                        borderRadius: "10px 0px 0px 10px",
                                      }}
                                    >
                                      <Subtitle3
                                        style={{
                                          height: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {instanceInfoStringBuilder(item)}
                                      </Subtitle3>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TitleBar>
                          </Grid>
                          <Grid container style={{ height: "161px" }}>
                            <Grid item xs={6}>
                              <Grid container style={{ height: "161px" }}>
                                <Grid item xs={3}>
                                  <ServerFieldName>Private DNS</ServerFieldName>
                                  <ServerFieldName>Order Id</ServerFieldName>
                                  <ServerFieldName>Client Name</ServerFieldName>
                                  <ServerFieldName>Instance Type</ServerFieldName>
                                </Grid>
                                <Grid item xs={7}>
                                  {item.privateDNSName === " " || (("privateDNSName" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue> {item.privateDNSName} </ServerFieldValue>
                                  )}

                                  {item.orderid === " " || (("orderid" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue> {item.orderid} </ServerFieldValue>
                                  )}

                                  {item.clientname === " " || (("clientname" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue> {item.clientname === "myWizard AiOps" ? "GenWizard AiOps" : item.clientname}{item.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : item.ClientName}{item.clientName === "myWizard AiOps" ? "GenWizard AiOps" : item.clientName} </ServerFieldValue>
                                  )}

                                  {item.instanceType === " " || (("instanceType" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue> {item.instanceType} </ServerFieldValue>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                borderLeftStyle: "solid",
                                borderColor: theme.backgroundBorderGreys.border,
                                borderWidth: "1px",
                                height: "100%",
                              }}
                            >
                              <Grid container style={{ height: "161px" }}>
                                <Grid item xs={6}>
                                  <ServerFieldName>
                                    Service End Date
                                  </ServerFieldName>
                                  <ServerFieldName>WBSE Code</ServerFieldName>
                                  <ServerFieldName>Region</ServerFieldName>
                                </Grid>
                                <Grid item xs={6}>
                                  {item.serviceEndDate === " " || (("serviceEndDate" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue>
                                      {item.serviceEndDate}{item.serviceenddate}{item.ServiceEndDate}{item.Serviceenddate}{item.Service_End_Date}
                                    </ServerFieldValue>
                                  )}
                                  {item.wbseCode === " " || (("wbseCode" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue>
                                      {item.wbseCode}{item.wbsecode}{item.WBSECode}{item.WBSEcode}
                                    </ServerFieldValue>
                                  )}
                                  {item.regionAlias === " " || (("regionAlias" in item) == false) ? (
                                    <ServerFieldValue> - </ServerFieldValue>
                                  ) : (
                                    <ServerFieldValue>
                                      {item.regionAlias}
                                    </ServerFieldValue>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={2} xl={1}>
                              {/*
                              The ActionItems component handles the Card Actions(stop, start, terminate, storage...)
                            */}
                              <ActionItems
                                email={email}
                                index={index}
                                instance={item}
                                idToken={idToken}
                                history={history}
                                classes={classes}
                                editTags={editTagsAction}
                                disabled={item.isDisabled}
                                instanceId={item.instanceId}
                                InstanceType={item.InstanceType}
                                ApplicationName={item.applicationname}
                                environment={item.environment}
                                serviceEndDate={item.serviceEndDate}
                                setTerminatingIntances={setTerminatingIntances}
                                initiative={
                                  item.initiative === "PAM"
                                    ? item.initiative
                                    : item.Initiative
                                }
                              />
                              <IconWrapper>
                                <IconButton
                                  className={classes.servers_removedPadding}
                                  // onClick={() => changeOpenState(item.instanceId)} //filtering based on orderid because atr caas containers will not have instanceid field
                                  onClick={() => changeOpenState(item.orderid)}
                                  style={{ paddingTop: "3%" }}
                                >
                                  {item.isOpen ? (
                                    <StyledExpandLessIcon data-cy="expandLessIcon-cy" />
                                  ) : (
                                    <StyledExpandMoreIcon data-cy="expandMoreIcon-cy" />
                                  )}
                                </IconButton>
                              </IconWrapper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledServerCard>
                <Grid item xs={12}>
                  <StyledCoppalse
                    style={{ width: "95%" }}
                    in={item.isOpen}
                    display={item.isOpen ? item.isOpen.toString() : undefined}
                    bottomradius={"8px"}
                  >
                    <InstanceDetailsCard
                      item={item}
                      classes={classes}
                      loadingIconStatusBool={item.IsDisabled}
                      currentId={currentInstanceId}
                      handleClickOpen={handleClickOpen}
                      setOpenSplunkDialog={setOpenSplunkDialog}
                    />
                    {item.instanceId === instanceEditTags && (
                      <EditTags
                        open={open}
                        idToken={idToken}
                        email={email}
                        item={currentItem}
                        editTags={editTagsAction}
                        handleClose={handleClose}
                        currentId={instanceEditTags}
                        setHandleApiCall={setHandleApiCall}
                        handleApiCall={handleApiCall}
                        initiative={item.Initiative}
                      />
                    )}
                  </StyledCoppalse>
                </Grid>
              </Fragment>
            ))}
      </Grid>
      <CommonDialog
        className="AzureOpenAIDialog"
        open={showOpenAiDetails}
        onClose={closeOpenAiDetails}
        title={"OpenAI Details"}
        dialogType={"fui"}
        dialogContent={
          <DialogContent>
            <DialogContentText id="alert-dialog-descreption">
              <div>
                {accessData ? accessData.map((data) => (
                  data.InstanceId === displayDetail ?
                    <div>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        {/* <p style={{ fontSize: "1.15rem", color: "black !important" }}><b>Congratulations! Your OpenAI access is available.</b></p> */}
                        <p style={{ marginTop: "5%" }}>Please find the details below:</p>
                        <div style={{ lineHeight: "0.9rem" }}>
                          <p><span><b>AWS Hosted Region: </b></span>{data.AWSHostedRegion}</p>
                          <p><span><b>Air ID: </b></span>{data.AirID}</p>
                          <p><span><b>Application Name: </b></span>{data.ApplicationName}</p>
                          <p><span><b>Vendor: </b></span>Azure OpenAI</p>
                          <p><span><b>Az Region: </b></span>{data.AzRegion}</p>
                          <p><span><b>Az Subscription ID: </b></span>{data.AzSubscriptionID}</p>
                          <p><span><b>Az Subscription Name: </b></span>{data.AWSHostedRegion}</p>
                          <p><span><b>Client Name: </b></span>{data.ClientName}</p>
                          <p><span><b>Cluster ID: </b></span>{data.ClusterId}</p>
                          <p><span><b>Contract ID: </b></span>{data.ContractID}</p>
                          <p><span><b>Customer Enterprise ID: </b></span>{data.CustomerEnterpriseID}</p>
                          <p><span><b>Deployment ID: </b></span>{data.DeploymentID}</p>
                          <p><span><b>Environment: </b></span>{data.Environment}</p>
                          <p><span><b>Instance IP: </b></span>{data.InstanceIP}</p>
                          <p><span><b>Instance ID: </b></span>{data.InstanceId}</p>
                          <p><span><b>Key Vault Name: </b></span>{data.KeyVaultName}</p>
                          <p><span><b>Key Vault OpenAI Secret Name: </b></span>{data.KeyVaultOAISecretName}</p>
                          <p><span><b>OpenAI EndPoint: </b></span>{data.OpenAIEndPoint}</p>
                          <p><span><b>OpenAI Key: </b></span>{data.OpenAIKey}</p>
                          <p><span><b>OpenAI ServiceName: </b></span>{data.OpenAIServiceName}</p>
                          <p><span><b>Order Date: </b></span>{data.OrderDate}</p>
                          <p><span><b>Resource GroupName: </b></span>{data.ResourceGroupName}</p>
                          <p>
                            <span>
                              <b>SP SecretKey: </b>
                            </span>
                            <Input
                              style={{ padding: "0px !important", width: "60%", color: "rgba(0, 0, 0, 0.54)", marginTop: "-1%", marginBottom: "-1%" }}
                              disableUnderline={true}
                              type={values.showKey ? "text" : "password"}
                              onChange={handleKeyChange("password")}
                              value={data.SPSecretKey}
                              inputProps={
                                { readOnly: true, }
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowKey}
                                    onMouseDown={handleMouseDownKey}
                                  >
                                    {values.showKey ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>

                              }
                            />
                          </p>
                          <p><span><b>SP Expiry Date: </b></span>{data.SPExpiryDate.split('T')[0]}</p>
                          <p><span><b>Secondary Customer Enterprise ID: </b></span>{data.SecondaryCustomerEnterpriseID}</p>
                          <p><span><b>Tenant ID: </b></span>{data.TenantID}</p>
                          <p><span><b>WBSE Code: </b></span>{data.WBSECode}</p>
                          <div style={{ lineHeight: "1.6rem", marginTop: "-1%", marginBottom: "-1%" }}>
                            <p><span>
                              <b>Deployment Details: </b>
                              {/* <span style={{ fontSize: "23px" }}>&#128071;</span> */}
                            </span>
                              <table style={{ width: "80%", textAlign: "center", border: "1px solid black", borderCollapse: "collapse", fontSize: "14px", margin: "10px 0px 20px" }}>
                                <thead style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                  <tr>

                                    <th>Deployment ID</th>
                                    <th>Model Name</th>
                                    <th>Model Version</th>
                                    <th>Model Capacity</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {data.DeploymentName.split(",").map((rowContent, rowID) => (
                                    <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                      {rowContent.split("|").map((test, i) => (
                                        <td style={{ border: "1px solid black", borderCollapse: "collapse" }} key={rowID}>{test}</td>
                                      ))}
                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            </p>
                          </div>
                        </div>
                      </Typography>
                    </div>
                    : ""
                )) : ""}
              </div>
            </DialogContentText>
          </DialogContent>
        }
        dialogActions={
          <DialogActions>
            <PrimaryButton
              data-cy="dialogOkButton"
              variant="outlined"
              onClick={handleCloseOpenAiDetails}
              label={"Close"}
              className={classes.requestOpenAIButton}
            />
          </DialogActions>
        }


      />
    </>
  );
};

ServersCard.propTypes = {
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Classes styles for material ui.
   */
  classes: PropTypes.object,
  /**
   * Current users Token.
   */
  idToken: PropTypes.string,
  /**
   * This contain an array of all the instances of a user.
   */
  serverList: PropTypes.array,
  /**
   * Function that manage a boolean for stop or restart a setInterval for make api calls.
   */
  setHandleApiCall: PropTypes.func,
  /**
   * This array manage the state of the instance, for example when the instance pass from start to stop.
   */
};

export default connect(null, {
  // fetchServersInstances,
  // openMoreInformationAction,
  // openMoreInformationAction
  openMoreInformationAction,
  editTagsAction,
})(ServersCard);
