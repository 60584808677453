import React, { useEffect, useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { headers } from "../../../helper/api";
import AppDetailedDialog from "./AppDetailedDialog";
import StatusIcon from "../StatusIcon";
import NoInstalledApp from "../NoInstalledApp";
import base from "../../../config";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  CircularProgress,
} from "@material-ui/core";
import { BoldTableCell, DetailTableWrapper, StatusWrapper } from "../FuiStyles";
import { appNameProjectId } from "../Constants/ProductIds";
import { BorderlessButton } from "../../common/CommonComponents/Buttons";

const InstalledApps = ({ data, classes }) => {
  /**
   * Shows all the installed apps onto a platform
   * @author
   * @version 1.0
   * @see Used in component: InstalledAppsDialog.js
   */
  const [installedAppsData, setInstalledAppsData] = useState([]);

  const [isInstalledAppLoading, setIsInstalledAppLoading] = useState(true);

  // fetch installed apps from backend
  const fetchInstalledApps = () => {
    axios
      .get(base.urls.apis.fui, {
        headers: {
          ...headers,
          Authorization: localStorage.getItem("token"),
        },
        params: {
          customer_enterprise_id: data.CustomerEnterpriseID,
          sap_contract_id: data.SAPContractID,
          project_id: data.ProjectId,
          inititive: "",

          page_number: 1,
          page_size: 999,
        },
      })
      .then((res) => {
        setInstalledAppsData(res.data);
        setIsInstalledAppLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => fetchInstalledApps(), []);

  // show Apps' name instead of productUId
  const showAppsName = (appId) => {
    switch (appId) {
      case appNameProjectId.ticketAnalysisId:
        return "GenWizard Ticket Analysis";
      case appNameProjectId.devOpsAnalyticsId:
        return "GenWizard DevOps Analytics";
      case appNameProjectId.automaticTicketResolverId:
        return "GenWizard Automatic Ticket Resolver";
      case appNameProjectId.eventOpsId:
        return "GenWizard EventOps";
      case appNameProjectId.turingId:
        return "GenWizard Quasar++";
      case appNameProjectId.stormseerId:
        return "GenWizard StormSeer";
      case appNameProjectId.sharepointAddOnId:
        return "GenWizard Sharepoint Add On";
      case appNameProjectId.reportExtractorAddOnId:
        return "GenWizard Report Extractor";
      case appNameProjectId.stormwatchId:
        return "GenWizard StormWatch";
      case appNameProjectId.pamInstanceId:
        return "GenWizard PAM Platform";
      case appNameProjectId.automationJourneyId:
        return "GenWizard Automation Journey";
      case appNameProjectId.automationPlannerId:
        return "GenWizard Automation Planner";
      case appNameProjectId.intelligentOpportunityMinerId:
        return "GenWizard Intelligent Opportunity Miner";
      case appNameProjectId.guidedTicketResolverId:
        return "GenWizard Guided Ticket Resolver";
      case appNameProjectId.klewerId:
        return "GenWizard Klewer";
      case appNameProjectId.quasarId:
        return "GenWizard Quasar";
      case appNameProjectId.virtualDataScientistId:
        return "GenWizard Virtual Data Scientist";
      case appNameProjectId.stack360Id:
        return "GenWizard Stack 360";
      default:
        return "Unknown App";
    }
  };
  // filter apps to make sure there is no instance or license
  const filteredApps = installedAppsData.filter((product) => {
    return product.appProductUId !== undefined
      ? product
      : product.ProductUId === appNameProjectId.ticketAnalysisId ||
      product.ProductUId === appNameProjectId.devOpsAnalyticsId ||
      product.ProductUId === appNameProjectId.automaticTicketResolverId ||
      product.ProductUId === appNameProjectId.eventOpsId ||
      product.ProductUId === appNameProjectId.turingId ||
      product.ProductUId === appNameProjectId.stormseerId ||
      product.ProductUId === appNameProjectId.sharepointAddOnId ||
      product.ProductUId === appNameProjectId.reportExtractorAddOnId ||
      product.ProductUId === appNameProjectId.stormwatchId ||
      product.ProductUId === appNameProjectId.automationJourneyId ||
      product.ProductUId === appNameProjectId.pamInstanceId ||
      product.ProductUId === appNameProjectId.utkmPlatformId ||
      product.ProductUId === appNameProjectId.automationPlannerId ||
      product.ProductUId ===
      appNameProjectId.intelligentOpportunityMinerId ||
      product.ProductUId === appNameProjectId.guidedTicketResolverId ||
      product.ProductUId === appNameProjectId.klewerId ||
      product.ProductUId === appNameProjectId.quasarId ||
      product.ProductUId === appNameProjectId.splunkClusterPlatformId ||
      product.ProductUId === appNameProjectId.virtualDataScientistId;
  });

  // open the dialog when user click Installed Apps
  const [open, setOpen] = useState(false);

  const handleClickOpen = (orderId) => () => {
    setOpen(true);
    setScroll(scroll);
    setCurrentOrderId(orderId);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const appsToAdd = (appName, app) => {
    if (appName === appNameProjectId.pamInstanceId && app.PAMApps) {
      return (
        <TableRow>
          <TableCell align="left">
            {showAppsName(appName) +
              `. Installed in this instance: ${app.PAMApps}`}
          </TableCell>
          <TableCell align="left">
            <StatusWrapper>
              <StatusIcon status={app.Status} />
              <div>{app.Status === "Placed" ? "Processing" : app.Status}</div>
            </StatusWrapper>
          </TableCell>
          <TableCell align="left">
            <BorderlessButton
              onClick={handleClickOpen(app.OrderUId)}
              label={"App Details"}
              className={classes.FuiBorderlessButtonButton}
            />
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell align="left">{showAppsName(appName)}</TableCell>
          <TableCell align="left">
            <StatusWrapper>
              <StatusIcon status={app.Status} />
              <div>{app.Status === "Placed" ? "Processing" : app.Status}</div>
            </StatusWrapper>
          </TableCell>
          <TableCell align="left">
            <BorderlessButton
              onClick={handleClickOpen(app.OrderUId)}
              label={"App Details"}
              className={classes.FuiBorderlessButtonButton}
            />
          </TableCell>
        </TableRow>
      );
    }
  };

  const addReportExtractor = (app) => {
    var date = new Date(data.CreatedDate);
    var janTwenty = new Date(2021, 0, 20);
    if (
      !(
        app.appProductUId !== undefined &&
        app.appProductUId.includes(appNameProjectId.reportExtractorAddOnId)
      ) &&
      app.appProductUId !== appNameProjectId.dedicatedPlatformId &&
      date < janTwenty
    ) {
      return appsToAdd(appNameProjectId.reportExtractorAddOnId, app);
    }
  };
  const [scroll, setScroll] = useState("paper");
  const [currentOrderId, setCurrentOrderId] = useState("");

  return (
    <DetailTableWrapper>
      {isInstalledAppLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {isEmpty(filteredApps) ? (
            <NoInstalledApp />
          ) : (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <BoldTableCell align="left">APP NAME</BoldTableCell>
                    <BoldTableCell align="left">STATUS</BoldTableCell>
                    <BoldTableCell align="center">DETAILS</BoldTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredApps.map((app) =>
                    app.appProductUId !== undefined
                      ? app.appProductUId.map((singleApp) =>
                        appsToAdd(singleApp, app)
                      )
                      : appsToAdd(app.ProductUId, app)
                  )}
                  {addReportExtractor(filteredApps[0])}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      )}

      <AppDetailedDialog
        open={open}
        handleClose={handleClose}
        scroll={scroll}
        filteredApps={filteredApps}
        currentOrderId={currentOrderId}
        classes={classes}
      />
    </DetailTableWrapper>
  );
};

export default InstalledApps;
