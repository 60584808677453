import React, { Fragment, useEffect, useState } from "react";
import {
  HoverTableRow,
  StatusWrapper,
  TableFirstRow,
  StyledTableFirstRow,
} from "../FuiStyles";
import {
  TableCell,
  Radio,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import StatusIcon from "../StatusIcon";
import { BorderlessButton } from "../../common/CommonComponents/Buttons";

const AllInstances = ({
  handleClickOpenInstalledApps,
  handleClickOpenDetails,
  sortFilteredInstances,
  filteredInstances,
  instanceType,
  screen,
  handleChange,
  selectedInstanceId,
  isLoading,
  classes,
  isPAM,
}) => {
  const [filteredInstanceByType, setFilteredInstanceyType] = useState([]);
  const [thereIsNothingToDisplay, setThereIsNothingToDisplay] = useState(false);

  useEffect(() => {
    let data = [];
    if (screen === "platforms") {
      data = sortFilteredInstances().filter((item) => {
        if (instanceType === "All Platforms") {
          return item;
        } else {
          return (
            item.productName
              .toLowerCase()
              .indexOf(instanceType.toLowerCase()) !== -1
          );
        }
      });
    } else if (screen === "apps") {
      data = filteredInstances.filter((item) => {
        if (instanceType === "All Platforms") {
          return item;
        } else {
          return (
            item.productName
              .toLowerCase()
              .indexOf(instanceType.toLowerCase()) !== -1
          );
        }
      });
    }
    setThereIsNothingToDisplay(data.length === 0 ? true : false);
    setFilteredInstanceyType(data);
  }, [sortFilteredInstances, filteredInstances, instanceType, screen]);

  return (
    <Fragment>
      <TableHead>
        <TableRow>
          {screen === "apps" && <StyledTableFirstRow />}
          <TableFirstRow align="center">ORDER ID</TableFirstRow>
          <TableFirstRow align="center">CLIENT NAME</TableFirstRow>
          <TableFirstRow align="center">PRODUCT NAME</TableFirstRow>
          <TableFirstRow align="center">DOMAIN CONTEXT ROOT</TableFirstRow>
          <TableFirstRow align="center">STATUS</TableFirstRow>
          <TableFirstRow align="center">DETAILS</TableFirstRow>
          <TableFirstRow align="center">INSTALLED APPS</TableFirstRow>
        </TableRow>
      </TableHead>
      {!isLoading && (
        <TableBody>
          {screen === "apps" && thereIsNothingToDisplay && isPAM ? (
            <TableRow>
              <TableCell
                style={{
                  height: "100%",
                  width: "100%",
                }}
                colSpan={7}
                align="center"
              >
                <Typography
                  style={{
                    color: " #0041F0",
                    textAlign: "center",
                    fontSize: "2em",
                  }}
                >
                  No available PAM instances found
                </Typography>
              </TableCell>
            </TableRow>
          ) : screen === "platforms" && thereIsNothingToDisplay ? (
            <TableRow>
              <TableCell
                style={{
                  height: "100%",
                  width: "100%",
                }}
                colSpan={7}
                align="center"
              >
                <Typography
                  style={{
                    color: " #0041F0",
                    textAlign: "center",
                    fontSize: "2em",
                  }}
                >
                  There is nothing to Display.
                </Typography>
              </TableCell>
            </TableRow>
          ) : screen === "platforms" && filteredInstanceByType ? (
            filteredInstanceByType.map((row, key) => (
              <HoverTableRow key={key}>
                <TableCell align="center">{row.orderItemUId}</TableCell>
                <TableCell align="center">{row.clientName === "myWizard AiOps" ? "GenWizard AiOps" : row.clientName}</TableCell>
                <TableCell align="center">{row.productName}</TableCell>
                <TableCell align="center">{row.domainContextRoot}</TableCell>
                <TableCell align="center">
                  <StatusWrapper>
                    <StatusIcon status={row.status} />
                    <div>{row.status}</div>
                  </StatusWrapper>
                </TableCell>
                <TableCell align="center">
                  <BorderlessButton
                    data-cy="detailsInstanceHistoryTable"
                    onClick={handleClickOpenDetails("paper", row.orderId)}
                    label={"Details"}
                    className={classes.FuiBorderlessButton}
                  />
                </TableCell>
                <TableCell align="center">
                  <BorderlessButton
                    data-cy="installedAppsInstanceHistoryTable"
                    onClick={handleClickOpenInstalledApps("paper", row.orderId)}
                    className={classes.FuiBorderlessButton}
                    label={"Installed Apps"}
                  />
                </TableCell>
              </HoverTableRow>
            ))
          ) : (
            filteredInstanceByType &&
            filteredInstanceByType
              .filter((row) => row.status !== "Stuck")
              .map((row, key) => (
                <HoverTableRow key={key}>
                  <TableCell align="center">
                    <Radio
                      data-cy="instanceRadioButton"
                      checked={selectedInstanceId === `${row.orderId}`}
                      onChange={handleChange}
                      value={row.orderId}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center">{row.orderItemUId}</TableCell>
                  <TableCell align="center">{row.clientName === "myWizard AiOps" ? "GenWizard AiOps" : row.clientName}</TableCell>
                  <TableCell align="center">{row.productName}</TableCell>
                  <TableCell align="center">{row.domainContextRoot}</TableCell>
                  <TableCell align="center">
                    <StatusWrapper>
                      <StatusIcon status={row.status} />
                      <div>{row.status}</div>
                    </StatusWrapper>
                  </TableCell>
                  <TableCell align="center">
                    <BorderlessButton
                      onClick={handleClickOpenDetails("paper", row.orderId)}
                      className={classes.FuiBorderlessButton}
                      label={"Details"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <BorderlessButton
                      onClick={handleClickOpenInstalledApps(
                        "paper",
                        row.orderId
                      )}
                      className={classes.FuiBorderlessButton}
                      label={"Installed Apps"}
                    />
                  </TableCell>
                </HoverTableRow>
              ))
          )}
        </TableBody>
      )}
    </Fragment>
  );
};

export default AllInstances;
