import React from "react";
import { connect } from "react-redux";
import StandardOrder from "./StandardOrder";
import AppStepper from "./AppStepper";
import { withRouter } from "react-router-dom";
import * as fuiRedux from "../../redux/fui/index";
import PropTypes from "prop-types";
import PlatformLicenseStepper from "./PlatformLicenseStepper";

/**
 * Fui page for platforms
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: FulfilmentUI
 */
const FuiHomePage = ({
  match: {
    params: { routeName },
  },
  classes,
}) => {
  // create function that guide to selected products
  const renderProduct = () => {
    switch (routeName) {
      case undefined:
        return <StandardOrder />;
      case "managedPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "dedicatedPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "utkmPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "genWizardPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "adPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "windowsProvisioningPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "plainInstance":
        return <PlatformLicenseStepper classes={classes} />;
      case "plainInstanceMcc":
        return <PlatformLicenseStepper classes={classes} />;
      case "pamInstance":
        return <PlatformLicenseStepper classes={classes} />;
      case "automaticTicketResolver":
        return <PlatformLicenseStepper classes={classes} />;
      case "eventOpsPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "quasarpp":
        return <PlatformLicenseStepper classes={classes} />;
      case "stormseer":
        return <PlatformLicenseStepper classes={classes} />;
      //case "automaticTicketResolverSaaS":
      //  return <PlatformLicenseStepper classes={classes} />;
      case "splunkClusterPlatform":
        return <PlatformLicenseStepper classes={classes} />;
      case "stack360":
        return <PlatformLicenseStepper classes={classes} />;
      case "onPremiseService":
        return <PlatformLicenseStepper classes={classes} />;
      case "automationAnywhereLicenses":
        return <PlatformLicenseStepper classes={classes} />;
      default:
        return <AppStepper classes={classes} />;
    }
  };
  return <div>{renderProduct()}</div>;
};

const MSTP = (state) => ({
  listenerClearLocalData: state.fui.listenerClearLocalData,
});
FuiHomePage.propTypes = {
  /**
   * Match property of route library
   */
  match: PropTypes.object,
  /**
   *
   */
  clearLocalComponentData: PropTypes.func,
  /**
   * Redux function to update an instance.
   */
  updateASelectedInstance: PropTypes.func,
};

export default withRouter(
  connect(MSTP, { updateASelectedInstance: fuiRedux.updateASelectedInstance })(
    FuiHomePage
  )
);
