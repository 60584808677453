import styled from "styled-components";
import { Link } from "react-router-dom";
import { ListItem, Grid, Paper, Menu, Avatar } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

export const StyledLink = styled(Link)`
  &&&&& {
    width: 100%;
    color: ${(props) => (props.color ? props.color : "white")};
    text-decoration: none;
  }
`;

export const GridScreenNameNavbar = styled(Grid)`
  &&& {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
export const StyledTitle = styled.div`
  &&& {
    color: #000000;
    padding-left: 0.4em;
    font-size: 1.6em;
    font-weight:400;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1246px) {
      font-size: 1.4em;
    }
    @media (max-width: 620px) {
      font-size: 1.2em;
    }
  }
`;

export const StyledSpan = styled.span`
  &&& {
    cursor: pointer;
  }
`;

export const StyledListCloudOptionsPosition = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledAccountMenu = styled(Menu)`
  &&& {
    margin-left: 2em;
  }
`;

export const StyledUserAvatar = styled(Avatar)`
  &&& {
    color: ${(props) => (props.color ? props.color : undefined)};
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
`;

export const StyledPaperElevation = styled(Paper)`
  &&& {
    background: ${(props) => (props.color ? "#989FA4" : "#5E656D")};
    :hover {
      background: #989fa4;
    }
    width: 90%;
  }
`;

export const StyledListItem = styled(ListItem)`
  &&& {
    padding: ${(props) => (props.padding ? "1.2em 0 1.3em 0" : "0")};
    /* margin: 0; */
    display: flex;
    justify-content: center;
    background-color: none;
    height: ${(props) => props.height && "3.6em"};
  }
`;

export const StyledMuiPickersUtilsProvider = styled(MuiPickersUtilsProvider)`
  &&&&&&&& {
    margin: 0px;
    #date-picker-dialog-label {
      color: #f2f2f2;
    }
  }
`;
