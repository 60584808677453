//Create function to verify whether ServiceNowTicket begins with RITM
export function checkServiceNowTicket(input) {
  return input.length !== 0 ? /^RITM([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/.test(input) || /^ritm([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/.test(input) : true;
}


//Create function to verify whether WBSECode begin with 4 capital letters and end with 4 numbers
export function checkWBSECode(input) {
  return /(?=^.{8}$)(?=(.*[a-zA-Z]){1,6})(?=(.*[0-9]){2,7})/.test(input);
}

//Create function to verify whehter MonthlyTicketVolume is a number large than 0 and less than 9
export function checkMonthlyTicketVolume(input) {
  return input ? /^[1-9][0-9]*$/.test(input) : true;
}

//Create function to verify whehter values are numbers large than 0 and less than 9
export function checkDailyDataVolume(input) {
  return input ? /^[0-9]{0,}$/.test(input) : true;
}

//Function to check Concurrent users is a numeric value
export function checkConcurrentUsers(input) {
  return input ? /^[0-9]{0,1}$/.test(input) : true;
}

//Create function to verify whehter value is a number large than 0
export function checkBenefitsExpected(input) {
  return input ? /^[0-9]{0,}$/.test(input) : true;
}

//Function to verify if enterprise id is valid
export function isEidValid(input) {
  return input.length === 0 ? false : /^[a-zA-Z._]*$/.test(input);
}

//Function to verify if secondary enterprise id is valid
export function checkSecondaryCustomerID(input) {
  return input.length === 0 ? false : /^[a-zA-Z._]*$/.test(input);
}

//Function to check notes values
export function checkNotesValue(input) {
  return input ? /^[^\s].*/.test(input) : false;
}

//Function to check project values
export function checkProjectValue(input) {
  return input ? /^[^\s].*/.test(input) : false;
}

//Function to check a license is requested
export function checkLicenses(input) {
  return input ? input > -1 : true;
}

export function checkTeams(input) {
  return input ? /^[A-Za-z0-9]{0,}$/.test(input) : true;
}
