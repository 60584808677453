import React from "react";
import theme from "../../../theme/theme";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

export const CommonDialog = (props) => {
  /**
   * Common reusable Dialog Style
   * @author Isabel Sprengers
   * @version 1.0
   */
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle
        style={{
          backgroundColor:
            props.dialogType === "servers"
              ? theme.palette.aws.main
              : props.dialogType === "fui"
              ? theme.palette.fui.main
              : props.dialogType === "ssh"
              ? theme.palette.ssh.main
              : props.dialogType === "admin"
              ? theme.palette.admin.main
              : theme.palette.secondary.main,
          color: "white",
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent style={{ overflowY: "unset" }}>
        {props.textContent ? (
          <DialogContentText style={{ color: "black", fontWeight: "500" }}>{props.textContent}</DialogContentText>
        ) : (
          <div></div>
        )}
      </DialogContent>
      {props.dialogContent}
      {props.dialogActions}
    </Dialog>
  );
};
