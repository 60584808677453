import React from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "react-router-dom";
import {
  IconButton,
  Paper,
  Tooltip,
  Typography
  // , Grid
} from "@material-ui/core";
import {
  StyledAvatar,
  FinalWrapper,
  SuccessWrapper,
  OrderResultWrapper,
  SuccessSecondLineWrapper,
  ResultTitleTypography,
  IdTypography,
  StyledFileCopyIcon,
  SuccessTypography,
} from "./FuiStyles";
import { submitSuccess } from "./Constants/LongText";
import DoneIcon from "@material-ui/icons/Done";
import PropTypes from "prop-types";
import { AcceptButton } from "../common/CommonComponents/Buttons";
import {CircularProgress} from "@material-ui/core";
/**
 * Components which shows hints to users
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: All platforms and licenses, and appstepper
 */

const l3Url =
  "https://mywizard-aiops.accenture.com/resources/support-requests/";

const SubmitSuccess = ({ history, orderItemUId, isLoading, approverName, requester, isUserApprovalWhitelisted}) => {
  approverName = approverName.split("@")[0];
  return (
    <Paper square elevation={0}>
      <FinalWrapper>
        {isLoading ? (
          <div>
            <div
              style={{
                paddingTop: "8%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
            <div
              style={{
                paddingTop: "8%",
                paddingBottom: "8%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <h4>Please wait.... </h4>
              <p>Submitting your request</p>
              <p style={{ lineHeight: "0.1px" }}><i>This may take up to a minute....</i></p>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <SuccessWrapper>
              <StyledAvatar>
                <DoneIcon />
              </StyledAvatar>
              <SuccessTypography style={{ margin: "5em 1em 2em 0.9em" }}>{submitSuccess}</SuccessTypography>
            </SuccessWrapper>
            <Typography>Your Request ID: <b>{orderItemUId}</b><CopyToClipboard text={orderItemUId}>
              <Tooltip title="Copy">
                <IconButton>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard></Typography>
            <Typography style={{ marginTop: "1.5rem" }}>{approverName === requester || isUserApprovalWhitelisted ? "" : "Please note that your order request details have been sent to the approver." } <br></br>You will receive a confirmation email shortly, which will include the necessary details of your order.
              If you have any questions or <br></br>need further assistance, please feel free to raise an <a href={l3Url} target="_blank" rel="noopener noreferrer">
                L3 Ticket
              </a>.</Typography>

            <div style={{ width: "25%", display: "block", margin: "75px auto" }}>
              <AcceptButton
                style={{ color: '#7500C0' }}
                onClick={() => history.push("/fui/order")}
                label={"GO BACK TO ORDERS PAGE"}
              />
            </div>
          </div>
        )}
      </FinalWrapper>
    </Paper>
  );
};

const MSTP = (state) => ({
  isLoading: state.fui.isLoading,
});

SubmitSuccess.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * A UUID for each platform/app/license order
   */
  orderItemUId: PropTypes.string,
};

export default connect(MSTP)(withRouter(SubmitSuccess));
