import React, { Fragment, useEffect, useState, useRef } from "react";
import { Grid, CircularProgress, DialogActions, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import { Subtext } from "../common/CommonStyles/CommonTypographyStyles";
import { connect } from "react-redux";
import axios from "axios";
import base from "../../config";
import {
  StyledDivider,
  StyledSSHKeyIcon,
  UpdateButton,
  StyledHeaderCard,
  StyledHourglass,
  SSHSmallHeader,
  StyledPendingApprovalsGrid,
} from "./SshStyles";
import {
  LargeSubtitle,
  LargeNumber,
} from "../common/CommonStyles/CommonTypographyStyles";
import theme from "../../theme/theme";
import AccessFormModal from "./AccessFormModal";
import PropTypes from "prop-types";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { DeclineButton, AcceptButton } from "../common/CommonComponents/Buttons";
import QrCode from "./QrCode";
import { StyledClickableElementForFile } from "../ec2management/ServerList/styledServerList";

/**
 * SSH Header component of ssh page
 * @author Isabel Sprengers
 * @version 1.1
 * @see Used in component: Ssh.js
 */

const SSHHeader = ({
  lastUpdated,
  pendingData,
  history,
  reload,
  downloadSSHConfigURL,
  // ResData object extraction
  resData,
  isRegistered,
  email,
  idToken,
}) => {
  const eId = email === undefined ? undefined : email.split("@")[0];
  const enableMFAURL = `${base.urls.apis.sshNew}mfa/enable`;
  const getMFAStatusURL = `${base.urls.apis.sshNew}mfa/status`;
  const getPresignedURL = `${base.urls.apis.faq}/mfa_registration_userguide.pdf`;
  const countOrderValues = (array, id) =>
    array.filter((obj) => obj.status === id).length;
  const draw = (context, link) => {
    const image = new Image();
    image.src = link;
    image.onload = () => {
      context.drawImage(image, 0, 0, 250, 250);
    };
  };
  const [openMFADialog, setOpenMFADialog] = useState(false);
  const [openMFAConfirmDialog, setOpenMFAConfirmDialog] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mfaData, setMfaData] = useState([]);
  const [initiative, setInitiative] = useState("");

  const l3Url =
    "https://mywizard-aiops.accenture.com/resources/support-requests/";

  const fetchFilePresignedUrl = (event) => {
    event.preventDefault();
    const urls = `${getPresignedURL}?customer_enterprise_id=${eId}`;
    axios.get(urls, options).then((res) => {
      const data = res.data
      window.open(data[0], "_blank");
    }).catch((error) => {
      console.log('Error', error)
    })
  };

  useEffect(() => {
    getMFAStatus();
    const interval = setInterval(() => {
      getMFAStatus();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const getMFAStatus = () => {
    const urls = `${getMFAStatusURL}?userepid=${eId}`;
    axios.get(urls, options).then((res) => {
      const data = res.data
      const isMFAVal = data[0]
      setInitiative(data[1])
      if ( isMFAVal === true) {
        setIsMFA(true)
      } else {
        setIsMFA(false)
      }
    }).catch((error) => {
      console.log('Error', error)
    })
  }

  const stringToList = (data) => {
    const emergencyScratchCodeArray = data.split(',');
    return emergencyScratchCodeArray.map((item) =>
      <p style={{ lineHeight: "1.5px" }}>{item}</p>
    );
  }

  const handleMFA = (value) => {
    if (value === true) {
      setIsLoading(true)
      const urls = `${enableMFAURL}?userepid=${eId}`;
      axios.get(urls, options).then((res) => {
        setMfaData(res.data)
        setIsLoading(false)
      }).catch((error) => {
        console.log('Error', error)
      })
      setOpenMFADialog(true);
    } else {
      setOpenMFAConfirmDialog(true);
    }
  }

  const handleEnableMFA = () => {
    setIsLoading(true)
    const urls = `${enableMFAURL}?userepid=${eId}`;
    axios.get(urls, options).then((res) => {
      setMfaData(res.data)
      setIsLoading(false)
    }).catch((error) => {
      console.log('Error', error)
    })
    setOpenMFAConfirmDialog(false);
    setOpenMFADialog(true);
  }


  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  return (
    <StyledHeaderCard>
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={3} style={{ height: "100%", maxWidth: "24%" }}>
          <Grid item xs={12} style={{ height: "100%" }}>
            <div style={{ height: "20%", width: "100%" }}>
              <SSHSmallHeader style={{ fontWeight: 400}}>SSH Setup</SSHSmallHeader>
            </div>
            <div style={{ height: "40%" }}>
              <Grid container style={{ height: "100%" }}>
                <Grid
                  item
                  xs={8}
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {lastUpdated === "loading" ? (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: `${theme.palette.ssh.main}` }}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      {lastUpdated !== "Not registered" && (
                        <LargeSubtitle
                          style={{
                            display: "flex",
                            // flexDirection : "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Last Updated:
                        </LargeSubtitle>
                      )}

                      <LargeSubtitle
                        style={{
                          height: `${lastUpdated === "Not registered" ? "55%" : "30%"
                            } `,
                          display: "flex",
                          // flexDirection : "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {lastUpdated}
                      </LargeSubtitle>

                    </Fragment>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <StyledSSHKeyIcon />
                </Grid>

              </Grid>
            </div>
            <div
              style={{
                height: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {lastUpdated !== "loading" && (
                <UpdateButton
                  variant="outlined"
                  onClick={() => history.push("/ssh/sshRegistration")}
                  style={{ fontSize: "17px" }}
                >
                  {isRegistered ? "Update SSH Key" : "Register SSH Key"}
                </UpdateButton>
              )}
            </div>
            {isRegistered ?
              <div
                style={{
                  height: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {lastUpdated !== "loading" && initiative === "myWizard AiOps" && (
                  <UpdateButton
                    variant="outlined"
                    onClick={() => handleMFA(isMFA)}
                    style={{ fontSize: "17px", fontWeight: "bold" }}
                  >
                    {isMFA ? "View MFA Setup" : "Enable MFA"}
                  </UpdateButton>
                )}
              </div>
              : " "

            }
          </Grid>
        </Grid>
        {/* <Grid container style={{ height: "100%" }}>
            <Grid item xs={7} style={{ height: "100%" }}>
              <div
                style={{
                  height: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SSHSmallHeader>SSH Setup</SSHSmallHeader>
              </div>
              <div style={{ height: "30%" }}>
                {lastUpdated === "loading" ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{ color: `${theme.palette.ssh.main}` }}
                    />
                  </div>
                ) : (
                  <Fragment>
                    {lastUpdated !== "Not registered" && (
                      <LargeSubtitle
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Last Updated:
                      </LargeSubtitle>
                    )}
                    <LargeSubtitle
                      style={{
                        height: `${
                          lastUpdated === "Not registered" ? "55%" : "40%"
                        } `,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {lastUpdated}
                    </LargeSubtitle>
                  </Fragment>
                )}
              </div>

              <div
                style={{
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {lastUpdated !== "loading" && (
                  <UpdateButton
                    variant="outlined"
                    onClick={() => history.push("/ssh/sshRegistration")}
                    style={{ fontSize: "17px", fontWeight: "bold" }}
                  >
                    {isRegistered ? "Update SSH Key" : "Register SSH Key"}
                  </UpdateButton>
                )}
              </div>
              { isRegistered ? 
                <div
                  style={{
                    height: "15%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {lastUpdated !== "loading" && (
                    <UpdateButton
                      variant="outlined"
                      onClick={() => handleMFA(isMFA)}
                      style={{ fontSize: "17px", fontWeight: "bold" }}
                    >
                      {isMFA ? "View MFA Setup" : "Enable MFA"}
                    </UpdateButton>
                  )}
                </div>
              : " "
              
              }
              
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledSSHKeyIcon/>
            </Grid> */}



        <StyledDivider variant="inset" orientation="vertical" flexItem />

        <Grid item xs={6} style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
              <div style={{ height: "20%", width: "100%" }}>
                <SSHSmallHeader style={{ fontWeight: 400}}>My Requests</SSHSmallHeader>
              </div>
              <div style={{ height: "55%" }}>
                <Grid container style={{ height: "100%" }}>
                  <Grid item xs={4} style={{ height: "100%" }}>
                    <LargeSubtitle
                      style={{
                        height: "30%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#7500c0"
                      }}
                    >
                      Pending
                    </LargeSubtitle>
                    <LargeNumber
                      style={{
                        color: "black",
                        height: "70%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* the function that obtains the count from MyRequest stopped being used since
                        I made some modifications to the backend so that these values ​​are obtained in number type */}
                      {resData.pending}
                    </LargeNumber>
                  </Grid>
                  <Grid item xs={4} style={{ height: "100%" }}>
                    <LargeSubtitle
                      style={{
                        height: "30%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#7500c0"
                      }}
                    >
                      Approved
                    </LargeSubtitle>
                    <LargeNumber
                      style={{
                        color:  "black",
                        height: "70%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* the function that obtains the count from MyRequest stopped being used since
                        I made some modifications to the backend so that these values ​​are obtained in number type */}
                      {resData.approved}
                    </LargeNumber>
                  </Grid>
                  <Grid item xs={4} style={{ height: "100%" }}>
                    <LargeSubtitle
                      style={{
                        height: "30%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#7500c0"
                      }}
                    >
                      Declined
                    </LargeSubtitle>
                    <LargeNumber
                      style={{
                        color:  "black",
                        height: "70%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* the function that obtains the count from MyRequest stopped being used since
                        I made some modifications to the backend so that these values ​​are obtained in number type */}
                      {resData.declined}
                    </LargeNumber>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  height: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UpdateButton
                  href={downloadSSHConfigURL}
                  variant="outlined"
                  style={{ fontSize: "17px", width: "auto", margin: "auto" }}
                >
                  Download SSH Config File
                </UpdateButton>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <StyledDivider variant="inset" orientation="vertical" flexItem />

        <StyledPendingApprovalsGrid item xs={3} style={{ height: "100%" }}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
              <div
                style={{
                  height: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SSHSmallHeader style={{ fontWeight: 400}}>Pending Approvals</SSHSmallHeader>
              </div>
              <div style={{ height: "40%" }}>
                <Grid container style={{ height: "100%" }}>
                  <Grid
                    item
                    xs={5}
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <LargeNumber style={{ color: "black" }}>
                      {countOrderValues(pendingData, "pending")}
                    </LargeNumber>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StyledHourglass />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  height: "36%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AccessFormModal reload={reload} />
              </div>
            </Grid>
          </Grid>
        </StyledPendingApprovalsGrid>
      </Grid>
      {openMFADialog && (
        <CommonDialog
          open={openMFADialog}
          onClose={() => setOpenMFADialog(false)}
          title={"MFA Registration"}
          dialogType={"ssh"}
          dialogContent={
            isLoading ? (
              <DialogContent style={{ padding: "30px 100px" }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={60}
                    style={{ color: `${theme.palette.ssh.main}` }}
                  />
                </div>
                <div
                  style={{
                    paddingTop: "8%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <h4>Please wait.... </h4>
                  <p>Fetching your details.</p>
                  <p style={{ lineHeight: "0.1px" }}><i>This may take up to a minute....</i></p>
                </div>
              </DialogContent>
            ) : (
              <DialogContent>
                {mfaData.map(data => {
                  return (
                    <div>
                      {(() => {
                        if (data.ticketStatus === 'PENDING') {
                          return (
                            <div style={{ padding: "24px 40px" }}>
                              <div
                                style={{
                                  // paddingTop: "3%",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h3 style={{ color: `${theme.palette.ssh.main}` }}>Your registration is in process.</h3>
                                <h4>Please wait.... </h4>
                                <p>Initial setup will take sometime....</p>
                                <p style={{ lineHeight: "0.5px" }}><i> Please check again for status after few minutes. </i></p>
                              </div>
                            </div>
                          )
                        } else if (data.ticketStatus === 'FAILED') {
                          return (
                            <div style={{ padding: "24px 40px" }}>
                              <div
                                style={{
                                  // paddingTop: "3%",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h3 style={{ color: `red` }}>Oops, an error occurred!</h3>
                                <h4>Looks like something went wrong during the MFA setup.</h4>
                                <p>Please reach out to support team by raising a {" "}
                                  <a href={l3Url} target="_blank" rel="noopener noreferrer">
                                    support ticket
                                  </a> to further investigate on this.</p>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              <div>
                                <h3><b>Please find the details of your MFA setup</b></h3>
                                <p>Scan the QR code using google authenticator app installed in your mobile OR enter the Secret Key provided.</p>
                                <p>
                                  For further information, please refer this {" "}
                                  <StyledClickableElementForFile
                                    variant="body2"
                                    onClick={(e) => fetchFilePresignedUrl(e)}
                                  >
                                    userguide
                                  </StyledClickableElementForFile >.
                                </p>
                              </div>
                              <Subtext style={{ textAlign: "initial", color: "red", opacity: "100%", paddingBottom: "3%" }}>
                                Note: Copy and keep the secret key for future use and emergency scratch codes to be used when codes generated in google authenticator app is not working due to time constraint.
                              </Subtext>
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <QrCode draw={draw} height={250} width={250} link={data.s3link} />
                                </div>
                                <p><b>Your secret key is:</b> {data.secretKey} </p>
                                {data.hasOwnProperty("verificationCode") ?
                                  <p><b>Your verification code is:</b> {data.verificationCode}</p> : " "
                                }
                                <p><b>Your emergency scratch codes are:</b> </p>
                                <p>{stringToList(data.emergencyScratchCode)}</p>
                              </div>
                            </div>
                          )
                        }
                      })()}
                    </div>
                  );
                })}
              </DialogContent>
            )

          }
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setOpenMFADialog(false)}
                label={"Close"}
                disabled={false}
              />
            </DialogActions>
          }
        ></CommonDialog>
      )}
      {openMFAConfirmDialog && (
        <CommonDialog
          open={openMFAConfirmDialog}
          onClose={() => setOpenMFAConfirmDialog(false)}
          title={"MFA Registration"}
          dialogType={"ssh"}
          textContent={"Are you sure you want to enable MFA now?"}
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setOpenMFAConfirmDialog(false)}
                label={"No"}
                disabled={false}
              />
              <AcceptButton
                onClick={() => handleEnableMFA()}
                label={"Yes"}
              />
            </DialogActions>
          }
        ></CommonDialog>
      )}
    </StyledHeaderCard>
  );
};

const mapStateToProps = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  activeAccount: state.auth.activeAccount,
});

SSHHeader.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Contains date of last time ssh was updated
   */
  lastUpdated: PropTypes.string,
  /**
   * Contains data of all pending requests
   */
  pendingData: PropTypes.array,
  /**
   * Contains data of all requests
   */
  idToken: PropTypes.object,
  /**
   * Boolean that is used to trigger when a reload should be done
   */
  isRegistration: PropTypes.bool,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  downloadSSHConfigURL: PropTypes.string,
};

export default connect(mapStateToProps)(SSHHeader);

