import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  TitleColumnWrapper,
  SSHRegistration,
  StyledCheckIcon,
  StyledCloseIcon,
  StyledPageContainer,
  StyledFirstRow,
  StyledHeaderTitle,
  StyledEC2Header,
  StyledSSHHeader,
  StyledFUIHeader,
  StyledEC2Number,
  StyledFUINumber,
  StyledSSHNumber,
  StyledSSHNumberWrapper,
  StyledFUINumberWrapper,
  StyledEC2NumberWrapper,
  SelectorWrapper,
  SshInstanceType,
  TypeName,
  InstanceNumber,
  SSHNumber,
  RowContentWrapper,
  FuiWrapper,
  WrapperDashboard,
  ServersWrapper,
  ServersInstanceType,
} from "./DashboardStyles";
import RowContent from "./RowContent";
import {
  fetchEc2Data,
  fetchFuiInstanceTotalNumber,
  fetchFuiInstanceMPNumber,
  fetchFuiInstanceDPNumber,
  fetchFuiInstancePINumber,
  fetchSshPendingApprovals,
  fetchSshMyRequests,
  checkIsRegistration,
  getPendingApprovals,
  getPendingMyRequests,
  fetchFuiInstanceATRNumber,
  fetchFuiInstanceSplunkClusterNumber,
  fetchFuiInstancePamNumber,
  fetchFuiInstanceTuringNumber,
  fetchNotifications,
  fetchDailyDataVolume,
} from "../../redux/home";
import { fetchServersInstances } from "../../redux/ServersReducer/ServersActions";
import { tokenValidation } from "../../helper/customizedHooks";
import { CircularProgress, Grid } from "@material-ui/core";
import { Footer } from "../common/Footer";
import theme from "../../theme/theme";
import { LargeHeader } from "../common/CommonStyles/CommonTypographyStyles";

/**
 * The dashboard page
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: N/A
 */

const App = ({
  fetchEc2DataAction,
  fetchFuiInstanceTotalNumberAction,
  fuiInstanceTotalNumberData,
  fetchFuiInstanceMPNumberAction,
  fetchFuiInstanceDPNumberAction,
  fetchFuiInstancePINumberAction,
  fuiMPNumberData,
  fuiDPNumberData,
  fuiPINumberData,
  fetchSshPendingApprovalsAction,
  fetchSshMyRequestsAction,
  checkIsRegistrationAction,
  isRegistration,
  ec2Data,
  notificationsIamAccessKeyData,
  dailyDataVolumeData,
  email,
  getPendingApprovalsAction,
  fetchNotificationsAction,
  fetchDailyDataVolumeAction,
  pendingApprovals,
  getPendingMyRequestsAction,
  pendingMyRequests,
  fetchServersInstancesAction,
  instancesNewTags,
  serversValuesRed,
  idToken,
  servers_isLoading,
  fuiInstanceNumberLoading,
  fui_MP_NumberLoading,
  fui_DP_NumberLoading,
  fui_PI_NumberLoading,
  pendingApprovalsLoading,
  isRegistrationLoading,
  pendingMyRequestsLoading,
  fuiATRNumberData,
  fuiSplunkClusterNumberData,
  fuiPamNumberData,
  fuiTuringNumberData,
  fui_ATR_NumberLoading,
  fui_Splunk_Cluster_NumberLoading,
  fui_Pam_NumberLoading,
  fetchFuiInstanceATRNumberAction,
  fetchFuiInstanceSplunkClusterNumberAction,
  fetchFuiInstancePamNumberAction,
  fetchFuiInstanceTuringNumberAction,
  camPermissions,
}) => {
  useEffect(() => {
    let timer;
    const eId = email === undefined ? undefined : email.split("@")[0];
    if (email && idToken) {
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchEc2DataAction();
          fetchFuiInstanceTotalNumberAction();
          fetchSshPendingApprovalsAction();
          fetchSshMyRequestsAction();
          fetchFuiInstanceMPNumberAction();
          getPendingApprovalsAction();
          fetchNotificationsAction();
          fetchDailyDataVolumeAction();
          getPendingMyRequestsAction();
          fetchFuiInstanceDPNumberAction();
          fetchFuiInstancePINumberAction();
          fetchFuiInstanceATRNumberAction();
          fetchFuiInstanceSplunkClusterNumberAction();
          fetchFuiInstancePamNumberAction();
          fetchFuiInstanceTuringNumberAction();
          checkIsRegistrationAction(eId, idToken);
          fetchServersInstancesAction(eId, idToken);
        };
        apiCall();
      }, 4000);
      return () => clearInterval(timer);
    }
  }, [ec2Data, notificationsIamAccessKeyData, dailyDataVolumeData, email, idToken]);

  const userRbac = JSON.parse(
    localStorage.getItem("userRoleBasedAccessControl")
  );
  const currentCamPermissions =
    userRbac !== null ? userRbac.data : camPermissions;

  return (
    <div>
      <WrapperDashboard>
        {Object.keys(currentCamPermissions).length !== 0 ? (
          <>
            <div>
              {currentCamPermissions[0].filter(
                (permission) => permission.component === "ServerManagementComponent"
              ).length !== 0 && (
                  <StyledPageContainer elevation={2}>
                    <TitleColumnWrapper
                      data-cy="ec2managementDashboardRow"
                      to="/servers"
                    >
                      <StyledFirstRow>
                        <StyledEC2Header elevation={2}>
                          <StyledHeaderTitle>Servers</StyledHeaderTitle>
                        </StyledEC2Header>

                        <StyledEC2NumberWrapper
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {servers_isLoading ? (
                            <CircularProgress style={{ color: "#7500C0" }} />
                          ) : (
                            <StyledEC2Number>{serversValuesRed}</StyledEC2Number>
                          )}
                        </StyledEC2NumberWrapper>
                      </StyledFirstRow>
                      <RowContentWrapper loading={servers_isLoading}>
                        {servers_isLoading ? (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress style={{ color: "#7500C0" }} />
                          </div>
                        ) : serversValuesRed > 0 ? (
                          <RowContentWrapper>
                            <RowContent serverList={instancesNewTags} />
                          </RowContentWrapper>
                        ) : (
                          <ServersWrapper>
                            <ServersInstanceType>
                              <Fragment>
                                <TypeName>Servers: </TypeName>
                                <InstanceNumber>{serversValuesRed}</InstanceNumber>
                              </Fragment>
                            </ServersInstanceType>
                          </ServersWrapper>
                        )}
                      </RowContentWrapper>
                    </TitleColumnWrapper>
                  </StyledPageContainer>
                )}
              {currentCamPermissions[0].filter(
                (permission) => permission.component === "FulfillmentUI"
              ).length !== 0 && (
                  <StyledPageContainer elevation={2}>
                    <TitleColumnWrapper to="/fui/order">
                      <StyledFirstRow>
                        <StyledFUIHeader elevation={2}>
                          <StyledHeaderTitle>Fulfillment</StyledHeaderTitle>
                        </StyledFUIHeader>
                        <StyledFUINumberWrapper
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {fuiInstanceNumberLoading ? (
                            <CircularProgress />
                          ) : (
                            <StyledFUINumber>
                              {fuiInstanceTotalNumberData}
                            </StyledFUINumber>
                          )}
                        </StyledFUINumberWrapper>
                      </StyledFirstRow>
                      <FuiWrapper style={{ width: "100%" }}>
                        {fui_MP_NumberLoading ||
                          fui_DP_NumberLoading ||
                          fui_PI_NumberLoading ||
                          fui_ATR_NumberLoading ||
                          fui_Splunk_Cluster_NumberLoading ||
                          fui_Pam_NumberLoading ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <Grid
                            container
                            spacing={1}
                          >
                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>Managed Platform:</TypeName>
                                <InstanceNumber>{fuiMPNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>
                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>Dedicated Platform:</TypeName>
                                <InstanceNumber>{fuiDPNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>

                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>Plain Instance:</TypeName>
                                <InstanceNumber>{fuiPINumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>

                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>ATR Instance:</TypeName>
                                <InstanceNumber>{fuiATRNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>

                            {/* <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>Splunk Cluster:</TypeName>
                                <InstanceNumber>{fuiSplunkClusterNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid> */}

                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>PAM Instance:</TypeName>
                                <InstanceNumber>{fuiPamNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>

                            <Grid item xs={3}>
                              <SshInstanceType to="/fui/platforms">
                                <TypeName>Quasar++ Platform:</TypeName>
                                <InstanceNumber>{fuiTuringNumberData}</InstanceNumber>
                              </SshInstanceType>
                            </Grid>
                          </Grid>
                        )}
                      </FuiWrapper>
                    </TitleColumnWrapper>
                  </StyledPageContainer>
                )}
              {currentCamPermissions[0].filter(
                (permission) => permission.component === "Ssh"
              ).length !== 0 && (
                  <StyledPageContainer elevation={2}>
                    <TitleColumnWrapper to="/ssh/pendingApprovals">
                      <StyledFirstRow>
                        <StyledSSHHeader elevation={2}>
                          <StyledHeaderTitle>SSH Management</StyledHeaderTitle>
                        </StyledSSHHeader>
                        <StyledSSHNumberWrapper>
                          {pendingApprovalsLoading ? (
                            <CircularProgress
                              style={{ color: `${theme.palette.ssh.main}` }}
                            />
                          ) : (
                            <StyledSSHNumber>
                              {pendingApprovals && pendingApprovals.length}
                            </StyledSSHNumber>
                          )}
                        </StyledSSHNumberWrapper>
                      </StyledFirstRow>

                      <SelectorWrapper style={{ width: "100%" }}>
                        {isRegistrationLoading ||
                          pendingApprovalsLoading ||
                          pendingMyRequestsLoading ? (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              style={{ color: `${theme.palette.ssh.main}` }}
                            />
                          </div>
                        ) : (
                          <Fragment>
                            <SshInstanceType
                              hometoregistrationcy="homeToRegistrationCy"
                              to="/ssh/sshRegistration"
                            >
                              <TypeName>SSH Key Registration:</TypeName>
                              <SSHRegistration>
                                {isRegistration ? (
                                  <StyledCheckIcon style={{ color: "#7500C0" }} />
                                ) : (
                                  <StyledCloseIcon />
                                )}
                              </SSHRegistration>
                            </SshInstanceType>
                            <SshInstanceType
                              hometopendingcy="hometopendingCy"
                              to="/ssh/pendingApprovals"
                            >
                              <TypeName>Pending Approvals:</TypeName>
                              <SSHNumber>
                                {pendingApprovals && pendingApprovals.length}
                              </SSHNumber>
                            </SshInstanceType>
                            <SshInstanceType
                              hometomyrequestscy="homeToMyRequestsCy"
                              to="/ssh/sshMyRequests"
                            >
                              <TypeName>My Requests:</TypeName>
                              <SSHNumber>
                                {pendingMyRequests && pendingMyRequests.length}
                              </SSHNumber>
                            </SshInstanceType>
                          </Fragment>
                        )}
                      </SelectorWrapper>
                    </TitleColumnWrapper>
                  </StyledPageContainer>
                )}
              {currentCamPermissions[0].length === 0 && (
                <LargeHeader>
                  Hey! Looks like you don't have permission to view CAM, if you
                  think this is a mistake, or would like to request access, please
                  raise a Support Ticket at
                  https://mywizard-aiops.accenture.com/user-guide-to-raise-a-service-request/
                </LargeHeader>
              )}
            </div>
            <div style={{
              // position: "fixed",
              left: 96,
              bottom: 0,
              right: 26,
            }}
            >
              <Footer />
            </div>
          </>
        ) :
          <>
            <div>
              <CircularProgress style={{ color: "#7500C0", position: 'absolute', left: '50%', top: '50%' }} />
            </div>
            <div style={{
              position: "fixed",
              left: 96,
              bottom: 0,
              right: 26,
            }}
            >
              <Footer />
            </div>
          </>
        }
      </WrapperDashboard>

    </div>
  );
};

// App.PropTypes={

// }

const MSTP = (state, props) => ({
  ec2Data: state.home.ec2Data,
  notificationsIamAccessKeyData: state.home.notificationsIamAccessKeyData,
  dailyDataVolumeData: state.home.dailyDataVolumeData,
  isServersLoading: state.home.isServersLoading,
  isFulfillmentLoading: state.home.isFulfillmentLoading,
  isSshLoading: state.home.isSshLoading,
  serversValuesRed: state.home.serversValuesRed,
  isLoading: state.home.isLoading,
  fetchEc2Data: state.home.fetchEc2Data,
  fetchNotifications: state.home.fetchNotifications,
  fetchDailyDataVolume: state.home.fetchDailyDataVolume,
  fuiInstanceTotalNumberData: state.home.fuiInstanceTotalNumberData,
  fuiMPNumberData: state.home.fuiMPNumberData,
  fuiDPNumberData: state.home.fuiDPNumberData,
  fuiPINumberData: state.home.fuiPINumberData,
  isRegistration: state.home.isRegistration,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  pendingApprovals: state.home.pendingApprovals,
  pendingMyRequests: state.home.pendingMyRequests,
  instancesNewTags: state.servers.instancesNewTags,

  servers_isLoading: state.servers.isLoading,

  fuiInstanceNumberLoading: state.home.fuiInstanceNumberLoading,
  fui_MP_NumberLoading: state.home.fui_MP_NumberLoading,
  fui_DP_NumberLoading: state.home.fui_DP_NumberLoading,
  fui_PI_NumberLoading: state.home.fui_PI_NumberLoading,
  fuiATRNumberData: state.home.fuiATRNumberData,
  fuiSplunkClusterNumberData: state.home.fuiSplunkClusterNumberData,
  fuiPamNumberData: state.home.fuiPamNumberData,
  fuiTuringNumberData: state.home.fuiTuringNumberData,
  fui_ATR_NumberLoading: state.home.fui_ATR_NumberLoading,
  fui_Splunk_Cluster_NumberLoading: state.home.fui_Splunk_Cluster_NumberLoading,
  fui_Pam_NumberLoading: state.home.fui_Pam_NumberLoading,
  pendingApprovalsLoading: state.home.pendingApprovalsLoading,
  isRegistrationLoading: state.home.isRegistrationLoading,
  pendingMyRequestsLoading: state.home.pendingMyRequestsLoading,
  camPermissions: state.auth.camPermissions,
});

export default connect(MSTP, {
  fetchEc2DataAction: fetchEc2Data,
  fetchFuiInstanceTotalNumberAction: fetchFuiInstanceTotalNumber,
  fetchSshPendingApprovalsAction: fetchSshPendingApprovals,
  fetchSshMyRequestsAction: fetchSshMyRequests,
  fetchFuiInstanceMPNumberAction: fetchFuiInstanceMPNumber,
  fetchFuiInstanceDPNumberAction: fetchFuiInstanceDPNumber,
  fetchFuiInstancePINumberAction: fetchFuiInstancePINumber,
  checkIsRegistrationAction: checkIsRegistration,
  getPendingApprovalsAction: getPendingApprovals,
  getPendingMyRequestsAction: getPendingMyRequests,
  fetchServersInstancesAction: fetchServersInstances,
  fetchFuiInstanceATRNumberAction: fetchFuiInstanceATRNumber,
  fetchFuiInstanceSplunkClusterNumberAction: fetchFuiInstanceSplunkClusterNumber,
  fetchFuiInstancePamNumberAction: fetchFuiInstancePamNumber,
  fetchFuiInstanceTuringNumberAction: fetchFuiInstanceTuringNumber,
  fetchNotificationsAction: fetchNotifications,
  fetchDailyDataVolumeAction: fetchDailyDataVolume,
})(withRouter(App));
