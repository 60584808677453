import {
  SIDEBAR_DROPDOWN_OPTIONS,
  SIDEBAR_COLLAPSE_STATE,
  SIDEBAR_ON_HOVER_CLASSES,
} from "./CommonSidebarActionTypes";

export const sidebarDropdownOptionsAction = (selectedScreenOption) => ({
  type: SIDEBAR_DROPDOWN_OPTIONS,
  selectedScreenOption,
});

export const sidebarCollapseStateAction = (
  collapseFulfillmentState,
  collapseSshManagementState
) => ({
  type: SIDEBAR_COLLAPSE_STATE,
  collapseFulfillmentState,
  collapseSshManagementState,
});

export const sidebarHandleOnHoverClassAction = (
  sidebarContaineroOnHoverClasses,
  sidebarTextOnHoverClasses
) => ({
  type: SIDEBAR_ON_HOVER_CLASSES,
  sidebarContaineroOnHoverClasses,
  sidebarTextOnHoverClasses,
});
