import React, { useEffect, useState } from "react";
import Axios from "axios";
import base from "../../config";
import "./custom.css";
import 'react-toastify/dist/ReactToastify.css'
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
} from "@material-ui/core/";
import { ToastContainer, toast } from 'react-toastify';
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import AccessCards from "./AccessCards";
import RequestForm from "./RequestForm";
import Requests from "./Requests";
import { Footer } from "../common/Footer";

/**
   * @author shilpa.adikeshava
   * @version 1.0
 */

const AzureOpenAI = () => {

    const idToken = localStorage.getItem("token");
    const [loading, setLoading] = useState(true);
    const [accessData, setAccessData] = useState([]);
    const [pdfFileUrl, setPdfFileUrl] = useState("");
    const [pptFileUrl, setPptFileUrl] = useState("");
    const [requestData, setRequestData] = useState([]);


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        requestOpenAIButton: {
            backgroundColor: theme.palette.admin.main,
            color: "white",
            float: "right"
        },
    }));

    const classes = useStyles();

    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: idToken,
        },
    };

    useEffect(() => {
        getOpenAIAccessDataOnLoad();
        getOpenAIRequestsOnLoad();
        getDocLinks();
        const interval = setInterval(() => {
            getOpenAIRequests();
            getOpenAIAccessData();
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    const getOpenAIAccessDataOnLoad = () => {
        const urls = `${base.urls.apis.azure}/openai-access/read/all?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setAccessData(data)
            // setLoading(false)
        }).catch((error) => {
            console.log('Error', error)
        })
    }

    const getOpenAIAccessData = () => {
        const urls = `${base.urls.apis.azure}/openai-access/read/all?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setAccessData(data)
            setLoading(false)
        }).catch((error) => {
            console.log('Error', error)
        })
    }

    const getOpenAIRequests = () => {
        const urls = `${base.urls.apis.azure}/addopenai/read/all?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setRequestData(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getOpenAIRequestsOnLoad = () => {
        const urls = `${base.urls.apis.azure}/addopenai/read/all?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setRequestData(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getDocLinks = () => {
        const urls = `${base.urls.apis.azure}/openai-access?inititive=na`;
        Axios.get(urls, options).then((res) => {
            const data = res.data
            setPdfFileUrl(data[0].genai_wkshp_pdf)
            setPptFileUrl(data[0].hipaa_ppt)
        }).catch((error) => {
            console.log('Error', error)
        })
    }

    return (
        <>
            <div className={classes.root}>
                {loading ? (
                    <>
                        <div
                            style={{
                                marginTop: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress size={40} />
                        </div>
                        {/* <div style={{
                            position: "fixed",
                            left: 96,
                            bottom: 0,
                            right: 26,
                        }}>
                            <Footer />
                        </div> */}
                    </>
                ) : (
                    <div>
                        {/* {accessData.length == 3 ? "" : */}
                        {/* <PrimaryButton
                        label={"+ New App"}
                        onClick={() => setOpen(true)}
                        className={classes.requestOpenAIButton}
                    /> */}
                        {/* } */}
                        <ToastContainer autoClose={2000} />
                        <h2 style={{ fontSize: "23px", fontWeight: "500" }}>Active OpenAI</h2>
                        <AccessCards data={accessData} />
                        {/* <h2>OpenAI Requests</h2>
                    <Requests data={requestData} /> */}
                    </div>
                )}
                <CommonDialog
                    className="AzureOpenAIDialog"
                    open={open}
                    onClose={handleClose}
                    title={"Request for Open AI Access"}
                    dialogType={"fui"}
                    dialogContent={< RequestForm pdfFileUrl={pdfFileUrl} pptFileUrl={pptFileUrl} close={handleClose} />}
                ></CommonDialog>
            </div>

            <Footer />
            {/* </div> */}
        </>
    );
};

export default AzureOpenAI;
