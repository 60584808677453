import React, { useState, useEffect, useRef, Fragment } from "react";

// styled components/materialUI
import {
  Grid,
  Tooltip,
  // , Checkbox
  CircularProgress,
} from "@material-ui/core";
//components
import Search from "../common/CommonSearch/Search";
//redux
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { tokenValidation } from "../../helper/customizedHooks";
import { StatisticsPieCharts } from "./StatisticsPieCharts/StatisticsPieCharts";

import {
  fetchServersInstances,
  openMoreInformationAction,
  dropdownFiltersServersAction,
  searchFilterValueAction,
  dropdownScheudleFilterValueAction,
  changeLoadingStatus,
  isCheckboxServiceEndDateSelectedAction,
} from "../../redux/ServersReducer/ServersActions";
import ServersCard from "./ServerList/ServersCard/ServersCard";
import { fetchEc2Data } from "../../redux/home";

import {
  StyledTablePagination,
  NoMatches,
  StyledSmallSizeGridSearch,
  StyledFilterButton,
  StyledSxScreenCollapse,
  StyledGridNormalSize,
  StyledFiltersAndSearchWrapper,
  StyledStatisticsGridWrapper,
  StyledTypographyStatisticsInstances,
  StyledTypographyStatisticsInstancesNumber,
  StyledStatisticsIcon,
  StyledStatisticsButton,
  StyledGridSearchWrapper,
  StyledServersWrapper,
  ServersLoading,
} from "./StyledEc2ManagementComponent";

import { useCommonEc2ManagementStyles } from "../common/makeStylesMaterialUi/CommonMakeStyles";
import { ServersDropdownsFilters } from "./ServersDropDownsFilters/ServersDropdownsFilters";
import { CommonServersFilter } from "../../redux/scheduler/CommonServersFilter/CommonServersFilter";
import { BorderlessButton } from "../common/CommonComponents/Buttons";
import { Footer } from "../common/Footer";
import { SmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";

/**
 * The Ec2ManagementComponent should contain all the components of the Ec2Management Screen.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: Ec2ManagementComponent.js
 */

const Ec2Management = (props) => {
  const {
    email,
    history,
    idToken,
    isLoading,
    fetchServersAction,
    instancesNewTags,
    dropdownFiltersServers,
    dropdownFiltersAction,
    searchFilterValue,
    searchFilterAction,
    filteredDropdownObject,
    dropdownScheduleFilterAction,
    ec2Data,
    fetchServers,
    isCheckboxServiceEndDateSelected,
  } = props;

  const [page, setPage] = useState(0);

  // constant used to make the ec2 api calls every 4 secconds
  const secconds = 4000;

  // booleans to handle the screen size of the page
  const smallScreen = false;
  const xsScreen = false;
  const statisticsSmallTitle = false;
  const handleSmallStatisticsTitle = true;
  const [handleApiCall, setHandleApiCall] = useState(true);

  // it is used to keep the value of the instances shown in the Ec2Table
  // we also save and obtain this value from the localStorage
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage") === null
      ? 5
      : localStorage.getItem("rowsPerPage")
  );
  const [, setSearchQuery] = useState([]);

  // variables used to handle the ec2 filters
  const [byScheduleInputRef, setByScheduleInputRef] = useState(0);
  const [byStateInputRef, setByStateInputRef] = useState(0);
  const [byRegionInputRef, setByRegionInputRef] = useState(0);
  const ByScheduleRef = useRef(null);
  const ByStateRef = useRef(null);
  const ByRegionRef = useRef(null);

  const [regionInstances, setRegionInstances] = useState([]);
  const [renderFilteredInstances, setRenderFilteredInstances] = useState([]);

  const [expandStatistics, setExpandStatistics] = useState(false);

  useEffect(() => {
    if (ec2Data && ec2Data[2] && ec2Data[2].options) {
      setRegionInstances(ec2Data[2].options);
    }
  }, [ec2Data]);

  useEffect(() => {
    // array of filtered instances
    let res = [];
    // extract of instances that are near to expire.
    if (isCheckboxServiceEndDateSelected) {
      instancesNewTags.forEach((item) => {
        if (item.nearToExpire) {
          res.push(item);
        }
      });
    } else {
      res = instancesNewTags;
    }

    // filter of each dropdown type.
    let byScheduleOptionSearchCase = filteredDropdownObject.bySchedule;
    let byInstanceStateOptionSearchCase =
      filteredDropdownObject.byInstanceState;
    let byRegionOptionSearchCase = filteredDropdownObject.byRegion;
    let filteredInstancesSearchCase = CommonServersFilter(
      byScheduleOptionSearchCase,
      byInstanceStateOptionSearchCase,
      byRegionOptionSearchCase,
      res
    );

    // validation to accept clusterId in different format
    if (searchFilterValue.length > 0) {
      let instancesToRender = filteredInstancesSearchCase.filter((item) => {
        if (item.hasOwnProperty("clusterId")) {
          return (
            item.clusterId
              .toLowerCase()
              .indexOf(searchFilterValue.toLowerCase()) !== -1
          );
        } else if (item.hasOwnProperty("clusterid")) {
          return (
            item.clusterid
              .toLowerCase()
              .indexOf(searchFilterValue.toLowerCase()) !== -1
          );
        } else if (item.hasOwnProperty("clusterID")) {
          return (
            item.clusterID
              .toLowerCase()
              .indexOf(searchFilterValue.toLowerCase()) !== -1
          );
        } else {
          return null;
        }
      });
      setRenderFilteredInstances(instancesToRender);
    } else {
      setRenderFilteredInstances(filteredInstancesSearchCase);
    }
  }, [
    searchFilterValue,
    instancesNewTags,
    filteredDropdownObject,
    isCheckboxServiceEndDateSelected,
  ]);

  // getting the lenght of the FILTERS labels width
  useEffect(() => {
    if (ReactDOM.findDOMNode(ByScheduleRef.current)) {
      setByScheduleInputRef(
        ReactDOM.findDOMNode(ByScheduleRef.current).offsetWidth
      );
      setByStateInputRef(ReactDOM.findDOMNode(ByStateRef.current).offsetWidth);
      setByRegionInputRef(
        ReactDOM.findDOMNode(ByRegionRef.current).offsetWidth
      );
    }
  }, [instancesNewTags]);

  // getting the Ec2 information every 4 secconds
  useEffect(() => {
    let timer;
    if (handleApiCall && email && idToken) {
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchServersAction(email, idToken);
          fetchServers();
        };
        apiCall();
      }, secconds);
      return () => clearInterval(timer);
    } else {
      return () => clearInterval(timer);
    }
  }, [handleApiCall, email, idToken]);

  useEffect(() => {
    if (email && idToken) {
      fetchServers();
    }
  }, [email, idToken]);

  // Material UI function to handleChangePage
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Material UI function to handleChangeRowsPerPage
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };

  // The purpose of this functions is filter the values of the FILTERS dropdowns
  const handleFilterDropdown = (e, filterCase) => {
    let value = e.target.value;
    // copying the filteredDropdownObject
    let copy_filteredDropdownObject = { ...filteredDropdownObject };
    if (filterCase === "bySchedule") {
      let type =
        value === 10 ? "ALL" : value === 20 ? "ec2Scheduler" : "un-schedule";
      if (type === "ALL") {
        copy_filteredDropdownObject.bySchedule = {
          value,
          type,
          filterCase,
          selected: false,
        };
        // this action will send the new info to the reducer
        dropdownScheduleFilterAction(copy_filteredDropdownObject);
      } else {
        copy_filteredDropdownObject.bySchedule = {
          value,
          type,
          filterCase,
          selected: true,
        };
        // this action will send the new info to the reducer
        dropdownScheduleFilterAction(copy_filteredDropdownObject);
      }
    } else if (filterCase === "byInstanceState") {
      let type =
        value === 10
          ? "ALL"
          : value === 20
          ? "running"
          : value === 30
          ? "stopped"
          : value === 40
          ? "stopping"
          : value === 50
          ? "pending"
          : value === 60
          ? "terminating"
          : "LOADING";
      if (type === "ALL") {
        copy_filteredDropdownObject.byInstanceState = {
          value,
          type,
          filterCase,
          selected: false,
        };
        // this action will send the new info to the reducer
        dropdownScheduleFilterAction(copy_filteredDropdownObject);
      } else {
        copy_filteredDropdownObject.byInstanceState = {
          value,
          type,
          filterCase,
          selected: true,
        };
        // this action will send the new info to the reducer
        dropdownScheduleFilterAction(copy_filteredDropdownObject);
      }
    } else {
      let type = value === "default" ? "default" : regionInstances[value];
      copy_filteredDropdownObject.byRegion = {
        value: value === "default" ? "default" : value,
        type,
        filterCase,
        selected: type === "default" ? false : true,
      };
      // this action will send the new info to the reducer
      dropdownScheduleFilterAction(copy_filteredDropdownObject);
    }
  };

  // clearing all the leaked information
  const clearDropdownFilters = () => {
    let newfilteredDropdownObject = {
      bySchedule: {
        value: 10,
        type: "ALL",
        filterCase: "bySchedule",
        selected: false,
      },
      byInstanceState: {
        value: 10,
        type: "ALL",
        filterCase: "byInstanceState",
        selected: false,
      },
      byRegion: {
        value: "default",
        type: "default",
        filterCase: "byRegion",
        selected: false,
      },
    };
    // this action will send the new info to the reducer
    dropdownScheduleFilterAction(newfilteredDropdownObject);
  };

  // some ec2 classes
  const classes = useCommonEc2ManagementStyles();

  return (
    <Fragment>
      <StyledServersWrapper>
        {/* Dropdonws */}
        <StyledFiltersAndSearchWrapper item xs={12}>
          {xsScreen ? (
            <Grid container className={classes.paddingsOnFilters}>
              <Grid item xs={12}>
                <StyledSmallSizeGridSearch container>
                  <StyledFilterButton item xs={2}>
                    {/* This is the FILTERS BUTTON  SMALL SCREEN*/}
                    <Tooltip
                      title={
                        dropdownFiltersServers
                          ? "Close search filters"
                          : "Open search filters"
                      }
                      placement="right"
                    >
                      <BorderlessButton
                        disabled={
                          instancesNewTags && instancesNewTags.length === 0
                            ? true
                            : false
                        }
                        onClick={() => dropdownFiltersAction()}
                        startIcon={<FilterListIcon />}
                        label={"Filters"}
                        className={classes.filterButton}
                      />
                    </Tooltip>
                  </StyledFilterButton>
                  <StyledGridSearchWrapper item xs={6}>
                    {/* SEARCH for the small screen */}
                    <Search
                      arrayData={instancesNewTags}
                      setSearchQuery={setSearchQuery}
                      searchFilterValue={searchFilterValue}
                      searchFilterValueAction={searchFilterAction}
                      screenName="servers"
                      placeholderValue="Domain Context Root"
                    />
                  </StyledGridSearchWrapper>
                </StyledSmallSizeGridSearch>
              </Grid>
              <StyledSxScreenCollapse in={dropdownFiltersServers} timeout={200}>
                {/* Dropdowns FILTERS SMALL SIZE */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* This components have all the FILTERS Dropdown info */}
                      <ServersDropdownsFilters
                        ByStateRef={ByStateRef}
                        ByRegionRef={ByRegionRef}
                        ByScheduleRef={ByScheduleRef}
                        byStateInputRef={byStateInputRef}
                        byRegionInputRef={byRegionInputRef}
                        byScheduleInputRef={byScheduleInputRef}
                        handleFilterDropdown={handleFilterDropdown}
                        filteredDropdownObject={filteredDropdownObject}
                        regionInstances={regionInstances}
                        clearDropdownFilters={clearDropdownFilters}
                        smallScreen={smallScreen}
                        classes={classes}
                        filterCollapse={dropdownFiltersServers}
                        xsScreen={xsScreen}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </StyledSxScreenCollapse>
            </Grid>
          ) : (
            <Grid container spacing={2} className={classes.paddingsOnFilters}>
              <Grid item xs={9}>
                <StyledGridNormalSize container spacing={2}>
                  <StyledFilterButton item lg={2} md={2} xs={2}>
                    {/*This is the FILTERS BUTTON  NORMAL SCREEN */}
                    <Tooltip
                      title={
                        dropdownFiltersServers
                          ? "Close search filters"
                          : "Open search filters"
                      }
                      placement="left"
                    >
                      <BorderlessButton
                        disabled={
                          instancesNewTags && instancesNewTags.length === 0
                            ? true
                            : false
                        }
                        onClick={() => dropdownFiltersAction()}
                        startIcon={<FilterListIcon />}
                        label={"Filters"}
                        className={classes.filterButton}
                      />
                    </Tooltip>
                  </StyledFilterButton>
                  <Grid item lg={10} md={10} xs={10}>
                    {/* This components have all the FILTERS Dropdown info */}
                    <ServersDropdownsFilters
                      ByStateRef={ByStateRef}
                      ByRegionRef={ByRegionRef}
                      ByScheduleRef={ByScheduleRef}
                      byStateInputRef={byStateInputRef}
                      byRegionInputRef={byRegionInputRef}
                      byScheduleInputRef={byScheduleInputRef}
                      handleFilterDropdown={handleFilterDropdown}
                      filteredDropdownObject={filteredDropdownObject}
                      regionInstances={regionInstances}
                      clearDropdownFilters={clearDropdownFilters}
                      smallScreen={smallScreen}
                      classes={classes}
                      filterCollapse={dropdownFiltersServers}
                      xsScreen={xsScreen}
                    />
                  </Grid>
                </StyledGridNormalSize>
              </Grid>
              <StyledGridSearchWrapper item xs={3}>
                <Search
                  arrayData={instancesNewTags}
                  setSearchQuery={setSearchQuery}
                  searchFilterValue={searchFilterValue}
                  searchFilterValueAction={searchFilterAction}
                  screenName="servers"
                  placeholderValue="Domain Context Root"
                />
              </StyledGridSearchWrapper>
            </Grid>
          )}
        </StyledFiltersAndSearchWrapper>

        {/* Statistics */}
        <StyledStatisticsGridWrapper item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.paddingsOnStatistics}>
                <Grid item xs={6}>
                  {/* Statistics button to show the PieCharts */}
                  <StyledStatisticsButton
                    onClick={() =>
                      setExpandStatistics(
                        (expandStatistics) => !expandStatistics
                      )
                    }
                  >
                    <SmallHeader>Statistics</SmallHeader>
                    <StyledStatisticsIcon>
                      {expandStatistics ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </StyledStatisticsIcon>
                  </StyledStatisticsButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* PIE CHARTS information */}
              <StyledSxScreenCollapse in={expandStatistics} timeout={200}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        {statisticsSmallTitle ? (
                          <>
                            {handleSmallStatisticsTitle && (
                              <StyledTypographyStatisticsInstances
                                variant="h6"
                                gutterBottom
                              >
                                Total
                              </StyledTypographyStatisticsInstances>
                            )}
                            <StyledTypographyStatisticsInstances
                              variant="h6"
                              gutterBottom
                            >
                              Instances
                            </StyledTypographyStatisticsInstances>
                          </>
                        ) : (
                          <StyledTypographyStatisticsInstances
                            variant="h6"
                            gutterBottom
                          >
                            Total Instances
                          </StyledTypographyStatisticsInstances>
                        )}
                        <StyledTypographyStatisticsInstancesNumber
                          variant="h1"
                          gutterBottom
                        >
                          {instancesNewTags && instancesNewTags.length}
                        </StyledTypographyStatisticsInstancesNumber>
                      </Grid>
                      <Grid item xs={9}>
                        {instancesNewTags && instancesNewTags.length > 0 ? (
                          <Grid container>
                            <StatisticsPieCharts
                              ec2Data={ec2Data}
                              instancesNewTags={instancesNewTags}
                            />
                          </Grid>
                        ) : (
                          "There are no instances to Display"
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledSxScreenCollapse>
            </Grid>
          </Grid>
        </StyledStatisticsGridWrapper>

        {/* First EC2 Loader */}
        {isLoading ? (
          <div>
            <ServersLoading>
              <CircularProgress data-cy="circularProgress-cy" />
            </ServersLoading>
            <div style={{
              position: "fixed",
              left: 96,
              bottom: 0,
              right: 26,
            }}>
              <Footer />
            </div>
          </div>
        ) : instancesNewTags.length > 0 ? (
          // Wrapper of the ServersCard
          // this component has all the Servers Info
          <div style={{ paddingLeft: "2.5%" }} data-cy="hasInstances">
            <ServersCard
              history={history}
              page={page}
              email={email}
              classes={classes}
              idToken={idToken}
              rowsPerPage={rowsPerPage}
              instancesNewTags={renderFilteredInstances}
              handleApiCall={handleApiCall}
              setHandleApiCall={setHandleApiCall}
              isCheckboxServiceEndDateSelected={
                isCheckboxServiceEndDateSelected
              }
            />
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={renderFilteredInstances && renderFilteredInstances.length}
              rowsPerPage={parseInt(rowsPerPage, 10)}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <div data-cy="hasNoInstances">
            <NoMatches>There is nothing to display.</NoMatches>
          </div>
        )}
      </StyledServersWrapper>
      {!isLoading ? <Footer /> : ""}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    idToken: state.auth.idToken,
    email: state.auth.userDetails.email
      ? state.auth.userDetails.email.split("@")[0]
      : "",
    isLoading: state.servers.isLoading,
    serversList: state.servers.instanceList,
    isFetchingError: state.servers.isFetchingError,
    instancesNewTags: state.servers.instancesNewTags,

    dropdownFiltersServers: state.servers.dropdownFiltersServers,

    searchFilterValue: state.servers.searchFilterValue,
    filteredDropdownObject: state.servers.filteredDropdownObject,
    isCheckboxServiceEndDateSelected:
      state.servers.isCheckboxServiceEndDateSelected,

    ec2Data: state.home.ec2Data,
  };
};

export default connect(mapStateToProps, {
  fetchServersAction: fetchServersInstances,
  openInformationAction: openMoreInformationAction,
  dropdownFiltersAction: dropdownFiltersServersAction,
  searchFilterAction: searchFilterValueAction,
  dropdownScheduleFilterAction: dropdownScheudleFilterValueAction,
  fetchServers: fetchEc2Data,
  changeLoading: changeLoadingStatus,
  isCheckboxSedAction: isCheckboxServiceEndDateSelectedAction,
})(Ec2Management);
