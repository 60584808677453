import React from "react";
import {
  StyledFormControl,
  StyledSelect,
  StyledOutlinedInput,
  ClearFilter,
} from "../StyledEc2ManagementComponent";
import { InputLabel, MenuItem, Grid, Grow } from "@material-ui/core";
import {
  StyledGridAlignItems,
  StyledGridResetBotton,
  GridDropdownBySize,
} from "./StyledServersDropdownsFilters";

export const ServersDropdownsFilters = ({
  ByStateRef,
  ByRegionRef,
  ByScheduleRef,
  byStateInputRef,
  byRegionInputRef,
  byScheduleInputRef,
  handleFilterDropdown,
  filteredDropdownObject,
  regionInstances,
  clearDropdownFilters,
  filterCollapse,
  xsScreen,
}) => {
  /**
   * Filters for filtering servers on ec2 dashboard
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: Ec2Management.js
   */
  return (
    <StyledGridAlignItems container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container>
              <GridDropdownBySize item xs={4}>
                <Grow
                  in={filterCollapse}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(filterCollapse
                    ? { timeout: xsScreen ? 250 : 1000 }
                    : {})}
                >
                  <StyledFormControl
                    color={
                      filteredDropdownObject.bySchedule.value !== 10
                        ? "#e38c30"
                        : undefined
                    }
                    variant="outlined"
                    margin="dense"
                  >
                    <InputLabel ref={ByScheduleRef}>By Schedule</InputLabel>
                    <StyledSelect
                      value={filteredDropdownObject.bySchedule.value}
                      onChange={(e) => handleFilterDropdown(e, "bySchedule")}
                      color={
                        filteredDropdownObject.bySchedule.value !== 10
                          ? "#e38c30"
                          : undefined
                      }
                      input={
                        <StyledOutlinedInput
                          color={
                            filteredDropdownObject.bySchedule.value !== 10
                              ? "#e38c30"
                              : undefined
                          }
                          labelWidth={byScheduleInputRef}
                        />
                      }
                    >
                      <MenuItem value={10}>ALL</MenuItem>
                      <MenuItem value={20}>SCHEDULED</MenuItem>
                      <MenuItem value={30}>UN-SCHEDULED</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </Grow>
              </GridDropdownBySize>

              <GridDropdownBySize item xs={4}>
                <Grow
                  in={filterCollapse}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(filterCollapse ? { timeout: xsScreen ? 500 : 750 } : {})}
                >
                  <StyledFormControl
                    color={
                      filteredDropdownObject.byInstanceState.value !== 10
                        ? "#e38c30"
                        : undefined
                    }
                    variant="outlined"
                    margin="dense"
                  >
                    <InputLabel ref={ByStateRef}>By State</InputLabel>
                    <StyledSelect
                      value={filteredDropdownObject.byInstanceState.value}
                      onChange={(e) =>
                        handleFilterDropdown(e, "byInstanceState")
                      }
                      color={
                        filteredDropdownObject.byInstanceState.value !== 10
                          ? "#e38c30"
                          : undefined
                      }
                      input={
                        <StyledOutlinedInput
                          color={
                            filteredDropdownObject.byInstanceState.value !== 10
                              ? "#e38c30"
                              : undefined
                          }
                          labelWidth={byStateInputRef}
                        />
                      }
                    >
                      <MenuItem value={10}>ALL</MenuItem>
                      <MenuItem value={20}>RUNNING</MenuItem>
                      <MenuItem value={30}>STOPPED</MenuItem>
                      <MenuItem value={40}>STOPPING</MenuItem>
                      <MenuItem value={50}>PENDING</MenuItem>
                      <MenuItem value={60}>TERMINATING</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </Grow>
              </GridDropdownBySize>

              <GridDropdownBySize item xs={4}>
                <Grow
                  in={filterCollapse}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(filterCollapse ? { timeout: xsScreen ? 750 : 500 } : {})}
                >
                  <StyledFormControl
                    color={
                      filteredDropdownObject.byRegion.value !== "default"
                        ? "#e38c30"
                        : undefined
                    }
                    variant="outlined"
                    margin="dense"
                  >
                    <InputLabel ref={ByRegionRef}>By Region</InputLabel>
                    <StyledSelect
                      value={filteredDropdownObject.byRegion.value}
                      onChange={(e) => handleFilterDropdown(e, "byRegion")}
                      color={
                        filteredDropdownObject.byRegion.value !== "default"
                          ? "#e38c30"
                          : undefined
                      }
                      input={
                        <StyledOutlinedInput
                          color={
                            filteredDropdownObject.byRegion.value !== "default"
                              ? "#e38c30"
                              : undefined
                          }
                          labelWidth={byRegionInputRef}
                        />
                      }
                    >
                      <MenuItem value={"default"}>ALL</MenuItem>
                      {regionInstances &&
                        regionInstances.map((item, index) => (
                          <MenuItem key={index} value={index}>
                            {item.toUpperCase()}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </StyledFormControl>
                </Grow>
              </GridDropdownBySize>
            </Grid>
          </Grid>
          <StyledGridResetBotton item xs={2}>
            <Grid container>
              <Grid item xs={12}>
                <Grow
                  in={filterCollapse}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(filterCollapse
                    ? { timeout: xsScreen ? 1000 : 250 }
                    : {})}
                >
                  <ClearFilter
                    disabled={
                      filteredDropdownObject.bySchedule.selected ||
                      filteredDropdownObject.byInstanceState.selected ||
                      filteredDropdownObject.byRegion.selected
                        ? false
                        : true
                    }
                    onClick={clearDropdownFilters}
                    variant="outlined"
                  >
                    {"Reset"}
                    {/* onClick={clearDropdownFilters} variant="outlined">{smallScreen ? 'Reset' : 'Reset Filters'} */}
                  </ClearFilter>
                </Grow>
              </Grid>
            </Grid>
          </StyledGridResetBotton>
        </Grid>
      </Grid>
    </StyledGridAlignItems>
  );
};
