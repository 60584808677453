import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

export const SearchWrapper = styled.div`
  /* margin-right: 25px; */
`;

export const StyledSearchIcon = styled(SearchIcon)`
  &&& {
    color: "#000000";
    font-weight: 500;
  }
`;

export const StyledTextField = styled(TextField)`
  &&&&& {
    /* width: 100%; */
    /* background-color: #f6f6f6; */
    /* border-radius: 500px; */
    /* padding: 0.75rem; */
    /* margin-top: 8px; */
    /* margin-bottom: 8px; */
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 0px;
    padding-top: .50em;
    padding-bottom: .50em;
    padding-left: .2em;
    margin-top: 8px;
    > div {
      color: #222;
      padding: 0;
      &::before,
      &::after,
      &:hover {
        border: 0;
      }
      > input {
        padding: 0;
      }
    }
  }
`;
