import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const StyledGridAlignItems = styled(Grid)`
  &&&&& {
    display: flex;
    align-items: center;
  }
`;

export const StyledGridResetBotton = styled(Grid)`
  &&&&& {
    display: flex;
    align-items: center;
  }
`;
export const GridDropdownBySize = styled(Grid)`
  &&&&& {
    @media (max-width: 720px) {
      width: 8em;
    }
  }
`;
