export const InstanceProgrammingDays = (scheduledDays, privateDNSName) => {
  if (privateDNSName.includes('ip-10') && scheduledDays === 'all') {
    return {
      Sun: 'aws',
      Mon: 'aws',
      Tue: 'aws',
      Wed: 'aws',
      Thu: 'aws',
      Fri: 'aws',
      Sat: 'aws',
    }
  } else if (privateDNSName.includes('ip-10') && scheduledDays === 'weekdays') {
    return {
      Sun: 'none',
      Mon: 'aws',
      Tue: 'aws',
      Wed: 'aws',
      Thu: 'aws',
      Fri: 'aws',
      Sat: 'none',
    }
  } else {
    return {
      Sun: 'none',
      Mon: 'none',
      Tue: 'none',
      Wed: 'none',
      Thu: 'none',
      Fri: 'none',
      Sat: 'none',
    }
  }
}