export const CommonServersFilter = (
  byScheduleOption,
  byInstanceStateOption,
  byRegionOption,
  array
) => {
  if (
    byScheduleOption.selected &&
    !byInstanceStateOption.selected &&
    !byRegionOption.selected
  ) {
    return array.filter((item) => {
      if (byScheduleOption.filterCase === "bySchedule") {
        if (byScheduleOption.type === "ALL") {
          return item;
        } else if (
          byScheduleOption.type === "ec2Scheduler" &&
          item.ec2Scheduler.length > 0 &&
          item.ec2Scheduler.length > 0
        ) {
          return item;
        } else if (
          byScheduleOption.type === "un-schedule" &&
          item.ec2Scheduler.length === 0 &&
          item.ec2Scheduler.length === 0
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  } else if (
    !byScheduleOption.selected &&
    byInstanceStateOption.selected &&
    !byRegionOption.selected
  ) {
    return array.filter((item) => {
      if (byInstanceStateOption.filterCase === "byInstanceState") {
        if (byInstanceStateOption.type === "ALL") {
          return item;
        } else if (
          byInstanceStateOption.type === "running" ||
          byInstanceStateOption.type === "stopping" ||
          byInstanceStateOption.type === "stopped" ||
          byInstanceStateOption.type === "pending" ||
          byInstanceStateOption.type === "terminating"
        ) {
          return (
            item.instanceState
              .toLowerCase()
              .indexOf(byInstanceStateOption.type.toLowerCase()) !== -1
          );
        } else if (byInstanceStateOption.type === "LOADING") {
          if (item.instanceState === "pending") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("pending".toLowerCase()) !== -1
            );
          } else if (item.instanceState === "stopping") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("stopping".toLowerCase()) !== -1
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  } else if (
    !byScheduleOption.selected &&
    !byInstanceStateOption.selected &&
    byRegionOption.selected
  ) {
    return array.filter((item) => {
      if (byRegionOption.filterCase === "byRegion") {
        if (byRegionOption.type === "default") {
          return item;
        } else {
          return (
            item.regionAlias
              .toLowerCase()
              .indexOf(byRegionOption.type.toLowerCase()) !== -1
          );
        }
      } else {
        return null;
      }
    });
  } else if (
    byScheduleOption.selected &&
    byInstanceStateOption.selected &&
    !byRegionOption.selected
  ) {
    let test = array.filter((item) => {
      if (byScheduleOption.filterCase === "bySchedule") {
        if (byScheduleOption.type === "ALL") {
          return item;
        } else if (
          byScheduleOption.type === "ec2Scheduler" &&
          item.ec2Scheduler.length > 0
        ) {
          return item;
        } else if (
          byScheduleOption.type === "un-schedule" &&
          item.ec2Scheduler.length === 0
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
    return test.filter((item) => {
      if (byInstanceStateOption.filterCase === "byInstanceState") {
        if (byInstanceStateOption.type === "ALL") {
          return item;
        } else if (
          byInstanceStateOption.type === "running" ||
          byInstanceStateOption.type === "stopping" ||
          byInstanceStateOption.type === "stopped" ||
          byInstanceStateOption.type === "pending" ||
          byInstanceStateOption.type === "terminating"
        ) {
          return (
            item.instanceState
              .toLowerCase()
              .indexOf(byInstanceStateOption.type.toLowerCase()) !== -1
          );
        } else if (byInstanceStateOption.type === "LOADING") {
          if (item.instanceState === "pending") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("pending".toLowerCase()) !== -1
            );
          } else if (item.instanceState === "stopping") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("stopping".toLowerCase()) !== -1
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  } else if (
    byScheduleOption.selected &&
    !byInstanceStateOption.selected &&
    byRegionOption.selected
  ) {
    let test = array.filter((item) => {
      if (byScheduleOption.filterCase === "bySchedule") {
        if (byScheduleOption.type === "ALL") {
          return item;
        } else if (
          byScheduleOption.type === "ec2Scheduler" &&
          item.ec2Scheduler.length > 0
        ) {
          return item;
        } else if (
          byScheduleOption.type === "un-schedule" &&
          item.ec2Scheduler.length === 0
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
    return test.filter((item) => {
      if (byRegionOption.filterCase === "byRegion") {
        if (byRegionOption.type === "default") {
          return item;
        } else {
          return (
            item.regionAlias
              .toLowerCase()
              .indexOf(byRegionOption.type.toLowerCase()) !== -1
          );
        }
      } else {
        return null;
      }
    });
  } else if (
    !byScheduleOption.selected &&
    byInstanceStateOption.selected &&
    byRegionOption.selected
  ) {
    let test = array.filter((item) => {
      if (byRegionOption.filterCase === "byRegion") {
        if (byRegionOption.type === "default") {
          return item;
        } else {
          return (
            item.regionAlias
              .toLowerCase()
              .indexOf(byRegionOption.type.toLowerCase()) !== -1
          );
        }
      } else {
        return null;
      }
    });
    return test.filter((item) => {
      if (byInstanceStateOption.filterCase === "byInstanceState") {
        if (byInstanceStateOption.type === "ALL") {
          return item;
        } else if (
          byInstanceStateOption.type === "running" ||
          byInstanceStateOption.type === "stopping" ||
          byInstanceStateOption.type === "stopped" ||
          byInstanceStateOption.type === "pending" ||
          byInstanceStateOption.type === "terminating"
        ) {
          return (
            item.instanceState
              .toLowerCase()
              .indexOf(byInstanceStateOption.type.toLowerCase()) !== -1
          );
        } else if (byInstanceStateOption.type === "LOADING") {
          if (item.instanceState === "pending") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("pending".toLowerCase()) !== -1
            );
          } else if (item.instanceState === "stopping") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("stopping".toLowerCase()) !== -1
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  } else if (
    byScheduleOption.selected &&
    byInstanceStateOption.selected &&
    byRegionOption.selected
  ) {
    let test = array.filter((item) => {
      if (byScheduleOption.filterCase === "bySchedule") {
        if (byScheduleOption.type === "ALL") {
          return item;
        } else if (
          byScheduleOption.type === "ec2Scheduler" &&
          item.ec2Scheduler.length > 0
        ) {
          return item;
        } else if (
          byScheduleOption.type === "un-schedule" &&
          item.ec2Scheduler.length === 0
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
    let test2 = test.filter((item) => {
      if (byInstanceStateOption.filterCase === "byInstanceState") {
        if (byInstanceStateOption.type === "ALL") {
          return item;
        } else if (
          byInstanceStateOption.type === "running" ||
          byInstanceStateOption.type === "stopping" ||
          byInstanceStateOption.type === "stopped" ||
          byInstanceStateOption.type === "pending" ||
          byInstanceStateOption.type === "terminating"
        ) {
          return (
            item.instanceState
              .toLowerCase()
              .indexOf(byInstanceStateOption.type.toLowerCase()) !== -1
          );
        } else if (byInstanceStateOption.type === "LOADING") {
          if (item.instanceState === "pending") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("pending".toLowerCase()) !== -1
            );
          } else if (item.instanceState === "stopping") {
            return (
              item.instanceState
                .toLowerCase()
                .indexOf("stopping".toLowerCase()) !== -1
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
    return test2.filter((item) => {
      if (byRegionOption.filterCase === "byRegion") {
        if (byRegionOption.type === "default") {
          return item;
        } else {
          return (
            item.regionAlias
              .toLowerCase()
              .indexOf(byRegionOption.type.toLowerCase()) !== -1
          );
        }
      } else {
        return null;
      }
    });
  } else if (
    !byScheduleOption.selected &&
    !byInstanceStateOption.selected &&
    !byRegionOption.selected
  ) {
    return array.filter((item) => {
      return item;
    });
  }
};
