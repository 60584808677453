import React from "react";
import { Avatar } from "@material-ui/core";
import {
  StepOnePaper,
  FirstLineWrapper,
  StyledTypography,
  LeftAlignTypography,
} from "./FuiStyles";
import { ProductsInfomation } from "./Constants/ProductsInfomation";
import WidgetsIcon from "@material-ui/icons/Widgets";
import PropTypes from "prop-types";

/**
 * Handles the description box of all app forms
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: AppStepper
 */
const AppStepperOne = ({ routeName }) => {
  const { productName, productDescription } = ProductsInfomation.products.find(
    (product) => product.routeName === routeName
  );
  return (
    <StepOnePaper>
      <FirstLineWrapper>
        <Avatar>
          <WidgetsIcon />
        </Avatar>
        <StyledTypography variant="h5">{productName}</StyledTypography>
      </FirstLineWrapper>
      <LeftAlignTypography variant="body1" gutterBottom>
        {productDescription}
      </LeftAlignTypography>
    </StepOnePaper>
  );
};
AppStepperOne.propTypes = {
  /**
   * Route name of a specific form
   */
  routeName: PropTypes.string,
};
export default AppStepperOne;
