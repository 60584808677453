import React, { Fragment, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputAdornment,
} from "@material-ui/core";
import {
  StyledSearchBarPlatforms,
  PlatformWrapper,
  PlatformTitleWrapper,
  StyledSearchIcon,
  StyledPaper,
  HoverTableRow,
  FinalWrapperFromSsh,
  SuccessWrapper,
  TableWrapper,
} from "../../fui/FuiStyles";
import {
  StyledTableHead,
  TableFirstRow,
  ToAccessTypography,
  StyledAvatar,
} from "../SshStyles";
import DialogComponent from "../../ec2management/ActionItems/DialogComponent";
import { StyledActionButton } from "../../ec2management/ServerList/styledServerList";

import {
  SmallHeader,
  Subtext,
  Subtitle4,
} from "../../common/CommonStyles/CommonTypographyStyles";
import theme from "../../../theme/theme";
import PropTypes from "prop-types";
import RemoveIcon from "@material-ui/icons/Remove";

import NotesOutlinedIcon from "@material-ui/icons/NotesOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";
import { DeclineButton } from "../../common/CommonComponents/Buttons";

export const StyledNotesDialogContent = styled(DialogContent)`
  &&& {
    width: 20em;
  }
`;

export const StyledDialogContentText = styled(DialogContentText)`
  &&& {
    padding: 16px 0px 0px 0px;
  }
`;

/**
 * Renders archival page in SSH
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: Ssh
 */

const SSHArchive = ({ data, instances }) => {
  const [instanceId, setInstanceId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(""); // try to turn this into one dialog ACCEPT OR NO
  const [notesInstanceId, setNotesInstanceId] = useState(""); // try to turn this into one dialog ACCEPT OR NO
  const [notesOpen, setNotesOpes] = useState(false); // try to turn this into one dialog ACCEPT OR NO

  let handleOpenNotes = (id) => {
    setNotesOpes(true);
    setNotesInstanceId(id);
  };

  let handleCloseNotes = () => {
    setNotesOpes(false);
    setNotesInstanceId("");
  };

  // Handle click on any menuItem in ellipsis menu
  const handleConnectClick = (event, id) => {
    setDialogOpen(event); // Open dialog
    setInstanceId(id);
  };

  const getStatusColor = (ele) => {
    return ele.status === "approved"
      ? theme.status.running
      : ele.status === "declined"
      ? theme.status.stopped
      : theme.status.pending;
  };
  const searchValue = (value) => {
    return value.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
  };

  const filterRows = () => {
    return data.filter((item) => {
      if (searchQuery === "*") {
        return item;
      }
      if (
        searchValue(item.cluster_id) ||
        searchValue(item.request_id) ||
        searchValue(item.instance_owner) ||
        searchValue(item.private_dns) ||
        searchValue(item.private_dns) ||
        searchValue(item.status)
      ) {
        return item;
      } else {
        return null;
      }
    });
  };

  return (
    <div style={{ height: "100%" }}>
      <TableWrapper style={{ height: "100%" }}>
        <PlatformWrapper style={{ height: "10%" }}>
          <PlatformTitleWrapper>
            <SmallHeader>SSH Archive List</SmallHeader>
          </PlatformTitleWrapper>
          <StyledSearchBarPlatforms
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            id="outlined-search"
            type="search"
            margin="normal"
            placeholder="Search Archived Requests"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </PlatformWrapper>
        {data.length === 0 ? (
          <FinalWrapperFromSsh style={{ height: "90%" }}>
            <SuccessWrapper
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledAvatar>
                <RemoveIcon />
              </StyledAvatar>
              <ToAccessTypography>
                You have no archived requests!
              </ToAccessTypography>
            </SuccessWrapper>
          </FinalWrapperFromSsh>
        ) : (
          <StyledPaper>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableFirstRow>
                    <Subtitle4>Cluster ID</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Instance Owner</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Request ID</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Private DNS</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Requested On</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Status</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Notes</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow></TableFirstRow>
                  {/*
                  <TableFirstRow align="center" /> */}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {filterRows().map((row) => (
                  <HoverTableRow key={row.orderId}>
                    <TableCell
                      style={{
                        borderLeft: "10px solid" + getStatusColor(row),
                      }}
                    >
                      <Subtext>{row.cluster_id}</Subtext>
                    </TableCell>
                    <TableCell>
                      <Subtext>{row.instance_owner}</Subtext>
                    </TableCell>
                    <TableCell>
                      <Subtext>{row.request_id}</Subtext>
                    </TableCell>
                    <TableCell>
                      <Subtext>{row.private_dns}</Subtext>
                    </TableCell>
                    <TableCell>
                      <Subtext>{row.request_time}</Subtext>
                    </TableCell>
                    <TableCell>
                      <Subtext style={{ color: getStatusColor(row) }}>
                        {row.status}
                      </Subtext>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Fragment>
                          <NotesOutlinedIcon
                            onClick={() => handleOpenNotes(row.request_id)}
                            style={{ color: "#A100FF", cursor: "pointer" }}
                          />
                          {notesInstanceId === row.request_id && (
                            <Dialog
                              open={notesOpen}
                              onClose={handleCloseNotes}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle
                                style={{
                                  background: "#7500C0",
                                  color: "#fff",
                                }}
                                id="alert-dialog-title"
                              >
                                {"Notes"}
                              </DialogTitle>
                              <StyledNotesDialogContent>
                                <StyledDialogContentText id="alert-dialog-description">
                                  {row.hasOwnProperty("notes") &&
                                  row.notes.length > 0
                                    ? row.notes
                                    : "N/A"}
                                </StyledDialogContentText>
                              </StyledNotesDialogContent>
                              <DialogActions>
                                <DeclineButton
                                  onClick={handleCloseNotes}
                                  label={"Close"}
                                />
                              </DialogActions>
                            </Dialog>
                          )}
                        </Fragment>
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.status === "approved" && (
                        <Fragment>
                          <StyledActionButton
                            aria-label="More"
                            aria-haspopup="true"
                            onClick={() =>
                              handleConnectClick("connect", row.request_id)
                            }
                            variant="outlined"
                            style={{ marginLeft: "2px" }}
                          >
                            CONNECT
                          </StyledActionButton>
                          {isDialogOpen && instanceId === row.request_id && (
                            <DialogComponent
                              isDialogOpen={isDialogOpen}
                              handleDialogClose={() => setDialogOpen(false)}
                              handleDialogConfirm={() => {}}
                              dialogType={isDialogOpen}
                              instance={row}
                              privateDNS={row.private_dns}
                            />
                          )}
                        </Fragment>
                      )}
                    </TableCell>
                  </HoverTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledPaper>
        )}
      </TableWrapper>
    </div>
  );
};

SSHArchive.propTypes = {
  /**
   * The Ssh requests passed in containing only requests with statuses approved, and pending
   */
  data: PropTypes.object,
};
export default SSHArchive;
