import styled from "styled-components";
import { colours } from "../../theme/colours";
import Avatar from "@material-ui/core/Avatar";
import {
  TablePagination,
  FormControl,
  Button,
  Select,
  OutlinedInput,
  Grid,
  Collapse,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  SmallHeader,
  LargeNumber,
  Subtitle2,
} from "../common/CommonStyles/CommonTypographyStyles";

export const ServersLoading = styled(Grid)`
  &&& {
    width: 100%;
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledServersWrapper = styled(Grid)`
  &&& {
    margin: 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2em;
  justify-content: space-around;
`;

export const StyledAvatar = styled(Avatar)`
  &&&&& {
    background: ${colours.orange.dark};
  }
`;

export const ToAccessTypography = styled(SmallHeader)`
  &&&&& {
    color: ${colours.orange.dark};
    text-align: center;
    margin: 0.5em 0 0 0;
    margin-left: 1em;
  }
`;

export const NoMatches = styled(SmallHeader)`
  &&&&& {
    color: ${colours.orange.dark};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FinalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8em 0 0 0;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

export const StyledTablePagination = styled(TablePagination)`
  &&& {
    width: 100%;
    div {
      div:first-child {
        margin-right: 0.3em;
      }
    }
  }
`;

export const StyledServersSearchWrapper = styled.div`
  &&& {
    /* display: flex;
    justify-content: flex-end; */
  }
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  &&& {
    fieldset {
      border-color: ${(props) => (props.color ? props.color : "")};
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  &&& {
    width: 10em;
    @media (max-width: 1170px) {
      width: 8em;
    }
    @media (max-width: 1110px) {
      width: 7em;
    }
    @media (max-width: 799px) {
      width: 9em;
    }
    @media (max-width: 720px) {
      width: 8em;
    }
    @media (max-width: 594px) {
      width: 7em;
    }
    @media (max-width: 540px) {
      width: 6em;
    }

    label {
      color: ${(props) => (props.color ? props.color : "")};
    }
  }
`;

export const StyledSelect = styled(Select)`
  &&& {
    color: ${(props) => (props.color ? props.color : "")};
    svg {
      color: ${(props) => (props.color ? props.color : "")};
    }
  }
`;

export const ClearFilter = styled(Button)`
  &&&&& {
    color: ${(props) => (props.disabled ? `${grey[400]}` : "#e38c30")};
    border-color: ${(props) => (props.disabled ? `${grey[400]}` : "#e38c30")};
  }
`;

export const StyledSmallSizeGridSearch = styled(Grid)`
  &&&&& {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledFilterButton = styled(Grid)`
  &&&&& {
    display: flex;
    justify-content: center;
  }
`;

export const StyledGridNormalSize = styled(Grid)`
  &&&&& {
    display: flex;
    flex-direction: row-reverse;
  }
`;
export const StyledSxScreenCollapse = styled(Collapse)`
  &&&&& {
    width: 100%;
  }
`;

export const StyledFiltersAndSearchWrapper = styled(Grid)`
  &&&&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledStatisticsGridWrapper = styled(Grid)`
  &&&&& {
    padding-top: 0;
  }
`;

export const StyledStatisticsIcon = styled.div`
  &&&&& {
    display: flex;
    align-items: center;
    font-size: 1.4em;
  }
`;

export const StyledStatisticsButton = styled(Button)`
  &&&&& {
    text-transform: none;
    font-size: 1.6em;
    font-weight: bold;
    padding-left: 0;
  }
`;

export const StyledTypographyStatisticsInstances = styled(Subtitle2)`
  &&&&& {
    text-align: center;
  }
`;
export const StyledTypographyStatisticsInstancesNumber = styled(LargeNumber)`
  &&&&& {
  }
`;

export const StyledGridSearchWrapper = styled(Grid)`
  &&&&& {
    display: flex;
    align-items: center;
  }
`;

export const StyledGrid_NearToExpire_Checkbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 7%;
`;
