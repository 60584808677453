//fetch instances by user
export const FETCH_EC2_INSTANCES_BY_USER_ID_REQUEST =
  "FETCH_EC2_INSTANCES_BY_USER_ID_REQUEST";
export const FETCH_EC2_INSTANCES_BY_USER_ID_SUCCESS =
  "FETCH_EC2_INSTANCES_BY_USER_ID_SUCCESS";
export const FETCH_EC2_INSTANCES_BY_USER_ID_FAILURE =
  "FETCH_EC2_INSTANCES_BY_USER_ID_FAILURE";
export const EDIT_TAGS = "EDIT_TAGS";
export const CHANGE_IS_LOADING = "CHANGE_IS_LOADING";
export const SEARCH_FILTER_VALUE = "SEARCH_FILTER_VALUE";
export const DROPDOWN_SCHEDULE_FILTER_VALUE = "DROPDOWN_SCHEDULE_FILTER_VALUE";
export const REDUX_OPEN_STATE = "REDUX_OPEN_STATE";
export const SET_REDUX_OPEN_STATE = "SET_REDUX_OPEN_STATE";
export const REMOVE_ELEMENT_REDUX_OPEN_STATE = "REMOVE_ELEMENT_REDUX_OPEN_STATE";


export const DROPDOWN_FILTERS_SERVERS = "DROPDOWN_FILTERS_SERVERS";
export const IS_CHECKBOX_SERVICE_END_DATE_SELECTED = 'IS_CHECKBOX_SERVICE_END_DATE_SELECTED';
export const SSH_CONFIG_URL = "SSH_CONFIG_URL";
export const BASTION_HOST = "BASTION_HOST";
