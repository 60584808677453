import React, { useState, useEffect } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { StyledSearchIcon, StyledTextField } from "./StyledSearch";
import { Grid } from "@material-ui/core";

/**
 * Search component used to filter a user's instances.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: Search.js
 */

const Search = ({
  arrayData,
  searchFilterValue,
  setSearchQuery,
  screenName,
  placeholderValue,
  searchFilterValueAction,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const match = (item, value) => {
    return item.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  };
  const handleSearchValue = (value) => {
    setSearchValue(value);

    screenName === "servers" && searchFilterValueAction(value);
    let filteredServerList = arrayData.filter((item) => {
      if (value === "*") {
        return item;
      } else if (screenName === "pendingSsh") {
        if (
          match(item.request_id, value) ||
          match(item.cluster_id, value) ||
          match(item.status, value) ||
          match(item.requester, value) ||
          match(item.request_time, value) ||
          match(item.private_dns, value)
        ) {
          return item;
        } else {
          return null;
        }
      } else if (screenName === "myRequestSsh") {
        if (value === "*") {
          return item;
        }
        if (
          match(item.request_id, value) ||
          match(item.cluster_id, value) ||
          match(item.status, value) ||
          match(item.private_dns, value) ||
          match(item.instance_owner, value) ||
          match(item.request_time, value)
        ) {
          return item;
        } else {
          return null;
        }
      } else if (screenName === "plaformsFUI") {
        if (value === "*") {
          return item;
        } else if (
          match(item.orderId, value) ||
          match(item.clientName, value) ||
          match(item.productName, value) ||
          match(item.status, value) ||
          match(item.domainContextRoot, value)
        ) {
          return item;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
    setSearchQuery(filteredServerList);
  };

  useEffect(() => {
    setSearchQuery(arrayData);
  }, [arrayData]);

  return (
    <Grid container>
      <Grid item xs={11}>
        <StyledTextField
          value={searchFilterValue ? searchFilterValue : searchValue}
          onChange={(e) => handleSearchValue(e.target.value)}
          type="search"
          margin="normal"
          placeholder={placeholderValue}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StyledSearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

Search.propTypes = {
  /**
   * Value of the search created by a user.
   */
  // arrayData: PropTypes.string,
  /**
   * Function that gets the value of the search through an onChange event.
   */
  // setSearchQuery: PropTypes.func
};

export default Search;
