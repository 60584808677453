import React, { useEffect, useState, version } from "react";

// Material UI
import { connect } from "react-redux";

// Styled Components
import {
  Title,
  StyledTable,
  StyledDivider,
  StyledTd,
  StyledTdContent,
  TagWrapper,
  TextOverflowGuard,
  StyledGrid,
  SplunkVersionWrapper,
} from "./StyledInstanceDetailCard";
import PropTypes from "prop-types";
import { upperFirst } from "lodash";
import { Grid, CircularProgress } from "@material-ui/core";
import { SecondaryButton } from "../../common/CommonComponents/Buttons";
import UpgradeIcon from "@material-ui/icons/Update";

/**
 * The InstanceDetailsCard should show all the informations of the instance, the tags that can not be editabled and the tags that can be editable.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: InstanceDetailsCard.js
 */

const InstanceDetailsCard = (props) => {
  const {
    currentId,
    handleClickOpen,
    item,
    instancesNewTags,
    loadingIconStatusBool,
    classes,
    setOpenSplunkDialog,
    fuiOrders,
  } = props;

  const [instanceObj, setInstnceObject] = useState();
  const [currentDescription, setCurrentDescription] = useState();
  const [editabledTags, setEditableTags] = useState({
    customerEnterpriseID: "",
    secondarypoc: "",
    wbseCode: "",
    clientname: "",
    serviceEndDate: "",
    projectname: "",
    notes: "",
  });

  useEffect(() => {
    let obj = fuiOrders.find(
      // (instanceObj) => instanceObj.DMSId === item.clusterId
      (instanceObj) => instanceObj.OrderItemUId === item.orderid

    );

    let objTags = {
      customerEnterpriseID: item.customerEnterpriseID,
      secondarypoc: item.secondarypoc,
      wbseCode: item.wbseCode,
      clientname: item.clientname,
      serviceEndDate: item.serviceEndDate,
      projectname: item.projectname,
      notes: item.notes,
    };
    setEditableTags(objTags);

    let moreInformation = [item].map((data) => {
      var accessLinkAD = "N/A"
      var accessLinkATR = "N/A"

      if (obj !== undefined) {
        if (obj.hasOwnProperty("AccessLinkURLAD")) {
          accessLinkAD = obj.AccessLinkURLAD
        }
        if (obj.hasOwnProperty("AccessLinkURLATR")) {
          accessLinkATR = obj.AccessLinkURLATR
        }
      }
      if (data) {
        const InstanceType = data.InstanceType
        obj = {
          "Instance State": data.instanceState,
          "Instance ID": data.instanceId,
          "Domain Context Root": data.clusterId,
          Environment: data.environment,
          Region: data.regionAlias,
          "Access Link URL":
            InstanceType === "Container" || InstanceType === "EventOps" ? data.AccessLinkURL
              : obj !== undefined
                // ? data.initiative !== "PAM"
                ? obj.AccessLinkURL
                : "N/A",
          "Instance Name": data.name,
        };


        if (accessLinkATR !== "N/A") {
          obj = { ...obj, "Access Link URL - ATR": accessLinkATR };
        }

        if (accessLinkAD !== "N/A") {
          obj = { ...obj, "Access Link URL - AD": accessLinkAD };
        }


        if (data.clientname !== "MCC") {
          if (data.hasOwnProperty("splunkVersion")) {
            obj = { ...obj, "Splunk Version": data.splunkVersion };
          }
          if (data.hasOwnProperty("splunk-version")) {
            obj = { ...obj, "Splunk Version": data["splunk-version"] };
          }
        }
        return obj;
      } else {
        return null;
      }
    });
    setCurrentDescription(moreInformation[0]);
  }, [currentId, instanceObj, item]);

  useEffect(() => {
    setInstnceObject(instancesNewTags);
  }, [instancesNewTags]);

  const handleOpenSplunkDialog = (item) => {
    setOpenSplunkDialog({
      openDialog: true,
      instanceId: item["Instance ID"],
    });
  };

  return (
    <StyledGrid container style={{ overflow: "hidden" }}>
      <Grid item xs={12}>
        <StyledDivider />
        <Grid container>
          <Grid item xs={6} className={classes.details_paddingLeft}>
            <Grid container>
              <Grid item xs={12}>
                <Title>More Information</Title>
                <StyledTable>
                  <tbody>
                    {currentDescription &&
                      Object.keys(currentDescription).map((item, key) => (
                        <tr key={key}>
                          <StyledTd>{`${item}:`}</StyledTd>
                          {[currentDescription].map((element, key) => (
                            <StyledTdContent key={key}>
                              <TextOverflowGuard>
                                <div
                                  style={{
                                    display: `${(item === "Access Link URL" || item === "Access Link URL - AD" || item === "Access Link URL - ATR") &&
                                      "none"}`,
                                  }}
                                >
                                  {item !== "Access Link URL" && element[item]}
                                </div>
                                <div>
                                  <div>
                                    {(item === "Access Link URL" || item === "Access Link URL - AD" || item === "Access Link URL - ATR") &&
                                      element[item] !== "N/A" ? (
                                      <a
                                        href={element[item]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {element[item]}
                                      </a>
                                    ) : (
                                      (item === "Access Link URL" || item === "Access Link URL - AD" || item === "Access Link URL - ATR") &&
                                      element[item] === "N/A" &&
                                      "N/A"
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {item === "Splunk Version" &&
                                    element[item] < "7.3.2" ? (
                                    <SplunkVersionWrapper
                                      onClick={() =>
                                        handleOpenSplunkDialog(element)
                                      }
                                    >
                                      <UpgradeIcon />
                                    </SplunkVersionWrapper>
                                  ) : (
                                    item === "Splunk Version" &&
                                    element[item] === "Loading" && (
                                      <SplunkVersionWrapper>
                                        <CircularProgress size={24} />
                                      </SplunkVersionWrapper>
                                    )
                                  )}
                                </div>
                              </TextOverflowGuard>
                            </StyledTdContent>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.details_paddingRight}>
            <Grid container>
              <Grid item xs={12}>
                <TagWrapper>
                  <SecondaryButton
                    instancedetailedittagscy="instanceDetailEditTagsCy"
                    disabled={
                      item.instanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                          ? true
                          : item.InstanceType === "Container"
                            ? true
                            : item.applicationname === "GenWizard AD"
                              ? true
                              : item.InstanceType === "EventOps"
                                ? true
                                : false
                    }
                    onClick={() => handleClickOpen(item)}
                    label={"edit"}
                    className={classes.actionButtonsStyle}
                  />
                </TagWrapper>
                <StyledTable>
                  <tbody>
                    <tr>
                      <StyledTd>{`Customer Enterprise ID:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {(editabledTags &&
                            editabledTags.customerEnterpriseID === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            editabledTags.customerEnterpriseID
                          )) || "-"}
                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`Secondary POC:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {(editabledTags &&
                            editabledTags.secondarypoc === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            editabledTags.secondarypoc
                          )) || "-"}

                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`WBSE Code:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {editabledTags &&
                            editabledTags.wbseCode === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            upperFirst(editabledTags.wbseCode) || "-"
                          )}
                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`Client Name:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {editabledTags &&
                            editabledTags.clientname === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            editabledTags.clientname === "myWizard AiOps" ? "GenWizard AiOps" :
                              upperFirst(editabledTags.clientname) || "-"
                          )}
                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`Service End Date:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {editabledTags &&
                            editabledTags.serviceEndDate === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            upperFirst(editabledTags.serviceEndDate) || "-"
                          )}
                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`Project Name:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard>
                          {(editabledTags &&
                            editabledTags.projectname === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            editabledTags.projectname
                          )) || "-"}

                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                    <tr>
                      <StyledTd>{`Notes:`}</StyledTd>
                      <StyledTdContent>
                        <TextOverflowGuard style={{ display: "inline-block" }}>
                          {(editabledTags &&
                            editabledTags.notes &&
                            editabledTags.notes === "loading" ? (
                            <SplunkVersionWrapper>
                              <CircularProgress size={24} />
                            </SplunkVersionWrapper>
                          ) : (
                            editabledTags.notes
                          )) || "-"}
                        </TextOverflowGuard>
                      </StyledTdContent>
                    </tr>
                  </tbody>
                </StyledTable>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

InstanceDetailsCard.propTypes = {
  /**
   * Id of the selected instance.
   */
  currentId: PropTypes.string,
  /**
   * Function that shows the detailed information of the instance.
   */
  handleClickOpen: PropTypes.func,
  /**
   * Currently selected instance.
   */
  item: PropTypes.object,
  /**
   * Instance array.
   */
  instancesNewTags: PropTypes.array,
  /**
   * Handler of the status of the button that shows the extended information of the instance.
   */
  loadingIconStatusBool: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    instancesNewTags: state.servers.instancesNewTags,
    fuiOrders: state.fui.instanceData,
  };
};

export default connect(mapStateToProps)(InstanceDetailsCard);
