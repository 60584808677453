import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../redux/fui/index";
import { withRouter } from "react-router-dom";
import { Stepper, Step, StepContent, StepButton, CircularProgress } from "@material-ui/core";
import {
  StepOnePaper,
  TypographyWrapper,
  ButtonWrapper,
  ContentWrapper,
  StepperTableWrapper,
  StyledSpan,
  StyledSnackbar,
} from "./FuiStyles";
import TicketAnalysis from "./forms/TicketAnalysis";
import PlatformList from "./PlatformDetailLists/PlatformList";
import Sharepoint from "./forms/Sharepoint";
import StormWatch from "./forms/StormWatch";
import ReportExtractor from "./forms/ReportExtractor";

import DevOpsAnalytics from "./forms/DevOpsAnalytics";
import {
  generateSubmitData,
  generatePAMAppSubmitData,
} from "../../helper/fuiDataConvert";
import AppStepperOne from "./AppStepperOne";
import SubmitSuccess from "./SubmitSuccess";
import SubmitFailed from "./SubmitFailed";
import BackToStandardList from "./BackToStandardList";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import uuidv4 from "uuidv4";
import { tokenValidation } from "../../helper/customizedHooks";
import { appNameProjectId } from "./Constants/ProductIds";
import { FormValidate } from "./FormValidator";
import PropTypes from "prop-types";
import {
  PrimaryButton,
  SecondaryButton,
} from "../common/CommonComponents/Buttons";
import AutomationJourney from "./forms/AutomationJourney";
import AutomationPlanner from "./forms/AutomationPlanner";
import GuidedTicketResolver from "./forms/GuidedTicketResolver";
import IntelligentOpportunityMiner from "./forms/IntelligentOpportunityMiner";
import Klewer from "./forms/Klewer";
import Quasar from "./forms/Quasar";
import VirtualDataScientist from "./forms/VirtualDataScientist";

import { CommonDialog } from "../common/CommonComponents/Dialog";
import { AcceptButton, DeclineButton } from "../common/CommonComponents/Buttons";
import { DialogActions } from "@material-ui/core";
import { Table, TableCell, TableBody, TableRow } from "@material-ui/core";
import { BoldTableCell, DetailTableWrapper } from "./FuiStyles";
import { Footer } from "../common/Footer";

/**
 * Stepper for App forms
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: FuiHomePage
 */
const AppStepper = ({
  history,
  match: {
    params: { routeName },
  },
  instanceObj,
  TAForm,
  DOAForm,
  SPForm,
  REForm,
  submitForm,
  submitFormNonApproval,
  submitFormPAMapp,
  StormWatchForm,
  AutomationJourneyForm,
  AutomationPlannerForm,
  IntelligentOpportunityMinerForm,
  GuidedTicketResolverForm,
  KlewerForm,
  QuasarForm,
  VirtualDataScientistForm,
  isFormSubmittedSuccessful,
  isFormSubmittedFailed,
  email,
  configurationType,
  listenerClearLocalData,
  classes,
  isSubmitFormLoading,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isFinished, setIsFinished] = useState(false);
  const [userSelectedInstance, setUserSelectedInstance] = useState(false);
  const [orderUId, setOrderUId] = useState("");
  // According to new information from API development, orderItemUId in the payload should be different that orderUId
  const [orderItemUId, setOrderItemUId] = useState("");
  const [DMSId, setDMSId] = useState("");
  const [deployRegion, setDeployRegion] = useState("NA");
  const [clientName, setClientName] = useState("");
  const [platformType, setPlatformType] = useState("");
  const [isAllValid, setIsAllValid] = useState(true);
  const [isWebServiceSelectedByService] = useState(false);
  const [selectedForm, setSelectedForm] = useState("");
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const generateConfirmPopup = (form, configurationType) => {
    setOpenConfirmationPopup(true);
    setSelectedForm(form)
  };

  const createdBy = email ? email.split("@")[0] : "";
  const customerEnterpriseID = createdBy;
  const sapContractID =
    instanceObj.SAPContractID === ""
      ? instanceObj.DomainContextRoot
      : instanceObj.SAPContractID;

  const userOrder = (productUId, sapCID, custEID) => {
    return ["", productUId, custEID, sapCID].join("");
  };

  const userOrderTA = userOrder(
    appNameProjectId.ticketAnalysisId,
    sapContractID,
    customerEnterpriseID
  );
  const userOrderDOA = userOrder(
    appNameProjectId.devOpsAnalyticsId,
    sapContractID,
    customerEnterpriseID
  );
  const userOrderSP = userOrder(
    appNameProjectId.sharepointAddOnId,
    sapContractID,
    customerEnterpriseID
  );
  const userOrderRE = userOrder(
    appNameProjectId.reportExtractorAddOnId,
    sapContractID,
    customerEnterpriseID
  );
  const userOrderStormWatch = userOrder(
    appNameProjectId.stormwatchId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderAutomationJourney = userOrder(
    appNameProjectId.automationJourneyId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderAutomationPlanner = userOrder(
    appNameProjectId.automationPlannerId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderIntelligentOpportunityMiner = userOrder(
    appNameProjectId.intelligentOpportunityMinerId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderGuidedTicketResolver = userOrder(
    appNameProjectId.guidedTicketResolverId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderKlewer = userOrder(
    appNameProjectId.klewerId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderQuasar = userOrder(
    appNameProjectId.quasarId,
    sapContractID,
    customerEnterpriseID
  );

  const userOrderVirtualDataScientist = userOrder(
    appNameProjectId.virtualDataScientistId,
    sapContractID,
    customerEnterpriseID
  );

  const isTAFinished = !FormValidate(
    TAForm,
    configurationType,
    "TicketAnalysis"
  );
  const isDOAFinished = !FormValidate(
    DOAForm,
    configurationType,
    "DevOpsAnalytics"
  );
  const isSPFinished = !FormValidate(SPForm, configurationType, "Sharepoint");

  const isREFinished = !FormValidate(
    REForm,
    configurationType,
    "ReportExtractor"
  );

  const isStormWatchFinished = !FormValidate(
    StormWatchForm,
    configurationType,
    "StormWatch"
  );

  const isAutomationJourneyFinished = !FormValidate(
    AutomationJourneyForm,
    configurationType,
    "AutomationJourney"
  );

  const isAutomationPlannerFinished = !FormValidate(
    AutomationPlannerForm,
    configurationType,
    "AutomationPlanner"
  );

  const isIntelligentOpportunityMinerFinished = !FormValidate(
    IntelligentOpportunityMinerForm,
    configurationType,
    "IntelligentOpportunityMiner"
  );

  const isGuidedTicketResolverFinished = !FormValidate(
    GuidedTicketResolverForm,
    configurationType,
    "GuidedTicketResolver"
  );

  const isKlewerFinished = !FormValidate(
    KlewerForm,
    configurationType,
    "Klewer"
  );

  const isQuasarFinished = !FormValidate(
    QuasarForm,
    configurationType,
    "Quasar"
  );

  const isVirtualDataScientistFinished = !FormValidate(
    VirtualDataScientistForm,
    configurationType,
    "VirtualDataScientist"
  );

  const isAppFinished =
    isTAFinished ||
    isDOAFinished ||
    isSPFinished ||
    isREFinished ||
    isStormWatchFinished ||
    isAutomationJourneyFinished ||
    isAutomationPlannerFinished ||
    isIntelligentOpportunityMinerFinished ||
    isGuidedTicketResolverFinished ||
    isKlewerFinished ||
    isQuasarFinished ||
    isVirtualDataScientistFinished;

  const dataFromInstance = instanceObj;

  const instanceArray = Object.entries(dataFromInstance).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  const DMSIdIndex = instanceArray.findIndex((i) =>
    (i.Key === "DMSId") !== ""
      ? i.Key === "DMSId"
      : (i.Key === "SAPContractID") !== ""
        ? i.Key === "SAPContractID"
        : i.Key === "DomainContextRoot"
  );

  const EndToEndUIdIndex = instanceArray.findIndex(
    (i) => i.Key === "EndToEndUId"
  );

  const OrderUIdIndex = instanceArray.findIndex(
    (i) => i.Key === "OrderUId"
  );

  const deployRegionIndex = instanceArray.findIndex(
    (i) => i.Key === "DeployRegion"
  );
  const clientNameIndex = instanceArray.findIndex(
    (i) => i.Key === "ClientName"
  );
  const platformTypeIndex = instanceArray.findIndex(
    (i) => i.Key === "AaamPlatformType"
  );

  //DevOps Specific
  const [PlanAndScope, setPlanAndScope] = useState([]);
  const [SourceManagement, setSourceManagement] = useState([]);
  const [
    BuildAndDeploymentManagement,
    setBuildAndDeploymentManagement,
  ] = useState([]);
  const [QualityManagement, setQualityManagement] = useState([]);
  const [ServiceManagement, setServiceManagement] = useState([]);
  const [DevSecOps, setDevSecOps] = useState([]);

  // const arr = new Array([]);
  // PlanAndScope.length > 0 && PlanAndScope.forEach((Addon) => arr.push(Addon));
  // SourceManagement.length > 0 &&
  //   SourceManagement.forEach((Addon) => arr.push(Addon));
  // BuildAndDeploymentManagement.length > 0 &&
  //   BuildAndDeploymentManagement.forEach((Addon) => arr.push(Addon));
  // QualityManagement.length > 0 &&
  //   QualityManagement.forEach((Addon) => arr.push(Addon));
  // DevSecOps.length > 0 && DevSecOps.forEach((Addon) => arr.push(Addon));
  // ServiceManagement.length > 0 &&
  //   ServiceManagement.forEach((Addon) => arr.push(Addon));

  const arr = new Array([]);
  PlanAndScope !== "" && arr.push(PlanAndScope);
  SourceManagement !== "" && arr.push(SourceManagement);
  BuildAndDeploymentManagement !== "" && arr.push(BuildAndDeploymentManagement);
  QualityManagement !== "" && arr.push(QualityManagement);
  DevSecOps !== "" && arr.push(DevSecOps);
  ServiceManagement !== "" && arr.push(ServiceManagement);

  const [isJiraSelectedByPlan, setIsJiraSelectedByPlan] = useState(false);
  const [isJiraSelectedByService, setIsJiraSelectedByService] = useState(false);
  const [test, setTest] = useState(false);

  const handleChangePlanAndScope = (event) => {
    setPlanAndScope(event.target.value);
    // if (!!event.target.value.find((addon) => addon === "Jira")) {
    if (event.target.value === "Jira") {
      setIsJiraSelectedByPlan(true);
    } else {
      setIsJiraSelectedByPlan(false);
    }
  };
  const handleChangeSourceManagement = (event) => {
    setSourceManagement(event.target.value);
  };
  const handleChangeBuildAndDeploymentManagement = (event) => {
    setBuildAndDeploymentManagement(event.target.value);
  };
  const handleChangeQualityManagement = (event) => {
    setQualityManagement(event.target.value);
  };
  const handleChangeServiceManagement = (event) => {
    setServiceManagement(event.target.value);
    // if (!!event.target.value.find((addon) => addon === "Jira")) {
    if (event.target.value === "Jira") {
      setIsJiraSelectedByService(true);
    } else {
      setIsJiraSelectedByService(false);
    }
  };
  const handleChangeDevSecOps = (event) => {
    setDevSecOps(event.target.value);
  };

  const DevOpsAddonsGroup = arr.join(",");

  useEffect(() => {
    setOrderUId(uuidv4());
    // Using a different generation for this value due to new requirements.
    setOrderItemUId(uuidv4());
  }, []);

  useEffect(() => {
    setPlanAndScope([]);
    setSourceManagement([]);
    setBuildAndDeploymentManagement([]);
    setQualityManagement([]);
    setServiceManagement([]);
    setDevSecOps([]);
  }, [listenerClearLocalData]);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    tokenValidation();
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOrderUId(uuidv4());
    //setDOAAddOns(initialDOAAddOns);
  }, []);

  const stepLabels = [
    "SELECT AN APP",
    "SELECT AN INSTANCE",
    "CONFIGURATE ORDER",
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AppStepperOne routeName={routeName} />;
      case 1:
        return (
          <StepperTableWrapper>
            <PlatformList
              setIsFinished={setIsFinished}
              selectedValue={instanceObj}
              routeName={routeName}
              classes={classes}
            />
          </StepperTableWrapper>
        );
      case 2:
        return (
          <StepOnePaper>
            {routeName === "ticketAnalysis" && (
              <TicketAnalysis
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsTAAllValid={setIsAllValid}
              />
            )}
            {routeName === "sharepoint" && (
              <Sharepoint
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "reportExtractor" && (
              <ReportExtractor
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsREAllValid={setIsAllValid}
              />
            )}
            {routeName === "devOpsAnalytics" && (
              <DevOpsAnalytics
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                handleChangePlanAndScope={handleChangePlanAndScope}
                handleChangeSourceManagement={handleChangeSourceManagement}
                handleChangeBuildAndDeploymentManagement={
                  handleChangeBuildAndDeploymentManagement
                }
                handleChangeQualityManagement={handleChangeQualityManagement}
                handleChangeServiceManagement={handleChangeServiceManagement}
                handleChangeDevSecOps={handleChangeDevSecOps}
                PlanAndScope={PlanAndScope}
                SourceManagement={SourceManagement}
                BuildAndDeploymentManagement={BuildAndDeploymentManagement}
                QualityManagement={QualityManagement}
                ServiceManagement={ServiceManagement}
                DevSecOps={DevSecOps}
                setIsDOAAllValid={setIsAllValid}
                isJiraSelectedByPlan={isJiraSelectedByPlan}
                isJiraSelectedByService={isJiraSelectedByService}
                isWebServiceSelectedByService={isWebServiceSelectedByService}
              />
            )}
            {routeName === "stormwatch" && (
              <StormWatch
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "automationJourney" && (
              <AutomationJourney
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "automationPlanner" && (
              <AutomationPlanner
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "intelligentOpportunityMiner" && (
              <IntelligentOpportunityMiner
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "guidedTicketResolver" && (
              <GuidedTicketResolver
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "klewer" && (
              <Klewer
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "quasar" && (
              <Quasar
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
            {routeName === "virtualDataScientist" && (
              <VirtualDataScientist
                dataFromInstance={dataFromInstance}
                createdBy={createdBy}
                setIsSPAllValid={setIsAllValid}
              />
            )}
          </StepOnePaper>
        );
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    tokenValidation();
    DMSIdIndex === -1
      ? setDMSId("UNKNOWN")
      : setDMSId(instanceArray[DMSIdIndex].Value);
    deployRegionIndex === -1
      ? setDeployRegion("UNKONWN")
      : setDeployRegion(instanceArray[deployRegionIndex].Value);
    clientNameIndex === -1
      ? setClientName("myWizard AiOps")
      : setClientName(instanceArray[clientNameIndex].Value);
    platformTypeIndex === -1
      ? setPlatformType("UNKONWN")
      : setPlatformType(instanceArray[platformTypeIndex].Value);



    activeStep !== 2 && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 2) {
      if (routeName === "ticketAnalysis") {
        generateConfirmPopup(TAForm, configurationType)
      }
      if (routeName === "sharepoint") {
        generateConfirmPopup(SPForm, configurationType)
      }
      if (routeName === "reportExtractor") {
        generateConfirmPopup(REForm, configurationType)
      }
      if (routeName === "devOpsAnalytics") {
        generateConfirmPopup(DOAForm, configurationType)
      }
      if (routeName === "stormwatch") {
        generateConfirmPopup(StormWatchForm, configurationType)
      }
      if (routeName === "automationJourney") {
        generateConfirmPopup(AutomationJourneyForm, configurationType)
      }
      if (routeName === "automationPlanner") {
        generateConfirmPopup(AutomationPlannerForm, configurationType)
      }
      if (routeName === "intelligentOpportunityMiner") {
        generateConfirmPopup(IntelligentOpportunityMinerForm, configurationType)
      }
      if (routeName === "guidedTicketResolver") {
        generateConfirmPopup(GuidedTicketResolverForm, configurationType)
      }
      if (routeName === "klewer") {
        generateConfirmPopup(KlewerForm, configurationType)
      }
      if (routeName === "quasar") {
        generateConfirmPopup(QuasarForm, configurationType)
      }
      if (routeName === "virtualDataScientist") {
        generateConfirmPopup(VirtualDataScientistForm, configurationType)
      }
    }
  };

  const handleSubmit = () => {
    setOpenConfirmationPopup(false)
    const generateNewForm = (form, configuration) => {
      const commonData = {
        CustomerEnterpriseID: createdBy,
        DMSId:
          DMSId === ""
            ? instanceArray.length === 0
              ? ""
              : instanceArray[EndToEndUIdIndex].Value
            : DMSId,
        SAPContractID: sapContractID,
        DeployRegion: deployRegion,
        ClientName: clientName,
        AaamPlatformType: platformType,
      };

      const commonFullData = {
        CustomerMobileNo: "UNKNOWN",
        Users: "UNKNOWN",
        ServiceGroup: "Application Outsourcing",
        UsersDistributionGroup: "UNKNOWN",
      };

      return configuration === "full"
        ? { ...form, ...commonData, ...commonFullData }
        : { ...form, ...commonData };
    };

    if (routeName === "ticketAnalysis") {
      setTest(true);
      submitFormNonApproval(
        generateSubmitData(
          generateNewForm(TAForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderTA,
          "TicketAnalysis"
        )
      );
    }
    if (routeName === "sharepoint") {
      setTest(true);
      submitForm(
        generateSubmitData(
          generateNewForm(SPForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderSP,
          "Sharepoint"
        )
      );
    }
    if (routeName === "reportExtractor") {
      setTest(true);
      submitForm(
        generateSubmitData(
          generateNewForm(REForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderRE,
          "ReportExtractor"
        )
      );
    }
    if (routeName === "devOpsAnalytics") {
      setTest(true);
      submitForm(
        generateSubmitData(
          {
            ...generateNewForm(DOAForm, configurationType),
            DevOpsAddonsGroup: DevOpsAddonsGroup,
          },
          orderUId,
          orderItemUId,
          createdBy,
          userOrderDOA,
          "DevOpsAnalytics"
        )
      );
    }
    if (routeName === "stormwatch") {
      setTest(true);
      submitForm(
        generateSubmitData(
          generateNewForm(StormWatchForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderStormWatch,
          "StormWatch"
        )
      );
    }
    if (routeName === "automationJourney") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(AutomationJourneyForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderAutomationJourney,
          "AutomationJourney",
          dataFromInstance
        )
      );
    }
    if (routeName === "automationPlanner") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(AutomationPlannerForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderAutomationPlanner,
          "AutomationPlanner",
          dataFromInstance
        )
      );
    }
    if (routeName === "intelligentOpportunityMiner") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(IntelligentOpportunityMinerForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderIntelligentOpportunityMiner,
          "IntelligentOpportunityPlanner",
          dataFromInstance
        )
      );
    }
    if (routeName === "guidedTicketResolver") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(GuidedTicketResolverForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderGuidedTicketResolver,
          "GuidedTicketResolver",
          dataFromInstance
        )
      );
    }
    if (routeName === "klewer") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(KlewerForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderKlewer,
          "Klewer",
          dataFromInstance
        )
      );
    }
    if (routeName === "quasar") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(QuasarForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderQuasar,
          "Quasar",
          dataFromInstance
        )
      );
    }
    if (routeName === "virtualDataScientist") {
      setTest(true);
      submitFormPAMapp(
        generatePAMAppSubmitData(
          generateNewForm(VirtualDataScientistForm, configurationType),
          orderUId,
          orderItemUId,
          createdBy,
          userOrderVirtualDataScientist,
          "VirtualDataScientist",
          dataFromInstance
        )
      );
    }
  }

  const handleBack = () => {
    tokenValidation();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsFinished(false);
  };
  const handleStep = (step) => () => {
    if (OrderUIdIndex == "-1") {
      setUserSelectedInstance(true)
    } else {
      setUserSelectedInstance(false)
    }
    setIsFinished(false);
    tokenValidation();
    setActiveStep(step);
  };

  return (
    <div>
      <div>
        {!isFormSubmittedSuccessful && !isFormSubmittedFailed && !isSubmitFormLoading && (
          <div>
            <BackToStandardList history={history} />
            {activeStep < 3 && (
              <Stepper activeStep={activeStep} orientation="vertical">
                {stepLabels.map((label, step) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(step)}>{label}</StepButton>
                    <StepContent>
                      <ContentWrapper>
                        <TypographyWrapper>
                          {getStepContent(step)}
                        </TypographyWrapper>
                        <ButtonWrapper>
                          <SecondaryButton
                            data-cy="backSelectAnInstance"
                            onClick={
                              activeStep === 0
                                ? () => history.push("/fui/order")
                                : handleBack
                            }
                            label={activeStep === 0 ? "BACK TO LIST" : "BACK"}
                            className={classes.FuiSecondaryButton}
                          />

                          {activeStep === 0 && (
                            <PrimaryButton
                              data-cy="activeStep-0"
                              onClick={handleNext}
                              label={"NEXT"}
                              className={classes.FuiPrimaryButton}
                            />
                          )}
                          {activeStep === 1 && (
                            <PrimaryButton
                              data-cy="activeStep-1"
                              onClick={handleNext}
                              disabled={!isFinished || (OrderUIdIndex == "-1" ? true : false)}
                              label={"NEXT"}
                              className={classes.FuiPrimaryButton}
                            />
                          )}
                          {activeStep === 2 && (
                            <PrimaryButton
                              onClick={
                                isAppFinished && isAllValid
                                  ? handleNext
                                  : handleClick
                              }
                              disabled={
                                test === true
                                  ? true
                                  : !(isAppFinished && isAllValid)
                                    ? true
                                    : false
                              }
                              label={"SUBMIT"}
                              className={classes.FuiPrimaryButton}
                            />
                          )}
                        </ButtonWrapper>
                      </ContentWrapper>
                    </StepContent>
                  </Step>
                ))}
                
              </Stepper>
            )}
            <Footer></Footer>
          </div>
        )}
        {isFormSubmittedSuccessful && (
          <SubmitSuccess orderItemUId={orderItemUId} approverName={email} requester={createdBy} />
        )}
        {isFormSubmittedFailed && (
          <SubmitFailed orderItemUId={orderItemUId} />
        )}
        <StyledSnackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={7000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          style={{ width: "max-content" }}
          message={
            <StyledSpan id="message-id">
              SUBMIT FAILED: Please note that all the fields are mandatory. Kindly review the form and provide the necessary information.
            </StyledSpan>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {openConfirmationPopup && (
          <CommonDialog
            open={openConfirmationPopup}
            onClose={() => setOpenConfirmationPopup(false)}
            title={"Review Order Details"}
            dialogType={"fui"}
            // textContent={"Thank you for choosing our service! Before you submit your request, please take a moment to review the order details below. Ensuring accuracy now will help prevent any issues later on."}
            textContent={
              <div>
                <div>
                  <p>Before you submit your request, please take a moment to review the order details below. Ensuring accuracy now will help prevent any issues later on.</p>
                  <DetailTableWrapper>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <BoldTableCell>Project Name</BoldTableCell>
                          <TableCell>{selectedForm.ProjectName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>WBSE Code</BoldTableCell>
                          <TableCell>{selectedForm.WBSEcode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Domain Context Root</BoldTableCell>
                          <TableCell>{selectedForm.DomainContextRoot === "" ? "NA" : selectedForm.DomainContextRoot}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Admin Distribution Group</BoldTableCell>
                          <TableCell>{selectedForm.AdminDistributionGroup}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Environment</BoldTableCell>
                          <TableCell>{selectedForm.Environment === "" ? "NA" : selectedForm.Environment}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>GDPR</BoldTableCell>
                          <TableCell>{selectedForm.GDPR.toUpperCase()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Deployment Region</BoldTableCell>
                          <TableCell>{selectedForm.DeploymentRegion === "" ? "NA" : selectedForm.DeploymentRegion.toUpperCase()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Instance Type</BoldTableCell>
                          <TableCell>{selectedForm.InstanceType}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Notes</BoldTableCell>
                          <TableCell>{selectedForm.RequestNotes === "" ? "NA" : selectedForm.RequestNotes}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Tier</BoldTableCell>
                          <TableCell>{selectedForm.Tier === "" ? "NA" : selectedForm.Tier}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                  </DetailTableWrapper>
                </div>
                <div style={{ marginTop: "20%" }}>
                  <p>By clicking the "Yes" button, you acknowledge that you have read, understood, and agree to be bound by the <a href="https://mywizard-aiops.accenture.com/accenture-splunk-msp-license-usage-policy/" target="_blank" rel="noopener noreferrer">
                    terms and conditions
                  </a> of our application.</p>
                  <p style={{ fontWeight: "600" }}>Are you sure you want to proceed with this order?</p>
                </div>

              </div>
            }
            dialogActions={
              <DialogActions >
                <DeclineButton
                  onClick={() => setOpenConfirmationPopup(false)}
                  label={"No"}
                  disabled={false}
                />
                <AcceptButton
                  onClick={() => handleSubmit()}
                  label={"Yes"}
                />
              </DialogActions>
            }
          ></CommonDialog>
        )}

      </div>
      {isSubmitFormLoading ? <div>
        <div
          style={{
            paddingTop: "8%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} />
        </div>
        <div
          style={{
            paddingTop: "8%",
            paddingBottom: "8%",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <h4>Please wait.... </h4>
          <p>Submitting your request</p>
          <p style={{ lineHeight: "0.1px" }}><i>This may take up to a minute....</i></p>
        </div>
      </div> : ""}
    </div>
  );
};
AppStepper.propTypes = {
  /**
   * History of route library
   */
  history: PropTypes.object,
  /**
   * Match property of route library
   */
  match: PropTypes.object,
  /**
   * Object of selected isntance to install app on
   */
  instanceObj: PropTypes.object,
  /**
   * Redux function to post/submit a form
   */
  submitFormNonApproval: PropTypes.func,
  submitForm: PropTypes.func,
  submitFormPAMapp: PropTypes.func,
  /**
   * Boolean if the submitForm redux function hasn't returned an error
   */
  isFormSubmittedSuccessful: PropTypes.bool,
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   *
   */
  listenerClearLocalData: PropTypes.bool,
};
const mapStateToProps = (state, props) => ({
  instanceObj: state.fui.aSelectedInstance,
  TAForm: state.fui.TAForm,
  DOAForm: state.fui.DOAForm,
  SPForm: state.fui.SPForm,
  REForm: state.fui.REForm,
  StormWatchForm: state.fui.StormWatchForm,
  AutomationJourneyForm: state.fui.AutomationJourneyForm,
  AutomationPlannerForm: state.fui.AutomationPlannerForm,
  IntelligentOpportunityMinerForm: state.fui.IntelligentOpportunityMinerForm,
  GuidedTicketResolverForm: state.fui.GuidedTicketResolverForm,
  KlewerForm: state.fui.KlewerForm,
  QuasarForm: state.fui.QuasarForm,
  VirtualDataScientistForm: state.fui.VirtualDataScientistForm,
  isSubmitFormLoading: state.fui.isSubmitFormLoading,
  isFormSubmittedSuccessful: state.fui.isFormSubmittedSuccessful,
  isFormSubmittedFailed: state.fui.isFormSubmittedFailed,
  email: state.auth.userDetails.email,
  configurationType: state.fui.configurationType,
  listenerClearLocalData: state.fui.listenerClearLocalData,
});

export default connect(mapStateToProps, {
  submitForm: fuiRedux.submitForm,
  submitFormPAMapp: fuiRedux.submitFormPAMapp,
  submitFormNonApproval: fuiRedux.submitFormNonApproval,
})(withRouter(AppStepper));