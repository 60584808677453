import React, { useEffect, useState } from "react";
import {
  StyledSidebarItemsWrapper,
  StyledSidebarItems,
} from "./StyledResponsiveSidebar";
import "./ResponsiveSidebar.css";
import { Grid, Collapse } from "@material-ui/core";
import { connect } from "react-redux";

import {
  sidebarDropdownOptionsAction,
  sidebarCollapseStateAction,
  sidebarHandleOnHoverClassAction,
} from "../../../redux/CommonSidebar/CommonSidebarActions";
import {
  TitleColumnWrapper,
  TitleColumnWrapperWithStyles,
} from "../../home/DashboardStyles";
import { tokenValidation } from "../../../helper/customizedHooks";
import { clearLocalComponentData } from "../../../redux/fui";
import VPNKeyIcon from "@material-ui/icons/VpnKey";
import HomeIcon from "@material-ui/icons/Home";
import StorageIcon from "@material-ui/icons/Storage";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpIcon from "@material-ui/icons/Help";
import WebIcon from "@material-ui/icons/Web";
import AdminSettingsPanelIcon from "../../../assets/icons/AdminSettingsPanel";

const ResponsiveSidebar = ({
  currentRoute,
  sidebarDropdownOptionsAction,
  sidebarCollapseStateAction,
  sidebarDropdownStateByClick,
  sidebarCollapseFulfillmentState,
  sidebarCollapseSshManagementState,
  sidebarCollapseAccessManagementState,
  sidebarContainerOnHoverClasses,
  sidebarHandleOnHoverClassAction,
  sidebarTextOnHoverClass,
  clearLocalComponentData,
  camPermissions,
}) => {
  /**
   * The floating sidebar menu
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: CommonLayout.js
   */
  const [fulfillmentIconTransition, setFulfillmentIconTransition] = useState(
    "material-icons colorIcon"
  );
  const [sshIconTransition, setSshIconTransition] = useState(
    "material-icons colorIcon"
  );
  const [accessIconTransition, setAccessIconTransition] = useState(
    "material-icons colorIcon"
  );

  const userRbac = JSON.parse(
    localStorage.getItem("userRoleBasedAccessControl")
  );
  const currentCamPermissions =
    userRbac !== null ? userRbac.data : camPermissions;

  const handleCollapse = (selectedScreenOption) => {
    sidebarDropdownOptionsAction(selectedScreenOption);
  };

  const handleMouseEnterEvent = () => {
    let collapseFulfillmentState =
      sidebarDropdownStateByClick.fulfillment.isOpenTab;
    let collapseSshManagementState =
      sidebarDropdownStateByClick.sshManagement.isOpenTab;
    sidebarCollapseStateAction(
      collapseFulfillmentState,
      collapseSshManagementState
    );
    sidebarHandleOnHoverClassAction(
      "sidebar_items_originalSize_hover",
      "showTextOnHover"
    );
  };

  const handleMouseCloseEvent = () => {
    sidebarHandleOnHoverClassAction("sidebar_items_originalSize", "text");
    sidebarCollapseStateAction(false, false);
  };

  useEffect(() => {
    if (sidebarCollapseFulfillmentState) {
      setFulfillmentIconTransition(
        "material-icons colorIcon iconTransitionOpen"
      );
    } else {
      setFulfillmentIconTransition(
        "material-icons colorIcon iconTransitionClose"
      );
    }
    if (sidebarCollapseSshManagementState) {
      setSshIconTransition("material-icons colorIcon iconTransitionOpen");
    } else {
      setSshIconTransition("material-icons colorIcon iconTransitionClose");
    }
    if (sidebarCollapseAccessManagementState) {
      setAccessIconTransition("material-icons colorIcon iconTransitionOpen");
    } else {
      setAccessIconTransition("material-icons colorIcon iconTransitionClose");
    }
  }, [sidebarCollapseFulfillmentState, sidebarCollapseSshManagementState, sidebarCollapseAccessManagementState]);

  const handleFuiTabs = () => {
    tokenValidation();
    clearLocalComponentData();
  };

  return (
    <div>
      {Object.keys(currentCamPermissions).length !== 0 && (
        <StyledSidebarItemsWrapper>
          <StyledSidebarItems
            className={sidebarContainerOnHoverClasses}
            onMouseEnter={handleMouseEnterEvent}
            onMouseLeave={handleMouseCloseEvent}
            data-cy="floatingMenu"
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container className="center">
                  {/* Dashboard */}
                  <Grid
                    item
                    xs={12}
                    className="center paddingBottom rowContent"
                  >
                    <TitleColumnWrapper to="/">
                      <div>
                        <HomeIcon
                          className={
                            currentRoute === "/"
                              ? `material-icons currentColorIcon`
                              : `material-icons colorIcon`
                          }
                        ></HomeIcon>
                      </div>
                    </TitleColumnWrapper>
                    <TitleColumnWrapperWithStyles
                      className={
                        currentRoute === "/"
                          ? `${sidebarTextOnHoverClass} currentRouteColor`
                          : `${sidebarTextOnHoverClass} text `
                      }
                      to="/"
                    >
                      Dashboard
                    </TitleColumnWrapperWithStyles>
                  </Grid>

                  {/* Servers */}
                  {currentCamPermissions[0].filter(
                    (permission) =>
                      permission.component === "ServerManagementComponent"
                  ).length !== 0 && (
                      <Grid
                        item
                        xs={12}
                        className="center paddingBottom rowContent"
                      >
                        <TitleColumnWrapper to="/servers">
                          <div>
                            <StorageIcon
                              className={
                                currentRoute.includes("servers")
                                  ? `material-icons currentColorIcon`
                                  : `material-icons colorIcon`
                              }
                            ></StorageIcon>
                          </div>
                        </TitleColumnWrapper>
                        <TitleColumnWrapperWithStyles
                          className={
                            currentRoute.includes("servers")
                              ? `${sidebarTextOnHoverClass} currentRouteColor`
                              : `${sidebarTextOnHoverClass} text `
                          }
                          to="/servers"
                        >
                          Servers
                        </TitleColumnWrapperWithStyles>
                      </Grid>
                    )}

                  {/* Fulfillment */}
                  {currentCamPermissions[0].filter(
                    (permission) => permission.component === "FulfillmentUI"
                  ).length !== 0 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          className="center paddingBottom rowContent"
                          onClick={() => handleCollapse("fulfillment")}
                        >
                          <div>
                            <WebIcon
                              className={
                                currentRoute.includes("fui")
                                  ? `material-icons currentColorIcon`
                                  : `material-icons colorIcon`
                              }
                            >
                              web
                            </WebIcon>
                          </div>
                          <Grid
                            container
                            className={
                              currentRoute.includes("fui")
                                ? `${sidebarTextOnHoverClass} currentRouteColor`
                                : `${sidebarTextOnHoverClass} text `
                            }
                          >
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={10} className="center">
                                  Fulfillment
                                </Grid>
                                <Grid item xs={2}>
                                  <ArrowDropDownIcon
                                    className={
                                      currentRoute.includes("fui")
                                        ? `${fulfillmentIconTransition} currentColorIcon`
                                        : `${fulfillmentIconTransition}`
                                    }
                                  ></ArrowDropDownIcon>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Collapse
                          in={sidebarCollapseFulfillmentState}
                          timeout={300}
                          className="collapse_row_size"
                        >
                          <Grid item xs={12}>
                            <TitleColumnWrapper
                              style={{
                                position: "relative",
                                right: "-36px"
                              }}
                              onClick={handleFuiTabs}
                              className={
                                currentRoute.includes("order")
                                  ? `collapse sidebar_screenOptions_size currentColorIcon`
                                  : `collapse sidebar_screenOptions_size subtext`
                              }
                              to="/fui/order"
                            >
                              Order
                            </TitleColumnWrapper>
                            <TitleColumnWrapper
                              style={{
                                position: "relative",
                                right: "-36px"
                              }}
                              onClick={handleFuiTabs}
                              className={
                                currentRoute.includes("platforms")
                                  ? `collapse sidebar_screenOptions_size currentColorIcon`
                                  : `collapse sidebar_screenOptions_size subtext`
                              }
                              to="/fui/platforms"
                            >
                              Platform
                            </TitleColumnWrapper>
                            <TitleColumnWrapper
                              style={{
                                position: "relative",
                                right: "-36px"
                              }}
                              onClick={handleFuiTabs}
                              className={
                                currentRoute.includes("licenses")
                                  ? `collapse sidebar_screenOptions_size currentColorIcon`
                                  : `collapse sidebar_screenOptions_size subtext`
                              }
                              to="/fui/licenses"
                            >
                              Licenses
                            </TitleColumnWrapper>
                          </Grid>
                        </Collapse>
                      </>
                    )}
                  {/* SSH Management */}
                  {currentCamPermissions[0].filter(
                    (permission) => permission.component === "Ssh"
                  ).length !== 0 && (
                      <>
                        <Grid
                          item
                          xs={12}
                          className="center paddingBottom rowContent"
                          onClick={() => handleCollapse("accessManagement")}
                        >
                          <div>
                            <VPNKeyIcon
                              className={
                                currentRoute.includes("ssh")
                                  ? `material-icons currentColorIcon`
                                  : `material-icons colorIcon`
                              }
                            ></VPNKeyIcon>
                          </div>
                          <Grid
                            container
                            className={
                              currentRoute.includes("ssh")
                                ? `${sidebarTextOnHoverClass} currentRouteColor`
                                : `${sidebarTextOnHoverClass} text `
                            }
                            data-cy="expandSSHMenu"
                          >
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={10} className="center">
                                  Access Management
                                </Grid>
                                <Grid item xs={2}>
                                  <ArrowDropDownIcon
                                    className={
                                      currentRoute.includes("ssh")
                                        ? `${accessIconTransition} currentColorIcon`
                                        : `${accessIconTransition}`
                                    }
                                  ></ArrowDropDownIcon>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Servers */}
                        
                        <Collapse
                          in={sidebarCollapseAccessManagementState}
                          timeout={300}
                          className="collapse_row_size"
                        >
                          {/* <Collapse in={sidebarDropdownStateByClick.sshManagement.isOpenTab} timeout={300} className='collapse_row_size' > */}
                          <Grid item xs={12} className="subtext">
                            <TitleColumnWrapper
                              className={
                                currentRoute.includes("ssh/sshDashboard")
                                  ? `collapse sidebar_screenOptions_size currentColorIcon`
                                  : `collapse sidebar_screenOptions_size subtext`
                              }
                              to="#"
                            >
                              <Grid
                                item
                                xs={12}
                                className="center paddingBottom rowContent"
                                onClick={() => handleCollapse("sshManagement")}
                              >
                                
                                <Grid
                                  container
                                  className={
                                    currentRoute.includes("ssh")
                                      ? `${sidebarTextOnHoverClass} currentRouteColor`
                                      : `${sidebarTextOnHoverClass} text `
                                  }
                                  data-cy="expandSSHMenu"
                                >
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={10} className="center" style={{ position: "relative", left: "20px" }}>
                                        SSH Management
                                      </Grid>
                                      <Grid item xs={2}>
                                        <ArrowDropDownIcon
                                          className={
                                            currentRoute.includes("ssh")
                                              ? `${sshIconTransition} currentColorIcon`
                                              : `${sshIconTransition}`
                                          }
                                        ></ArrowDropDownIcon>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TitleColumnWrapper>
                            <Collapse
                              in={sidebarCollapseSshManagementState}
                              timeout={300}
                              className="collapse_row_size"
                            >
                              {/* <Collapse in={sidebarDropdownStateByClick.sshManagement.isOpenTab} timeout={300} className='collapse_row_size' > */}
                              <Grid item xs={12} className="subtext">
                                <TitleColumnWrapper
                                  style={{
                                    position: "relative",
                                    left: "46px"
                                  }}
                                  className={
                                    currentRoute.includes("ssh/sshDashboard")
                                      ? `collapse sidebar_screenOptions_size currentColorIcon`
                                      : `collapse sidebar_screenOptions_size subtext`
                                  }
                                  to="/ssh/sshDashboard"
                                >
                                  SSH Dashboard
                                </TitleColumnWrapper>
                                <TitleColumnWrapper
                                  style={{
                                    position: "relative",
                                    left: "46px"
                                  }}
                                  className={
                                    currentRoute.includes("ssh/sshUserManagement")
                                      ? `collapse sidebar_screenOptions_size currentColorIcon`
                                      : `collapse sidebar_screenOptions_size subtext`
                                  }
                                  to="/ssh/sshUserManagement"
                                  data-cy="userManagementCy"
                                >
                                  SSH User Management
                                </TitleColumnWrapper>
                                <TitleColumnWrapper
                                  style={{
                                    position: "relative",
                                    left: "46px"
                                  }}
                                  data-cy="sshArchiveCy"
                                  className={
                                    currentRoute.includes("ssh/sshArchive")
                                      ? `collapse sidebar_screenOptions_size currentColorIcon`
                                      : `collapse sidebar_screenOptions_size subtext`
                                  }
                                  to="/ssh/sshArchive"
                                >
                                  SSH Archive
                                </TitleColumnWrapper>
                              </Grid>
                            </Collapse>
                            <TitleColumnWrapper
                              className={
                                currentRoute.includes("azureOpenAI")
                                  ? `collapse sidebar_screenOptions_size currentColorIcon`
                                  : `collapse sidebar_screenOptions_size subtext`
                              }
                              to="/genwizard"
                            >
                              <Grid
                                item
                                xs={12}
                                className="center paddingBottom rowContent"
                                // onClick={() => handleCollapse("sshManagement")}
                              >
                                <Grid
                                  container
                                  className={
                                    currentRoute.includes("azureOpenAI")
                                      ? `${sidebarTextOnHoverClass} currentRouteColor`
                                      : `${sidebarTextOnHoverClass} text `
                                  }
                                  data-cy="expandSSHMenu"
                                >
                                  <Grid item xs={12}>
                                    <Grid container>
                                      <Grid item xs={10} className="center" style={{ position: "relative", left: "20px" }}>
                                        Genwizard
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TitleColumnWrapper>
                          </Grid>
                          
                        </Collapse>
                        
                      </>
                    )}
                  <Grid
                    item
                    xs={12}
                    className="center paddingBottom rowContent"
                  >
                    <TitleColumnWrapper to="/faq" data-cy="faqDashboard">
                      <div>
                        <HelpIcon
                          className={
                            currentRoute.includes("faq")
                              ? `material-icons currentColorIcon`
                              : `material-icons colorIcon`
                          }
                        ></HelpIcon>
                      </div>
                    </TitleColumnWrapper>
                    <TitleColumnWrapperWithStyles
                      className={
                        currentRoute.includes("faq")
                          ? `${sidebarTextOnHoverClass} currentRouteColor`
                          : `${sidebarTextOnHoverClass} text `
                      }
                      to="/faq"
                    >
                      FAQ
                    </TitleColumnWrapperWithStyles>
                  </Grid>

                  {currentCamPermissions[0].filter(
                    (permission) => permission.component === "Admin"
                  ).length !== 0 && (
                      <Grid
                        item
                        xs={12}
                        className="center paddingBottom rowContent"
                      >
                        <TitleColumnWrapper to="/admin" data-cy="adminPage">
                          <div>
                            <AdminSettingsPanelIcon
                              className={
                                currentRoute.includes("admin")
                                  ? `material-icons currentColorIcon`
                                  : `material-icons colorIcon`
                              }
                            ></AdminSettingsPanelIcon>
                          </div>
                        </TitleColumnWrapper>
                        <TitleColumnWrapperWithStyles
                          className={
                            currentRoute.includes("admin")
                              ? `${sidebarTextOnHoverClass} currentRouteColor`
                              : `${sidebarTextOnHoverClass} text `
                          }
                          to="/admin"
                        >
                          Admin
                        </TitleColumnWrapperWithStyles>
                      </Grid>
                    )}
                </Grid>
                {/* <Divider /> */}
              </Grid>
            </Grid>
          </StyledSidebarItems>
        </StyledSidebarItemsWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.userDetails.email
    ? state.auth.userDetails.email
      .replace(/@[^@]+$/, "")
      .replace(".", " ")
      .split(" ")
      .map(function (item) {
        return item[0];
      })
      .join("")
      .toUpperCase()
    : "",
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  sidebarDropdownStateByClick: state.sidebarReducer.sidebarDropdownStateByClick,
  sidebarCollapseFulfillmentState:
    state.sidebarReducer.sidebarCollapseFulfillmentState,
  sidebarCollapseSshManagementState:
    state.sidebarReducer.sidebarCollapseSshManagementState,
  sidebarCollapseAccessManagementState: state.sidebarReducer.sidebarCollapseAccessManagementState,
  sidebarContainerOnHoverClasses:
    state.sidebarReducer.sidebarContainerOnHoverClasses,
  sidebarTextOnHoverClass: state.sidebarReducer.sidebarTextOnHoverClass,
  camPermissions: state.auth.camPermissions,
});

export default connect(mapStateToProps, {
  sidebarDropdownOptionsAction,
  sidebarCollapseStateAction,
  sidebarHandleOnHoverClassAction,
  clearLocalComponentData,
})(ResponsiveSidebar);
