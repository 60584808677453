import update from "immutability-helper";
import { headers } from "../../helper/api";
import base from "../../config";
import axios from "axios";

//Action
export const START_LOADING = "home/START_LOADING";

export const FETCH_EC2_DATA = "home/FETCH_EC2_DATA";

export const FETCH_FUI_INSTANCE_NUMBER = "home/FETCH_FUI_INSTANCE_NUMBER";

export const FETCH_FUI_MP_NUMBER = "home/FETCH_FUI_MP_NUMBER";

export const FETCH_FUI_DP_NUMBER = "home/FETCH_FUI_DP_NUMBER";

export const FETCH_FUI_PI_NUMBER = "home/FETCH_FUI_PI_NUMBER";
export const FETCH_FUI_ATR_NUMBER = "home/FETCH_FUI_ATR_NUMBER";

export const FETCH_FUI_SPLUNK_CLUSTER_NUMBER = "home/FETCH_FUI_SPLUNK_CLUSTER_NUMBER";
export const FETCH_FUI_PAM_NUMBER = "home/FETCH_FUI_PAM_NUMBER";
export const FETCH_FUI_TURING_NUMBER = "home/FETCH_FUI_TURING_NUMBER";

export const FETCH_SSH_PENDING_APPROVALS = "home/FETCH_SSH_PENDING_APPROVALS";
export const FETCH_SSH_MY_REQUESTS = "home/FETCH_SSH_MY_REQUESTS";

export const CHECK_HAS_REGISTERED = "home/CHECK_HAS_REGISTERED";
export const CHECK_HAS_NOT_REGISTERED = "home/CHECK_HAS_NOT_REGISTERED";

export const ASYNC_ERROR = "home/ASYNC_ERROR";

export const PENDING_APPROVALS = "PENDING_APPROVALS";

export const PENDING_MYREQUESTS = "PENDING_MYREQUESTS";

export const FETCH_NOTIFICATIONS_IAM_ACCESS_KEY = "home/FETCH_NOTIFICATIONS_IAM_ACCESS_KEY";

export const FETCH_DAILY_DATA_VOLUME_VALUES = "home/FETCH_DAILY_DATA_VOLUME_VALUES";

//URLs
const fetchEc2DataUrl = `${base.urls.apis.dashboard}ec2`;
const fetchFuiInstanceNumberUrl = `${base.urls.apis.dashboard}fui`;
const fetchSshNumberUrl = `${base.urls.apis.dashboard}ssh-management`;
const checkIsRegistrationUrl = `${base.urls.apis.sshNew}users`;
const fetchSSHRequests = `${base.urls.apis.sshNew}requests`;
const fetchNotificationsIAMAccessKey = `${base.urls.apis.notifications}iamaccesskey`;
const fetchDailyDataVolumeValues = `${base.urls.apis.details}`;


//Reducer
const initState = {
  isServersLoading: true,
  isFulfillmentLoading: true,
  isSshLoading: true,

  fuiInstanceNumberLoading: true,
  fui_MP_NumberLoading: true,
  fui_DP_NumberLoading: true,
  fui_PI_NumberLoading: true,
  fui_ATR_NumberLoading: true,
  fui_Splunk_Cluster_NumberLoading: true,
  fui_Pam_NumberLoading: true,
  pendingApprovalsLoading: true,
  isRegistrationLoading: true,
  pendingMyRequestsLoading: true,

  ec2DataLoading: true,

  ec2Data: [],

  fuiInstanceTotalNumberData: 0,
  fuiMPNumberData: 0,
  fuiDPNumberData: 0,
  fuiPINumberData: 0,
  fuiATRNumberData: 0,
  fuiSplunkClusterNumberData: 0,
  fuiPamNumberData: 0,
  fuiTuringNumberData: 0,
  sshPendingApprovalsData: 0,
  sshMyRequestsData: 0,
  isRegistration: false,

  error: {},
  pendingApprovals: [],
  pendingMyRequests: [],

  serversValuesRed: 0,
  notificationsIamAccessKeyData: [],
  dailyDataVolumeData: [],
};

export default function reducer(state = initState, action) {
  const data = action.data;
  switch (action.type) {
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case FETCH_EC2_DATA:
      if (JSON.stringify(state.ec2Data) !== JSON.stringify(data)) {
        return {
          ...state,
          ec2Data: data,
          isLoading: false,
          serversValuesRed: action.values,
        };
      }
      return { ...state };

    case FETCH_FUI_MP_NUMBER:
      if (action.type === "home/FETCH_FUI_MP_NUMBER") {
        return update(state, {
          fuiMPNumberData: { $set: data },
          isLoading: { $set: false },
          fui_MP_NumberLoading: { $set: false },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_FUI_DP_NUMBER:
      if (action.type === "home/FETCH_FUI_DP_NUMBER") {
        return update(state, {
          fuiDPNumberData: { $set: data },
          isLoading: { $set: false },
          fui_DP_NumberLoading: { $set: false },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_FUI_PI_NUMBER:
      if (action.type === "home/FETCH_FUI_PI_NUMBER") {
        return update(state, {
          fuiPINumberData: { $set: data },
          isLoading: { $set: false },
          fui_PI_NumberLoading: { $set: false },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_FUI_ATR_NUMBER:
      if (action.type === "home/FETCH_FUI_ATR_NUMBER") {
        return update(state, {
          fuiATRNumberData: { $set: data },
          isLoading: { $set: false },
          fui_ATR_NumberLoading: { $set: false },
        });
      }
      return { ...state };
      

    case FETCH_FUI_SPLUNK_CLUSTER_NUMBER:
      if (action.type === "home/FETCH_FUI_SPLUNK_CLUSTER_NUMBER") {
        return update(state, {
          fuiSplunkClusterNumberData: { $set: data },
          isLoading: { $set: false },
          fui_Splunk_Cluster_NumberLoading: { $set: false },
        });
      }
      return { ...state };
    
      case FETCH_FUI_PAM_NUMBER:
        if (action.type === "home/FETCH_FUI_PAM_NUMBER") {
          return update(state, {
            fuiPamNumberData: { $set: data },
            isLoading: { $set: false },
            fui_Pam_NumberLoading: { $set: false },
          });
        }
        return { ...state };

    case FETCH_FUI_TURING_NUMBER:
        if (action.type === "home/FETCH_FUI_TURING_NUMBER") {
          return update(state, {
            fuiTuringNumberData: { $set: data },
            isLoading: { $set: false },
            fui_Turing_NumberLoading: { $set: false },
          });
        }
        return { ...state };
    
    case FETCH_FUI_INSTANCE_NUMBER:
      if (action.type === "home/FETCH_FUI_INSTANCE_NUMBER") {
        return update(state, {
          fuiInstanceTotalNumberData: { $set: data },
          isLoading: { $set: false },
          fuiInstanceNumberLoading: { $set: false },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_SSH_PENDING_APPROVALS:
      if (action.type === "home/FETCH_SSH_PENDING_APPROVALS") {
        return update(state, {
          sshPendingApprovalsData: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_SSH_MY_REQUESTS:
      if (action.type === "home/FETCH_SSH_MY_REQUESTS") {
        return update(state, {
          sshMyRequestsData: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state }; 

    case FETCH_NOTIFICATIONS_IAM_ACCESS_KEY:
      if (action.type === "home/FETCH_NOTIFICATIONS_IAM_ACCESS_KEY") {
        return update(state, {
          notificationsIamAccessKeyData: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state };

    case FETCH_DAILY_DATA_VOLUME_VALUES:
      if (action.type === "home/FETCH_DAILY_DATA_VOLUME_VALUES") {
        return update(state, {
          dailyDataVolumeData: { $set: data },
        });
      }
      return { ...state };

    case CHECK_HAS_REGISTERED:
      if (action.type === "home/CHECK_HAS_REGISTERED") {
        return update(state, {
          isLoading: { $set: false },
          isRegistration: { $set: action.hasRegistered },
          isRegistrationLoading: { $set: false },
        });
      }
      return { ...state };

    // case CHECK_HAS_NOT_REGISTERED:
    //   if (action.type === "home/CHECK_HAS_NOT_REGISTERED") {
    //     return update(state, {
    //       // isLoading: { $set: false },
    //       isRegistration: { $set: false }
    //     });
    //   };
    case ASYNC_ERROR:
      if (action.type === "home/ASYNC_ERROR") {
        return update(state, {
          error: { $set: data },
          // isLoading: { $set: false }
        });
      }
      return { ...state };
    case PENDING_APPROVALS:
      if (action.type === "PENDING_APPROVALS") {
        return {
          ...state,
          pendingApprovals: action.pending,
          pendingApprovalsLoading: false,
        };
      }
      return { ...state };
    case PENDING_MYREQUESTS:
      if (action.type === "PENDING_MYREQUESTS") {
        if (JSON.stringify(state.pendingMyRequests) !== JSON.stringify(data)) {
          return {
            ...state,
            pendingMyRequests: action.pending,
            pendingMyRequestsLoading: false,
          };
        }
      }
      return { ...state };

    case "HANDLE_IS_SERVERS_LOADING":
      if (action.type === "HANDLE_IS_SERVERS_LOADING") {
        return { ...state, isServersLoading: false };
      }
      return { ...state };
    case "HANDLE_IS_FULFILLMENT_LOADING":
      if (action.type === "HANDLE_IS_FULFILLMENT_LOADING") {
        return { ...state, isFulfillmentLoading: false };
      }
      return { ...state };
    case "HANDLE_IS_SSH_LOADING":
      if (action.type === "HANDLE_IS_SSH_LOADING") {
        return { ...state, isSshLoading: false };
      }
      return { ...state };
    default:
      return state;
  }
}

//Action Creator

export const fetchEc2Data = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });

  //let url = `${fetchEc2DataUrl}?customer_enterprise_id=${customerEnterpriseId}`;
  if (customerEnterpriseId && idToken) {
    return axios
      .get(fetchEc2DataUrl, {
        headers: { ...headers, Authorization: idToken },
        params: {
          customer_enterprise_id: customerEnterpriseId,
          inititive: activeAccount,
        },
      })
      .then((res) => {
        let resData = res.data && res.data[0];
        let first = Object.values(resData.values)[0];
        let seccond = Object.values(resData.values)[1];
        let values;
        if (first && seccond) {
          values = first + seccond;
        } else if (first && seccond === undefined) {
          values = first;
        } else if (first === undefined && seccond) {
          values = seccond;
        } else if (first === undefined && seccond === undefined) {
          values = 0;
        }

        dispatch({ type: "HANDLE_IS_SERVERS_LOADING" });
        dispatch({ type: FETCH_EC2_DATA, data: res.data, values });
      })
      .catch((err) => {
        dispatch({ type: "HANDLE_IS_SERVERS_LOADING" });
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const fetchFuiInstanceTotalNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "All",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: "HANDLE_IS_FULFILLMENT_LOADING" });
      dispatch({ type: FETCH_FUI_INSTANCE_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstanceMPNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps Managed Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: "HANDLE_IS_SSH_LOADING" });
      dispatch({ type: FETCH_FUI_MP_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstanceDPNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps Dedicated Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_DP_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstancePINumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "Plain Instace",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_PI_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchSshPendingApprovals = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchSshNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        instance_owner: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_SSH_PENDING_APPROVALS, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchSshMyRequests = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;

  dispatch({ type: START_LOADING });
  return axios
    .get(fetchSshNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        requester: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_SSH_MY_REQUESTS, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstanceATRNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps ATR Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_ATR_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FUI_ATR_NUMBER, data: 0 });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstanceSplunkClusterNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps Splunk Cluster Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_SPLUNK_CLUSTER_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FUI_SPLUNK_CLUSTER_NUMBER, data: 0 });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstancePamNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps PAM Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_PAM_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FUI_PAM_NUMBER, data: 0 });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const fetchFuiInstanceTuringNumber = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const customerEnterpriseId = getState().auth.userDetails.email
    ? getState().auth.userDetails.email.split("@")[0]
    : null;
  dispatch({ type: START_LOADING });
  return axios
    .get(fetchFuiInstanceNumberUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        product_type: "myWizard AiOps Turing Platform",
        customer_enterprise_id: customerEnterpriseId,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_FUI_TURING_NUMBER, data: res.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_FUI_TURING_NUMBER, data: 0 });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const checkIsRegistration = (eid, idToken) => (dispatch, getState) => {
  //const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const email = eid;
  dispatch({ type: START_LOADING });
  return axios
    .get(checkIsRegistrationUrl, {
      headers: { ...headers, Authorization: idToken },
      params: { email: email, inititive: activeAccount },
    })
    .then((res) => {
      // const hasRegistered = res.data === "true";
      // const hasNotRegistered = res.data === "false";
      dispatch({ type: CHECK_HAS_REGISTERED, hasRegistered: res.data });
      // hasNotRegistered && dispatch({ type: CHECK_HAS_NOT_REGISTERED });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const getPendingApprovals = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${fetchSSHRequests}?instance_owner=${eId}&inititive=${activeAccount}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const apiCall = () => {
    return axios
      .get(url2, options)
      .then((res) => {
        if (res.data) {
          let pending = [];
          res.data.forEach((item) => {
            if (item.status === "pending") {
              pending.push(item);
            }
          });
          if (pending) {
            dispatch({ type: PENDING_APPROVALS, pending });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return apiCall();
};

export const getPendingMyRequests = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${fetchSSHRequests}?requester=${eId}&inititive=${activeAccount}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const apiCall = () => {
    return axios
      .get(url2, options)
      .then((res) => {
        if (res.data.requests) {
          let pending = [];
          res.data.requests.forEach((item) => {
            if (item.status === "pending") {
              pending.push(item);
            }
          });
          if (pending) {
            dispatch({ type: PENDING_MYREQUESTS, pending });
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return apiCall();
};


export const fetchNotifications = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${fetchNotificationsIAMAccessKey}?userepid=${eId}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const apiCall = () => {
    return axios
      .get(url2, options)
      .then((res) => {
        //console.log(res);
        dispatch({ type: FETCH_NOTIFICATIONS_IAM_ACCESS_KEY, data: res.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return apiCall();
};


export const fetchDailyDataVolume = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${fetchDailyDataVolumeValues}?userepid=${eId}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const apiCall = async () => {
    try {
      const res = await axios
        .get(url2, options);
      //console.log(res);
      dispatch({ type: FETCH_DAILY_DATA_VOLUME_VALUES, data: res.data });
      localStorage.setItem("dailyDataVolumes", res.data.sizeinGB);
    } catch (err) {
      console.log("err", err);
    }
  };
  return apiCall();
};

