import React, { useState, useEffect, useRef } from "react";
import { Chip, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";

import { ChipsWrapper, ChipWrapper } from "../SshStyles";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import {
  DialogActions,
  TextField,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import {
  addUsers,
  checkUserExisted,
  disabledChipButtonAction,
  sshManagement_DebounceEmptyString,
  checkUserExistAction,
  resetValidation,
  isLoadingSshManagementDialogAction,
} from "../../../redux/ssh/index";
import AddUsersSuccessFailDialog from "./AddUsersSuccessFailDialog";
import { useDebounce } from "../../../helper/customizedHooks";

import { DeclineButton } from "../../common/CommonComponents/Buttons";
import { AcceptButtonSshUserDialog } from "../../common/CommonStyles/CommonButtons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
import theme from "../../../theme/theme";
/**
 * Dialog that lets the user add several users to ec2 instance.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: SSHUserManagementTable
 */

const AddUsersDialog = ({
  ec2Data,
  openAdd,
  handleClose,
  addUsersCall,
  email,
  users,
  userManagementAddSuccess,
  userManagementAddResults,

  // Variables for new user exists in database validations
  checkUserExistedCall,
  isAddUserIconDisabled,
  checkUserStartLoadingIcon,
  disabledChipAction,
  isUserHaveNotSshKey,
  apiError,
  sshManagement_DebounceEmptyStringAction,
  checkUserExistAction,
  idToken,
  resetValidationAction,
  isLoadingSshManagementDialogAction,
  isLoadingSshManagementDialog,
}) => {
  const [addChip, setAddChip] = useState(false);
  const [error, setError] = useState(false);
  const [currentUserValue, setCurrentUserValue] = useState("");
  const [errorText, setErrorText] = useState("");
  const [openInner, setOpenInner] = useState(false);
  const [response, setResponse] = useState("");
  const [, setUpdateChipToggle] = useState(false);
  const [addUserToggle, setAddUserToggle] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // useState to save the information of the TextInput
  const [eidValue, setEidValue] = useState("");

  const [, setInvalidFormat] = useState(false);
  const chipsRef = useRef([]);
  const labelsRef = useRef([]);

  const innerHandleClose = () => {
    setOpenInner(false);
    setEidValue("");
  };

  const closeDialog = () => {
    setEidValue("");
    handleClose();
    setInvalidFormat(false);
    setError(false);
    setErrorText("");
  };

  //Chip delete function to delete the users selected chip to delete.
  const handleDelete = (event) => {
    var currentChips = chipsRef.current;
    //Gets all chips but the deleted chip
    var temp = currentChips.filter((chip) => {
      return chip.key.toString() !== event.id.toString();
    });
    chipsRef.current = temp;
    //Gets all chip names but the deleted chips name
    var res = [];
    chipsRef.current.forEach((chip) => {
      res.push(chip.props.label);
    });
    labelsRef.current = res;
    //Toggle to trigger rerender.
    setUpdateChipToggle((updateChipToggle) => !updateChipToggle);
  };

  const validateChips = (chip) => {
    var notError = true;
    //Validates that the chip has a '.' in it
    if (!chip.includes(".") || chip.includes("@")) {
      setErrorText("Please enter a valid enterprise ID");
      notError = false;
    }
    //Validates that the chip isnt already being added.
    if (labelsRef.current.includes(chip)) {
      setErrorText("User pending to be added");
      notError = false;
    }
    //Validates if the user already has ssh access to an instance
    if (users) {
      if (users.includes(chip)) {
        setErrorText("User already has ssh access to instance");
        notError = false;
      }
    }
    return notError;
  };

  //Handler to add chip to list of chips currently displayed
  const handleAddChip = (eventTar) => {
    // for avoid multiple clicks in "ADD USERS" button
    setEidValue("");
    // Acction that will disabled the AddChipButton
    disabledChipAction();
    if (validateChips(eventTar.value)) {
      setError(false);
      var newChip = (
        <Chip
          id={chipsRef.current.length}
          key={chipsRef.current.length}
          label={eventTar.value}
          onDelete={(e) => handleDelete(e.target.parentNode.parentNode)}
          data-cy="deletable-chip"
        />
      );
      //Adds new chip to list of chips
      var temp = [...chipsRef.current, newChip];
      chipsRef.current = temp;
      setAddChip(!addChip);
      //Adds new chips name to list of names
      var temp2 = [...labelsRef.current, eventTar.value];
      labelsRef.current = temp2;
    } else {
      setError(true);
    }
  };

  //Allows user to press 'enter' to add a chip (as well as the + button)
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // handleAddChip(event.target);
    }
  };

  //Submits users selected chips for ssh access
  const handleSubmitSelection = () => {
    isLoadingSshManagementDialogAction(true);
    setAddChip(!addChip);
    addUsersCall(
      email,
      ec2Data["instanceId"],
      labelsRef.current,
      ec2Data["accountType"],
      ec2Data["accountId"]

    );
    setAddUserToggle(true);
  };

  //Toggles the inner dialog to display the results of adding users to instance.
  useEffect(() => {
    if (
      userManagementAddSuccess &&
      chipsRef.current.length > 0 &&
      addUserToggle
    ) {
      setResponse(userManagementAddResults);
      setOpenInner(true);
    }
  }, [userManagementAddResults]);

  //Resets the chips when the user closes the dialog.
  useEffect(() => {
    chipsRef.current = [];
    labelsRef.current = [];
    setAddUserToggle(false);
  }, [openAdd]);

  // it would check whether the User has an ssh key and existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(eidValue, 1000);
  useEffect(() => {
    if (debouncedValue) {
      checkUserExistedCall(
        debouncedValue,
        email,
        ec2Data["accountType"],
        false
      );
    } else {
      sshManagement_DebounceEmptyString();
    }
  }, [debouncedValue]);

  // this funciont collect the value of the TextField
  const handleCurrentValue = (e) => {
    setError(false);
    setErrorText("");
    resetValidationAction();
    let str = e.target.value;
    if (str.includes("@")) {
      setInvalidFormat(true);
      setEidValue(e.target.value);
      setCurrentUserValue(e.target);
    } else {
      setInvalidFormat(false);
      setCurrentUserValue(e.target);
      setEidValue(e.target.value);
    }
  };

  return (
    <CommonDialog
      open={openAdd}
      onClose={closeDialog}
      title={"Add SSH User"}
      textContent={"Please type in the dialog below to find a user"}
      dialogType={"ssh"}
      dialogContent={
        <DialogContent>
          <TextField
            placeholder="example: joe.brown"
            label="Add User"
            style={{ width: "100%" }}
            // style={{ margin: "0px 20px 20px 20px" }}
            // new function added to collect the value
            onChange={handleCurrentValue}
            onKeyDown={(e) => handleKeyDown(e)}
            // isUserHaveSshKey variable added to show an errror whent the user doesn't exist in the MongoDB Database
            error={error ? true : isUserHaveNotSshKey && true}
            helperText={
              apiError
                ? "There is an error in the API, contact the Support Team."
                : error
                ? errorText
                : isUserHaveNotSshKey &&
                  "This user doesn't have a Registered Key"
            }
            value={eidValue}
            data-cy="addUserCy"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/*Validation added to show a circular progress when API call is fetching */}
                  {checkUserStartLoadingIcon ? (
                    <CircularProgress size={20} />
                  ) : (
                    <IconButton
                      disabled={isAddUserIconDisabled}
                      aria-label="add user"
                      onClick={(e) => handleAddChip(currentUserValue)}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <ChipsWrapper>
            {chipsRef.current.map((Component, key) => {
              return <ChipWrapper key={key}>{Component}</ChipWrapper>;
            })}
          </ChipsWrapper>
          {openInner && (
            <AddUsersSuccessFailDialog
              response={response}
              open={openInner}
              innerHandleClose={innerHandleClose}
              handleClose={handleClose}
              setIsLoading={isLoadingSshManagementDialogAction}
            />
          )}
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <AcceptButtonSshUserDialog
            variant="outlined"
            onClick={handleSubmitSelection}
            disabled={
              chipsRef.current.length < 1 || isLoadingSshManagementDialog
            }
          >
            {isLoadingSshManagementDialog ? (
              <CircularProgress
                size={28}
                style={{ color: `${theme.palette.ssh.main}` }}
              />
            ) : (
              "Add Users"
            )}
          </AcceptButtonSshUserDialog>
          <DeclineButton
            onClick={closeDialog}
            data-cy="addUsersCancel"
            label={"Cancel"}
            disabled={false}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

AddUsersDialog.propTypes = {
  /**
   * An instance owned by the user
   */
  ec2Data: PropTypes.object,
  /**
   * Toggle to open/close current dialog
   */
  openAdd: PropTypes.bool,
  /**
   * Function to close current dialog
   */
  handleClose: PropTypes.func,
  /**
   * Redux function to add the ssh user selected users to an instnace
   */
  addUsersCall: PropTypes.func,
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * State which tells us if addUsers was called successfull
   */
  userManagementAddSuccess: PropTypes.bool,
  /**
   * State which tells us the result of adding users.
   */
  userManagementAddResults: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    userManagementAddSuccess: state.ssh.userManagementAddSuccess,
    userManagementAddResults: state.ssh.userManagementAddResults,
    isAddUserIconDisabled: state.ssh.isAddUserIconDisabled,
    checkUserStartLoadingIcon: state.ssh.checkUserStartLoadingIcon,
    isUserHaveNotSshKey: state.ssh.isUserHaveNotSshKey,
    apiError: state.ssh.apiError,
    isLoadingSshManagementDialog: state.ssh.isLoadingSshManagementDialog,
    idToken: state.auth.idToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addUsersCall: (email, instance_id, userepids, accountType, accountId) =>
    dispatch(addUsers(email, instance_id, userepids, accountType, accountId)),
  checkUserExistedCall: (debouncedValue, email, accountType) =>
    dispatch(checkUserExisted(debouncedValue, email, accountType)),
  disabledChipAction: () => dispatch(disabledChipButtonAction()),
  sshManagement_DebounceEmptyStringAction: () =>
    dispatch(sshManagement_DebounceEmptyString()),
  resetValidationAction: () => dispatch(resetValidation()),
  checkUserExistAction: (data) => dispatch(checkUserExistAction(data)),
  isLoadingSshManagementDialogAction: (data) =>
    dispatch(isLoadingSshManagementDialogAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersDialog);
