import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import FuiHomePage from "./FuiHomePage";
import PlatformList from "./PlatformDetailLists/PlatformList";
import LicenseList from "./LicenseDetailLists/LicenseList";
import * as fuiRedux from "../../redux/fui/index";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFUIMakeStyles } from "./FuiMakeStyles";
import { Footer } from "../common/Footer";

/**
 * Fulfilment home page and apps
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: routes
 */
const FulfillmentUI = ({ match }) => {
  const isStandardOrder = match.params.type === "order";
  const isPlatforms = match.params.type === "platforms";
  const isLicenses = match.params.type === "licenses";
  const classes = useFUIMakeStyles();

  return (
    <Fragment>
      {(isStandardOrder && <FuiHomePage classes={classes} />) ||
        (isPlatforms && (
            <PlatformList isNotProducts={true} classes={classes} routeName={"platforms"}/>
        )) ||
        (isLicenses && <LicenseList isNotProducts={true} classes={classes} />)}
      {/* <Footer /> */}
    </Fragment>
  );
};
FulfillmentUI.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Match property of route library
   */
  match: PropTypes.object,
  /**
   *
   */
  clearLocalComponentData: PropTypes.func,
  /**
   * Start loading component
   */
  startLoading: PropTypes.func,
};
export default connect(null, {
  clearLocalComponentData: fuiRedux.clearLocalComponentData,
  startLoading: fuiRedux.startLoading,
})(withRouter(FulfillmentUI));
