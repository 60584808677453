import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import {
  ProgressWrapper,
  FinalWrapper,
  SuccessWrapper,
  StyledAvatar,
  ToAccessTypography,
} from "../SshStyles";

import RemoveIcon from "@material-ui/icons/Remove";
import SSHCards from "../SshCommon/SSHCards";
import PropTypes from "prop-types";
import theme from "../../../theme/theme";

/**
 * Renders the requests cards
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: Ssh.js
 */

const MyRequests = ({ loading, data }) => {
  return (
    <div>
      {loading ? (
        <ProgressWrapper>
          <CircularProgress style={{ color: `${theme.palette.ssh.main}` }} />
        </ProgressWrapper>
      ) : data && data.length === 0 ? (
        <FinalWrapper>
          <SuccessWrapper>
            <StyledAvatar>
              <RemoveIcon />
            </StyledAvatar>
            <ToAccessTypography>There is no my request!</ToAccessTypography>
          </SuccessWrapper>
        </FinalWrapper>
      ) : (
            <Grid container>
              {data.map((element, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    style={{ padding: "10px 20px 10px 20px" }}
                    key={i}
                  >
                    <SSHCards cardData={element} cardType={"MyRequests"} />
                  </Grid>
                );
              })}
            </Grid>
          )}
    </div>
  );
};

MyRequests.propTypes = {
  /**
   * Boolean that confirms is the page is loading
   */
  loading: PropTypes.bool,
  /**
   * The complete data containing all requests of a user.
   */
  data: PropTypes.array,
};

export default MyRequests;
