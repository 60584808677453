import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const QrCode = ({ draw, height, width, link }) => {
  const canvas = React.useRef();
  React.useEffect(() => {
    const context = canvas.current.getContext("2d");
    draw(context, link);
  });

  return <canvas ref={canvas} height={height} width={width} />;
};

QrCode.propTypes = {
  draw: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default QrCode;
