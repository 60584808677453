import React from "react";

import { DialogTextWrapper, IconWrapper } from "../ssh/SshStyles";

import { Subtitle3 } from "../common/CommonStyles/CommonTypographyStyles";
import theme from "../../theme/theme";
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/Done";
import FailIcon from "@material-ui/icons/Clear";
import { AcceptButton } from "../common/CommonComponents/Buttons";
import { CommonDialog } from "../common/CommonComponents/Dialog";
/**
 * Dialog after user submits ssh user access request to with results of attempting to add ssh users.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: AddUsersDialog
 */

const AdminSuccessFailDialog = ({
  response,
  open,
  innerHandleClose,
  handleClose,
  isLoading,
  title,
}) => {
  const closeDialog = () => {
    innerHandleClose();
    handleClose();
  };

  return (
    <CommonDialog
      open={open}
      onClose={closeDialog}
      title={title}
      dialogType={"admin"}
      dialogContent={
        !isLoading ? (
          <DialogTextWrapper>
            <DialogContentText id="alert-dialog-description">
              {response.includes("success") ? (
                <IconWrapper>
                  <SuccessIcon
                    style={{
                      color: theme.status.running,
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  />
                  <Subtitle3>{response}</Subtitle3>
                </IconWrapper>
              ) : (
                <div>
                  <IconWrapper>
                    <FailIcon
                      style={{
                        color: theme.status.stopped,
                        width: "1.5em",
                        height: "1.5em",
                      }}
                    />
                    <Subtitle3>{response}</Subtitle3>
                  </IconWrapper>
                </div>
              )}
            </DialogContentText>
          </DialogTextWrapper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={40} />
          </div>
        )
      }
      dialogActions={
        <DialogActions>
          <AcceptButton
            onClick={closeDialog}
            label={"Ok"}
            data-cy="CreateRoleOk"
            disabled={false}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

AdminSuccessFailDialog.propTypes = {
  /**
   * Response from submitting ssh user access for an instance, denoting any errors.
   */
  response: PropTypes.array,
  /**
   * Toggle to open/close current dialog
   */
  openAdd: PropTypes.bool,
  /**
   * Function to close current dialog
   */
  handleClose: PropTypes.func,
};

export default AdminSuccessFailDialog;
