import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { DeclineButton } from "../common/CommonComponents/Buttons";
import { HoverTableRow, TableFirstRow, StyledPaper, TableWrapper, PlatformDetailsWrapper, PlatformWrapper, PlatformTitleWrapper } from "./FuiStyles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  DialogActions,
  Button
  // , Grid
} from "@material-ui/core";
import { XSmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import {
  StyledAvatar,
  ProgressWrapper,
  SuccessWrapper,
  ButtonWrapperFromSsh,
  FinalWrapperFromSsh,
  ToAccessTypography,
} from "./FuiStyles";
import RemoveIcon from "@material-ui/icons/Remove";
import PropTypes from "prop-types";
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import { CircularProgress } from "@material-ui/core";

/**
 * Components which shows order requests
 * @author shilpa.adikeshava
 * @version 1.0
 */


const OrderRequestsList = (item, history, isLoading) => {
  const [openDeclinedReasonPopup, setDeclinedReasonPopup] = useState(false);
  const [declinedReason, setDeclinedReason] = useState("");

  const handleOpen = (data) => {
    if (data.OrderItems[0].Config.find(item => item.Key === "DeclinedReason")) {
      const reason = data.OrderItems[0].Config.find(item => item.Key === "DeclinedReason")['Value']
      setDeclinedReason(reason)
    } else {
      setDeclinedReason("")
    }
    setDeclinedReasonPopup(true);
  };

  const sortedRequestedData = item ? item.data.sort((a, b) => new Date(b.OrderItems[0].CreatedDate) - new Date(a.OrderItems[0].CreatedDate)) : undefined;

  return (
    <>
      <div style={{ height: "90%", marginTop: "2%", maxHeight: "90%" }}>
        <TableWrapper style={{ height: "100%" }}>
          <PlatformWrapper style={{ height: "10%", maxHeight: "10%" }}>
            <PlatformTitleWrapper>
              <XSmallHeader>Order Requests Status</XSmallHeader>
            </PlatformTitleWrapper>
          </PlatformWrapper>
          {isLoading ? (
            <div style={{ height: "90%", maxHeight: "90%" }}>
              <ProgressWrapper
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </ProgressWrapper>
            </div>
          ) : (sortedRequestedData.length == 0) ? (
            <FinalWrapperFromSsh>
              <SuccessWrapper>
                <StyledAvatar>
                  <RemoveIcon />
                </StyledAvatar>
                <ToAccessTypography>
                  You haven't ordered any instance!
                </ToAccessTypography>
              </SuccessWrapper>
              <ButtonWrapperFromSsh>
                <PrimaryButton
                  onClick={() => history.push("/fui/order")}
                  data-cy="goToOrderAnInstance"
                  label={"GO TO ORDER AN INSTANCE"}
                  style={{ background: "#7500C0", color: "#FFF" }}
                // className={classes.FuiPrimaryButton}
                />
              </ButtonWrapperFromSsh>
            </FinalWrapperFromSsh>
          ) : (
            <StyledPaper style={{ overflow: "auto", marginTop: "1%", height: "90%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableFirstRow align="center">REQUEST ID</TableFirstRow>
                    <TableFirstRow align="center">CLIENT NAME</TableFirstRow>
                    <TableFirstRow align="center">PRODUCT NAME</TableFirstRow>
                    <TableFirstRow align="center">DOMAIN CONTEXT ROOT</TableFirstRow>
                    <TableFirstRow align="center">CREATED DATE</TableFirstRow>
                    <TableFirstRow align="center">APPROVER</TableFirstRow>
                    <TableFirstRow align="center">STATUS</TableFirstRow>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRequestedData.map((row, key) => (
                    <HoverTableRow key={key}>
                      <TableCell align="center">{row.OrderItems[0].OrderItemUId}</TableCell>
                      <TableCell align="center">{row.ClientName === "myWizard AiOps" ? "GenWizard AiOps" : row.ClientName}</TableCell>
                      <TableCell align="center">
                        {
                          row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value'].includes("myWizard AiOps") 
                          ? row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value'].replace('myWizard AiOps','') 
                          : row.OrderItems[0].Config.find(item => item.Key === "AaamPlatformType")['Value']
                        }
                      </TableCell>
                      <TableCell align="center">{row.OrderItems[0].Config.find(item => item.Key === "DomainContextRoot")['Value'] == "" ? row.DMSId : row.OrderItems[0].Config.find(item => item.Key === "DomainContextRoot")['Value']}</TableCell>
                      <TableCell align="center">{row.OrderItems[0].CreatedDate ? row.OrderItems[0].CreatedDate.split("T")[0] : row.OrderItems[0].CreateDate.split("T")[0]}</TableCell>
                      <TableCell align="center">{row.OrderItems[0].Config.find(item => item.Key === "Approver") ? row.OrderItems[0].Config.find(item => item.Key === "Approver")['Value'].split("@")[0] : "NA"}</TableCell>
                      <TableCell align="center">
                        {row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus") ?
                          (() => {
                            if (row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value'] === "Pending Approval")
                              return <span style={{ padding: "6px", color: "rgb(255, 120, 0)", fontWeight: "500" }}>PENDING</span>
                            else if (row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value'] === "Approved")
                              return <span style={{ padding: "6px", color: "rgb(72, 203, 72)", fontWeight: "500" }}>APPROVED</span>
                            else if (row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value'] === "Declined")
                              return <span>
                                <Button
                                  style={{ padding: "6px", color: "rgb(233, 13, 13)", fontWeight: "500" }}
                                  onClick={() => handleOpen(row)}
                                >
                                  Declined
                                </Button>
                              </span>
                            else
                              return <span>{row.OrderItems[0].Config.find(item => item.Key === "ApprovalStatus")['Value']}</span>
                          })() :
                          "NA"
                        }
                      </TableCell>
                    </HoverTableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledPaper>
          )}
        </TableWrapper>
      </div>
      {openDeclinedReasonPopup && (
        <CommonDialog
          open={openDeclinedReasonPopup}
          onClose={() => setDeclinedReasonPopup(false)}
          title={"Declined Reason"}
          dialogType={"fui"}
          textContent={
            <div>
              <p style={{ fontWeight: "400" }}>
                We regret to inform you that your order request has been declined!
              </p>
              {declinedReason !== "" ?
                <p>Reason: {declinedReason}</p> : ""}
            </div>
          }
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setDeclinedReasonPopup(false)}
                label={"CLOSE"}
                disabled={false}
              />
            </DialogActions>
          }
        ></CommonDialog>
      )}
    </>
  );
};

const MSTP = (state) => ({
  isLoading: state.fui.isLoading,
});

OrderRequestsList.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,

};

export default connect(MSTP)(withRouter(OrderRequestsList));
