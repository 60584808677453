import { makeStyles } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
const drawerWidth = 210;

export const useCommonSidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: "#f5f5f5",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#f5f5f5",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // background: "#2a2a2a"
  },
  toolbarhor: {
    width: "100%",
    display: "flex",
    color: "#000000",
    fontSize: "2em",
  },
  imgflex: {
    display: "flex",
    paddingTop: "0.6em",
    paddingBottom: "4px",
  },
  toolbarcolor: {
    background: "#f5f5f5",
  },
  container: {
    display: "flex",
  },
  fatherContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paper: {
    margin: theme.spacing(1),
  },
  paddingPaper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
  firstColSize: {
    display: "flex",
    height: "100%",
    // marginLeft: '.6em'
  },
  firstColColor: {
    height: "100%",
    color: "white",
    display: "flex",
    background: "#f5f5f5",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  typographyMargin: {
    margin: "10px 0 10px 10px",
  },
  flexPaper: {
    display: "flex",
  },
  flexGrid: {
    display: "flex",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rowReverse: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  marginBottomSm: {
    marginBottom: ".2em",
    paddingTop: "0px",
  },
  sedCard: {
    display: "flex",
    flexDirection: "column",
    margin: ".6em",
    color: "black",
    height: "6em",
  },
  sed_grid_fixHeight: {
    height: "100%",
  },
  rightSedCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftSedCard: {
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 .6em",
    margin: "0",
    color: "#f2f2f2",
  },
  sedInstanceFirstRow: {
    background: "#f5f5f5",
    // borderRadius: "3px 3px 0 0",
    height: "32%",
  },
  sedInstanceSeccondRow: {
    background: "#f2f2f2",
    // borderRadius: "0 0 3px 3px",
    height: "68%",
  },
  sed_content_fixHeight: {
    height: "100%",
  },
  titleContentLeft: {
    marginLeft: ".6em",
  },
  paperServiceEndDateTable: {
    width: "100%",
    marginTop: "1em",
    maxHeight: "32em",
  },
  paperServiceEndDateContainer: {
    maxHeight: "9em",
  },
  paperServiceEndDateContainer2: {
    maxHeight: "27em",
  },
  paper_marginLeft: {
    marginLeft: "2em",
    background: "red",
  },
}));

export const useCommonEc2ManagementStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    background: "#e00000",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
  instanceCard: {
    border: "1px solid black",
    // borderRadius: "5px",
  },
  rowEm: {
    height: "9em",
  },
  scheduledHeight: {
    height: "100%",
    width: "100%",
  },
  instanceStateText: {
    letterSpacing: "4px",
    color: "#f2f2f2",
  },
  paperHeight1: {
    height: "100%",
    borderRight: "1px solid",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  paperHeight: {
    height: "100%",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "0px",
  },
  centerScheduledItems_title: {
    display: "flex",
    justifyContent: "center",
    // paddingTop: '.4em',
    fontWeight: "bold",
    // paddingBottom: '.2em',
    letterSpacing: ".4px",
  },
  centerScheduledItems_days: {
    display: "flex",
    justifyContent: "center",
    letterSpacing: ".1em",
    // paddingBottom: '.2em'
  },
  centerScheduledItems_runTime: {
    display: "flex",
    justifyContent: "center",
    fontSize: ".9em",
  },
  centerScheduledItems: {
    display: "flex",
    justifyContent: "center",
  },
  serversContainer: {
    display: "flex",
    height: "100%",
    // border: '1px solid',
    // borderRadius: "5px",
  },
  scheduledContainer: {
    display: "flex",
    height: "100%",
    // borderBottom: '1px solid grey'
  },
  instanceCardVerticalWrapper: {
    writingMode: "vertical-lr",
    width: "36px",
    transform: "rotate(-180deg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  instanceCardItemWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  instanceStatusItem: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },

  // Cars details
  cardDetailsFirstRowWrapper: {
    background: "#f5f5f5",
    padding: "0 0 0 12px",
    borderTopRightRadius: "3px",
    height: "20%",
    color: "#f2f2f2",
  },
  cloudsServersCard: {
    display: "flex",
  },
  cardDetailsFirstRowTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  cardDetailsFirstRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftCardDetails: {
    // padding: '5px 10px 5px 10px',
  },
  leftCardDetailsBorderRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingLeft: "1em",
  },
  rightCardDetailsEditAndShow: {
    height: "50%",
  },
  rightCardDetailsEditAndShowCenter: {
    display: "flex",
    justifyContent: "center",
  },

  containerFlex: {},

  // New and last servers Card styles
  paperContainer: {
    width: "99%",
    height: "10em",
    marginLeft: "1em",
  },
  serversCardLeftContent: {
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12);",

    borderRight: "1px solid rgba(0, 0, 0, 0.12);",
  },
  serversCardHeight: {
    height: "100%",
  },
  serversCard_Schedule_Height: {
    height: "100%",
    paddingTop: ".2em",
    paddingBottom: ".2em",
  },
  commonFlexCenterTitles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
  commonFlexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  serversCard_scheduleDaysWrapper_position: {
    display: "flex",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  serversCard_ScheduleDays_spacing: {
    margin: "0 .2em 0 .2em",
  },

  cardFrontDetails: {
    height: "80%",
  },

  cardDetailsWrapperHeight: {
    height: "100%",
  },

  cardDetails_values: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },

  cardDetails_keyValue_padding: {
    paddingLeft: ".4em",
    fontWeight: "bold",
  },

  displayFlex: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  paddingsOnFilters: {
    paddingTop: ".4em",
    paddingLeft: "2em",
    paddingBottom: "0px",
  },
  paddingsOnStatistics: {
    paddingLeft: "1.5em",
  },
  servers_filtersAndSearch_padding: {
    paddingBottom: "0px",
  },
  servers_removedPadding: {
    padding: "0px",
  },
  details_paddingLeft: {
    paddingLeft: "2em",
    paddingRight: "1em",
    paddingBottom: "1em",
  },
  details_paddingRight: {
    paddingLeft: "1em",
    paddingRight: "2em",
    paddingBottom: "1em",
  },

  actionsButton: {
    height: "50%",
  },

  centerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  nothingToDisplay: {
    width: "100%",
  },
  actionButtonsStyle: {
    borderColor: theme.palette.aws.main,
    color: theme.palette.aws.main,
    width: "100px",
    fontSize: "15px",
    marginTop: "9px",
    "@media (max-width: 1300px)": {
      fontSize: "14px",
      width: "75px",
    },
    "@media (max-width: 1100px)": {
      fontSize: "12px",
      width: "60px",
    },
    "@media (max-width: 900px)": {
      fontSize: "10px",
      width: "40px",
    },
  },
  editScheduledButton: {
    borderColor: theme.palette.aws.main,
    color: theme.palette.aws.main,
  },
  filterButton: {
    color: theme.typography.main,
  },
  downloadButton: {
    borderColor: theme.palette.aws.main,
    color: theme.palette.aws.main,
  },
}));
