import { makeStyles } from "@material-ui/core";

export const useNavbarStyles = makeStyles((theme) => ({
  navbar_size: {
    height: "4em",
    background: "#f5f5f5",
    display: "flex",
    boxShadow: "unset",
    // boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
  },
  allHeight: {
    height: "100%",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  navbar_logo: {
    display: "flex",
    alignItems: "center",
    width: "14em",
    background: "#f5f5f5",
    height: "100%",
    paddingLeft: "1em",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  commonFlexAlign: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  alignSimpleCloudTarget: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  marginSimpleCloudButton: {
    marginRight: "1em",
  },
  paper: {
    position: "absolute",
    width: 770,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    paddingBottom: "2%",
  }
}));
