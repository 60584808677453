import React from "react";
import BackToStandardList from "../BackToStandardList";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as fuiRedux from "../../../redux/fui/index";

import Typography from '@material-ui/core/Typography';
import {
  Avatar,
  Tooltip,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import {
  PageWrapper,
  LeftColumn,
  RightColumn,
  DetailsTypography,
  StyledExpansionPanel,
  FirstLineWrapper,
  StyledTypography,
  StyledParagraphBold,
  TextWrapper,
  LongDivider,
  StyledTypographyHeader,
} from "../FuiStyles";
import ConfigurationType from "../ConfigurationType";
import { ProductsInfomation } from "../Constants/ProductsInfomation";
import {
  ProductsInformationPam,
  ProductsInformationMcc,
} from "../Constants/ProductsInformationOtherIntiatives";
import BookIcon from "@material-ui/icons/Book";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Automatic Ticket Resolver form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: AppStepper
 */


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: "0px"
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

//Choose a configuration type and enter into quick or full configuration components
const GeneralForm = ({
  setActiveStep,
  activeStep,
  FormId,
  LeftColumnFields,
  ClientContractFields,
  EnvironmentFields,
  ApplicationFields,
  RightColumnFields,
  history,
  NoConfig,
  belong,
}) => {
  function getSteps() {
    return ['Client & Contract Information', 'Environment Details', 'Application Configuration Details'];
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return ClientContractFields
      case 1:
        return EnvironmentFields
      case 2:
        return ApplicationFields
      default:
        return 'Unknown step';
    }
  }
  const classes = useStyles();
  const steps = getSteps();

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div>
      {/* <PageWrapper> */}
        <BackToStandardList history={history} />
        <StyledTypographyHeader variant="h1">
          {
            (belong === "MCC"
              ? ProductsInformationMcc
              : belong === "pam"
                ? ProductsInformationPam
                : ProductsInfomation
            ).products[FormId].productName
          }
        </StyledTypographyHeader>

        <DetailsTypography>
          {
            (belong === "MCC"
              ? ProductsInformationMcc
              : belong === "pam"
                ? ProductsInformationPam
                : ProductsInfomation
            ).products[FormId].productDescription
          }
        </DetailsTypography>
        {!NoConfig && <ConfigurationType />}

        <LongDivider />
        <LeftColumn>{LeftColumnFields}</LeftColumn>
        <RightColumn>
          {/* <TextWrapper>
            <StyledParagraphBold>
              PRODUCT CONFIGURATION DETAILS:
            </StyledParagraphBold>
          </TextWrapper> */}

          {RightColumnFields}
        </RightColumn>
      {/* </PageWrapper> */}
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical" style={{ padding: "0px" }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel><p style={{ fontSize: "17px" }}>{label}</p></StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button

                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? "" :
                      <Button
                        style={{ float: "right" }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        Next
                      </Button>}

                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )} */}

      </div>
    </div>
  );
};

const MSTP = (state) => ({
  activeStep: state.fui.activeStep
});

GeneralForm.propTypes = {

  setActiveStep: PropTypes.func,

};

export default connect(MSTP, {
  setActiveStep: fuiRedux.setActiveStep,
})(GeneralForm);

