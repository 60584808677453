import * as serversTypes from "./ServersActionTypes";
import { instanceSEDVerification } from "../../components/common/InstanceSEDVerification/InstanceSEDVerification";

const initialState = {
  isLoading: true,
  instanceList: [],
  isFetchingError: {
    isErrorExist: false,
    isErrorText: "",
  },
  instancesNewTags: [],

  dropdownFiltersServers: false,

  searchFilterValue: "",
  filteredDropdownObject: {
    bySchedule: {
      value: 10,
      type: "ALL",
      filterCase: "bySchedule",
      selected: false,
    },
    byInstanceState: {
      value: 10,
      type: "ALL",
      filterCase: "byInstanceState",
      selected: false,
    },
    byRegion: {
      value: "default",
      type: "default",
      filterCase: "byRegion",
      selected: false,
    },
  },

  isCheckboxServiceEndDateSelected: false,
  sshConfig: "",
  bastionHost: "",
  instanceTypeDetails: "",
  downsizeInstanceType: "",
};

function serversReducer(state = initialState, action) {
  switch (action.type) {
    case serversTypes.FETCH_SERVERS_INSTANCES_SUCCESS:
      if (
        JSON.stringify(state.instanceList) !== JSON.stringify(action.payload) ||
        action.payload.length === 0
      ) {
        if (action.payload.length === 0) {
          return {
            ...state,
            isLoading: false,
            instanceList: [],
            isFetchingError: {
              isErrorExist: false,
              isErrorText: "",
            },
            // instancesNewTags: currentInstanceState,
            instancesNewTags: [],
          };
        } else {
          let { payload } = action;
          let { instancesNewTags } = state;

          let copy_payload = [...payload];
          let copy_instancesNewTags = [...instancesNewTags];

          let reducer_openStates = [];

          if (instancesNewTags.length === 0) {
            copy_payload.map((item) => {
              let obj = {
                ...item,
                isOpen: false,
              };
              reducer_openStates.push(obj);
              return item;
            });
          } else if (payload.length > instancesNewTags.length) {
            let arrayOfPayloadIds = copy_payload.map((item) => item.instanceId);
            let arrayOfCurrentInstanceIds = copy_instancesNewTags.map(
              (item) => item.instanceId
            );

            function updateNewOpenIdsCollection(
              current_openInstances,
              payloadIdsToFind
            ) {
              let foundItem = arrayOfPayloadIds.indexOf(payloadIdsToFind);
              let obj = {
                ...copy_payload[foundItem],
                isOpen: false,
              };
              reducer_openStates.push(obj);
            }
            for (let index = 0; index < arrayOfPayloadIds.length; index++) {
              updateNewOpenIdsCollection(
                arrayOfCurrentInstanceIds,
                arrayOfPayloadIds[index]
              );
            }
          } else if (payload.length < instancesNewTags.length) {
            let arrayOfPayloadIds = payload.map((item) => item.instanceId);
            let arrayOfCurrentInstanceIds = instancesNewTags.map(
              (item) => item.instanceId
            );
            for (
              let index = 0;
              index < arrayOfCurrentInstanceIds.length;
              index++
            ) {
              if (
                arrayOfPayloadIds.indexOf(arrayOfCurrentInstanceIds[index]) ===
                -1
              ) {
                copy_instancesNewTags.splice(index, 1);
              }
            }
            reducer_openStates = copy_instancesNewTags;
          } else if (payload.length === instancesNewTags.length) {
            let array_payload_ids = copy_payload.map((item) => item.instanceId);
            let array_newTags_ids = copy_instancesNewTags.map(
              (item) => item.instanceId
            );

            for (let index = 0; index < copy_payload.length; index++) {
              const string_newTags_id = array_newTags_ids[index];

              const payload_id_string = array_payload_ids.indexOf(
                string_newTags_id
              );
              const newTags_id_string = array_newTags_ids.indexOf(
                string_newTags_id
              );

              let copy_payload_object = copy_payload[payload_id_string];
              let copy_newTags_object =
                copy_instancesNewTags[newTags_id_string];

              let obj = {
                ...copy_payload_object,
                isOpen: copy_newTags_object.isOpen,
                isDisabled: copy_newTags_object.isDisabled,
              };
              reducer_openStates.push(obj);
            }
          }
          let instanceListWithNewTags = instanceSEDVerification(
            reducer_openStates
          );

          let currentInstanceState = instanceListWithNewTags.map((item) => {
            if (
              item.instanceState === "pending" ||
              item.instanceState === "stopping" ||
              item.instanceState === "terminating"
            ) {
              let obj = {
                ...item,
                isDisabled: true,
              };
              return obj;
            } else if (
              item.instanceState === "running" ||
              item.instanceState === "stopped" ||
              item.instanceState === "-"
            ) {
              let obj = {
                ...item,
                isDisabled: false,
              };
              return obj;
            } else if (item.instanceState === "terminated") {
              let obj = {
                ...item,
                isDisabled: true,
              };
              return obj;
            } else if (item.instanceState === "shutting-down") {
              let obj = {
                ...item,
                isDisabled: false,
              };
              return obj;

            } else {
                return null
            }
          });

          let instances_ServerEnvTag = currentInstanceState.map((item) => {
            //console.log(item);
            if (item["accountType"] === "Dev") {
              return { ...item, accountType: "Non-Prod" };
            } else if (item.hasOwnProperty("accountType")) {
              return item;
            } else {
              return { ...item, accountType: "Non-Prod" };
            }
          });

          let instances_schedulerTags = instances_ServerEnvTag.map((item) => {
            if (item.hasOwnProperty("ec2Scheduler")) {
              return item;
            } else {
              return { ...item, ec2Scheduler: "" };
            }
          });
          let filteredInstances = [];

          instances_schedulerTags.forEach((item) => {
            //console.log(item)
            var searchKey = "Initiative";
            res =
              item[
                Object.keys(item).find(
                  (key) => key.toLowerCase() === searchKey.toLowerCase()
                )
              ];
            if (res !== undefined) {
              //console.log(item)
              filteredInstances.push(item);
            }
          });

          return {
            ...state,
            isLoading: false,
            instanceList: payload,
            isFetchingError: {
              isErrorExist: false,
              isErrorText: "",
            },
            // instancesNewTags: currentInstanceState,
            instancesNewTags: filteredInstances,
          };
        }
      }
      return { ...state };
    case serversTypes.FETCH_SERVERS_INSTANCES_ERROR:
      if (!state.isErrorExist) {
        console.log("FETCH ERROR", action);
      }
      return { ...state };
    case serversTypes.OPEN_MORE_INFORMATION_CARD:
      let { instancesNewTags } = state;
      let copy_instancesNewTags = [...instancesNewTags];
      let res = copy_instancesNewTags.map((item) => {
        // if (item.instanceId === action.instanceId) {
        if (item.orderid === action.instanceId) {
          let Obj = {
            ...item,
            isOpen: item.isOpen ? false : true,
          };
          return Obj;
        } else {
          return item;
        }
      });
      return { ...state, instancesNewTags: res };

    case serversTypes.EDIT_TAGS_WITH_LOADER:
      if (
        state.instancesNewTags.find((item) => item.instanceId === action.id)
      ) {
        const newinstancesNewTags = state.instancesNewTags.map((element) => {
          if(element.Initiative === "mywizard.techarch.core" || element.Initiative === "myindustry"){
            if (element.instanceId === action.id) {
              let obj = {
                ...element,
                customerEnterpriseID: action.data.CustomerEnterpriseID
                  ? "loading"
                  : element.customerEnterpriseID,
                environment: action.data.Environment
                  ? "loading"
                  : element.environment,
                secondarypoc: action.data.SecondaryPOC ? "loading" : element.secondarypoc,
                wbseCode: action.data.WBSECode ? "loading" : element.wbseCode,
                projectname: action.data.ProjectName ? "loading" : element.projectname,
                clientname: action.data.clientName
                  ? "loading"
                  : element.clientname,
                notes: action.data.Notes ? "loading" : element.notes,

                serviceEndDate: action.data.ServiceEndDate
                  ? "loading"
                  : element.serviceEndDate,
              };
              return obj;
            }
          } else {
            if (element.instanceId === action.id) {
              let obj = {
                ...element,
                customerEnterpriseID: action.data.CustomerEnterpriseID
                  ? "loading"
                  : element.customerEnterpriseID,
                environment: action.data.Environment
                  ? "loading"
                  : element.environment,
                secondarypoc: action.data.SecondaryPOC ? "loading" : element.secondarypoc,
                wbseCode: action.data.WBSEcode ? "loading" : element.wbseCode,
                projectname: action.data.ProjectName ? "loading" : element.projectname,
                clientname: action.data.clientName
                  ? "loading"
                  : element.clientname,
                notes: action.data.notes ? "loading" : element.notes,

                serviceEndDate: action.data.service_end_date
                  ? "loading"
                  : element.serviceEndDate,
              };
              return obj;
            }
          }
          return element;
        });
        // const newInstanceList = state.instanceList.map(element => {
        //   if (element.instanceId === action.id) {
        //     let obj = {
        //       ...element,
        //       customerEnterpriseID: action.data.CustomerEnterpriseID ? action.data.CustomerEnterpriseID : element.customerEnterpriseID,
        //       environment: action.data.Environment ? action.data.Environment : element.environment,
        //       wbseCode: action.data.WBSEcode ? action.data.WBSEcode : element.wbseCode,
        //       clientname: action.data.clientName ? action.data.clientName : element.clientname,
        //       notes: action.data.notes ? action.data.notes : element.notes,
        //       serviceEndDate: action.data.service_end_date ? action.data.service_end_date : element.serviceEndDate
        //     }
        //     return obj
        //   };
        //   return element
        // });
        return { ...state, instancesNewTags: newinstancesNewTags };
        // return { ...state, instancesNewTags: newinstancesNewTags, instanceList: newInstanceList }
      }
      // return { ...state, instancesNewTags: [...state.instancesNewTags, action.data] }
      return { ...state };

    case serversTypes.DROPDOWN_FILTERS_SERVERS:
      if (action.state) {
        return { ...state, dropdownFiltersServers: true };
      } else if (state.dropdownFiltersServers) {
        return { ...state, dropdownFiltersServers: false };
      } else {
        return { ...state, dropdownFiltersServers: true };
      }

    case serversTypes.SEARCH_FILTER_VALUE:
      return { ...state, searchFilterValue: action.data };

    case serversTypes.DROPDOWN_SCHEDULE_FILTER_VALUE:
      return { ...state, filteredDropdownObject: action.data };

    case serversTypes.CHANGE_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case serversTypes.IS_CHECKBOX_SERVICE_END_DATE_SELECTED:
      return { ...state, isCheckboxServiceEndDateSelected: action.isSelected };
    case serversTypes.SET_LOADING_TAG_BY_CLICK:
      return { ...state };
    case serversTypes.SSH_CONFIG_URL:
      return { ...state, sshConfig: action.data };
    case serversTypes.BASTION_HOST:
      return {
        ...state,
        bastionHost: JSON.parse(action.data)[0]["BastionHost"],
      }; 
    case serversTypes.FETCH_INSTANCE_TYPE_DETAILS:
      //console.log('Triggered Server type')
      //console.log(action.data)
      //console.log(JSON.stringify(action.data))
      //console.log(action.data[0].subtype)
      return {
        ...state,
        instanceTypeDetails: JSON.stringify(action.data),
      };
    case serversTypes.SELECTED_DOWNSIZE_INSTANCE_TYPE:
      //console.log('Triggered Server type')
      //console.log(action.data)
      //console.log(JSON.stringify(action.data))
      //console.log(action.data[0].subtype)
      return {
        ...state,
        downsizeInstanceType: JSON.stringify(action.data),
      };
    default:
      return {
        ...state,
      };
  }
}

export default serversReducer;
