import React from "react";
import {
  StatusIconWrapper,
  OrangeCircularProgress,
  BlueCircularProgress,
  PlacedCircularProgress,
} from "./FuiStyles";
import PropTypes from "prop-types";

/**
 * Components which shows loading icons for provisioning/provisioned/failed platforms/apps/licenses
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: InstalledApps, LicenseList and PlatformList
 */

const StatusIcon = status => {
  switch (status.status) {
    case "Fulfilled":
      return (
        <StatusIconWrapper>
          <BlueCircularProgress
            variant="static"
            value={100}
            size={20}
            thickness={6}
          />
        </StatusIconWrapper>
      );
    case "Stuck":
      return (
        <StatusIconWrapper>
          <OrangeCircularProgress
            variant="static"
            value={100}
            size={20}
            thickness={6}
          />
        </StatusIconWrapper>
      );
    default:
      return (
        <StatusIconWrapper>
          <PlacedCircularProgress value={100} size={20} thickness={6} />
        </StatusIconWrapper>
      );
  }
};

StatusIcon.propTypes = {
  /**
   * The status of an order of app/platform/license.
   */
  status: PropTypes.string,
};
export default StatusIcon;
