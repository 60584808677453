import React, { useState, useEffect } from "react";
import { IconButton, Tooltip, CircularProgress } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { StyledFileCopyIcon } from "../../fui/FuiStyles";
import { connect } from "react-redux";
import { fetchSSHConfig } from "../../../redux/scheduler/schedulerActions";
import { fetchBastionHost } from "../../../redux/scheduler/schedulerActions";
import {
  StyledActionButton,
  StyledClickableElement,
} from "../ServerList/styledServerList";
import PropTypes from "prop-types";
import { Subtext } from "../../common/CommonStyles/CommonTypographyStyles";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import theme from "../../../theme/theme";
import { fetchPresignedUrl } from "../../../redux/faq/index";
// import { instances } from "chart.js";
/**
 * Displays the bastion host of instance, ssh instructions for instance and download button for ssh config
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: DialogComponent.js
 */

const ConnectToInstance = ({
  instance,
  email,
  sshConfigUrl,
  getSSHConfig,
  getBastionHost,
  bastionHostVal,
  privateDNS,
  serverList,
  fetchPresignedUrl,
  isLoading,
  presignedUrls,
}) => {
  const [openNewTab, setOpenNewTab] = useState(false);
  const [file, setFile] = useState("");

  const fetchFilePresignedUrl = (fileName, event) => {
    event.preventDefault();
    setFile(fileName);
    if (!presignedUrls.hasOwnProperty(fileName)) {
      fetchPresignedUrl(email, fileName);
      setOpenNewTab(true);
    } else {
      setFile(fileName);

      window.open(presignedUrls[fileName], "_blank");
    }
  };

  useEffect(() => {
    if (file !== "" && openNewTab && !isLoading) {
      window.open(presignedUrls[file], "_blank");
      setOpenNewTab(false);
    }
  }, [isLoading, openNewTab]);

  var connectionOS = "linuxMac";
  const [macLinuxSSHCommand, setMacLinuxSSHCommand] = useState("");

  const bastionEID =
    email === undefined ? undefined : "aaam_" + email.split("@")[0];
  const port = 443;
  let regionCode;
  if (instance.hasOwnProperty("regionCode") && instance.regionCode) {
    regionCode = instance.regionCode;
  } else {
    let regionCodeArray = serverList.filter((server) => {
      return privateDNS === server.privateDNSName;
    });
    if (regionCodeArray.length > 0) {
      regionCode = regionCodeArray[0].regionCode;
    } else {
      regionCode = "";
    }
  }

  useEffect(() => {
    const account =
      instance.Initiative === "MCC"
        ? instance.accountType
        : instance.initiative === "PAM"
          ? "PAM"
          : privateDNS.startsWith("ip-10-130")
            ? "myindustry"
            : privateDNS.startsWith("ip-10-131")
              ? "myxo"
              : privateDNS.startsWith("ip-10-10")
                ? "aiops-nonprod"
                : privateDNS.startsWith("ip-10-51")
                  ? "core-prod"
                  : privateDNS.startsWith("ip-10-52")
                    ? "core-prod"
                    : privateDNS.startsWith("ip-10-53")
                      ? "core-prod"
                      : privateDNS.startsWith("ip-10-151")
                        ? "core-uat"
                        : privateDNS.startsWith("ip-10-152")
                          ? "core-uat"
                          : privateDNS.startsWith("ip-10-156")
                            ? "core-dev"
                            : "aiops-prod";
    if (email) {
      setMacLinuxSSHCommand("");
      getSSHConfig(email, instance.Initiative, instance.accountId);
      getBastionHost(email, regionCode, account);

      setMacLinuxSSHCommand(
        'ssh -o ProxyCommand="ssh -i <privatekey file> -A -W %h:%p ' +
        bastionEID +
        "@" +
        bastionHostVal +
        " -p " +
        port +
        '" -i <privatekey file> ' +
        bastionEID +
        "@" +
        privateDNS
      );
    }
  }, [email, bastionHostVal]);

  return (
    <div style={{ padding: "0px 24px" }}>
      <div>
        <Divider
          style={{ height: "2px", backgroundColor: "rgba(0,0,0,0.32)" }}
        />
        <h4 style={{ fontWeight: 600 }}>Connecting via SSH</h4>
        <Grid container>
          <Grid item xs={1}>
            <CopyToClipboard text={macLinuxSSHCommand}>
              <Tooltip title="Copy">
                <IconButton>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={11}>
            <div
              style={{
                borderStyle: "solid",
                padding: "5px",
                borderColor: "black",
              }}
            >
              {bastionHostVal ? macLinuxSSHCommand : <CircularProgress />}
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ marginBottom: "2%", fontWeight: 600 }}>Download SSH Config File</h4>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: "2%" }}>
            <StyledActionButton
              href={sshConfigUrl}
              variant="outlined"
              style={{ width: "auto", fontWeight: 600 }}
              data-cy="downloadSshConfigFile"
            >
              Download
            </StyledActionButton>
            <Tooltip title="Please update the values at the top of the downloaded config file">
              <InfoIcon
                style={{
                  verticalAlign: "middle",
                  padding: "5px 12px",
                  color: theme.typography.mainColor,
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <StyledClickableElement
              variant="body2"
              onClick={(e) => fetchFilePresignedUrl("ssh_instructions.pdf", e)}
            >
              More Instructions
            </StyledClickableElement >
          </Grid>
        </Grid>
      </div>

      {connectionOS === "linuxMac" && (
        <Grid container>
          <Grid item xs={12}>
            <h4 style={{ fontWeight: 600 }}>Connecting via Other SSH Clients </h4>
          </Grid>

          <Grid item xs={1}>
            <CopyToClipboard text={bastionHostVal}>
              <Tooltip title="Copy">
                <IconButton style={{ padding: "3px" }}>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ margin: "0.5em 0em", fontWeight: 600 }}>Proxy Host Name: </h4>
          </Grid>
          <Grid item xs={8}>
            <h4
              style={{
                fontWeight: "normal",
                margin: "0.5em 0em",
                marginLeft: "10px",
              }}
            >
              {bastionHostVal}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <CopyToClipboard text={443}>
              <Tooltip title="Copy">
                <IconButton style={{ padding: "3px" }}>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ margin: "0.5em 0em", fontWeight: 600 }}>Proxy Host Port: </h4>
          </Grid>
          <Grid item xs={8}>
            <h4
              style={{
                fontWeight: "normal",
                marginLeft: "5px",
                margin: "0.5em 0em",
              }}
            >
              {443}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <CopyToClipboard text={privateDNS}>
              <Tooltip title="Copy">
                <IconButton style={{ padding: "3px" }}>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={2}>
            <h4 style={{ margin: "0.5em 0em", fontWeight: 600 }}>Host Name: </h4>
          </Grid>
          <Grid item xs={9}>
            <h4
              style={{
                fontWeight: "normal",
                margin: "0.5em 0em",
                marginLeft: "10px",
              }}
            >
              {privateDNS}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <CopyToClipboard text={22}>
              <Tooltip title="Copy">
                <IconButton style={{ padding: "3px" }}>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>

          <Grid item xs={2}>
            <h4 style={{ margin: "0.5em 0em", fontWeight: 600 }}>Host Port: </h4>
          </Grid>
          <Grid item xs={9}>
            <h4
              style={{
                fontWeight: "normal",
                marginLeft: "5px",
                margin: "0.5em 0em",
              }}
            >
              {22}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <CopyToClipboard text={bastionEID}>
              <Tooltip title="Copy">
                <IconButton style={{ padding: "3px" }}>
                  <StyledFileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={1}>
            <h4 style={{ margin: "0.5em 0em", fontWeight: 600 }}>User: </h4>
          </Grid>
          <Grid item xs={10}>
            <h4
              style={{
                fontWeight: "normal",
                marginLeft: "5px",
                margin: "0.5em 0em",
              }}
            >
              {bastionEID}
            </h4>
          </Grid>
          <Subtext style={{ textAlign: "initial" }}>
            ** Note: Private key needs to be used to login.
          </Subtext>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sshConfigUrl: state.servers.sshConfig,
    email: state.auth.userDetails.email,
    bastionHostVal: state.servers.bastionHost,
    serverList: state.servers.instanceList,
    isLoading: state.faq.isLoading,
    presignedUrls: state.faq.presignedUrls,
  };
};

ConnectToInstance.propTypes = {
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  sshConfigUrl: PropTypes.string,
  /**
   * Redux api to fetch presigned url from s3 bucket.
   */
  fetchSSHConfig: PropTypes.func,
  /**
   * String containing bastion host of instance
   */
  bastionHostVal: PropTypes.string,
  /**
   * API to Fetch bastion host of instance with account and region.
   */
  fetchBastionHost: PropTypes.func,
  /**
   * List of all instances
   */
  serverList: PropTypes.array,
  /**
   * Data of instance
   */
  instance: PropTypes.object,
  /**
   * PrivateDNS of selected instance
   */
  privateDNS: PropTypes.string,
};

export default connect(mapStateToProps, {
  getSSHConfig: fetchSSHConfig,
  getBastionHost: fetchBastionHost,
  fetchPresignedUrl: fetchPresignedUrl,
})(ConnectToInstance);
