import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Checkbox, DialogActions, DialogContent } from "@material-ui/core";
import { HintTextWrapper, StyledArcher, HintText } from "./SshStyles";
import * as actions from "../../redux/ssh";
import { tokenValidation } from "../../helper/customizedHooks";

import { AccessFormTextField, UpdateButton } from "./SshStyles";
import {
  Subtitle3,
  Subtext,
} from "../common/CommonStyles/CommonTypographyStyles";
import PropTypes from "prop-types";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { CommonDialog } from "../common/CommonComponents/Dialog";
/**
 * Opens the 'request access model'
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: SSHHeader.js
 */

const AccessFormModal = ({
  isSubmitLoading,
  submitAccess,
  reload,
  classes,
}) => {
  const [values, setValues] = useState({
    privateDns: "",
    isConditionRead: false,
  });

  const [isPrivateDNSValid, setIsPrivateDNSValid] = useState(true);

  const submitData = {
    private_dns: values.privateDns,
  };

  const checkPrivateDNS = (input) => {
    const reStart = /^ip-.*?\.internal$/gim;
    return reStart.exec(input) ? true : false;
  };

  const handleChange = (field) => (event) => {
    event.preventDefault();
    let eValue = event.target.value;

    if (field === "privateDns" && eValue.length > 0) {
      setIsPrivateDNSValid(checkPrivateDNS(eValue));
    } else {
      setIsPrivateDNSValid(true);
    }
    setValues({ ...values, [field]: eValue });
  };

  const handleCheckbox = (e) => {
    setValues((preValue) => ({
      privateDns: preValue.privateDns,
      isConditionRead: e.target.checked,
    }));
  };

  const isInputsValid = (value) => {
    const isValid = Object.values(value).find((v) => !v);
    return isValid === undefined && !isPrivateDNSValid === false ? false : true;
  };

  const handleSubmit = () => {
    tokenValidation();
    submitAccess(submitData);
    setValues({ privateDns: "", isConditionRead: false });
    handleClose();
    reload(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <UpdateButton type="button" variant="outlined" onClick={handleOpen} style={{ fontSize: "17px"}}>
        Request Access
      </UpdateButton>

      <CommonDialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        title={"Request Access"}
        dialogType={"ssh"}
        dialogContent={
          <DialogContent>
            <Subtitle3>Private DNS:</Subtitle3>
            <Subtext>
              Please provide your Private DNS to the text field below to apply
              for the rights to enter in the backend of the server.
            </Subtext>
            <AccessFormTextField
              label="PRIVATE DNS"
              margin="dense"
              variant="outlined"
              value={values.privateDns}
              onChange={handleChange("privateDns")}
              helperText={
                !isPrivateDNSValid &&
                `Please provide a valid Private DNS, and submit again!`
              }
              error={isPrivateDNSValid === true ? false : true}
            />
            <HintTextWrapper>
              <Checkbox color="primary" onChange={handleCheckbox} />
              <HintText align="left">
                I have read and accept{" "}
                <StyledArcher href="/AAAMTermsConditions" target="_blank">
                  GenWizard Terms and Conditions
                </StyledArcher>
              </HintText>
            </HintTextWrapper>
          </DialogContent>
        }
        dialogActions={
          <DialogActions>
            <DeclineButton onClick={handleClose} label={"Cancel"} />
            <AcceptButton
              onClick={handleSubmit}
              disabled={isInputsValid(values)}
              label={"Submit"}
            />
          </DialogActions>
        }
      />
    </div>
  );
};

AccessFormModal.propTypes = {
  /**
   * Boolean that confirms is the page is loading
   */
  isSubmitLoading: PropTypes.bool,
  /**
   * Redux access to submit
   */
  submitAccess: PropTypes.func,
  /**
   * Passed into ssh to trigger a reload a few seconds after the approve/decline button.
   */
  reload: PropTypes.func,
};

const MSTP = (state, props) => ({
  isSubmitLoading: state.ssh.isSubmitLoading,
});

export default connect(MSTP, { submitAccess: actions.submitAccess })(
  withRouter(AccessFormModal)
);
