import { Button } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../theme/theme";

export const StyledButton = styled(Button)`
  &&&&& {
    position: relative;
    border-width: 2px;
    border-radius: 0px;
    font-size: 15px;
  }
`;

export const ApproveButton = styled(StyledButton)`
  &&&&& { 
    border-color: ${(props) =>
    props.disabled ? theme.buttons.disabled : theme.palette.admin.main};
    color: ${(props) =>
    props.disabled ? theme.buttons.disabled : theme.palette.admin.main};
  }
`;

export const AcceptButtonSshUserDialog = styled(StyledButton)`
  &&&&& {
    font-size: 16px;
    font-weight: 600;
    width: 35%;
    border-color: ${(props) =>
    props.disabled ? theme.buttons.disabled : theme.status.running};
    color: ${(props) =>
    props.disabled ? theme.buttons.disabled : theme.status.running};
  }
`;

export const DenyButton = styled(StyledButton)`
  &&&&& {
    border-color: ${theme.status.stopped};
    color: ${theme.status.stopped};
  }
`;

export const SmallButton = styled(Button)`
  &&&&& {
    border-width: 2px;
    border-radius: 0px;
    height: 35px;
  }
`;

export const StyledLongButton = styled(Button)`
  &&&&& {
    border-width: 2px;
    border-radius: 0px;
    width: 40%;
    height: 30px;
  }
`;
