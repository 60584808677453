export const instanceSEDVerification = (serverList) => {
  //Get todays date and add 7 days
  let newCurrentExpirationDays7 = new Date();
  newCurrentExpirationDays7.setDate(newCurrentExpirationDays7.getDate() + 7);

  let arr = [];

  serverList.forEach((item) => {
    let instanceSED = item.serviceEndDate;
    if (instanceSED) {
      const [SED_Day, SED_Month, SED_Year] = instanceSED.includes("/")
        ? instanceSED.split("/")
        : instanceSED.split("-");

      //Create SED to check against if instance is near to expiry
      let instanceSEDCheck = new Date(SED_Year, SED_Month - 1, SED_Day);
      const diffDays = Math.ceil(
        (newCurrentExpirationDays7 - instanceSEDCheck) / (1000 * 60 * 60 * 24)
      );

      //If expiring in the next 7 days, then tag as nearToExpire. Check logic for what is done for expired instances!!!!!
      let obj = {
        ...item,
        nearToExpire: false,
      };
      if (diffDays >= 0) {
        obj.nearToExpire = true;
      }

      arr.push(obj);
    }

    if (("serviceEndDate" in item)==false){
      arr.push(item);
    }
    
  });
  return arr;
};
