import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import {
  PlatformWrapper,
  PlatformTitleWrapper,
  StyledPaper,
  FinalWrapperFromSsh,
  SuccessWrapper,
  TableWrapper,
  StyledSearchBarPlatforms,
  StyledSearchIcon
} from "../../fui/FuiStyles";
import {
  StyledTableHead,
  TableFirstRow,
  ToAccessTypography,
  StyledAvatar,
} from "../SshStyles";
import { tokenValidation } from "../../../helper/customizedHooks";

import {
  SmallHeader,
  Subtitle4,
} from "../../common/CommonStyles/CommonTypographyStyles";
import PropTypes from "prop-types";
import RemoveIcon from "@material-ui/icons/Remove";
import { connect } from "react-redux";
import {
  fetchServersInstances,
  searchFilterValueAction,
} from "../../../redux/ServersReducer/ServersActions";

import SSHUserManagementTable from "./SSHUserManagementTable";
import { getUsers } from "../../../redux/ssh/index";

/**
 * User Management page in SSH to add/revoke user ssh access in each instance.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: Ssh
 */

const SSHUserManagement = ({
  fetchServersInstances,
  email,
  getUsers,
  ec2Instances,
  idToken,
  isSshManagementLoading,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const eId = email === undefined ? undefined : email.split("@")[0];

  useEffect(() => {
    tokenValidation();
    if (eId && idToken) {
      fetchServersInstances(eId, idToken);
    }
    let myTimer;
    if (eId && !fetchedUsers) {
      getUsers(eId);
      setFetchedUsers(true);
    }
    if (eId) {
      myTimer = setInterval(() => {
        getUsers(eId);
      }, 5000);
    }
    return () => clearInterval(myTimer);
  }, [email, idToken]);


  const filterRows = () => {
    return ec2Instances.filter((item) => {
      if (searchQuery === "*") {
        return item;
      }
      if (item.hasOwnProperty("clusterId")) {
        return (
          item.clusterId
            .toLowerCase()
            .indexOf(searchQuery.toLowerCase()) !== -1
        );
      } else if (item.hasOwnProperty("clusterid")) {
        return (
          item.clusterid
            .toLowerCase()
            .indexOf(searchQuery.toLowerCase()) !== -1
        );
      } else if (item.hasOwnProperty("clusterID")) {
        return (
          item.clusterID
            .toLowerCase()
            .indexOf(searchQuery.toLowerCase()) !== -1
        );
      } else {
        return null;
      }
    });
  };

  const renderSearch = () => (
    <StyledSearchBarPlatforms
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      id="outlined-search"
      type="search"
      margin="normal"
      placeholder="Domain Context Root"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledSearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <React.Fragment>
      <TableWrapper>
        <PlatformWrapper style={{ height: "10%" }}>
          <PlatformTitleWrapper>
            <SmallHeader style={{ textAlign: "left" }}>
              SSH User Management
            </SmallHeader>
          </PlatformTitleWrapper>
          <div style={{ width: "40%" }}>{renderSearch()}</div>
        </PlatformWrapper>
        {isSshManagementLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90%",
            }}
          >
            <CircularProgress size={40} />
          </div>
        ) : filterRows().length === 0 ? (
          <FinalWrapperFromSsh style={{ height: "90%" }}>
            <SuccessWrapper
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledAvatar>
                <RemoveIcon />
              </StyledAvatar>
              <ToAccessTypography>You have no servers!</ToAccessTypography>
            </SuccessWrapper>
          </FinalWrapperFromSsh>
        ) : (
          <StyledPaper
            style={{
              maxHeight: "90%",
              // overflowX: 'hidden'
            }}
          >
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableFirstRow>
                    <Subtitle4>Domain Context Root</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Private DNS</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Service End Date</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Client Name</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow>
                    <Subtitle4>Status</Subtitle4>
                  </TableFirstRow>
                  <TableFirstRow></TableFirstRow>
                </TableRow>
              </StyledTableHead>
              <TableBody data-cy="instanceTable">
                {fetchedUsers &&
                  filterRows &&
                  filterRows().map((row) => (
                    <SSHUserManagementTable ec2Data={row} email={email} />
                  ))}
              </TableBody>
            </Table>
          </StyledPaper>
        )}
      </TableWrapper>
    </React.Fragment>
  );
};

SSHUserManagement.propTypes = {
  /**
   * A list of all instances owned by the user
   */
  instancesNewTags: PropTypes.array,
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * An array of ec2Instances with their corresponding ssh users attatched.
   */
  ec2Instances: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    idToken: state.auth.idToken,
    email: state.auth.userDetails.email,
    instancesNewTags: state.servers.instancesNewTags,
    searchFilterValue: state.servers.searchFilterValue,
    ec2Instances: state.ssh.ec2Instances,
    isSshManagementLoading: state.ssh.isSshManagementLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchServersInstances: (email, idToken) =>
    dispatch(fetchServersInstances(email, idToken)),
  getUsers: (email) => dispatch(getUsers(email)),
  searchFilterAction: (serverList) =>
    dispatch(searchFilterValueAction(serverList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SSHUserManagement);
