import React, { useEffect } from "react";
import { connect } from "react-redux";
import PieChart from "./PieChart";
import { fetchEc2Data } from "../../redux/home";
import {
  Container,
  PieChartWrapper,
  OnePieChartWrapper
} from "./DashboardStyles";

export const RowContent = ({ fetchEc2Data, ec2Data, serverList }) => {
  useEffect(() => {
    fetchEc2Data();
  }, []);
  return (
    <>
      <Container>
        <PieChartWrapper>
          {ec2Data.map((v, i) => (
            <OnePieChartWrapper key={i}>
              <PieChart serverList={serverList} pieData={v} />
            </OnePieChartWrapper>
          ))}
        </PieChartWrapper>
      </Container>
    </>
  );
};

const MSTP = (state, props) => ({
  ec2Data: state.home.ec2Data,
  isLoading: state.home.isLoading,
  fetchEc2Data: state.home.fetchEc2Data,
  fuiInstanceTotalNumberData: state.home.fuiInstanceTotalNumberData,
  fuiMPNumberData: state.home.fuiMPNumberData,
  fuiDPNumberData: state.home.fuiDPNumberData,
  fuiPINumberData: state.home.fuiPINumberData,
  sshPendingApprovalsData: state.home.sshPendingApprovalsData,
  sshMyRequestsData: state.home.sshMyRequestsData,
  isRegistration: state.home.isRegistration
});

export default connect(
  MSTP,
  { fetchEc2Data }
)(RowContent);
