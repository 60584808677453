
const SEARCH_FILTER_VALUE = "SEARCH_FILTER_VALUE";

const initState = {
  filteredSearchInstances: [],
  searchFilterValue: ''
};

export default function commonSearchReducer(state = initState, action) {
  const data = action.data;
  switch (action.type) {
    //Update data selected from instance list
    case SEARCH_FILTER_VALUE:
      return { ...state, searchFilterValue: data }
    default:
      return state;
  }
}

export const searchFilterValueAction = (data) => ({
  type: SEARCH_FILTER_VALUE,
  data
});