import React, { useState, useEffect } from "react";
import { MenuItem, InputLabel } from "@material-ui/core";
import { StyledFormControl, StyledSelect } from "../../fui/FuiStyles";
import { fetchInstanceTypeDetails } from "../../../redux/ServersReducer/ServersActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/**
 * Displays the bastion host of instance, ssh instructions for instance and download button for ssh config
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: DialogComponent.js
 */

const DownsizeInstance = ({
  instance,
  email,
  instanceTypeDetailsVal,
  handleDownsizeInstanceDropdown,
  getInstanceTypeDetails,
  handleDownsizeInstanceTypeDialogConfirm,
  handleInstanceType,
}) => {
  useEffect(() => {
    if (email) {
      getInstanceTypeDetails(
        instance.initiative,
        instance.instanceId,
        instance.accountId,
        instance.accountType,
        instance.instanceType,
        instance.privateDNSName
      );
    }
  }, [email, instanceTypeDetailsVal]);

  //console.log(instanceTypeDetailsVal);
  var setDownsizeInstanceTypeDropdown = eval(instanceTypeDetailsVal);
  var newArray = [];
  for (var i in setDownsizeInstanceTypeDropdown) {
    var downsizeInstanceTypeValDropdown =
      setDownsizeInstanceTypeDropdown[i]["type"] +
      "." +
      setDownsizeInstanceTypeDropdown[i]["subtype"] +
      " CPU: " +
      setDownsizeInstanceTypeDropdown[i]["cpu"] +
      " Memory(in GB): " +
      setDownsizeInstanceTypeDropdown[i]["memory"];
      newArray[i] = downsizeInstanceTypeValDropdown;
  }
  const [downsizeInstanceTypeVal, setDownsizeInstanceTypeVal] = useState("");

  handleDownsizeInstanceDropdown = (e) => {
    setDownsizeInstanceTypeVal(e.target.value);
  };
  //handleDownsizeInstanceTypeDialogConfirm(downsizeInstanceTypeVal);

  return (
    <div style={{ padding: "10px 24px" }}>
      <StyledFormControl variant="outlined" margin="dense">
        <InputLabel htmlFor="outlined-service-group">Instance Type</InputLabel>
        <StyledSelect
          onChange={(e) => handleInstanceType("DownsizeInstanceType", e.target.value, downsizeInstanceTypeVal)}
          label="Downsize Instance Type"
        >
          <MenuItem value="">
            <em>--Select Instance Type--</em>
          </MenuItem>
          {newArray.length === 0 ? (
            <MenuItem key="No more size to downgrade" value="No more size to downgrade">
              No more size to downgrade
            </MenuItem>
          ) :
          newArray.map((downsizeInstanceTypeValDropdown) => (
            <MenuItem key={downsizeInstanceTypeValDropdown} value={downsizeInstanceTypeValDropdown}>
              {downsizeInstanceTypeValDropdown}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sshConfigUrl: state.servers.sshConfig,
    email: state.auth.userDetails.email,
    bastionHostVal: state.servers.bastionHost,
    instanceTypeDetailsVal: state.servers.instanceTypeDetails,
    serverList: state.servers.instanceList,
    isLoading: state.faq.isLoading,
    presignedUrls: state.faq.presignedUrls,
  };
};

DownsizeInstance.propTypes = {
  /**
   * Current users email
   */
  email: PropTypes.string,
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  sshConfigUrl: PropTypes.string,
  /**
   * Redux api to fetch presigned url from s3 bucket.
   */
  fetchSSHConfig: PropTypes.func,
  /**
   * String containing bastion host of instance
   */
  bastionHostVal: PropTypes.string,
  instanceTypeDetailsVal: PropTypes.string,
  /**
   * API to Fetch bastion host of instance with account and region.
   */
  fetchBastionHost: PropTypes.func,
  /**
   * API to Fetch instance type details.
   */
  fetchInstanceTypeDetails: PropTypes.func,
  /**
   * List of all instances
   */
  serverList: PropTypes.array,
  /**
   * Data of instance
   */
  instance: PropTypes.object,
  /**
   * PrivateDNS of selected instance
   */
  privateDNS: PropTypes.string,
  /**
   * Selected Downsize instance val
   */
  downsizeInstanceTypeVal: PropTypes.string,
  handleDownsizeInstanceTypeDialogConfirm: PropTypes.func,
};

export default connect(mapStateToProps, {
  getInstanceTypeDetails: fetchInstanceTypeDetails,
})(DownsizeInstance);
