import { makeStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const useFUIMakeStyles = makeStyles(() => ({
  FuiPrimaryButton: {
    backgroundColor: theme.palette.fui.main,
    color: theme.palette.fui.contrastText,
    margin: "2px",
  },
  FuiSecondaryButton: {
    color: theme.palette.fui.main,
    borderColor: theme.palette.fui.main,
    margin: "2px",
  },
  FuiBorderlessButton: {
    color: theme.palette.fui.main,
    margin: "0 auto",
    display: "block",
  },
}));
