export const TimeFormatConverter = (startTime) => {
  /**
   * Converts time into a readable format for the user
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: TimePickerInformation.js, SetSchedule.js
   */
  if (startTime === "") {
    return "00:00";
  } else {
    let splitTime = startTime.substr(0, 2);
    let newTimeFormat =
      startTime.substr(0, 2) === "00"
        ? `12:${startTime.substr(2, 2)} AM`
        : startTime.substr(0, 2) < 12
        ? `${startTime.substr(0, 2)}:${startTime.substr(2, 2)} AM`
        : startTime.substr(0, 2) === "12"
        ? `12:${startTime.substr(2, 2)} PM`
        : ` ${
            startTime.substr(0, 2) > 12 && startTime.substr(0, 2) < 22
              ? `0${parseInt(splitTime, 10) - 12}:${startTime.substr(2, 2)} PM`
              : `${parseInt(splitTime, 10) - 12}:${startTime.substr(2, 2)} PM`
          }`;

    return newTimeFormat;
  }
};
