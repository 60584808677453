export const FETCH_SERVERS_INSTANCES_SUCCESS =
  "FETCH_SERVERS_INSTANCES_SUCCESS";
export const FETCH_SERVERS_INSTANCES_ERROR = "FETCH_SERVERS_INSTANCES_ERROR";
export const OPEN_MORE_INFORMATION_CARD = "OPEN_MORE_INFORMATION_CARD";
export const EDIT_TAGS_WITH_LOADER = "EDIT_TAGS_WITH_LOADER";

export const DROPDOWN_FILTERS_SERVERS = "DROPDOWN_FILTERS_SERVERS";
export const SEARCH_FILTER_VALUE = "SEARCH_FILTER_VALUE";
export const DROPDOWN_SCHEDULE_FILTER_VALUE = "DROPDOWN_SCHEDULE_FILTER_VALUE";
export const CHANGE_IS_LOADING = "CHANGE_IS_LOADING";

export const IS_CHECKBOX_SERVICE_END_DATE_SELECTED =
  "IS_CHECKBOX_SERVICE_END_DATE_SELECTED";
export const SET_LOADING_TAG_BY_CLICK = "SET_LOADING_TAG_BY_CLICK";

export const SSH_CONFIG_URL = "SSH_CONFIG_URL";
export const BASTION_HOST = "BASTION_HOST";
export const FETCH_INSTANCE_TYPE_DETAILS = "FETCH_INSTANCE_TYPE_DETAILS";
export const SELECTED_DOWNSIZE_INSTANCE_TYPE = "SELECTED_DOWNSIZE_INSTANCE_TYPE";