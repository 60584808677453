import React, { useState } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import { checkWBSECode } from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import PropTypes from "prop-types";
import GeneralForm from "./GeneralForm";
import { Grid } from "@material-ui/core";

/**
 * Automation Journey form
 * @author Andres Mc Leod
 * @version 1.0
 * @see Used in component: AppStepper
 */
const AutomationJourney = ({
  configurationType,
  setIsSPAllValid,
  values, // the value would be changed by user's input
  updateAutomationJourneyForm, //the function to update SPForm values which is from redux
  aSelectedInstance, // the instance that selected by users in step two
  email, // user's email comes from token
  history,
}) => {
  const config = configurationType === "quick";
  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);

  // get user's input
  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    updateAutomationJourneyForm(fieldName, event.target.value);
    if (fieldName === "WBSEcode") {
      const wbsevalue = value.toUpperCase()
      if (wbsevalue.trim() === "CBYDQ001") {
        setIsWBSECodeMDIValid(false)
      }
      setIsWBSECodeValid(checkWBSECode(value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3) {
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
  };

  // get user's EID from email
  const createdBy = email ? email.split("@")[0] : "";

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateAutomationJourneyForm(fieldName, value);
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateAutomationJourneyForm("ConfigurationType", configurationType);
  updateAutomationJourneyForm("DomainContextRoot", aSelectedInstance.DomainContextRoot);
  updateAutomationJourneyForm("Environment", aSelectedInstance.Environment);
  updateAutomationJourneyForm("DeploymentRegion", aSelectedInstance.DeploymentRegion);
  updateAutomationJourneyForm("GDPR", aSelectedInstance.GDPR);
  updateAutomationJourneyForm("RequestNotes", aSelectedInstance.RequestNotes);
  updateAutomationJourneyForm("Tier", aSelectedInstance.Tier);
  updateAutomationJourneyForm(
    "EndToEndUId",
    aSelectedInstance.EndToEndUId === ""
      ? aSelectedInstance.DMSId
      : aSelectedInstance.EndToEndUId
  );

  // change validation status
  !isWBSECodeValid || !isWBSECodeMDIValid || !isProjectNameValid ? setIsSPAllValid(false) : setIsSPAllValid(true);

  return (
    <>
      <GeneralForm
        FormId={1}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={4}>
                {FormField.SAPContractIDField(handleChange, aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.ClientNameField(handleChange, aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.EndToEndUIdField(aSelectedInstance)}
              </Grid>
              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
              </Grid>
            </Grid>
          </div>
        }

        EnvironmentFields={
          <div>
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <p>Environment details have been successfully fetched from the selected instance details provided in the previous step.</p>
                </Grid>
              </Grid>
            </div>
          </div>
        }

        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>}
              <Grid item xs={6}>
                {FormField.PlatformTypeField(handleChange, aSelectedInstance)}
              </Grid>
            </Grid>
          </div>
        }
        history={history}
        NoConfig={undefined}
        belong={"pam"}
      ></GeneralForm>
    </>
  );
};
AutomationJourney.propTypes = {
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   * Function update DEvOpsAnalytics form
   */
  updateAutomationJourneyForm: PropTypes.func,
  /**
   * Object containing current AutomationJourney form
   */
  values: PropTypes.object,
  /**
   * Contains selected instance from the stepper
   */
  aSelectedInstance: PropTypes.object,
  /**
   * Sets true if all fields are valid for submit
   */
  setIsSPAllValid: PropTypes.func,
};
const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
  values: state.fui.AutomationJourneyForm,
  aSelectedInstance: state.fui.aSelectedInstance,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, {
  updateAutomationJourneyForm: fuiRedux.updateAutomationJourneyForm,
})(AutomationJourney);
