import React, { useState } from "react";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";

import { checkWBSECode } from "./CommonFunctions";
import * as FormField from "./IndividualFormFields";
import PropTypes from "prop-types";
import GeneralForm from "./GeneralForm";

//Choose a configuration type and enter into quick or full configuration components
//PlanAndScope, SourceManagement, BuildAndDeploymentManagement, QualityManagement, ServiceManagement and their changing methods are transferred into configuration forms to get values and change values

/**
 * Dev Ops Analytics form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: AppStepper
 */
const DevOpsAnalytics = ({
  values, // the value would be changed by user's input
  updateDOAForm, //the function to update DOAForm values which is from redux
  aSelectedInstance, // the instance that selected by users in step two
  email, // user's email comes from token
  configurationType,
  PlanAndScope,
  SourceManagement,
  BuildAndDeploymentManagement,
  QualityManagement,
  ServiceManagement,
  DevSecOps,
  handleChangePlanAndScope,
  handleChangeSourceManagement,
  handleChangeBuildAndDeploymentManagement,
  handleChangeQualityManagement,
  handleChangeServiceManagement,
  handleChangeDevSecOps,
  setIsDOAAllValid,
  isJiraSelectedByPlan,
  isJiraSelectedByService,
  history,
}) => {
  const config = configurationType === "quick";
  // check whether WBSECode and MonthlyTicketVolume are valid
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  // get user's EID from email
  const createdBy = email ? email.split("@")[0] : "";

  // get user's input
  const handleChange = (event, fieldName) => {
    const value = event.target.value;
    event.preventDefault();
    updateDOAForm(fieldName, event.target.value);
    if (fieldName === "WBSEcode") {
      const wbsevalue = value.toUpperCase()
      if (wbsevalue.trim() === "CBYDQ001") {
        setIsWBSECodeMDIValid(false)
      }
      setIsWBSECodeValid(checkWBSECode(value));
    }
    if (fieldName === "ProjectName") {
      if (value.length > 32 || value.length < 3) {
        setIsProjectNameValid(false)
      } else {
        setIsProjectNameValid(true)
      }
    }
  };

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateDOAForm(fieldName, value);
  };

  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateDOAForm("ConfigurationType", configurationType);

  updateDOAForm(
    "EndToEndUId",
    aSelectedInstance.EndToEndUId === ""
      ? aSelectedInstance.DMSId
      : aSelectedInstance.EndToEndUId
  );

  // change validation status
  !isWBSECodeValid && !isWBSECodeMDIValid && !isProjectNameValid ? setIsDOAAllValid(false) : setIsDOAAllValid(true);
  return (
    <GeneralForm
      FormId={5}
      LeftColumnFields={
        <div>
          {FormField.EndToEndUIdField(aSelectedInstance)}
          {FormField.DeployRegionField(handleChange, aSelectedInstance)}
          {FormField.ClientNameField(handleChange, aSelectedInstance)}
          {FormField.VendorNameField(values.VendorName)}
          {FormField.SAPContractIDField(handleChange, aSelectedInstance)}
        </div>
      }
      RightColumnFields={
        <div>
          {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
          {FormField.PlatformTypeField(handleChange, aSelectedInstance)}
          {/* {FormField.PlanAndScopeField(
            handleChangePlanAndScope,
            PlanAndScope,
            isJiraSelectedByService,
            !!!SourceManagement.find((addon) => addon === "Webservice") &&
              !!!BuildAndDeploymentManagement.find(
                (addon) => addon === "Webservice"
              ) &&
              !!!QualityManagement.find((addon) => addon === "Webservice") &&
              !!!ServiceManagement.find((addon) => addon === "Webservice")
          )}

          {FormField.SourceManagementField(
            handleChangeSourceManagement,
            SourceManagement,
            !!!PlanAndScope.find((addon) => addon === "Webservice") &&
              !!!BuildAndDeploymentManagement.find(
                (addon) => addon === "Webservice"
              ) &&
              !!!QualityManagement.find((addon) => addon === "Webservice") &&
              !!!ServiceManagement.find((addon) => addon === "Webservice")
          )}

          {FormField.BuildAndDeploymentField(
            handleChangeBuildAndDeploymentManagement,
            BuildAndDeploymentManagement,
            !!!SourceManagement.find((addon) => addon === "Webservice") &&
              !!!PlanAndScope.find((addon) => addon === "Webservice") &&
              !!!QualityManagement.find((addon) => addon === "Webservice") &&
              !!!ServiceManagement.find((addon) => addon === "Webservice")
          )}

          {FormField.QualityManagementField(
            handleChangeQualityManagement,
            QualityManagement,
            !!!SourceManagement.find((addon) => addon === "Webservice") &&
              !!!BuildAndDeploymentManagement.find(
                (addon) => addon === "Webservice"
              ) &&
              !!!PlanAndScope.find((addon) => addon === "Webservice") &&
              !!!ServiceManagement.find((addon) => addon === "Webservice")
          )}

          {FormField.ServiceManagementField(
            handleChangeServiceManagement,
            ServiceManagement,
            isJiraSelectedByPlan,
            !!!SourceManagement.find((addon) => addon === "Webservice") &&
              !!!BuildAndDeploymentManagement.find(
                (addon) => addon === "Webservice"
              ) &&
              !!!QualityManagement.find((addon) => addon === "Webservice") &&
              !!!PlanAndScope.find((addon) => addon === "Webservice")
          )}

          {FormField.DevSecOpsField(handleChangeDevSecOps, DevSecOps)} */}

          {FormField.PlanAndScopeField(
            handleChangePlanAndScope,
            PlanAndScope,
            isJiraSelectedByService,
            SourceManagement !== "Webservice" &&
            BuildAndDeploymentManagement !== "Webservice" &&
            QualityManagement !== "Webservice" &&
            ServiceManagement !== "Webservice"
          )}

          {FormField.SourceManagementField(
            handleChangeSourceManagement,
            SourceManagement,
            PlanAndScope !== "Webservice" &&
            BuildAndDeploymentManagement !== "Webservice" &&
            QualityManagement !== "Webservice" &&
            ServiceManagement !== "Webservice"
          )}

          {FormField.BuildAndDeploymentField(
            handleChangeBuildAndDeploymentManagement,
            BuildAndDeploymentManagement,
            SourceManagement !== "Webservice" &&
            PlanAndScope !== "Webservice" &&
            QualityManagement !== "Webservice" &&
            ServiceManagement !== "Webservice"
          )}

          {FormField.QualityManagementField(
            handleChangeQualityManagement,
            QualityManagement,
            SourceManagement !== "Webservice" &&
            BuildAndDeploymentManagement !== "Webservice" &&
            PlanAndScope !== "Webservice" &&
            ServiceManagement !== "Webservice"
          )}

          {FormField.ServiceManagementField(
            handleChangeServiceManagement,
            ServiceManagement,
            isJiraSelectedByPlan,
            SourceManagement !== "Webservice" &&
            BuildAndDeploymentManagement !== "Webservice" &&
            QualityManagement !== "Webservice" &&
            PlanAndScope !== "Webservice"
          )}

          {/* {FormField.DevSecOpsField(handleChangeDevSecOps, DevSecOps)} */}

          {FormField.CustomerEnterpriseIDField(createdBy, config)}

          {!config && FormField.MobileNumberField(handleChangeMobileNo, values)}

          {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}

          {!config && FormField.UsersField(handleChange, values)}
          {!config && FormField.ServiceGroupField(handleChange, values)}

          {FormField.AdminDistributionGroupField(handleChange, values)}

          {!config &&
            FormField.UsersDistributionGroupField(handleChange, values)}
          {FormField.TermsAndConditionsField(
            handleChange,
            values,
            isAAAMTermsConditions
          )}
        </div>
      }
      history={history}
    ></GeneralForm>
  );
};

const MSTP = (state, ownProps) => ({
  configurationType: state.fui.configurationType,
  values: state.fui.DOAForm,
  aSelectedInstance: state.fui.aSelectedInstance,
  email: state.auth.userDetails.email,
});

DevOpsAnalytics.propTypes = {
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * The configuration type of the form
   */
  configurationType: PropTypes.string,
  /**
   * Function update DEvOpsAnalytics form
   */
  updateDOAForm: PropTypes.func,
  /**
   * Object containing current atr form
   */
  values: PropTypes.object,
  /**
   * Contains selected instance from the stepper
   */
  aSelectedInstance: PropTypes.object,
};

export default connect(MSTP, { updateDOAForm: fuiRedux.updateDOAForm })(
  DevOpsAnalytics
);
