import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { colours } from "../../theme/colours";
import { Title } from "./DashboardStyles";
import { connect } from "react-redux";
import {
  dropdownScheudleFilterValueAction,
  dropdownFiltersServersAction,
} from "../../redux/ServersReducer/ServersActions";

/**
 * Ec2 server piecharts
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: StatisticPieCharts, RowContent
 */

const PieChart = ({
  pieData,
  dropdownScheudleFilterValueAction,
  screen,
  dropdownFiltersServersAction,
}) => {
  const [newData, setNewData] = useState([]);
  const [legendDisplay, setLegendDisplay] = useState(true);
  const [fontSize, setFontSize] = useState();

  useEffect(() => {
    if (pieData.options) {
      const data = {
        labels: pieData.options.map((item) => item.toUpperCase()),
        datasets: [
          {
            data: pieData.options.map((i) => pieData.values[i]),
            backgroundColor: [
              // '#E74C3C',
              // '#2ECC71',
              // '#FFA42E',
              // "#4142AA",
              // "#898AD2",
              // "#00D8FF",
              // "#00343D"
              "#460073",
              "#7500C0",
              "#A100FF",
              "#A055f5",
              "#BE82FF",
              "#DCAFFF",
              "#E6DCFF",
            ],
            hoverBackgroundColor: [
              `#B3B2B5`,
              `#B3B2B5`,
              `#B3B2B5`,
              `#B3B2B5`,
              `#B3B2B5`,
            ],
          },
        ],
        text: "",
      };
      setNewData(data);
    }
  }, [pieData]);

  const handleScreenSizeUpdate = () => {
    setLegendDisplay(window.innerWidth < 820 ? false : true);
    if (screen === "dashboard") {
      setFontSize(
        window.innerWidth < 960 ? 10 : window.innerWidth < 1150 ? 8 : 10
      );
    } else if (screen === "servers") {
      setFontSize(
        window.innerWidth < 910
          ? 8
          : window.innerWidth < 960
          ? 10
          : window.innerWidth < 1150
          ? 8
          : 10
      );
    }
  };

  useEffect(() => {
    handleScreenSizeUpdate();
    const callback = () => {
      handleScreenSizeUpdate();
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  const options = {
    legend: {
      display: legendDisplay,
      position: "right",
      labels: {
        fontSize: fontSize,
        boxWidth: 16,
      },
      align: "end",
    },
    // circumference: 1 * Math.PI,
    // rotation: 1 * Math.PI,
    // cutoutPercentage: 90
  };

  const filter = (e, values) => {
    dropdownFiltersServersAction(true);
    let index = e && e[0] && e[0]._index;

    let clearAndFilteredDropdownObject = {
      bySchedule: {
        value: 10,
        type: "ALL",
        filterCase: "bySchedule",
        selected: false,
      },
      byInstanceState: {
        value: 10,
        type: "ALL",
        filterCase: "byInstanceState",
        selected: false,
      },
      byRegion: {
        value: "default",
        type: "default",
        filterCase: "byRegion",
        selected: false,
      },
    };

    if (index !== undefined && values) {
      if (values[index] === "SCHEDULED") {
        clearAndFilteredDropdownObject.bySchedule = {
          value: 20,
          type: "ec2Scheduler",
          filterCase: "bySchedule",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "UNSCHEDULED") {
        clearAndFilteredDropdownObject.bySchedule = {
          value: 30,
          type: "un-schedule",
          filterCase: "bySchedule",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "RUNNING") {
        clearAndFilteredDropdownObject.byInstanceState = {
          value: 20,
          type: "running",
          filterCase: "byInstanceState",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "STOPPED") {
        clearAndFilteredDropdownObject.byInstanceState = {
          value: 30,
          type: "stopped",
          filterCase: "byInstanceState",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "STOPPING") {
        clearAndFilteredDropdownObject.byInstanceState = {
          value: 40,
          type: "stopping",
          filterCase: "byInstanceState",
          selected: true,
        };

        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "PENDING") {
        clearAndFilteredDropdownObject.byInstanceState = {
          value: 50,
          type: "pending",
          filterCase: "byInstanceState",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else if (values[index] === "TERMINATING") {
        clearAndFilteredDropdownObject.byInstanceState = {
          value: 30,
          type: "terminating",
          filterCase: "byInstanceState",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      } else {
        let type = values[index].toLowerCase();
        clearAndFilteredDropdownObject.byRegion = {
          value: index,
          type,
          filterCase: "byRegion",
          selected: true,
        };
        dropdownScheudleFilterValueAction(clearAndFilteredDropdownObject);
      }
    }
  };

  return (
    <>
      {
        pieData.name !== "Account" ?
          <>
            <Title>{pieData.name}</Title>
            <Doughnut
              getElementsAtEvent={(e) => filter(e, newData.labels)}
              data={newData}
              options={options}
            />
          </> : ""
      }

    </>
  );
};

export default connect(null, {
  dropdownScheudleFilterValueAction,
  dropdownFiltersServersAction,
})(PieChart);
