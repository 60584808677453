import * as serversTypes from "./ServersActionTypes";
import base from "../../config";
import axios from "axios";
import { headers } from "../../helper/api";

const sshConfigUrl = `${base.urls.apis.sshConfig}sshConfig`;
const bastionHostUrl = `${base.urls.apis.sshConfig}bastion-host`;
const fetchInstanceTypeDetailsUrl = `${base.urls.apis.ec2}`;

export const fetchServersInstances = (user_eid, idToken) => (
  dispatch,
  getState
) => {
  const fetchInstancesBaseUrl = `${base.urls.apis.ec2}`;
  const activeAccount = getState().auth.activeAccount;

  const fetchInstancesUrl = `${fetchInstancesBaseUrl}?customer_enterprise_id=${user_eid}&inititive=${activeAccount}&page_size=999&page_number=1`;
  if (user_eid && idToken) {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    };
    axios
      .get(fetchInstancesUrl, options)
      .then((res) => {
        const { data } = res;
        //console.log(res);
        dispatch({
          type: serversTypes.FETCH_SERVERS_INSTANCES_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({ type: serversTypes.FETCH_SERVERS_INSTANCES_ERROR, error });
      });
  }
};

export const openMoreInformationAction = (instanceId) => ({
  type: serversTypes.OPEN_MORE_INFORMATION_CARD,
  instanceId,
});

export const editTagsAction = (data, id) => ({
  type: serversTypes.EDIT_TAGS_WITH_LOADER,
  data,
  id,
});

export const dropdownFiltersServersAction = (state) => ({
  type: serversTypes.DROPDOWN_FILTERS_SERVERS,
  state,
});

export const searchFilterValueAction = (data) => ({
  type: serversTypes.SEARCH_FILTER_VALUE,
  data,
});

export const dropdownScheudleFilterValueAction = (data) => ({
  type: serversTypes.DROPDOWN_SCHEDULE_FILTER_VALUE,
  data,
});

export const changeLoadingStatus = () => (dispatch) => {
  dispatch({ type: serversTypes.CHANGE_IS_LOADING, isLoading: true });
  setTimeout(() => {
    dispatch({ type: serversTypes.CHANGE_IS_LOADING, isLoading: false });
  }, 1000);
};

export const isCheckboxServiceEndDateSelectedAction = (isSelected) => ({
  type: serversTypes.IS_CHECKBOX_SERVICE_END_DATE_SELECTED,
  isSelected,
});

export const setLoadingByClick = (instanceTag, instanceValue, id) => ({
  type: serversTypes.SET_LOADING_TAG_BY_CLICK,
  instanceTag,
  instanceValue,
  id,
});

export const fetchSSHConfig = (email, inititive) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;
  var res = "";
  res = inititive === "MCC" ? "ssh_config_mcc.txt" : "ssh_config.txt";
  dispatch(changeLoadingStatus());
  return axios
    .get(sshConfigUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eId,
        inititive: activeAccount,
        resource_name: res,
      },
    })
    .then((res) => {
      dispatch({ type: serversTypes.SSH_CONFIG_URL, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
    });
};

export const fetchBastionHost = (email, regionName, accountName) => (
  dispatch,
  getState
) => {
  const idToken = localStorage.getItem("token");
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  dispatch(changeLoadingStatus());
  return axios
    .get(bastionHostUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eId,
        region_name: regionName,
        account_name: accountName.toLowerCase(),
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: serversTypes.BASTION_HOST, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
    });
};

export const fetchInstanceTypeDetails = (initiative, instanceId, accountId, accountType, instanceType, privateDNSName ) => (
  dispatch,
  getState
) => {
    const idToken = localStorage.getItem("token");
    const urls = `${fetchInstanceTypeDetailsUrl}/${instanceId}/instancetypedetails?accountId=${accountId}&accountType=${accountType}&instancetype=${instanceType}&privateDNS=${privateDNSName}&inititive=${initiative}`;

    return axios
    .get(urls, {
      headers: { ...headers, Authorization: idToken },
    })
    .then((res) => {
      //console.log("Instance Type Details",res.data)
      dispatch({ type: serversTypes.FETCH_INSTANCE_TYPE_DETAILS, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
    });
};


