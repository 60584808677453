import styled from "styled-components";
import { Typography, Divider, Button, Grid } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

export const Title = styled(Typography)`
  &&&&& {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin: 8px 0 8px 0;
  }
`;

export const StyledInstanceDetails = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1px;
  background: "#5cb85c";
`;

export const ContainerList = styled.div`
  margin-left: 1.5em;
  margin-bottom: 1.5em;
  width: 50%;
`;

export const StyledTable = styled.table`
  width: 100%;
  tr:nth-child(odd) {
    background-color: ${grey[200]};
  }
`;

export const StyledDivider = styled(Divider)`
  &&&&& {
    margin: 0 30px 10px 30px;
  }
`;

export const TextOverflowGuard = styled.div`
  word-wrap: break-word;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1366px) {
    max-width: 450px;
  }
  @media (min-width: 1500px) {
    max-width: 550px;
  }
`;

export const ExpendableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 0.4em;
`;
export const StyledTd = styled.td`
  color: "#000000";
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  width: 50%;
`;

export const StyledTdContent = styled.td`
  color: "#000000";
  // font-weight: 600;
  font-size: 14px;
  text-align: left;
  width: 50%;
`;
export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
`;
export const StyledButton = styled(Button)`
  &&&&& {
    color: ${(props) => (props.disabled ? `${grey[400]}` : "#e38c30")};
    border-color: ${(props) => (props.disabled ? `${grey[400]}` : "#e38c30")};
    margin: 0 15px 4px 0;
  }
`;

export const StyledGrid = styled(Grid)`
  &&&&& {
    width: 100%;
    display: flex;
    margin-top: 1px;
    background: "#5cb85c";
  }
`;

export const SplunkVersionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
