import React, { useState } from "react";
import { StyledDatePicker } from "../FuiStyles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

export const DateTextFieldCommonComponent = ({
  tentativeServiceEndDate,
  selectedEnvironment,
  isServiceEndDateInvalid,
  minDate,
  maxDate,
  handleChange,
}) => {
  /**
   * Date Field Component for Tentative Service End Date
   * @author Isabel Sprengers
   * @version 3.0
   * @see Used in component: AutomaticTicketResolver.js, DedicatedPlatform.js, ManagedPlatform.js, OnPremiseService,js, PlainInstance.js, etc
   */

  const [selectedDate, setSelectedDate] = useState(tentativeServiceEndDate);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        autoOk
        label="Tentative Service End Date"
        id="TentativeServiceEndDate"
        value={selectedEnvironment === "" ? null : selectedDate}
        disabled={selectedEnvironment === ""}
        maxDate={maxDate ? maxDate : undefined}
        minDate={minDate ? minDate : undefined}
        onChange={(e) => {
          setSelectedDate(e);
          return handleChange(
            moment(e).format("YYYY-MM-DD"),
            "TentativeServiceEndDate"
          );
        }}
        helperText={isServiceEndDateInvalid && "Please enter a valid date."}
        error={
          isServiceEndDateInvalid ||
          (!selectedDate &&
            selectedEnvironment !== "" &&
            isServiceEndDateInvalid) ||
          (!tentativeServiceEndDate && selectedEnvironment !== "")
        }
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        disableCloseOnSelect={true}
        disablePast={true}
      />
    </MuiPickersUtilsProvider>
  );
};
