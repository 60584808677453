import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  StatusSideBar,
  TitleBar,
  StyledCard,
  StatusSideBarText,
} from "./SSHCardsStyles";
import {
  LargeSubtitle,
  FieldName,
  FieldValue,
  Subtitle3,
} from "../../common/CommonStyles/CommonTypographyStyles";
import theme from "../../../theme/theme";
import PropTypes from "prop-types";
import { images } from "../../../assets";

/**
 * The component that renders the card elements of SSH containing the requests and pending approvals
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: Ssh.js
 */

const SSHCards = ({ cardData, cardType, approvalButton, declineButton }) => {
  return (
    <StyledCard>
      <Grid container>
        <Grid
          item
          xs={1}
          style={{
            background:
              cardData.status === "approved"
                ? theme.status.running
                : cardData.status === "declined"
                ? theme.status.stopped
                : theme.status.pending,
            maxWidth: "4.333333%",
            flexBasis: "6.333333%",
          }}
        >
          <StatusSideBar>
            <div>
              <StatusSideBarText style={{ fontSize: "22px"}}>
                {cardData.status.substr(0, 1).toUpperCase() +
                  cardData.status.substr(1)}
              </StatusSideBarText>
            </div>
          </StatusSideBar>
        </Grid>
        <Grid
          item
          xs={11}
          style={{ maxWidth: "93.666667%", flexBasis: "93.666667%" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <TitleBar>
                <Grid
                  container
                  style={{
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={2} style={{ marginTop: ".4em" }}>
                    {/*
                                  here we have to handle the conditionals to show different cloud images.
                                  In order to see more images, see the "images" object
                                */}
                    <img
                      src={images.AWS_Black}
                      width="50px"
                      style={{ marginRight: "25%" }}
                      alt="AWS_Black_not_found"
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <LargeSubtitle>{cardData.cluster_id}</LargeSubtitle>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      height: "100%",
                    }}
                  >
                    <Grid
                      container
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid
                        item
                        xs={10}
                        style={{
                          borderLeftStyle: "solid",
                          borderColor: theme.backgroundBorderGreys.border,
                          borderWidth: "1px",
                          height: "100%",
                          backgroundColor: "white",
                          // borderRadius: "10px 0px 0px 10px",
                        }}
                      >
                        <Subtitle3
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {cardData.Intiative
                            ? cardData.Intiative === "aaammg"
                              ? "AiOps"
                              : cardData.Intiative === "mcc"
                              ? "MCC"
                              : cardData.Intiative
                            : "N/A"}
                        </Subtitle3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TitleBar>
            </Grid>
            <Grid item xs={4} lg={3}>
              <FieldName>Request ID</FieldName>
              <FieldName>Private DNS</FieldName>
              <FieldName>
                {cardType !== "MyRequests" ? "Requester" : "Instance Owner"}
              </FieldName>

              <FieldName>Requested On</FieldName>
            </Grid>
            <Grid item xs={8} lg={7}>
              <FieldValue>{cardData.request_id}</FieldValue>
              <FieldValue style={{ textWrap : "nowrap"}}>{cardData.private_dns}</FieldValue>
              <FieldValue>
                {cardType !== "MyRequests"
                  ? cardData.requester
                  : cardData.instance_owner}
              </FieldValue>
              <FieldValue>{cardData.request_time}</FieldValue>
            </Grid>
            {cardType !== "MyRequests" &&
            cardData.status !== "approved" &&
            cardData.status !== "declined" ? (
              <Grid item xs={12} lg={2}>
                <div style={{ padding: "10px" }}>
                  {approvalButton}
                  {declineButton}
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledCard>
  );
};
SSHCards.propTypes = {
  /**
   * The data of the request/pending approval being rendered
   */
  cardData: PropTypes.object,
  /**
   * String to say if the card is a pending approvals card or a requests card.
   */
  cardType: PropTypes.string,
  /**
   * JSX object passing in the approval button
   */
  approvalButton: PropTypes.object,
  /**
   * JSX object passing in the decline button
   */
  checkIsRegistration: PropTypes.func,
};

export default SSHCards;
