import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import { Box, Menu, MenuItem } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
// import Box from "@material-ui/core";
import { Grid, CircularProgress } from "@material-ui/core";
import { StyledFormControl, StyledSelect } from "../fui/FuiStyles";
import { SmallHeader, Subtitle4 } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from '../../assets';
import { connect } from "react-redux";
import base from "../../config";
import { headers } from "../../helper/api";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import "./Recommendations.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokenValidation } from "../../helper/customizedHooks";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import DownsizeInstance from "../ec2management/ActionItems/DownsizeInstance"
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
import { CSVLink } from "react-csv";
import { Radio, FormControlLabel } from "@material-ui/core";
import { StyledRadioGroup, TopRadioWrapper } from "../fui/FuiStyles";
import { DownloadButton, UpdateButton } from "../ssh/SshStyles";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend,  Title} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


/**
 * Recommendations page
 * @author shilpa.adikeshava
 * @version 1.0
 */

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    boxShadow: "unset",
    padding: "15px 32px 32px 32px"
  }
}));


const Recommendations = (email, idToken) => {
  useEffect(() => {
    let timer;
    if (email && idToken) {
      fetchCostOwnerDetails(email);
      fetchInstanceOwnerDetails(email);
      // timer = setInterval(() => {
      //   const apiCall = () => {
      //     tokenValidation();
      //     fetchCostOwnerDetails(email);
      //     fetchInstanceOwnerDetails(email);
      //   };
      //   apiCall();
      // }, 60000);
      // return () => clearInterval(timer);
    }
  }, [email, idToken]);

  const [loaded, setLoaded] = useState(true);
  const [instanceOwnerDetails, setInstanceOwnerDetails] = useState([]);
  const [costOwnerDetails, setCostOwnerDetails] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [modalData, setData] = useState();
  const [notes, setNotes] = useState();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [selectedRowData, setSelectedRowData] = useState();
  const [selectedInstanceOwnerData, setSelectedInstanceOwnerData] = useState([]);
  const [selectedCostOwnerData, setSelectedCostOwnerData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [downloadData, setDownloadData] = useState([]);


  const handleClose = () => {
    setOpen(false);
    setSelectedRowData(" ");
    setNotes(" ");
  };

  const [isOptionSelected, setIsOptionSelected] = useState(true);
  const [dialogInstanceType, setdialogInstanceType] = useState("");

  const handleInstanceType = (instanceTypeString, targetValue, downsizeInstanceTypeVal) => {
    //console.log("setting this -=-=-=-", instanceTypeString);
    //console.log("also log this  -=-=-=-", downsizeInstanceTypeVal);
    //console.log("also log this  -=-=-=-", targetValue);
    if (targetValue === "No more size to downgrade") {
      setIsOptionSelected(true);
    } else {
      setIsOptionSelected(false);
    }
    setdialogInstanceType(targetValue);
  };

  const handleChange = (event) => {
    setData(event.target.value);
    setOpen(true);
  };

  const handleChoosedRow = (row) => {
    // console.log("choosed row", row);
    setSelectedRowData(row);
  };


  const handleDownsize = () => {
    // console.log(selectedRowData)
    // console.log(dialogInstanceType)
    selectedRowData.status = "In Progress";
    selectedRowData.userResponse = "Downsize Instance";
    const customerEnterpriseId = email.email.split("@")[0];
    const idToken = localStorage.getItem("token");
    const initiative = selectedRowData.initiative;
    const instanceId = selectedRowData.instanceId;
    const accountId = selectedRowData.accountId;
    const accountType = selectedRowData.accountType;
    const instanceType = selectedRowData.instanceType;
    const privateDNS = selectedRowData.privateDNS;
    const uniqueId = selectedRowData.uniqueId;
    const downsizeInstanceTypeVal = dialogInstanceType.split(" ")[0];
    const urls = `${base.urls.apis.ec2}/${instanceId}/changeinstancetype?userepid=${customerEnterpriseId}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}&instancetype=${instanceType}&newinstancetype=${downsizeInstanceTypeVal}`;
    const selectedInstanceTypeValue = { "newinstancetype": downsizeInstanceTypeVal };
    Axios.post(urls, selectedInstanceTypeValue, {
      headers: { Authorization: idToken },
    }).then((res) => {
      //console.log("Downsize Instance Value",res.status)
      handleSubmit(instanceId, "NA", "Downsize Instance", downsizeInstanceTypeVal, "NA", "In Progress", uniqueId);
    })
      .catch((err) => {
        console.log("Async error", err);
      });
    handleSubmit(instanceId, "NA", "Downsize Instance", downsizeInstanceTypeVal, "NA", "In Progress", uniqueId);
    handleClose();
    // setLoaded(false);
  };

  const handleTerminate = () => {
    tokenValidation();
    const idToken = localStorage.getItem("token");
    selectedRowData.status = "In Progress";
    selectedRowData.userResponse = "Terminate";
    const initiative = selectedRowData.initiative;
    const instanceId = selectedRowData.instanceId;
    const accountId = selectedRowData.accountId;
    const accountType = selectedRowData.accountType;
    const privateDNS = selectedRowData.privateDNS;
    const uniqueId = selectedRowData.uniqueId;
    const urls = `${base.urls.apis.ec2}/${instanceId}/terminate?accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}`;
    Axios.get(urls, {
      headers: { Authorization: idToken },
    }).then((response) => {
      const executionId = response.data;
      // console.log(executionId.executionId)
      // toast.info("Request is submitted. Thank you for actioning on it!")
      handleSubmit(instanceId, "NA", "Terminate", "NA", executionId.executionId, "In Progress", uniqueId);
    }).catch((error) => {
      console.log('Error terminating instance', error)
      toast.error("ERROR! Please try again after sometime.")
    })
    handleClose();
    setLoaded(false);
  };

  const handleKeepAsItIs = () => {
    tokenValidation();
    const idToken = localStorage.getItem("token");
    selectedRowData.status = "In Progress";
    selectedRowData.userResponse = "Keep as it is";
    const uniqueId = selectedRowData.uniqueId;
    const instanceId = selectedRowData.instanceId;
    if (notes == undefined) {
      toast.info("Please state the reason to keep as it is!")
    } else {
      handleSubmit(instanceId, notes, "Keep as it is", "NA", "NA", "Completed", uniqueId);
      handleClose();
      setLoaded(false);
    }
  };

  const handleNotesChange = (field) => (event) => {
    event.preventDefault();
    if (field === "notes") {
      setNotes(event.target.value);
    }
  };


  const handleSubmit = (instanceId, notes, userResponse, newInstanceType, executionId, status, uniqueId) => {
    tokenValidation();
    const idToken = localStorage.getItem("token");
    const urls = `${base.urls.apis.recommendations}/submitfeedback`;
    Axios.post(urls, " ", {
      headers: { Authorization: idToken },
      params: {
        instanceId: instanceId,
        notes: notes,
        userResponse: userResponse,
        newInstanceType: newInstanceType,
        executionId: executionId,
        status: status,
        uniqueId: uniqueId
      },
    }).then((res) => {
      setLoaded(true);
      // console.log(userResponse)
      for (var i = 0; i < instanceOwnerDetails.length; i++) {
        if (instanceOwnerDetails[i].instanceId == instanceId) {
          instanceOwnerDetails[i].status = "In Progress"
          instanceOwnerDetails[i].userResponse = userResponse
        } else {

        }
        // console.log(instanceOwnerDetails)
      }
      toast.info("Your request is submitted. Thank you for actioning on it!")
    })
      .catch((err) => {
        toast.error("ERROR! Please try again after sometime.");
        console.log("Async error", err);
      });
  };

  const fetchInstanceOwnerDetails = async () => {
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];
    if (customerEnterpriseId && idToken) {
      return Axios
        .get(
          `${base.urls.apis.recommendations}` +
          `/instanceownerdetails?userepid=${customerEnterpriseId}`,
          {
            headers: { ...headers, Authorization: idToken },
          }
        )
        .then((res) => {
          const data = res.data;
          data.sort(function (a, b) {
            if (a.status < b.status) {
              return -1;
            }
            if (a.status > b.status) {
              return 1;
            }
            return 0;
          });
          setInstanceOwnerDetails(data);
          // if (isMyServers) {
          //   handleMyServersClick(res.data);
          // }
          if (res.data.length !== 0) {
            setIsLoading(false)
            generateInstanceOwnerCount(res.data)
          } else {
            setIsLoading(false)
            document.getElementById("no-instance-owner-data").style.display = "block";
          }
        })
        .catch((err) => {
          //toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
        });
    }
  };

  const fetchCostOwnerDetails = async () => {
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];
    if (customerEnterpriseId && idToken) {
      return Axios
        .get(
          `${base.urls.apis.recommendations}` +
          `/costownerdetails?userepid=${customerEnterpriseId}`,
          {
            headers: { ...headers, Authorization: idToken },
          }
        )
        .then((res) => {
          const data = res.data;
          data.sort(function (a, b) {
            if (a.instanceOwner < b.instanceOwner) {
              return -1;
            }
            if (a.instanceOwner > b.instanceOwner) {
              return 1;
            }
            return 0;
          });
          setCostOwnerDetails(data);
          // if (!isMyServers) {
          //   handleMyTeamServersClick(res.data);
          // }
          if (res.data.length !== 0) {
            setIsLoading(false)
            generateCostOwnerCount(res.data)
          } else {
            setIsLoading(false)
          }
        })
        .catch((err) => {
          //toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
        });
    }
  };

  const handleMyServersDownload = (instanceOwnerDetails) => {
    setDownloadData(instanceOwnerDetails);
  };

  const handleMyTeamServersDownload = (costOwnerDetails) => {
    setDownloadData(costOwnerDetails);
  };

  const [isMyServers, setMyServers] = useState(true);
  const [accountName, setAccountName] = useState([]);
  const [currentCost, setCurrentCost] = useState([]);


  const handleMyServersClick = (instanceOwnerDetails) => {
    setMyServers(true)
    modifyInstanceOwnerData(instanceOwnerDetails);
  };

  const handleMyTeamServersClick = (costOwnerDetails) => {
    setMyServers(false)
    modifyCostOwnerData(costOwnerDetails);
  };

  const generateInstanceOwnerCount = (instanceOwnerDetails) => {
    modifyInstanceOwnerData(instanceOwnerDetails);
  };

  const generateCostOwnerCount = (costOwnerDetails) => {
    modifyCostOwnerData(costOwnerDetails);
  };

  const modifyInstanceOwnerData = (data) => {
    // console.log(data)
    const modifiedData = data.map((item) => {
      const oldCurrentCost = item.currentCost;// here we cache old value
      item.currentCost = oldCurrentCost.replace("$", "");// here we assign the modified value
      // console.log(item.currentCost);
      const oldEstimatedCostSavings = item.estimatedCostSavings;
      item.estimatedCostSavings = oldEstimatedCostSavings.replace("$", "");
      // console.log(item)
      return item;
    });
    setSelectedInstanceOwnerData(modifiedData)
    setSelectedData(modifiedData)
    if (modifiedData.length > 0) {
      document.getElementById("piegraph").style.display = "block";
      generatePieGraph(modifiedData)
    } else {
      document.getElementById("piegraph").style.display = "none";
    }
  };

  const modifyCostOwnerData = (data) => {
    // console.log(data)
    const modifiedData = data.map((item) => {
      const oldCurrentCost = item.currentCost;// here we cache old value
      item.currentCost = oldCurrentCost.replace("$", "");// here we assign the modified value
      // console.log(item.currentCost);
      const oldEstimatedCostSavings = item.estimatedCostSavings;
      item.estimatedCostSavings = oldEstimatedCostSavings.replace("$", "");
      // console.log(item)
      return item;
    });
    // console.log("modified", modifiedData)
    setSelectedCostOwnerData(modifiedData)
    setSelectedData(modifiedData)
    if (modifiedData.length > 0) {
      document.getElementById("piegraph").style.display = "block";
      generatePieGraph(modifiedData)
    } else {
      document.getElementById("piegraph").style.display = "none";
    }
  };

  // console.log(selectedData);

  const currentMyServerTotalCost = selectedInstanceOwnerData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.currentCost), 0);
  // console.log(currentTotalCost)
  const optimalSavings = selectedData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.estimatedCostSavings), 0);
  const currentMyTeamServerTotalCost = selectedCostOwnerData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.currentCost), 0);

  const generatePieGraph = (data) => {
    const getAccountName = [];
    const getCurrentCost = [];
    const getdata = async () => {
      const resData = data
      // console.log(resData);
      const sumArrBy = (resData = [], accountName = "") =>
        resData.reduce(
          (prev, { currentCost, accountName: cat }) =>
            cat === accountName ? prev + parseInt(currentCost) : prev,
          0
        );
      // console.log(sumArrBy(resData, 'myWizard-aiops prod'))
      const resData1 = [
        {
          accountName: "myWizard-aiops prod",
          currentCost: sumArrBy(resData, 'myWizard-aiops prod')
        },
        {
          accountName: "myWizard-aiops non-prod",
          currentCost: sumArrBy(resData, 'myWizard-aiops non-prod')
        },
        {
          accountName: "myWizardCore dev",
          currentCost: sumArrBy(resData, 'myWizardCore dev')
        },
        {
          accountName: "myWizardCore sit",
          currentCost: sumArrBy(resData, 'myWizardCore sit')
        },
        {
          accountName: "myWizardCore prod",
          currentCost: sumArrBy(resData, 'myWizardCore prod')
        },
        {
          accountName: "myWizardIEMP dev",
          currentCost: sumArrBy(resData, 'myWizardIEMP dev')
        },
        {
          accountName: "myWizardIEMP sit",
          currentCost: sumArrBy(resData, 'myWizardIEMP sit')
        },
        {
          accountName: "myWizardIEMP prod",
          currentCost: sumArrBy(resData, 'myWizardIEMP prod')
        }
      ]
      for (let i = 0; i < resData1.length; i++) {
        getAccountName.push(resData1[i].accountName);
        getCurrentCost.push(resData1[i].currentCost);
      }
      setAccountName(getAccountName);
      setCurrentCost(getCurrentCost);
    }
    getdata();

  };


  return (
    <div className="mainDiv">
      <DarkBackground disappear={!loaded}>
        <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
        // text="Loading your content..."
        >
          {/* <p>Some content or children or something.</p> */}
        </LoadingOverlay>
      </DarkBackground>
      <ToastContainer autoClose={7000} />
      <Grid className="headerItems" container spacing={3}>
        <Grid item md={6}>
          <SmallHeader className="recomHeader" style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "600" }}>Recommendations </SmallHeader>
        </Grid>
        <Grid item md={6}>
          <img
            alt=""
            src={images.FooterImage}
            width="93%"
            style={{ float: "right" }}
          />
        </Grid>
      </Grid>
      <Divider />
      <div>
        { isLoading ?
          <div id="loader"
            style={{
              paddingTop: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={40} />
          </div>
          : 
          <div>
            <Grid className="displayItems" container>
              <Grid className="radio" item md={2} xs={1} sm={1}>
                <TopRadioWrapper>
                  <StyledRadioGroup column>
                    <FormControlLabel
                      checked={isMyServers}
                      value="myservers"
                      control={<Radio style={{ color: "#7500c0" }} />}
                      label={`My Servers`}
                      onClick={() => handleMyServersClick(instanceOwnerDetails)}
                    />
                    <FormControlLabel
                      checked={!isMyServers}
                      value="myteamservers"
                      control={<Radio style={{ color: "#7500c0" }} />}
                      label={`My Team Servers`}
                      onClick={() => handleMyTeamServersClick(costOwnerDetails)}
                    />
                  </StyledRadioGroup>
                </TopRadioWrapper>
              </Grid>
              <Grid className="itemPieGraph" item md={4} xs={2} sm={2}>
                <div>
                  <div id="piegraph">
                    <Doughnut
                      width={100}
                      height={50}
                      data={{
                        labels: accountName,
                        datasets: [
                          {
                            label: '# of Votes',
                            data: currentCost,
                            backgroundColor: [
                              '#A45EE94f',
                              '#8B008B4f',
                              '#D34DD24f',
                              '#A50B5E4f',
                              '#3114654f',
                              '#957A814f',
                              '#6A5ACD4f',
                              '#DA70D64f',
                            ],
                            borderColor: [
                              '#A45EE9',
                              '#8B008B',
                              '#D34DD2',
                              '#A50B5E',
                              '#311465',
                              '#957A81',
                              '#6A5ACD',
                              '#DA70D6',
                            ],
                            borderWidth: 1,
                            hoverOffset: 10,
                            // offset: [0,0,0,0,0,0,0,0]                     
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        legend: {
                          display: true,
                          position: "right",
                          // position: "bottom"
                        },
                        animation: {
                          animateScale: true,
                          animateRotate: true
                        },
                        plugins: {
                          title: {
                            fontSize: 30,
                            text: 'Chart js tutorial',
                            display: true,
                            font: { size: 20 }
                          },
                          legend: {
                            labels: {
                              font: { size: 12 }
                            }
                          }
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid className="cardItems" container direction="row" justifyContent="center" alignItems="center" item md={6} xs={6} sm={6} spacing={2} style={{
                justifyContent: "center"
              }}>
                <Grid item>
                  <Paper elevation={3}>
                    <Card>
                      <div>
                        <CardContent
                          style={{
                            // paddingBottom: "4px",
                            // paddingTop: "10px",
                            padding: "10px",
                            width: "150px"
                          }}
                        >
                          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", paddingBottom: '1px' }}>
                          
                            <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" md={8} xs={4} sm={4}>
                              <Typography variant="subtitle2" color="textSecondary" className="card-title" style={{ fontSize: "0.8rem", paddingBottom: "14px", width: "80px" }}>Current Total Cost - My Servers</Typography>
                              <Typography variant="body1" color="textSecondary" className="card-body" style={{ fontWeight: 900 }}>
                                ${currentMyServerTotalCost}
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  background: "#7500c0",
                                  // borderRadius: "12px",
                                  height: "45px",
                                  width: "45px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <AttachMoneyIcon style={{
                                  width: "24px",
                                  height: "24px",
                                  display: "inline-block",
                                  lineHeight: "1em",
                                  flexShrink: 0,
                                  verticalAlign: "middle",
                                  color: "white"
                                }} />
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </div>
                    </Card>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper elevation={3}>
                    <Card>
                      <div>
                        <CardContent
                          style={{
                            // paddingBottom: "4px",
                            // paddingTop: "10px",
                            padding: "10px",
                            width: "150px"
                          }}
                        >
                          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", paddingBottom: '1px' }}>
                            <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" md={8} xs={4} sm={4}>
                              <Typography variant="subtitle2" color="textSecondary" className="card-title" style={{ fontSize: "0.8rem", paddingBottom: "14px", width: "80px" }}>Potential Savings from Recommendations</Typography>
                              <Typography variant="body1" color="textSecondary" className="card-body" style={{ fontWeight: 900 }}>
                                ${optimalSavings}
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  background: "#7500c0",
                                  // borderRadius: "12px",
                                  height: "45px",
                                  width: "45px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <AccountBalanceWalletIcon style={{
                                  width: "24px",
                                  height: "24px",
                                  display: "inline-block",
                                  lineHeight: "1em",
                                  flexShrink: 0,
                                  verticalAlign: "middle",
                                  color: "white"
                                }} />
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </div>
                    </Card>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper elevation={3}>
                    <Card>
                      <div>
                        <CardContent
                          style={{
                            // paddingBottom: "4px",
                            // paddingTop: "10px",
                            padding: "10px",
                            width: "150px"
                          }}
                        >
                          <Grid container spacing={2} style={{ display: "flex", alignItems: "center", paddingBottom: '1px' }}>
                            <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" md={8} xs={4} sm={4}>
                              <Typography variant="subtitle2" color="textSecondary" className="card-title" style={{ fontSize: "0.8rem", paddingBottom: "14px", width: "80px" }}>Current Total Cost - My Team Servers</Typography>
                              <Typography variant="body1" color="textSecondary" className="card-body" style={{ fontWeight: 900 }}>
                                ${currentMyTeamServerTotalCost}
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  background: "#7500c0",
                                  // borderRadius: "12px",
                                  height: "45px",
                                  width: "45px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <AttachMoneyIcon style={{
                                  width: "24px",
                                  height: "24px",
                                  display: "inline-block",
                                  lineHeight: "1em",
                                  flexShrink: 0,
                                  verticalAlign: "middle",
                                  color: "white"
                                }} />
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </div>
                    </Card>
                  </Paper>
                </Grid>
                <p style={{ fontSize: "0.85rem" }}><span style={{ color: "red"}}>&#x2a;</span> Above costs are calculated for a time period of three months (DEC 1 to FEB 28)</p>
              </Grid>
            </Grid>
            { isMyServers ? 
              <div id='myservers'>
                <br></br>
                <br></br>
                {instanceOwnerDetails.length > 0 ?
                  <div>
                    <Grid container spacing={20}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
      
                        <h2 style={{ fontFamily: "Graphik" }}>My Servers </h2>
      
                        <CSVLink data={downloadData} filename="my_servers.csv" style={{ textDecoration: "none" }}>
                          <DownloadButton
                            variant="outlined"
                            onClick={() => handleMyServersDownload(instanceOwnerDetails)}
                          >
                            Download Report
                            <SaveAltIcon />
                          </DownloadButton>
                        </CSVLink>
                      </Grid>
                    </Grid>
                    <Paper className={styles.root}>
                      <div className={styles.tableWrapper} style={{ background: "#f2f2f2", maxWidth: "95% !important" }}>
                        <Table className={styles.table}>
                          <TableHead style={{ backgroundColor: "rgb(55 62 73)" }}>
                            <TableRow >
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Private DNS
                              </TableCell>
                              {/* <TableCell style={{color:"hsla(0, 0%, 100%, 0.8)", padding:"12px"}} >
                                    Region
                                </TableCell> */}
                              {/* <TableCell style={{color:"hsla(0, 0%, 100%, 0.8)", padding:"12px"}} >
                                    Account ID 
                                </TableCell> */}
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Account
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Server Name
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Current Instance Type
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Average CPU Utilization
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Average Network Utilization
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Estimated Cost Savings
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px" }} >
                                Recommendations
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "12px", width: "60%" }} >
                                Review / Action
                              </TableCell>
                              <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)", padding: "30px", width: "100%" }} >
                                Status
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody >
                            {instanceOwnerDetails.map((row, key) => (
                              <TableRow key={key} style={row.recommendation == "-" ? { backgroundColor: "#efefef" } : { backgroundColor: "#fff" }}>
                                <TableCell component="th" scope="row" style={{ padding: "12px" }}>
                                  <a href="/servers" target="_blank">{row.privateDNS}</a>
                                </TableCell>
                                {/* <TableCell align="left" style={{padding:"12px"}}>
                                      {row.region}
                                  </TableCell> */}
                                {/* <TableCell align="left" style={{padding:"12px"}}>
                                      {row.accountId}
                                  </TableCell> */}
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.accountName.trim()}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.clusterId}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.instanceType}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.cpuUtilization}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.networkUtilization}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.estimatedCostSavings}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  {row.recommendation === "-" ? "Optimised" : row.recommendation}
                                </TableCell>
                                <TableCell align="left" style={{ padding: "12px" }}>
                                  <div style={{ padding: "8px 0px" }}>
                                    <StyledFormControl variant="" margin="dense">
      
                                      {(() => {
                                        if (row.status === "Completed") {
                                          return <span>{row.userResponse}</span>
                                        } else if (row.status === "In Progress") {
                                          return <span>{row.userResponse}</span>
                                        } else if (row.recommendation === "-") {
                                          return (
                                            <StyledSelect defaultValue="SELECT" onChange={handleChange} style={{ fontSize: "0.875rem" }}>
                                              <MenuItem disabled value="SELECT" data-item={row}>
                                                -- SELECT --
                                              </MenuItem>
                                              <MenuItem value="Downsize Instance" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Downsize Instance
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem value="Terminate" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Terminate
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem value="Keep as it is" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Keep as it is
                                              </MenuItem>
                                            </StyledSelect>
                                          )
                                        } else if (row.status === "Action Pending") {
                                          return (
                                            <StyledSelect defaultValue="SELECT" onChange={handleChange} style={{ fontSize: "0.875rem" }}>
                                              <MenuItem disabled value="SELECT" data-item={row}>
                                                -- SELECT --
                                              </MenuItem>
                                              <MenuItem value="Downsize Instance" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Downsize Instance
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem value="Terminate" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Terminate
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem value="Keep as it is" data-item={row} onClick={() => handleChoosedRow(row)}>
                                                Keep as it is
                                              </MenuItem>
                                            </StyledSelect>
                                          )
                                        }
                                      })()}
                                    </StyledFormControl>
                                  </div>
                                </TableCell>
                                {row.status == "Action Pending" ?
                                  <TableCell align="left" style={{ padding: "30px" }}>
                                    <span style={{ padding: "6px", color: "rgb(226 149 0)"}}>{row.status}</span>
                                  </TableCell> :
                                  <TableCell align="left" style={{ padding: "30px" }}>
                                    <span style={row.status == "Completed" ? { padding: "6px", color: "rgb(4 169 4)" } : { padding: "6px", color: "rgb(4 169 4)" }}>{row.status}</span>
                                  </TableCell>
                                }
      
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Paper>
                  </div> :
                  <div style={{ alignContent: "left" }}>
                    <h3 style={{ textAlign: "left", fontFamily: "Graphik", paddingTop: "10px" }}>There are no underutilized servers for you to review</h3>
                  </div>
                }
              </div> 
            : 
            <div id='myteamservers'>
              <br></br>
              <br></br>
              {costOwnerDetails.length > 0 ?
                <div>
                  <Grid container spacing={20}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2 style={{ fontFamily: "Graphik" }}>My Team Servers </h2>
                      <CSVLink data={downloadData} filename="my_team_servers.csv" style={{ textDecoration: "none" }}>
                        <DownloadButton
                          variant="contained"
                          // id="cancelButton"
                          onClick={() => handleMyTeamServersDownload(costOwnerDetails)}
                        >
                          Download Report
                          <SaveAltIcon />
                        </DownloadButton>
                      </CSVLink>
                      {/* <Button variant="contained">Download Report</Button> */}
                    </Grid>
                  </Grid>
                  {/* <h2 style={{ textAlign: "left", fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}> My Team Servers </h2> */}
                  <Paper className={styles.root}>
                    <div className={styles.tableWrapper} style={{ background: "#f2f2f2" }}>
                      <Table className={styles.table}>
                        <TableHead style={{ backgroundColor: "rgb(55 62 73)" }}>
                          <TableRow >
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Instance Owner
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Private DNS
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Region
                            </TableCell>
                            {/* <TableCell style={{color:"hsla(0, 0%, 100%, 0.8)"}} >
                                    Account ID 
                                </TableCell> */}
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Initiative
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Server Name
                            </TableCell>
    
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Average CPU Utilization
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Average Network Utilization
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Estimated Cost Savings
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              Recommendations
                            </TableCell>
                            <TableCell style={{ color: "hsla(0, 0%, 100%, 0.8)" }} >
                              User Response
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {costOwnerDetails.map((row, key) => (
                            <TableRow key={key}>
                              <TableCell align="left">
                                {row.instanceOwner}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <a href="/servers" target="_blank">{row.privateDNS}</a>
                              </TableCell>
                              <TableCell align="left">
                                {row.region}
                              </TableCell>
                              {/* <TableCell align="left">
                                    {row.accountId}
                                </TableCell> */}
                              <TableCell align="left">
                                {row.initiative}
                              </TableCell>
                              <TableCell align="left">
                                {row.clusterId}
                              </TableCell>
                              <TableCell align="left">
                                {row.cpuUtilization}
                              </TableCell>
                              <TableCell align="left">
                                {row.networkUtilization}
                              </TableCell>
                              <TableCell align="left">
                                {row.estimatedCostSavings}
                              </TableCell>
                              <TableCell align="left">
                                {row.recommendation}
                              </TableCell>
                              <TableCell align="left">
                                {row.userResponse}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Paper>
                </div> :
                <div style={{ alignContent: "left" }}>
                  <h3 style={{ textAlign: "left", fontFamily: "Graphik", paddingTop: "10px" }}>There are no underutilized team servers for you to review</h3>
    
                </div>
              }
            </div>
            }
          </div>
        }        
      </div>
      <br></br><br></br><br></br><br></br><br></br><br></br>
      <div>
        <Modal open={open} onClose={handleClose}>
          <div style={modalStyle} className={classes.paper}>
            {modalData === "Terminate" ?
              <div>
                <h2>Terminate server</h2>
                <Divider />
                <p><b>Server Details</b></p>
                <p>Instance ID: {selectedRowData.instanceId}</p>
                <p>PrivateDNS: {selectedRowData.privateDNS}</p>
                <p><br></br>Are you sure you want to <b>terminate</b> this server now?</p>
                <div style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleTerminate}
                  >
                    Yes, Terminate
                  </Button> &nbsp;
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              : ""}
            {modalData === "Keep as it is" ?
              <div>
                <h2>Keep as it is</h2>
                <Divider />
                <p>You have chose <b>"Keep as it is"</b>. Please state the reason for it.</p>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  InputProps={{ rows: 4 }}
                  onChange={handleNotesChange("notes")}
                />
                <br /><br />
                <div style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleKeepAsItIs}
                  >
                    Yes, Confirm
                  </Button> &nbsp;
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              : ""}

            {modalData === "Downsize Instance" ?
              <div>
                <h2>Downgrade Instance</h2>
                <Divider />
                <p><b>Downsize requires a reboot and the instance will be unavailable for few mins.</b></p>
                <p>Are you sure you want to proceed now? If yes, please select the instance type for which you want to downsize.</p>
                {/* <p>PrivateDNS: {selectedRowData.PrivateDNS}</p> */}
                <DownsizeInstance
                  instanceType={selectedRowData.instanceType}
                  instance={{
                    initiative: selectedRowData.initiative,
                    instanceId: selectedRowData.instanceId,
                    accountId: selectedRowData.accountId,
                    accountType: selectedRowData.accountType,
                    instanceType: selectedRowData.instanceType,
                    privateDNSName: selectedRowData.privateDNS
                  }}
                  privateDNS={selectedRowData.privateDNS}
                  handleInstanceType={handleInstanceType}
                />
                <br /><br />
                <div style={{ float: "right" }}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleDownsize}
                  >
                    Downsize
                  </Button> &nbsp;
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
              : ""}
          </div>
        </Modal>
      </div>
    </div >
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,

});


Recommendations.propTypes = {

  /**

   * Data of instance

   */

  instance: PropTypes.object,

  /**

   * PrivateDNS of selected instance

   */

  privateDNS: PropTypes.string,

  /**

   * InstanceType of selected instance

   */

  instanceType: PropTypes.string,

};


export default connect(MSTP)(withRouter(withStyles(styles)(Recommendations)));
