import React, { useState } from "react";
import "./custom.css";
import 'react-toastify/dist/ReactToastify.css'
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardHeader,
    Button, CardActions, Avatar, Divider, DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core/";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

/**
   * @author shilpa.adikeshava
   * @version 1.0
 */

const AccessCards = (props) => {
    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = useState([]);
    const [expiryDate, setExpiryDate] = useState('');
    const [deploymentName, setDeploymentName] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = (data) => {
        setModalData(data)
        const date = data.SPExpiryDate
        const [first, ...rest] = date.split('T')
        setExpiryDate(first)
        const deploymentName = data.DeploymentName
        const rows = deploymentName.split(",");
        setDeploymentName(deploymentName)
        setOpen(true);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        subheader: {
            color: '#fff',
            fontSize: '16px'
        },
        title: {
            color: '#fff',
            fontSize: '20px'
        },
        requestOpenAIButton: {
            backgroundColor: theme.palette.admin.main,
            color: "white",
            float: "right"
        },
    }));

    const classes = useStyles();

    const [values, setValues] = React.useState({
        password: "",
        showKey: false,
    });

    const handleClickShowKey = () => {
        setValues({ ...values, showKey: !values.showKey });
    };

    const handleMouseDownKey = (event) => {
        event.preventDefault();
    };

    const handleKeyChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <div style={{ marginBottom: "5%" }}>
            <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                {props.data.length === 0 ?
                    <p style={{ fontSize: "1.15rem", color: "black !important", margin: "12px", fontWeight: "600" }}><b>No Active OpenAI!</b></p>
                    : props.data.map((row, key) => (
                        <Grid item xs={4} key={key}>
                            <Card variant="outlined">
                                <CardHeader style={{ background: "linear-gradient(to right,#7500C0,#A100FF)" }}
                                    avatar={
                                        <Avatar style={{ background: "#fff", color: "#7500c0" }} aria-label="recipe">
                                            <b>{key + 1}</b>
                                        </Avatar>
                                    }
                                    title={<Typography className={classes.title}><b><span>{row.ClusterId}</span></b></Typography>}
                                    subheader={<Typography className={classes.subheader}>ID: {row.OpenAIServiceName}</Typography>}
                                />
                                <CardContent style={{ background: "#f7faff", color: "#000000" }}>
                                    {/* <Typography gutterBottom style={{fontSize:"19px"}}>
                                        <b>{elem.asseletName}</b>
                                    </Typography> */}
                                    <Typography gutterBottom variant="body1" color="text.secondary">
                                        <b style={{fontWeight: 600}}>Tenant ID:</b> {row.TenantID}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color="text.secondary" fontWeight="600">
                                        <b style={{fontWeight: 600}}>Subscription ID:</b> {row.AzSubscriptionID}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color="text.secondary" >
                                        <b style={{fontWeight: 600}}>Deployment ID:</b> {row.DeploymentID}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color="text.secondary">
                                        <b style={{fontWeight: 600}}>Region:</b> {row.AWSHostedRegion}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color="text.secondary">
                                        <b style={{fontWeight: 600}}>Approval Status:</b> {row.Status}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color="text.secondary">
                                        <b style={{fontWeight: 600}}>Customer Enterprise ID: </b> {row.CustomerEnterpriseID}
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ float: 'right' }}>
                                    <Button size="small" onClick={() => handleOpen(row)}><span style={{ color: "#7500C0" }}>Show more</span></Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                <br></br><br></br><br></br>
            </Grid>
            <CommonDialog
                className="AzureOpenAIDialog"
                open={open}
                onClose={handleClose}
                title={"Open AI Access Status"}
                dialogType={"fui"}
                dialogContent={
                    <DialogContent>
                        <DialogContentText id="alert-dialog-descreption">
                            <div>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    {/* <p style={{ fontSize: "1.15rem", color: "black !important" }}><b>Congratulations! Your OpenAI access is available.</b></p> */}
                                    <p style={{ color: "black" }}>Please find the details below:</p>
                                    <div style={{ lineHeight: "0.9rem", color: "black" }}>
                                        <p><span><b style={{fontWeight: 600}}>AWS Hosted Region: </b></span>{modalData.AWSHostedRegion}</p>
                                        <p><span><b style={{fontWeight: 600}}>Air ID: </b></span>{modalData.AirID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Application Name: </b></span>{modalData.ApplicationName}</p>
                                        <p><span><b style={{fontWeight: 600}}>Vendor: </b></span>Azure OpenAI</p>
                                        <p><span><b style={{fontWeight: 600}}>Az Region: </b></span>{modalData.AzRegion}</p>
                                        <p><span><b style={{fontWeight: 600}}>Az Subscription ID: </b></span>{modalData.AzSubscriptionID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Az Subscription Name: </b></span>{modalData.AWSHostedRegion}</p>
                                        <p><span><b style={{fontWeight: 600}}>Client Name: </b></span>{modalData.ClientName === "myWizard AiOps" || modalData.ClientName === "mywizard aiops" ? "GenWizard AiOps" : modalData.ClientName}</p>
                                        <p><span><b style={{fontWeight: 600}}>Cluster ID: </b></span>{modalData.ClusterId}</p>
                                        <p><span><b style={{fontWeight: 600}}>Contract ID: </b></span>{modalData.ContractID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Customer Enterprise ID: </b></span>{modalData.CustomerEnterpriseID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Deployment ID: </b></span>{modalData.DeploymentID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Environment: </b></span>{modalData.Environment}</p>
                                        <p><span><b style={{fontWeight: 600}}>Instance IP: </b></span>{modalData.InstanceIP}</p>
                                        <p><span><b style={{fontWeight: 600}}>Instance ID: </b></span>{modalData.InstanceId}</p>
                                        <p><span><b style={{fontWeight: 600}}>Key Vault Name: </b></span>{modalData.KeyVaultName}</p>
                                        <p><span><b style={{fontWeight: 600}}>Key Vault OpenAI Secret Name: </b></span>{modalData.KeyVaultOAISecretName}</p>
                                        <p><span><b style={{fontWeight: 600}}>OpenAI EndPoint: </b></span>{modalData.OpenAIEndPoint}</p>
                                        <p><span><b style={{fontWeight: 600}}>OpenAI Key: </b></span>{modalData.OpenAIKey}</p>
                                        <p><span><b style={{fontWeight: 600}}>OpenAI ServiceName: </b></span>{modalData.OpenAIServiceName}</p>
                                        <p><span><b style={{fontWeight: 600}}>Order Date: </b></span>{modalData.OrderDate}</p>
                                        <p><span><b style={{fontWeight: 600}}>Resource GroupName: </b></span>{modalData.ResourceGroupName}</p>
                                        <p><span><b style={{fontWeight: 600}}>SP ID: </b></span>{modalData.SPID}</p>
                                        <p>
                                            <span>
                                                <b style={{fontWeight: 600}}>SP SecretKey: </b>
                                            </span>
                                            <Input
                                                style={{ padding: "0px !important", width: "60%", color: "black", marginTop: "-1%", marginBottom: "-1%" }}
                                                disableUnderline={true}
                                                type={values.showKey ? "text" : "password"}
                                                onChange={handleKeyChange("password")}
                                                value={modalData.SPSecretKey}
                                                inputProps={
                                                    { readOnly: true, }
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowKey}
                                                            onMouseDown={handleMouseDownKey}
                                                        >
                                                            {values.showKey ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>

                                                }
                                            />
                                        </p>
                                        <p><span><b style={{fontWeight: 600}}>SP Expiry Date: </b></span>{expiryDate}</p>
                                        <p><span><b style={{fontWeight: 600}}>Secondary Customer Enterprise ID: </b></span>{modalData.SecondaryCustomerEnterpriseID}</p>
                                        <p><span><b style={{fontWeight: 600}}>Status: </b></span>{modalData.Status}</p>
                                        <p><span><b style={{fontWeight: 600}}>Tenant ID: </b></span>{modalData.TenantID}</p>
                                        <p><span><b style={{fontWeight: 600}}>WBSE Code: </b></span>{modalData.WBSECode}</p>
                                        <div style={{ lineHeight: "1.6rem", marginTop: "-1%", marginBottom: "-1%" }}>
                                            <p><span>
                                                <b style={{fontWeight: 600}}>Deployment Details: </b>
                                                <span style={{ fontSize: "23px" }}>&#128071;</span>
                                            </span>
                                                <table style={{ width: "80%", textAlign: "center", border: "1px solid black", borderCollapse: "collapse", fontSize: "14px", margin: "10px 0px 20px" }}>
                                                    <thead style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                        <tr>
                                                            <th>Deployment ID</th>
                                                            <th>Model Name</th>
                                                            <th>Model Version</th>
                                                            <th>Model Capacity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {deploymentName.split(",").map((rowContent, rowID) => (
                                                            <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                                {rowContent.split("|").map((test, i) => (
                                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }} key={rowID}>{test}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <p style={{ color: "black"}}>Check your email for further details and instructions regarding your OpenAI access, including login credentials, account setup instructions, and any additional steps you need to take. </p><p style={{ color: "black"}}>Thank you!</p>
                                </Typography>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                }
                dialogActions={
                    <DialogActions>
                        <PrimaryButton
                            data-cy="dialogOkButton"
                            variant="outlined"
                            onClick={handleClose}
                            label={"Close"}
                            className={classes.requestOpenAIButton}
                        />
                    </DialogActions>
                }
            >
            </CommonDialog>
        </div>
    );
};

export default AccessCards;
