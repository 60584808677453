import momentTZ from "moment-timezone";
import { getTimezone } from "countries-and-timezones";

const getTimeZones = () => {
  /**
   * Gets all the avaliable timezones and adds the UTC offset next to them
   * @author Isabel Sprengers
   * @version 1.0
   * @see Used in component: TimePickerInformation.js
   */
  var timeZones = momentTZ.tz.names();
  for (var i = 0; i < timeZones.length; i++) {
    var zone = getTimezone(timeZones[i])
    // console.log(zone)
    if(zone == null){
      continue;
    }
    zone = zone.utcOffsetStr
    // console.log(zone)
    timeZones[i] = timeZones[i] + " (" + zone + " UTC)";
  }
  return timeZones;
};

export const timeZoneArr = getTimeZones();
