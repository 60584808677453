import React, { useState } from "react";
import { Table, TableCell, TableRow } from "@material-ui/core";

import { HoverTableRow } from "../ssh/SshStyles";

import { Subtext } from "../common/CommonStyles/CommonTypographyStyles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import TableHead from "@material-ui/core/TableHead";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import AdminInnerPermissionsTable from "./AdminInnerPermissionsTable";
import Chip from "@material-ui/core/Chip";
import { SecondaryButton } from "../common/CommonComponents/Buttons";
import EditGroupPermRolesDialog from "./EditGroupPermRolesDialog";
import DeleteAdGroupDialog from "./DeleteAdGroupDialog";

/**
 * Renders each Table row with the ec2 data.
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: SSHUserManagement
 */

const AdminGroupsTable = ({ data, classes, roles, permissions }) => {
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteAdOpenDialog, setDeleteAdOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDeleteAdClose = () => {
    setDeleteAdOpenDialog(false);
  };

  var dataPermissions = [];
  data["permissions"].forEach((perm) => {
    dataPermissions.push(findPermission(permissions, perm)[0]);
  });

  function findPermission(arr, searchKey) {
    return arr.filter((obj) =>
      Object.keys(obj).some((key) => obj[key].includes(searchKey))
    );
  }

  return (
    <React.Fragment>
      <HoverTableRow key={""}>
        <TableCell align="center">
          <Subtext>{data.ad_group_name}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext>{data.role_count}</Subtext>
        </TableCell>
        <TableCell align="center">
          <Subtext>{data.permissions_count}</Subtext>
        </TableCell>
        <TableCell key={""}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon data-cy="keyboardArrowUpIcon" />
            ) : (
              <KeyboardArrowDownIcon data-cy="keyboardArrowDownIcon" />
            )}
          </IconButton>
        </TableCell>
      </HoverTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>Roles</TableHead>
              <TableRow>
                <TableCell>
                  {data.cam_roles.map((role, index) => {
                    return <Chip label={role} variant="outlined" key={index} />;
                  })}
                </TableCell>
                <div style={{ float: "right", padding: "10px 0px" }}>
                  <SecondaryButton
                    label="Delete AD Group"
                    className={classes.createRoleButtonSecondary}
                    onClick={() => setDeleteAdOpenDialog(true)}
                  />
                  <SecondaryButton
                    label="Edit AD Group Roles"
                    className={classes.createRoleButtonSecondary}
                    onClick={() => setOpenDialog(true)}
                  />
                </div>
                {openDialog && (
                  <EditGroupPermRolesDialog
                    openDialog={openDialog}
                    handleClose={handleClose}
                    data={data}
                    roles={roles}
                  />
                )}
                {deleteAdOpenDialog && (
                  <DeleteAdGroupDialog
                    open={deleteAdOpenDialog}
                    handleClose={handleDeleteAdClose}
                    data={data}
                  />
                )}
              </TableRow>
            </Table>
            <Box margin={1}>
              <AdminInnerPermissionsTable data={dataPermissions} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

AdminGroupsTable.propTypes = {};

export default AdminGroupsTable;
