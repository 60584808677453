import React from "react";
import { Avatar } from "@material-ui/core";
import {
  StyledTypography,
  PlatformTitleWrapper,
  NoInstalledAppWrapper,
  NoUnderlineLink
} from "./FuiStyles";
import FeedbackIcon from "@material-ui/icons/Feedback";

/**
 * No installed apps on instance dialog
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: InstalledApps
 */
const NoInstalledApp = () => {
  return (
    <NoInstalledAppWrapper>
      <PlatformTitleWrapper>
        <Avatar>
          <FeedbackIcon />
        </Avatar>
        <StyledTypography variant="h8">
          There is NO app installed on this instance.{" "}
          <NoUnderlineLink to="/fui/order">
            Click to install
          </NoUnderlineLink>{" "}
        </StyledTypography>
      </PlatformTitleWrapper>
    </NoInstalledAppWrapper>
  );
};

export default NoInstalledApp;
