import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  StyledParagraphBold,
  TextWrapper,
  // , LongDivider
} from "../FuiStyles";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import * as FormField from "./IndividualFormFields";
import { BorderlessButton } from "../../common/CommonComponents/Buttons";
import { appNameProjectId } from "../Constants/ProductIds";
import { Divider } from "@material-ui/core";
import * as PlatformFormField from "./IndividualFormFields";
import * as fuiRedux from "../../../redux/fui/index";

const BundleOrderApps = ({
  classes,
  updateSPLUCLUForm,
  handleChange,
  config,
  values,
  updateForm,
  isMonthlyTicketVolumeValid,
  formName,
}) => {
  //TA Specific
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const handleCheckBoxChange = (fieldName) => {
    const valueInArray =
      values.StackApplication.filter((addon) => addon === `${fieldName}`)
        .length > 0;
    if (valueInArray) {
      let removedFromArray = values.StackApplication.filter(
        (addon) => addon !== `${fieldName}`
      );
      //console.log(removedFromArray)
      removedFromArray.sort();
      if(removedFromArray[0] == removedFromArray[removedFromArray.length -1]){
        removedFromArray = removedFromArray.filter(
          (addon) => addon !== "acn-alertcentre360"
        );
      }
     {/* if (
        fieldName === "acn-infra360host-addons-detailed-windows" ||
        fieldName === "acn-infra360host-addons-detailed-linux" ||
        fieldName === "acn-infra360host-ping" ||
        fieldName === "acn-hostprocess360-linux" ||
        fieldName === "acn-infra360host" ||
        fieldName === "acn-db360-oracledb" ||
        fieldName === "acn-db360-mssqlserver" ||
        fieldName === "acn-db360-mysql" ||
        fieldName === "acn-infra360host-addons-windows" ||
        fieldName === "acn-hostprocess360-windows" ||
        fieldName === "acn-hostservice360-services" ||
        fieldName === "acn-infra360snmp-traps" ||
        fieldName === "acn-infra360nnmi" ||
        fieldName === "acn-infra360solarwinds" ||
        fieldName === "acn-cloud360-addons-aws" ||
        fieldName === "acn-cloud360-addons-azure" ||
        fieldName === "acn-db360-postgresql" ||
        fieldName === "acn-url360-api"
      ) {
        removedFromArray = removedFromArray.filter(
          (addon) => addon !== "acn-alertcentre360"
        );
        console.log(removedFromArray);
      } */}
      if (
        fieldName === "acn-infra360host-addons-detailed-windows" ||
        fieldName === "acn-infra360host-addons-detailed-linux" 
      ) {
        removedFromArray = removedFromArray.filter(
          (addon) => addon !== "acn-infra360host"
        );
        removedFromArray = removedFromArray.filter(
          (addon) => addon !== "acn-alertcentre360"
        );
      }
      updateSPLUCLUForm("StackApplication", removedFromArray);
    } else if (
      fieldName === "acn-infra360host-addons-detailed-windows" ||
      fieldName === "acn-infra360host-addons-detailed-linux"
    ) {
      updateSPLUCLUForm("StackApplication", [
        ...values.StackApplication,
        `${fieldName}`,
        "acn-infra360host","acn-alertcentre360",
      ]);
    } else {
      updateSPLUCLUForm("StackApplication", [
        ...values.StackApplication,
        `${fieldName}`,
        "acn-alertcentre360",
      ]);
    }
  };


  const [displayCheckboxes, setDisplayCheckboxes] = React.useState(false);
  const [displayRadioButtons, setDisplayRadioButtons] = React.useState({
    ticketAnalysis: false,
    ticketAnalysisForManaged: false,
    sharepoint: false,
    devOpsAnalytics: false,
    reportExtractor: false,
    stormwatch: false,
    stack: false,
  });

  useEffect(() => {
    var res = [];
    displayRadioButtons.ticketAnalysis &&
      res.push(appNameProjectId.ticketAnalysisId);
    displayRadioButtons.ticketAnalysisForManaged &&
      res.push(appNameProjectId.ticketAnalysisId);
    displayRadioButtons.sharepoint &&
      res.push(appNameProjectId.sharepointAddOnId);
    displayRadioButtons.devOpsAnalytics &&
      res.push(appNameProjectId.devOpsAnalyticsId);
    displayRadioButtons.reportExtractor &&
      res.push(appNameProjectId.reportExtractorAddOnId);
    displayRadioButtons.stack &&
      res.push(appNameProjectId.stack360Id);
    displayRadioButtons.stormwatch && res.push(appNameProjectId.stormwatchId);
    updateForm("appProductUId", res);
    res.length > 0
      ? updateForm("bundledProv", true)
      : updateForm("bundledProv", false);
  }, [displayRadioButtons]);

  const handleRadioButtonChange = (event) => {
    setDisplayRadioButtons({
      ...displayRadioButtons,
      [event.target.name]: event.target.checked,
    });
  };

  const {
    ticketAnalysis,
    ticketAnalysisForManaged,
    sharepoint,
    devOpsAnalytics,
    reportExtractor,
    stormwatch,
    stack,
  } = displayRadioButtons;

  //DevOps Specific
  const [PlanAndScope, setPlanAndScope] = useState("");
  const [SourceManagement, setSourceManagement] = useState("");
  const [
    BuildAndDeploymentManagement,
    setBuildAndDeploymentManagement,
  ] = useState("");
  const [QualityManagement, setQualityManagement] = useState("");
  const [ServiceManagement, setServiceManagement] = useState("");
  const [DevSecOps, setDevSecOps] = useState("");

  const arr = new Array([]);
  PlanAndScope !== "" && arr.push(PlanAndScope);
  SourceManagement !== "" && arr.push(SourceManagement);
  BuildAndDeploymentManagement !== "" && arr.push(BuildAndDeploymentManagement);
  QualityManagement !== "" && arr.push(QualityManagement);
  DevSecOps !== "" && arr.push(DevSecOps);
  ServiceManagement !== "" && arr.push(ServiceManagement);

  const [isJiraSelectedByPlan, setIsJiraSelectedByPlan] = useState(false);
  const [isJiraSelectedByService, setIsJiraSelectedByService] = useState(false);

  const handleChangePlanAndScope = (event) => {
    setPlanAndScope(event.target.value);
    if (event.target.value === "Jira") {
      setIsJiraSelectedByPlan(true);
    }
    if (event.target.value !== "Jira") {
      setIsJiraSelectedByPlan(false);
    }
  };

  const handleChangeSourceManagement = (event) => {
    setSourceManagement(event.target.value);
  };
  const handleChangeBuildAndDeploymentManagement = (event) => {
    setBuildAndDeploymentManagement(event.target.value);
  };
  const handleChangeQualityManagement = (event) => {
    setQualityManagement(event.target.value);
  };
  const handleChangeServiceManagement = (event) => {
    setServiceManagement(event.target.value);
    if (event.target.value === "Jira") {
      setIsJiraSelectedByService(true);
    }
    if (event.target.value !== "Jira") {
      setIsJiraSelectedByService(false);
    }
  };

  const handleChangeDevSecOps = (event) => {
    setDevSecOps(event.target.value);
  };

  const DevOpsAddonsGroup = arr.join(",");

  useEffect(() => {
    updateForm("DevOpsAddonsGroup", DevOpsAddonsGroup);
  }, [arr]);

  return (
    <div style={{ float: "left" }}>
      <BorderlessButton
        label={
          (displayCheckboxes ? "-" : "+") +
          " Configure Splunk Application"
        }
        className={classes.FuiBorderlessButton}
        onClick={() => setDisplayCheckboxes(!displayCheckboxes)}
      ></BorderlessButton>
      
      { displayCheckboxes && (() => {
        if (formName === "dedicated") {
          return (
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{paddingTop:"20px"}}>
                Configure Splunk Application
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  style={{paddingTop:"10px"}}
                  control={
                    <Checkbox
                      checked={ticketAnalysis}
                      onChange={handleRadioButtonChange}
                      name="ticketAnalysis"
                    />
                  }
                  label="Ticket Analysis"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={sharepoint}
                      onChange={handleRadioButtonChange}
                      name="sharepoint"
                    />
                  }
                  label="Sharepoint"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={devOpsAnalytics}
                      onChange={handleRadioButtonChange}
                      name="devOpsAnalytics"
                    />
                  }
                  label="DevOps Analytics"
                /> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={reportExtractor}
                      onChange={handleRadioButtonChange}
                      name="reportExtractor"
                    />
                  }
                  label="Report Extractor"
                /> */}
                {/* 
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stormwatch}
                      onChange={handleRadioButtonChange}
                      name="stormwatch"
                    />
                  }
                  label="StormWatch"
                /> */}
              </FormGroup>
            </FormControl>
          )
        } else if (formName === "splunkcluster") {
          return (
            <FormControl component="fieldset">
                <FormLabel component="legend" style={{paddingTop:"20px"}}>
                  Configure Splunk Application
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    style={{paddingTop:"10px"}}
                    control={
                      <Checkbox
                        checked={stack}
                        onChange={handleRadioButtonChange}
                        name="stack"
                        //disabled={true} 
                        //checked={true} 
                        //label={"Disabled checked"}
                      />
                    }
                    label="Stack"
                  />
                </FormGroup>
              </FormControl>
          )
        } else {
            return (
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{paddingTop:"20px"}}>
                  Configure Splunk Application
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    style={{paddingTop:"10px"}}
                    control={
                      <Checkbox
                        checked={ticketAnalysisForManaged}
                        onChange={handleRadioButtonChange}
                        name="ticketAnalysisForManaged"
                      />
                    }
                    label="Ticket Analysis"
                  />
                </FormGroup>
              </FormControl>
            )}
      })()}
      {displayRadioButtons.ticketAnalysis && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>Ticket Analysis Details:</StyledParagraphBold>
          </TextWrapper>
          {FormField.TicketFeedMethodField(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowURlField(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowUsernameField(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowPasswordField(
              handleChange,
              values,
              showPassword,
              handleShowPassword
            )}

          {FormField.MonthlyTicketVolumeField(
            handleChange,
            values,
            isMonthlyTicketVolumeValid
          )}
        </div>
      )}
      {displayRadioButtons.ticketAnalysisForManaged && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>Ticket Analysis Details:</StyledParagraphBold>
          </TextWrapper>
          {FormField.TicketFeedMethodFieldForManagedPlatform(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowURlField(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowUsernameField(handleChange, values)}

          {values.TicketFeed === "availbleOverServiceNowAPI" &&
            FormField.ServiceNowPasswordField(
              handleChange,
              values,
              showPassword,
              handleShowPassword
            )}

          {FormField.MonthlyTicketVolumeField(
            handleChange,
            values,
            isMonthlyTicketVolumeValid
          )}
        </div>
      )}

      {displayRadioButtons.sharepoint && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>Sharepoint Details:</StyledParagraphBold>
          </TextWrapper>
          Nothing additional to fill out here
        </div>
      )}
      {displayRadioButtons.devOpsAnalytics && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>DevOps Details:</StyledParagraphBold>
          </TextWrapper>
          {FormField.PlanAndScopeField(
            handleChangePlanAndScope,
            PlanAndScope,
            isJiraSelectedByService,
            SourceManagement !== "Webservice" &&
              BuildAndDeploymentManagement !== "Webservice" &&
              QualityManagement !== "Webservice" &&
              ServiceManagement !== "Webservice"
          )}

          {FormField.SourceManagementField(
            handleChangeSourceManagement,
            SourceManagement,
            PlanAndScope !== "Webservice" &&
              BuildAndDeploymentManagement !== "Webservice" &&
              QualityManagement !== "Webservice" &&
              ServiceManagement !== "Webservice"
          )}

          {FormField.BuildAndDeploymentField(
            handleChangeBuildAndDeploymentManagement,
            BuildAndDeploymentManagement,
            SourceManagement !== "Webservice" &&
              PlanAndScope !== "Webservice" &&
              QualityManagement !== "Webservice" &&
              ServiceManagement !== "Webservice"
          )}

          {FormField.QualityManagementField(
            handleChangeQualityManagement,
            QualityManagement,
            SourceManagement !== "Webservice" &&
              BuildAndDeploymentManagement !== "Webservice" &&
              PlanAndScope !== "Webservice" &&
              ServiceManagement !== "Webservice"
          )}

          {FormField.ServiceManagementField(
            handleChangeServiceManagement,
            ServiceManagement,
            isJiraSelectedByPlan,
            SourceManagement !== "Webservice" &&
              BuildAndDeploymentManagement !== "Webservice" &&
              QualityManagement !== "Webservice" &&
              PlanAndScope !== "Webservice"
          )}

          {/* {FormField.DevSecOpsField(handleChangeDevSecOps, DevSecOps)} */}
        </div>
      )}
      {displayRadioButtons.reportExtractor && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>Report Extractor Details:</StyledParagraphBold>
          </TextWrapper>
          Nothing additional to fill out here
        </div>
      )}
      {displayRadioButtons.stormwatch && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>Stormwatch Details:</StyledParagraphBold>
          </TextWrapper>
          Nothing additional to fill out here
        </div>
      )}
      {displayRadioButtons.stack && (
        <div>
          <TextWrapper>
            <StyledParagraphBold>List of Stack Applications:</StyledParagraphBold>
          </TextWrapper>
          <StyledParagraphBold>OS Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360HostDetailedLinuxCheckbox(
              values,
              handleCheckBoxChange
            )}

            {/*PlatformFormField.Infra360HostDetailedWindowsCheckbox(
              values,
              handleCheckBoxChange
            )*/}

            {PlatformFormField.HostProcess360LinuxCheckbox(
              values,
              handleCheckBoxChange
            )}

            {/*PlatformFormField.HostProcess360WindowsCheckbox(
              values,
              handleCheckBoxChange
            )*/}

            {PlatformFormField.Infra360HostCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>DB Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.DB360OracleDBCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.DB360MSSQLServerCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.DB360MYSQLCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>VM Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360HostDetailedWindowsCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.HostProcessWindowsCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.HostService360Checkbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Host Ping</StyledParagraphBold>

            <Divider />

            {PlatformFormField.HostPingCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Hardware Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360SNMPTrapsCheckbox(
              values,
              handleCheckBoxChange
            )}

            

            <StyledParagraphBold>Alerting</StyledParagraphBold>

            <Divider />

            {PlatformFormField.AlertCentreCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>Network Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Infra360NNMiCheckbox(
              values,
              handleCheckBoxChange
            )} 

            {PlatformFormField.Infra360SolarWindsCheckbox(
              values,
              handleCheckBoxChange
            )}


            <StyledParagraphBold>Cloud Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.Cloud360AWSCheckbox(
              values,
              handleCheckBoxChange
            )}

            {PlatformFormField.Cloud360AzureCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>DB360 Postgre SQL</StyledParagraphBold>

            <Divider />

            {PlatformFormField.DB360PostgresSQLCheckbox(
              values,
              handleCheckBoxChange
            )}

            <StyledParagraphBold>URL Monitoring</StyledParagraphBold>

            <Divider />

            {PlatformFormField.URL360APICheckbox(
              values,
              handleCheckBoxChange
            )}
        </div>
      )}
    </div>
  );
};
const MSTP = (state, ownProps) => ({
  dpState: state.fui.DPForm,
  values: state.fui.SPLUCLUForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
});

export default connect(MSTP, {
  updateSPLUCLUForm: fuiRedux.updateSPLUCLUForm,
  updateASelectedInstanceByField: fuiRedux.updateASelectedInstanceByField,
  checkDomainAvaliable: fuiRedux.checkRootExisted,
})(withRouter(BundleOrderApps));
