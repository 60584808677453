import styled from "styled-components";


export const StyledSidebarItemsWrapper = styled.div`
  position: absolute;
  padding: 1em;
  /* padding: .6em; */
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const StyledSidebarItems = styled.div`
  padding: 1em;
`

