import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { PlatformDetailsWrapper } from "../FuiStyles";
import InstalledApps from "../AppDetailLists/InstalledApps";
import PlatformDetails from "../PlatformDetailLists/PlatformDetails";
import InstalledAppsDetails from "../AppDetailLists/InstalledAppsDetails";

import { SecondaryButton } from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
const DetailsDialog = ({
  open,
  handleClose,
  scroll,
  rowsReal,
  currentOrderId,
  classes,
  dialogName,
}) => {
  const mapPayloadToADetail = (originData, orderId) => {
    if (isEmpty(originData) || !orderId) {
      return [];
    } else {
      const aOrderItem = originData.find(
        (pieceData) => orderId === pieceData.OrderUId
      );
      return aOrderItem;
    }
  };

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      title={
        dialogName === "apps"
          ? "Installed Apps"
          : dialogName === "platform"
          ? "Platform Details"
          : "Installed Apps Details"
      }
      dialogType={"fui"}
      dialogContent={
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText>
            <PlatformDetailsWrapper>
              {dialogName === "apps" && (
                <InstalledApps
                  data={mapPayloadToADetail(rowsReal, currentOrderId)}
                  classes={classes}
                />
              )}
              {dialogName === "platform" && (
                <PlatformDetails
                  data={mapPayloadToADetail(rowsReal, currentOrderId)}
                />
              )}
              {dialogName === "appDetail" && (
                <InstalledAppsDetails
                  data={mapPayloadToADetail(rowsReal, currentOrderId)}
                />
              )}
            </PlatformDetailsWrapper>
          </DialogContentText>
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <SecondaryButton
            data-cy="closeInstalledAppsDialog"
            onClick={handleClose}
            label={"Close"}
            className={classes.FuiSecondaryButton}
          />
        </DialogActions>
      }
    ></CommonDialog>
  );
};

const MSTP = (state, ownProps) => ({
  installedApps: state.fui.instanceData,
  fetchInstalledApps: state.fui.fetchInstalledApps,
});

export default connect(MSTP)(DetailsDialog);
