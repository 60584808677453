import * as schedulerTypes from "./schedulerTypes";
import base from "../../config";
import axios from "axios";
import { headers } from "../../helper/api";

const sshConfigUrl = `${base.urls.apis.sshConfig}sshConfig`;
const bastionHostUrl = `${base.urls.apis.sshConfig}bastion-host`;

const options = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token"),
  },
};

export const fetchServersUserInstances = (EID, screenName = "") => (
  dispatch,
  getState
) => {
  // dispatch({ type: START_LOADING });
  dispatch({ type: schedulerTypes.FETCH_EC2_INSTANCES_BY_USER_ID_REQUEST });
  const newEndpoint = `${base.urls.apis.ec2}`;
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${newEndpoint}?customer_enterprise_id=${EID}&inititive=${activeAccount}&page_size=999&page_number=1`;
  if (EID) {
    axios
      .get(url2, options)
      .then((res) => {
        const { data } = res;
        dispatch({ type: "IS_LOADING" });
        dispatch({
          type: "FETCH_EC2_INSTANCES_BY_USER_ID_SUCCESS",
          payload: data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
};

export const fetchSSHConfig = (email, inititive, accountId) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;
  var res = "";
  res = inititive === "MCC" ? "ssh_config_mcc.txt" : "ssh_config.txt";
  dispatch(changeLoadingStatus());
  return axios
    .get(sshConfigUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eId,
        inititive: activeAccount,
        resource_name: res,
        accountId: accountId,

      },
    })
    .then((res) => {
      dispatch({ type: schedulerTypes.SSH_CONFIG_URL, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
    });
};

export const fetchBastionHost = (email, regionName, accountName) => (
  dispatch,
  getState
) => {
  const idToken = localStorage.getItem("token");
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  dispatch(changeLoadingStatus());
  return axios
    .get(bastionHostUrl, {
      headers: { ...headers, Authorization: idToken },
      params: {
        customer_enterprise_id: eId,
        region_name: regionName,
        account_name: accountName,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: schedulerTypes.BASTION_HOST, data: res.data });
    })
    .catch((err) => {
      console.log("Async error", err);
    });
};

// const fetchUserInstancesRequest = () => ({
//   type: schedulerTypes.FETCH_EC2_INSTANCES_BY_USER_ID_REQUEST,
// });

// const fetchUserInstancesSuccess = (instances) => ({
//   type: schedulerTypes.FETCH_EC2_INSTANCES_BY_USER_ID_SUCCESS,
//   payload: instances,
// });

// const fetchUserInstancesFailure = (message) => ({
//   type: schedulerTypes.FETCH_EC2_INSTANCES_BY_USER_ID_FAILURE,
//   errMessage: message,
//   variant: "error",
// });

export const editTags = (data, id) => ({
  type: schedulerTypes.EDIT_TAGS,
  data,
  id,
});

export const changeLoadingStatus = () => ({
  type: schedulerTypes.CHANGE_IS_LOADING,
});

export const searchFilterValueAction = (data) => ({
  type: schedulerTypes.SEARCH_FILTER_VALUE,
  data,
});

export const dropdownScheudleFilterValueAction = (data) => ({
  type: schedulerTypes.DROPDOWN_SCHEDULE_FILTER_VALUE,
  data,
});

export const reduxOpenStateAction = (index, instanceId) => ({
  type: schedulerTypes.REDUX_OPEN_STATE,
  index,
  instanceId,
});

export const setReduxOpenStateAction = (data) => ({
  type: schedulerTypes.SET_REDUX_OPEN_STATE,
  data,
});

export const setRemovedElementReduxOpenStateAction = (data) => ({
  type: schedulerTypes.REMOVE_ELEMENT_REDUX_OPEN_STATE,
  data,
});

export const dropdownFiltersServersAction = (state) => ({
  type: schedulerTypes.DROPDOWN_FILTERS_SERVERS,
  state,
});

export const isCheckboxServiceEndDateSelectedAction = (isSelected) => ({
  type: schedulerTypes.IS_CHECKBOX_SERVICE_END_DATE_SELECTED,
  isSelected,
});
