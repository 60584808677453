import React, { useState, useEffect, Fragment } from "react";

// Material UI
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Radio,
  TextField,
  InputLabel,
  FormControl,
  NativeSelect,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

// Styled components
import {
  ButtonWrapper,
  StyledCheckbox,
  SchedulerWrapper,
  StyledRadioGroup,
  TimePickerWrapper,
  PickerDialogContent,
  TimerMessageErrorFormat,
} from "../StyledTimePicker";

// Components
import Axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { timeZoneArr } from "../TimePickerActions/AvailableTimeZone";
import { tokenValidation } from "../../../../helper/customizedHooks";
import base from "../../../../config";
import {
  AcceptButton,
  DeclineButton,
} from "../../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../../common/CommonComponents/Dialog";
import { setLoadingByClick } from "../../../../redux/ServersReducer/ServersActions";
import momentTZ from "moment-timezone";

/**
 * In the EditSchedule the user should modify the schedule for an instance.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: EditSchedule.js
 */

export const EditSchedule = ({
  item,
  currentInstanceState,
  email,
  loadingIconStatusBool,
  instancesNewTags,
  idToken,
  setHandleApiCall,
  setOpenEditSchedule,
  openEditSchedule,
  setLoadingByClick,
}) => {
  const [newTimeStart, setNewTimeStart] = useState("");
  const [newTimeEnd, setNewTimeEnd] = useState("");
  const [newTimeZone, setNewTimeZone] = useState("");
  const [newSchedulerStatus, setNewSchedulerStatus] = useState("");

  const [lastTimeStart, setLastTimeStart] = useState();
  const [lastTimeEnd, setLastTimeEnd] = useState();
  const [lastTimeZone, setLastTimeZone] = useState();
  const [lastSchedulerStatus, setLastSchedulerStatus] = useState();

  const [currentStartHour, setCurrentStartHour] = useState("");
  const [currentEndHour, setCurrentEndHour] = useState("");
  const [currentTimeZone, setCurrentTimeZone] = useState("");
  const [currentSchedulerStatus, setCurrentSchedulerStatus] = useState("");

  const [open, setOpen] = useState(false);
  const [timeZoneId, setTimeZoneId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [, setNewTimeZoneId] = useState("");
  const [timePickerState, setTimePickerState] = useState(true);

  const [correctTimeStartFormat, setCorrectTimeStartFormat] = useState(true);
  const [correctTimeEndFormat, setCorrectTimeEndFormat] = useState(true);
  const [saveStateForIEMPServers, setSaveStateForIEMPServers] = useState(true);
  const [saveStateForCoreServers, setSaveStateForCoreServers] = useState(true);
  const [openConfirmationScheduleForCoreServers, setOpenConfirmationScheduleForCoreServers] = useState(false);
  const [openConfirmationScheduleForIEMPServers, setOpenConfirmationScheduleForIEMPServers] = useState(false);
  const [saveState, setSaveState] = useState(true);

  useEffect(() => {
    if (item) {
      let [startHour, endHour, timeZone, schedulerStatus] =
        "ec2Scheduler" in item ? item.ec2Scheduler.split(";") : [];
      // let [startHour, endHour, timeZone, schedulerStatus] = '0500;2300;Asia/Calcutta;all'.split(";");
      let setStartHour =
        startHour && startHour.length > 0
          ? `${startHour.substr(0, 2)}:${startHour.substr(2, 4)}`
          : "";
      let setEndHour =
        endHour && endHour.length > 0
          ? `${endHour.substr(0, 2)}:${endHour.substr(2, 4)}`
          : "";

      let timeZones = momentTZ.tz.names();
      let setTimeZone = timeZones.indexOf(timeZone);

      let setScheduleStatus = schedulerStatus ? schedulerStatus : "";
      if (
        startHour !== undefined &&
        endHour !== undefined &&
        timeZone !== undefined &&
        schedulerStatus !== undefined
      ) {
        setNewTimeStart(startHour);
        setNewTimeEnd(endHour);
        setNewTimeZone(timeZone);
        setNewSchedulerStatus(schedulerStatus);
        setLastTimeStart(startHour);
        setLastTimeEnd(endHour);
        setLastTimeZone(timeZone);

        setLastSchedulerStatus(schedulerStatus);
        setCurrentStartHour(setStartHour);
        setCurrentEndHour(setEndHour);
        setCurrentTimeZone(setTimeZone);
        setZoneId(setTimeZone);
        setCurrentSchedulerStatus(setScheduleStatus);
      }
      if (setStartHour && setEndHour && setTimeZone && setScheduleStatus) {
        setCurrentStartHour(setStartHour);
        setCurrentEndHour(setEndHour);
        setCurrentTimeZone(setTimeZone);
        setCurrentSchedulerStatus(setScheduleStatus);
      } else {
        setCurrentStartHour("");
        setCurrentEndHour("");
        setCurrentTimeZone("");
        setCurrentSchedulerStatus("");
        setNewTimeStart("");
        setNewTimeEnd("");
        setNewTimeZone("");
        setNewSchedulerStatus("");
      }
    }
  }, [item, instancesNewTags]);

  useEffect(() => {
    currentTimeZone && setTimeZoneId(currentTimeZone);
  }, [currentTimeZone, instancesNewTags]);

  useEffect(() => {
    if (newSchedulerStatus === "remove_schedule") {
      setTimePickerState(false);
      setSaveStateForIEMPServers(false);
    } else if (
      lastTimeStart === newTimeStart &&
      lastTimeEnd === newTimeEnd &&
      lastTimeZone === newTimeZone &&
      lastSchedulerStatus === newSchedulerStatus
    ) {
      setTimePickerState(true);
    } else if (
      newTimeStart === "" ||
      newTimeEnd === "" ||
      newTimeZone === "" ||
      newSchedulerStatus === ""
    ) {
      setTimePickerState(true);
    } else if (
      newTimeStart.length > 0 &&
      newTimeEnd.length > 0 &&
      newTimeZone.length > 0 &&
      newSchedulerStatus.length > 0
    ) {
      let startType00 = newTimeStart[2] === "0" && newTimeStart[3] === "0";
      let startType30 = newTimeStart[2] === "3" && newTimeStart[3] === "0";
      let endType00 = newTimeEnd[2] === "0" && newTimeEnd[3] === "0";
      let endType30 = newTimeEnd[2] === "3" && newTimeEnd[3] === "0";
      if (startType00 && endType00) {
        setTimePickerState(false);
      } else if (startType00 && endType30) {
        setTimePickerState(false);
      } else if (startType30 && endType00) {
        setTimePickerState(false);
      } else if (startType30 && endType30) {
        setTimePickerState(false);
      } else {
        setTimePickerState(true);
      }
    }
    if (newTimeStart) {
      let startType00 =
        newTimeStart &&
        newTimeStart[2] === "0" &&
        newTimeStart &&
        newTimeStart[3] === "0";
      let startType30 =
        newTimeStart &&
        newTimeStart[2] === "3" &&
        newTimeStart &&
        newTimeStart[3] === "0";
      if (startType00) {
        setCorrectTimeStartFormat(true);
      } else if (startType30) {
        setCorrectTimeStartFormat(true);
      } else {
        setCorrectTimeStartFormat(false);
      }
    }
    if (newTimeEnd) {
      let endType00 =
        newTimeEnd &&
        newTimeEnd[2] === "0" &&
        newTimeEnd &&
        newTimeEnd[3] === "0";
      let endType30 =
        newTimeEnd &&
        newTimeEnd[2] === "3" &&
        newTimeEnd &&
        newTimeEnd[3] === "0";
      if (endType00) {
        setCorrectTimeEndFormat(true);
      } else if (endType30) {
        setCorrectTimeEndFormat(true);
      } else {
        setCorrectTimeEndFormat(false);
      }
    }
  }, [newTimeStart, newTimeEnd, newTimeZone, newSchedulerStatus]);

  const handleStartHour = (e) => {
    const value = e.target.value;
    setNewTimeStart(value.split(":").join(""));
  };
  const handleEndHour = (e) => {
    const value = e.target.value;
    setNewTimeEnd(value.split(":").join(""));
  };
  const handleTimeZone = (e) => {
    const { value } = e.target;
    setNewTimeZone(timeZoneArr[value]);
    setTimeZoneId(value);
    setNewTimeZoneId(value);
  };

  const handleIEMPServersTimeZone = (e) => {
    setSaveStateForIEMPServers(false);
    const { value } = e.target;
    setNewTimeZone(e.target.value);
  };

  const handleCoreServersTimeZone = (e) => {
    setSaveStateForCoreServers(false);
    const { value } = e.target;
    setNewTimeZone(e.target.value);
  };

  const handleClickOpenConfirmationScheduleForCoreServers = () => {
    setOpenConfirmationScheduleForCoreServers(true);
  };

  const handleClickOpenConfirmationScheduleForIEMPServers = () => {
    setOpenConfirmationScheduleForIEMPServers(true);
  };

  const handleSchedulerStatus = (e) => {
    const { value } = e.target;
    setNewSchedulerStatus(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseConfirmationScheduleForCoreServers = () => {
    setOpenConfirmationScheduleForCoreServers(false);
  };

  const handleCloseConfirmationScheduleForIEMPServers = () => {
    setOpenConfirmationScheduleForIEMPServers(false);
  }

  const timeFormat = (time) => {
    if (time !== undefined) {
      let splitTime = time.substr(0, 2);
      let newTimeFormat =
        time.substr(0, 2) === "00"
          ? `12:${time.substr(2, 2)} AM`
          : time.substr(0, 2) < 12
            ? `${time.substr(0, 2)}:${time.substr(2, 2)} AM`
            : time.substr(0, 2) === "12"
              ? `12:${time.substr(2, 2)} PM`
              : ` ${time.substr(0, 2) > 12 && time.substr(0, 2) < 22
                ? `0${parseInt(splitTime, 10) - 12}:${time.substr(2, 2)} PM`
                : `${parseInt(splitTime, 10) - 12}:${time.substr(2, 2)} PM`
              }`;
      return newTimeFormat;
    } else {
      return false;
    }
  };

  const handleCloseSchedule = () => {
    setNewTimeStart(lastTimeStart);
    setNewTimeEnd(lastTimeEnd);
    setNewTimeZone(lastTimeZone);
    setTimeZoneId(zoneId);
    setNewTimeZoneId(zoneId);
    setNewSchedulerStatus(lastSchedulerStatus);
    setOpenEditSchedule(false);
    setHandleApiCall(true);
  };

  const url = `${base.urls.apis.ec2}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const handleSaveForCoreServers = (accountType, privateDNS, initiative, accountId) => {
    tokenValidation();
    const action = `schedule?userepid=${email}&timezone=${newTimeZone}&schedule_type=set&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
    const urls = `${url}/${item.instanceId}/${action}`;
    setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);
    Axios.get(urls, options)
      .then(function(res) {
        console.log("RES", res);
      })
      .catch(function(error) {
        console.log("ERROR", error);
      });
    setOpenConfirmationScheduleForCoreServers(false);
    setSaveState(true);
    setOpenEditSchedule(false);
    setHandleApiCall(true);
  };

  const handleSaveForIEMPServers = (accountType, privateDNS, initiative, accountId) => {
    tokenValidation();
    
    if (newSchedulerStatus === "remove_schedule") {
      const action = `remove_schedule?userepid=${email}&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
      const urls = `${url}/${item.instanceId}/${action}`;
      
      Axios.get(urls, options)
        .then(function (response) {
          console.log("RES", response);
         })
        .catch(function (error) {
          console.log("ERROR", error);
        });
      // setLastTimeStart("");
      // setLastTimeEnd("");
      // setLastTimeZone("");
      // setLastSchedulerStatus("");
      setOpenConfirmationScheduleForIEMPServers(false);
      setSaveStateForIEMPServers(true);
      setOpenEditSchedule(false);
      setHandleApiCall(true);
      // setOpen(false);
      
    } else {
      const action = `schedule?userepid=${email}&timezone=${newTimeZone}&schedule_type=set&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
      const urls = `${url}/${item.instanceId}/${action}`;
      
      setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);
      Axios.get(urls, options)
        .then(function (res) {
          console.log("RES", res);
        })
        .catch(function (error) {
          console.log("ERROR", error);
        });
      setOpenConfirmationScheduleForIEMPServers(false);
      setSaveState(true);
      setOpenEditSchedule(false);
      setHandleApiCall(true);
    }
  };

  const handleSave = (accountType, privateDNS, initiative, accountId) => {
    tokenValidation();
    setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);

    if (newSchedulerStatus === "remove_schedule") {
      const action = `remove_schedule?userepid=${email}&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
      const urls = `${url}/${item.instanceId}/${action}`;
      Axios.get(urls, options)
        .then(function(response) {})
        .catch(function(error) {
          console.log("ERROR", error);
        });
      setLastTimeStart("");
      setLastTimeEnd("");
      setLastTimeZone("");
      setLastSchedulerStatus("");
      setOpen(false);
      setTimePickerState(true);
      setOpenEditSchedule(false);
      setHandleApiCall(true);
    } else {
      const action = `schedule?userepid=${email}&start_time=${newTimeStart}&stop_time=${newTimeEnd}&timezone=${newTimeZone.split(" ")[0]
        }&schedule_day=${newSchedulerStatus}&schedule_type=edit&accountType=${accountType}&accountId=${accountId}&inititive=${initiative}`;
      const urls = `${url}/${item.instanceId}/${action}`;
      Axios.get(urls, options)
        .then(function(response) {})
        .catch(function(error) {
          console.log("ERROR", error);
        });
      setLastTimeStart(newTimeStart);
      setLastTimeEnd(newTimeEnd);
      setLastTimeZone(newTimeZone);
      setLastSchedulerStatus(newSchedulerStatus);
      setOpen(false);
      setTimePickerState(true);
      setOpenEditSchedule(false);
      setHandleApiCall(true);
    }
  };

  let displayDialogContent
  if (item.Initiative === "mywizard.techarch.iemp") {
    if (item.accountId === "004439949415") {
      displayDialogContent =
        < PickerDialogContent padding={"0px 20px 20px 20px"} >
          <div>
            <StyledCheckbox>
              <FormControl component="fieldset">
                <StyledRadioGroup
                  aria-label="speak"
                  name="position"
                  onChange={(e) => handleSchedulerStatus(e)}
                  defaultValue={currentSchedulerStatus}
                  row
                >
                  <FormControlLabel
                    value="remove_schedule"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        editremoveschedulecy="editRemoveScheduleCy"
                        color="primary"
                      />
                    }
                    label="Do not schedule"
                  />
                </StyledRadioGroup>
              </FormControl>
            </StyledCheckbox>
            <FormControl>
              <InputLabel shrink>Time Zone</InputLabel>
              <NativeSelect
                native="true"
                onChange={(e) => handleIEMPServersTimeZone(e)}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                inputProps={{ name: "timeZone", id: "timeZone" }}
              >
                <option value="">--Select desired scheduler option--</option>
                <option value="iemp-dev-schedule-weekdays-11AM-5PM">iemp-dev-schedule-weekdays-11AM-5PM</option>
                <option value="iemp-prod-schedule-weekdays">iemp-prod-schedule-weekdays</option>
                <option value="iemp-schedule-weekdays-7:30AM-11PM">iemp-schedule-weekdays-7:30AM-11PM</option>
              </NativeSelect>
            </FormControl>
          </div>
        </PickerDialogContent>
    } else {
      displayDialogContent =
        < PickerDialogContent padding={"0px 20px 20px 20px"} >
          <div>
            <StyledCheckbox>
              <FormControl component="fieldset">
                <StyledRadioGroup
                  aria-label="speak"
                  name="position"
                  onChange={(e) => handleSchedulerStatus(e)}
                  defaultValue={currentSchedulerStatus}
                  row
                >
                  <FormControlLabel
                    value="remove_schedule"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        editremoveschedulecy="editRemoveScheduleCy"
                        color="primary"
                      />
                    }
                    label="Do not schedule"
                  />
                </StyledRadioGroup>
              </FormControl>
            </StyledCheckbox>
            <FormControl>
              <InputLabel shrink>Time Zone</InputLabel>
              <NativeSelect
                native="true"
                onChange={(e) => handleIEMPServersTimeZone(e)}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                inputProps={{ name: "timeZone", id: "timeZone" }}
              >
                <option value="">--Select desired scheduler option--</option>
                <option value="iemp-schedule-weekdays">iemp-schedule-weekdays</option>
                <option value="iemp-custom-schedule-weekdays-11AMto5PM">iemp-custom-schedule-weekdays-11AMto5PM</option>
              </NativeSelect>
            </FormControl>
          </div>
        </PickerDialogContent>
    }
  } else if (item.Initiative === "mywizard.techarch.core") {
    displayDialogContent =
      <PickerDialogContent padding={"0px 20px 20px 20px"}>
        <div>
          <FormControl>
            <InputLabel shrink>Time Zone</InputLabel>
            <NativeSelect
              native="true"
              onChange={(e) => handleCoreServersTimeZone(e)}
              disabled={
                currentInstanceState === "terminated" ||
                  newSchedulerStatus === "remove_schedule"
                  ? true
                  : loadingIconStatusBool
              }
              inputProps={{ name: "timeZone", id: "timeZone" }}
            >
              <option value="">--Select desired scheduler option--</option>
              <option value="06-21-schedule">06-21-schedule</option>
              <option value="06-22-uptime-hours">06-22-uptime-hours</option>
              <option value="06-23-schedule">06-23-schedule</option>
              <option value="06-23-schedule-mon-sat">06-23-schedule-mon-sat</option>
              <option value="06-23-schedule-weekdays">06-23-schedule-weekdays</option>
              <option value="08-22-schedule-mon-sat">08-22-schedule-mon-sat</option>
              <option value="08-22-schedule-weekdays">08-22-schedule-weekdays</option>
              <option value="7.30-21-schedule weekdays">7.30-21-schedule weekdays</option>
              <option value="7.30-22-schedule">7.30-22-schedule</option>
              <option value="7.30-22-schedule-mon-sat">7.30-22-schedule-mon-sat</option>
              <option value="7.30-22-schedule-weekdays">7.30-22-schedule-weekdays</option>
              <option value="7.30-23-schedule-weekdays">7.30-23-schedule-weekdays</option>
              <option value="alc-10-21-schedule">alc-10-21-schedule</option>
              <option value="alc-7-23-schedule">alc-7-23-schedule</option>
              <option value="alc-7-23-schedule-weekdays">alc-7-23-schedule-weekdays</option>
              <option value="alc-9-1-schedule">alc-9-1-schedule</option>
              <option value="lifecycle-automation-schedule">lifecycle-automation-schedule</option>
              <option value="myWizard-SaaS-nonprod-schedule-1am-6am">myWizard-SaaS-nonprod-schedule-1am-6am</option>
              <option value="myWizard-SaaS-nonprod-schedule-1am-6am mon-sat ">myWizard-SaaS-nonprod-schedule-1am-6am mon-sat </option>
              <option value="mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679">mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679</option>
              <option value="mywizard-SaaS-schedule-9am-9pm">mywizard-SaaS-schedule-9am-9pm</option>
              <option value="mywizard-schedule-9am-9pm weekdays">mywizard-schedule-9am-9pm weekdays</option>
              <option value="schedule-6am-7.30am-alldays">schedule-6am-7.30am-alldays</option>
              <option value="schedule-6am-9am-alldays">schedule-6am-9am-alldays</option>
              <option value="schedule-9am-11am-alldays">schedule-9am-11am-alldays</option>
            </NativeSelect>
          </FormControl>
        </div>
      </PickerDialogContent>
  } else {
    displayDialogContent =
      <PickerDialogContent padding={"0px 12px 8px"}>
        <ButtonWrapper paddingLeft={"10px"}>
          <div>
            <StyledCheckbox>
              <FormControl component="fieldset">
                <StyledRadioGroup
                  aria-label="speak"
                  name="position"
                  onChange={(e) => handleSchedulerStatus(e)}
                  defaultValue={currentSchedulerStatus}
                  row
                >
                  <FormControlLabel
                    value="weekdays"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        editweekdaysradiocy="editWeekdaysRadioCy"
                        color="primary"
                      />
                    }
                    label="Weekdays"
                  />
                  <FormControlLabel
                    value="all"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        editalldaysradiocy="editAlldaysRadioCy"
                        color="primary"
                      />
                    }
                    label="All Days"
                  />
                  <FormControlLabel
                    value="remove_schedule"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        editremoveschedulecy="editRemoveScheduleCy"
                        color="primary"
                      />
                    }
                    label="Do not schedule"
                  />
                </StyledRadioGroup>
              </FormControl>
            </StyledCheckbox>
          </div>
        </ButtonWrapper>

        <TimePickerWrapper padding={"14px"} paddingLeft={"10px"}>
          <FormControl>
            <InputLabel shrink>Time Zone</InputLabel>
            <NativeSelect
              native="true"
              disabled={
                currentInstanceState === "terminated" ||
                  newSchedulerStatus === "remove_schedule"
                  ? true
                  : loadingIconStatusBool
              }
              value={timeZoneId}
              onChange={(e) => handleTimeZone(e)}
              inputProps={{ name: "timeZone", id: "timeZone" }}
            >
              <option value="">-- Select A Time Zone --</option>
              {timeZoneArr.map((item, index) => (
                <option key={index} value={index}>
                  {item}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <Fragment>
            <form noValidate>
              <TextField
                type="time"
                label="Time Start"
                name={"startHour"}
                defaultValue={currentStartHour}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800,
                  edittimestartcy: "editTimeStartCy",
                }}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                onChange={handleStartHour}
              />
            </form>
            <form noValidate>
              <TextField
                type="time"
                label="Time End"
                name={"endHour"}
                defaultValue={currentEndHour}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800,
                  edittimeendcy: "editTimeEndCy",
                }}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                onChange={(e) => handleEndHour(e)}
              />
            </form>
            {correctTimeStartFormat === false ||
              correctTimeEndFormat === false ? (
              <TimerMessageErrorFormat invalidminutesformatcy="invalidMinutesFormatCy">
                The minute format is incorrect. Only 00 or 30 is
                accepted
              </TimerMessageErrorFormat>
            ) : null}
          </Fragment>
        </TimePickerWrapper>
        <CommonDialog
          open={open}
          onClose={handleClose}
          title={"Confirm Scheduler Change"}
          dialogType={"servers"}
          dialogContent={
            newSchedulerStatus === "remove_schedule" ? (
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {`Scheduler: ${newSchedulerStatus}`}
                </DialogContentText>
              </DialogContent>
            ) : (
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please confirm the following changes:
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {`Time Start: ${timeFormat(newTimeStart)}`}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {`Time End: ${timeFormat(newTimeEnd)}`}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {`Time Zone: ${newTimeZone}`}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {`Scheduler: ${newSchedulerStatus}`}
                </DialogContentText>
                <br />
                <DialogContentText>
                  This request may take 5 minutes to reflect changes
                </DialogContentText>
              </DialogContent>
            )
          }
          dialogActions={
            <DialogActions>
              <DeclineButton
                cancelconfirmationschedulecy="cancelConfirmationScheduleCy"
                onClick={handleClose}
                label={"Cancel"}
              />
              <AcceptButton
                onClick={() =>
                  handleSave(
                    item.accountType,
                    item.privateDNSName,
                    item.Initiative,
                    item.accountId
                  )
                }
                label={"Confirm"}
              />
            </DialogActions>
          }
        ></CommonDialog>
      </PickerDialogContent>
  }

  let displayDialogActions
  if (item.Initiative === "mywizard.techarch.iemp") {
    displayDialogActions =
      <DialogActions paddingBottom={"15px"}>
        <DeclineButton
          onClick={handleCloseSchedule}
          label={"Cancel"}
        />
        <AcceptButton
          disabled={saveStateForIEMPServers}
          onClick={handleClickOpenConfirmationScheduleForIEMPServers}
          label={"Save"}
        />
      </DialogActions>
  } else if (item.Initiative === "mywizard.techarch.core") {
    displayDialogActions =
      <DialogActions paddingBottom={"15px"}>
        <DeclineButton
          onClick={handleCloseSchedule}
          label={"Cancel"}
        />
        <AcceptButton
          disabled={saveStateForCoreServers}
          onClick={handleClickOpenConfirmationScheduleForCoreServers}
          label={"Save"}
        />
      </DialogActions>
  } else {
    displayDialogActions =
      <DialogActions>
        <DeclineButton
          onClick={handleCloseSchedule}
          editschedulecancelbuttoncy="editScheduleCancelButtonCy"
          label={"Cancel"}
        />
        <AcceptButton
          disabled={timePickerState ? true : loadingIconStatusBool}
          onClick={handleClickOpen}
          editschedulesavebuttoncy="editScheduleSaveButtonCy"
          label={"Save"}
        />
      </DialogActions>
  }



  return (
    <SchedulerWrapper>
      <Fragment>
        <div>
          <Grid container>
            <CommonDialog
              open={openEditSchedule}
              onClose={handleCloseSchedule}
              title={"Editing Schedule"}
              dialogType={"servers"}
              textContent={"Schedule the duration using the server:"}
              dialogContent={displayDialogContent}
              dialogActions={displayDialogActions}
            // dialogContent={item.Initiative === "mywizard.techarch.core" ?
            //   <PickerDialogContent padding={"0px 20px 20px 20px"}>
            //   <div>
            //     <FormControl>
            //       <InputLabel shrink>Time Zone</InputLabel>
            //       <NativeSelect
            //         native="true"
            //         onChange={(e) => handleCoreServersTimeZone(e)}
            //         disabled={
            //           currentInstanceState === "terminated" ||
            //             newSchedulerStatus === "remove_schedule"
            //             ? true
            //             : loadingIconStatusBool
            //         }
            //         inputProps={{ name: "timeZone", id: "timeZone" }}
            //       >
            //         <option value="">--Select desired scheduler option--</option>
            //         <option value="06-21-schedule">06-21-schedule</option>
            //         <option value="06-22-uptime-hours">06-22-uptime-hours</option>
            //         <option value="06-23-schedule">06-23-schedule</option>
            //         <option value="06-23-schedule-mon-sat">06-23-schedule-mon-sat</option>
            //         <option value="06-23-schedule-weekdays">06-23-schedule-weekdays</option>
            //         <option value="08-22-schedule-mon-sat">08-22-schedule-mon-sat</option>
            //         <option value="08-22-schedule-weekdays">08-22-schedule-weekdays</option>
            //         <option value="7.30-21-schedule weekdays">7.30-21-schedule weekdays</option>
            //         <option value="7.30-22-schedule">7.30-22-schedule</option>
            //         <option value="7.30-22-schedule-mon-sat">7.30-22-schedule-mon-sat</option>
            //         <option value="7.30-22-schedule-weekdays">7.30-22-schedule-weekdays</option>
            //         <option value="7.30-23-schedule-weekdays">7.30-23-schedule-weekdays</option>
            //         <option value="alc-10-21-schedule">alc-10-21-schedule</option>
            //         <option value="alc-7-23-schedule">alc-7-23-schedule</option>
            //         <option value="alc-7-23-schedule-weekdays">alc-7-23-schedule-weekdays</option>
            //         <option value="alc-9-1-schedule">alc-9-1-schedule</option>
            //         <option value="lifecycle-automation-schedule">lifecycle-automation-schedule</option>
            //         <option value="myWizard-SaaS-nonprod-schedule-1am-6am">myWizard-SaaS-nonprod-schedule-1am-6am</option>
            //         <option value="myWizard-SaaS-nonprod-schedule-1am-6am mon-sat ">myWizard-SaaS-nonprod-schedule-1am-6am mon-sat </option>
            //         <option value="mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679">mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679</option>
            //         <option value="mywizard-SaaS-schedule-9am-9pm">mywizard-SaaS-schedule-9am-9pm</option>
            //         <option value="mywizard-schedule-9am-9pm weekdays">mywizard-schedule-9am-9pm weekdays</option>
            //         <option value="schedule-6am-7.30am-alldays">schedule-6am-7.30am-alldays</option>
            //         <option value="schedule-6am-9am-alldays">schedule-6am-9am-alldays</option>
            //         <option value="schedule-9am-11am-alldays">schedule-9am-11am-alldays</option>
            //       </NativeSelect>
            //     </FormControl>
            //   </div>
            // </PickerDialogContent>
            //   :
            // <PickerDialogContent padding={"0px 12px 8px"}>
            //   <ButtonWrapper paddingLeft={"10px"}>
            //     <div>
            //       <StyledCheckbox>
            //         <FormControl component="fieldset">
            //           <StyledRadioGroup
            //             aria-label="speak"
            //             name="position"
            //             onChange={(e) => handleSchedulerStatus(e)}
            //             defaultValue={currentSchedulerStatus}
            //             row
            //           >
            //             <FormControlLabel
            //               value="weekdays"
            //               disabled={
            //                 currentInstanceState === "terminated"
            //                   ? true
            //                   : loadingIconStatusBool
            //               }
            //               control={
            //                 <Radio
            //                   editweekdaysradiocy="editWeekdaysRadioCy"
            //                   color="primary"
            //                 />
            //               }
            //               label="Weekdays"
            //             />
            //             <FormControlLabel
            //               value="all"
            //               disabled={
            //                 currentInstanceState === "terminated"
            //                   ? true
            //                   : loadingIconStatusBool
            //               }
            //               control={
            //                 <Radio
            //                   editalldaysradiocy="editAlldaysRadioCy"
            //                   color="primary"
            //                 />
            //               }
            //               label="All Days"
            //             />
            //             <FormControlLabel
            //               value="remove_schedule"
            //               disabled={
            //                 currentInstanceState === "terminated"
            //                   ? true
            //                   : loadingIconStatusBool
            //               }
            //               control={
            //                 <Radio
            //                   editremoveschedulecy="editRemoveScheduleCy"
            //                   color="primary"
            //                 />
            //               }
            //               label="Do not schedule"
            //             />
            //           </StyledRadioGroup>
            //         </FormControl>
            //       </StyledCheckbox>
            //     </div>
            //   </ButtonWrapper>

            //   <TimePickerWrapper padding={"14px"} paddingLeft={"10px"}>
            //     <FormControl>
            //       <InputLabel shrink>Time Zone</InputLabel>
            //       <NativeSelect
            //         native="true"
            //         disabled={
            //           currentInstanceState === "terminated" ||
            //             newSchedulerStatus === "remove_schedule"
            //             ? true
            //             : loadingIconStatusBool
            //         }
            //         value={timeZoneId}
            //         onChange={(e) => handleTimeZone(e)}
            //         inputProps={{ name: "timeZone", id: "timeZone" }}
            //       >
            //         <option value="">-- Select A Time Zone --</option>
            //         {timeZoneArr.map((item, index) => (
            //           <option key={index} value={index}>
            //             {item}
            //           </option>
            //         ))}
            //       </NativeSelect>
            //     </FormControl>
            //     <Fragment>
            //       <form noValidate>
            //         <TextField
            //           type="time"
            //           label="Time Start"
            //           name={"startHour"}
            //           defaultValue={currentStartHour}
            //           InputLabelProps={{
            //             shrink: true,
            //           }}
            //           inputProps={{
            //             step: 1800,
            //             edittimestartcy: "editTimeStartCy",
            //           }}
            //           disabled={
            //             currentInstanceState === "terminated" ||
            //               newSchedulerStatus === "remove_schedule"
            //               ? true
            //               : loadingIconStatusBool
            //           }
            //           onChange={handleStartHour}
            //         />
            //       </form>
            //       <form noValidate>
            //         <TextField
            //           type="time"
            //           label="Time End"
            //           name={"endHour"}
            //           defaultValue={currentEndHour}
            //           InputLabelProps={{
            //             shrink: true,
            //           }}
            //           inputProps={{
            //             step: 1800,
            //             edittimeendcy: "editTimeEndCy",
            //           }}
            //           disabled={
            //             currentInstanceState === "terminated" ||
            //               newSchedulerStatus === "remove_schedule"
            //               ? true
            //               : loadingIconStatusBool
            //           }
            //           onChange={(e) => handleEndHour(e)}
            //         />
            //       </form>
            //       {correctTimeStartFormat === false ||
            //         correctTimeEndFormat === false ? (
            //         <TimerMessageErrorFormat invalidminutesformatcy="invalidMinutesFormatCy">
            //           The minute format is incorrect. Only 00 or 30 is
            //           accepted
            //         </TimerMessageErrorFormat>
            //       ) : null}
            //     </Fragment>
            //   </TimePickerWrapper>
            //   <CommonDialog
            //     open={open}
            //     onClose={handleClose}
            //     title={"Confirm Scheduler Change"}
            //     dialogType={"servers"}
            //     dialogContent={
            //       newSchedulerStatus === "remove_schedule" ? (
            //         <DialogContent>
            //           <DialogContentText id="alert-dialog-description">
            //             {`Scheduler: ${newSchedulerStatus}`}
            //           </DialogContentText>
            //         </DialogContent>
            //       ) : (
            //         <DialogContent>
            //           <DialogContentText id="alert-dialog-description">
            //             Please confirm the following changes:
            //           </DialogContentText>
            //           <DialogContentText id="alert-dialog-description">
            //             {`Time Start: ${timeFormat(newTimeStart)}`}
            //           </DialogContentText>
            //           <DialogContentText id="alert-dialog-description">
            //             {`Time End: ${timeFormat(newTimeEnd)}`}
            //           </DialogContentText>
            //           <DialogContentText id="alert-dialog-description">
            //             {`Time Zone: ${newTimeZone}`}
            //           </DialogContentText>
            //           <DialogContentText id="alert-dialog-description">
            //             {`Scheduler: ${newSchedulerStatus}`}
            //           </DialogContentText>
            //           <br />
            //           <DialogContentText>
            //             This request may take 5 minutes to reflect changes
            //           </DialogContentText>
            //         </DialogContent>
            //       )
            //     }
            //     dialogActions={
            //       <DialogActions>
            //         <DeclineButton
            //           cancelconfirmationschedulecy="cancelConfirmationScheduleCy"
            //           onClick={handleClose}
            //           label={"Cancel"}
            //         />
            //         <AcceptButton
            //           onClick={() =>
            //             handleSave(
            //               item.accountType,
            //               item.privateDNSName,
            //               item.Initiative,
            //               item.accountId
            //             )
            //           }
            //           label={"Confirm"}
            //         />
            //       </DialogActions>
            //     }
            //   ></CommonDialog>
            // </PickerDialogContent>
            // }
            // dialogActions={item.Initiative === "mywizard.techarch.core" ?
            //   <DialogActions paddingBottom={"15px"}>
            //     <DeclineButton
            //       onClick={handleCloseSchedule}
            //       label={"Cancel"}
            //     />
            //     <AcceptButton
            //       disabled={saveStateForCoreServers}
            //       onClick={handleClickOpenConfirmationScheduleForCoreServers}
            //       label={"Save"}
            //     />
            //   </DialogActions> :
            //   <DialogActions>
            //     <DeclineButton
            //       onClick={handleCloseSchedule}
            //       editschedulecancelbuttoncy="editScheduleCancelButtonCy"
            //       label={"Cancel"}
            //     />
            //     <AcceptButton
            //       disabled={timePickerState ? true : loadingIconStatusBool}
            //       onClick={handleClickOpen}
            //       editschedulesavebuttoncy="editScheduleSaveButtonCy"
            //       label={"Save"}
            //     />
            //   </DialogActions>
            // }
            ></CommonDialog>
            <CommonDialog
              open={openConfirmationScheduleForCoreServers}
              onClose={handleCloseConfirmationScheduleForCoreServers}
              title={"Confirm Scheduler Change"}
              dialogType={"servers"}
              dialogContent={
                newSchedulerStatus === "remove_schedule" ? (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {`Scheduler: ${newSchedulerStatus}`}
                    </DialogContentText>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please confirm the following changes:
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                      {`Time Zone: ${newTimeZone}`}
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                      This request may take 5 minutes to reflect changes
                    </DialogContentText>
                  </DialogContent>
                )
              }
              dialogActions={
                <DialogActions>
                  <DeclineButton
                    onClick={handleCloseConfirmationScheduleForCoreServers}
                    setcancelconfirmationschedulecy="setCancelConfirmationScheduleCy"
                    label={"Cancel"}
                  />

                  <AcceptButton
                    onClick={() =>
                      handleSaveForCoreServers(
                        item.accountType,
                        item.privateDNSName,
                        item.Initiative,
                        item.accountId
                      )
                    }
                    setconfirmschedulecy="setConfirmScheduleCy"
                    autoFocus
                    label={"Confirm"}
                  />
                </DialogActions>
              }
            ></CommonDialog>
            <CommonDialog
              open={openConfirmationScheduleForIEMPServers}
              onClose={handleCloseConfirmationScheduleForIEMPServers}
              title={"Confirm Scheduler Change"}
              dialogType={"servers"}
              dialogContent={
                newSchedulerStatus === "remove_schedule" ? (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {`Scheduler: ${newSchedulerStatus}`}
                    </DialogContentText>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please confirm the following changes:
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                      {`Time Zone: ${newTimeZone}`}
                    </DialogContentText>
                    <br />
                    <DialogContentText>
                      This request may take 5 minutes to reflect changes
                    </DialogContentText>
                  </DialogContent>
                )
              }
              dialogActions={
                <DialogActions>
                  <DeclineButton
                    onClick={handleCloseConfirmationScheduleForIEMPServers}
                    setcancelconfirmationschedulecy="setCancelConfirmationScheduleCy"
                    label={"Cancel"}
                  />

                  <AcceptButton
                    onClick={() =>
                      handleSaveForIEMPServers(
                        item.accountType,
                        item.privateDNSName,
                        item.Initiative,
                        item.accountId
                      )
                    }
                    setconfirmschedulecy="setConfirmScheduleCy"
                    autoFocus
                    label={"Confirm"}
                  />
                </DialogActions>
              }
            ></CommonDialog>
          </Grid>
        </div>
      </Fragment>
    </SchedulerWrapper>
  );
};

EditSchedule.propTypes = {
  /**
   * Selected instance to which a schedule will be established or changed
   */
  item: PropTypes.object,
  /**
   * Current state of the instance (running, stopped, terminated).
   */
  currentInstanceState: PropTypes.string,
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Handler of the status of the button that shows the extended information of the instance.
   */
  loadingIconStatusBool: PropTypes.bool,
  /**
   * This contain an array of all the instances of a user.
   */
  instancesNewTags: PropTypes.array,
  /**
   * Current users Token.
   */
  idToken: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    instancesNewTags: state.servers.instanceList,
  };
};

export default connect(mapStateToProps, { setLoadingByClick })(EditSchedule);
