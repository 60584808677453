import React, { useState, useEffect, Fragment } from "react";

// Styled components
import {
  StyledScheduledDays,
  StyledSubtitle3,
  StyledSubtextSolid,
  StyledLargeSubtitle,
  StyledSubtitle4,
} from "./StyledTimePicker";

// Components
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TimeFormatConverter } from "./TimePickerActions/TimeFormatConverter";
import { InstanceProgrammingDays } from "./TimePickerActions/InstanceProgrammingDays";
import { Grid, CircularProgress } from "@material-ui/core";
import { LongSecondaryButton } from "../../common/CommonComponents/Buttons";
import { getTimezone } from "countries-and-timezones";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

/**
 * In the TimePickerContainer the user should modify the schedule for an instance.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: TimePickerContainer.js
 */

export const TimePickerInformation = ({
  item,
  classes,
  loadingIconStatusBool,
  handleEditSchedule,
  handleSetSchedule,
}) => {
  const [instanceTimeStart, setInstanceTimeStart] = useState();
  const [instanceTimeEnd, setInstanceTimeEnd] = useState();
  const [instanceTimeZone, setInstanceTimeZone] = useState();
  const [instanceScheduledDays, setInstanceScheduledDays] = useState();
  const [, setSmallScreen] = useState(false);

  useEffect(() => {
    handleScreenSizeUpdate();
    const callback = () => {
      handleScreenSizeUpdate();
    };
    window.addEventListener("resize", callback);

    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  const handleScreenSizeUpdate = () => {
    setSmallScreen(window.innerWidth < 1200 ? true : false);
  };

  const getInstanceTimezone = (instanceTz) => {
    var result = instanceTz;

    if (getTimezone(instanceTz) !== null) {
      result = result + " (" + getTimezone(instanceTz).utcOffsetStr + " UTC)";
    }

    return result;
  };

  useEffect(() => {
    if (item.Initiative === "mywizard.techarch.core") {
      let timeZone =
        item.ec2Scheduler && item.ec2Scheduler !== "Loading"
          ? item.ec2Scheduler.split(";")
          : ";;-;-".split(";");
      setInstanceTimeZone(timeZone);
    } else if (item.Initiative === "mywizard.techarch.iemp") {
      let timeZone =
        item.ec2Scheduler && item.ec2Scheduler !== "Loading"
          ? item.ec2Scheduler.split(";")
          : ";;-;-".split(";");
      setInstanceTimeZone(timeZone);
    } else {
      let [timeStart, timeEnd, timeZone, scheduledDays] =
        item.ec2Scheduler && item.ec2Scheduler !== "Loading"
          ? item.ec2Scheduler.split(";")
          : ";;-;-".split(";");
      let formattedTimeStart = TimeFormatConverter(timeStart);
      let formattedTimeEnd = TimeFormatConverter(timeEnd);
      let formattedScheduledDays = InstanceProgrammingDays(
        scheduledDays,
        item.privateDNSName
      );
      setInstanceTimeStart(formattedTimeStart);
      setInstanceTimeEnd(formattedTimeEnd);
      setInstanceTimeZone(timeZone);
      setInstanceScheduledDays(formattedScheduledDays);
    }
  }, [item]);

  let ec2ScheduleDisplay
  if (item.Initiative === "mywizard.techarch.iemp") {
    ec2ScheduleDisplay =
      <div style={{ height: "80%" }}>
        {item.ec2Scheduler === "Loading" ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <StyledSubtitle3
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "38%",
                marginTop: "7%",
              }}
            >
              Run Time:
            </StyledSubtitle3>
            <StyledSubtextSolid
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                height: "18%",
                marginTop: "-4%",
              }}
            >

              {instanceTimeZone !== undefined &&
                instanceTimeZone !== "-" &&
                getInstanceTimezone(instanceTimeZone)}
            </StyledSubtextSolid>

            <div
              className={classes.commonFlexCenter}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "28%",
              }}
            >
              {item.hasOwnProperty("ec2Scheduler") &&
                item.ec2Scheduler.length > 0 ? (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleEditSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating"
                  }
                  label={"Edit"}
                  className={classes.editScheduledButton}
                />
              ) : (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleSetSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating"
                    //item.Initiative === "mywizard.techarch.core"
                  }
                  label={"Set"}
                  className={classes.editScheduledButton}
                />
              )}
            </div>
          </div>
        )}
      </div>

  } else if (item.Initiative === "mywizard.techarch.core") {
    ec2ScheduleDisplay =
      <div style={{ height: "80%" }}>
        {item.ec2Scheduler === "Loading" ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <StyledSubtitle3
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "38%",
                marginTop: "7%",
              }}
            >
              Run Time:
            </StyledSubtitle3>
            <StyledSubtextSolid
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                height: "18%",
                marginTop: "-4%",
              }}
            >

              {instanceTimeZone !== undefined &&
                instanceTimeZone !== "-" &&
                getInstanceTimezone(instanceTimeZone)}
            </StyledSubtextSolid>

            <div
              className={classes.commonFlexCenter}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "28%",
              }}
            >
              {item.hasOwnProperty("ec2Scheduler") &&
                item.ec2Scheduler.length > 0 ? (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleEditSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating" ||
                    item.applicationname === "GenWizard AD"
                  }
                  label={"Edit"}
                  className={classes.editScheduledButton}
                />
              ) : (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleSetSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating" ||
                    item.applicationname === "GenWizard AD"
                    //item.Initiative === "mywizard.techarch.core"
                  }
                  label={"Set"}
                  className={classes.editScheduledButton}
                />
              )}
            </div>
          </div>
        )}
      </div>
  } else {
    ec2ScheduleDisplay =
      <div style={{ height: "80%" }}>
        {item.ec2Scheduler === "Loading" ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "18%",
              }}
            >
              {instanceScheduledDays &&
                Object.entries(instanceScheduledDays).map(([key, value]) => {
                  return (
                    <StyledScheduledDays color={value} key={key}>
                      {key.substring(0, 1).toUpperCase()}
                    </StyledScheduledDays>
                  );
                })}
            </div>
            <StyledSubtitle3
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "18%",
              }}
            >
              Run Time:
            </StyledSubtitle3>
            <Grid
              container
              className={classes.commonFlexCenter}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "18%",
              }}
            >
              <StyledSubtitle4>{instanceTimeStart}</StyledSubtitle4>
              <KeyboardArrowRight />
              <StyledSubtitle4>{instanceTimeEnd}</StyledSubtitle4>
            </Grid>

            <StyledSubtextSolid
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "18%",
              }}
            >
              Time Zone:
              {instanceTimeZone !== undefined &&
                instanceTimeZone !== "-" &&
                getInstanceTimezone(instanceTimeZone)}
            </StyledSubtextSolid>

            <div
              className={classes.commonFlexCenter}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "28%",
              }}
            >
              {item.hasOwnProperty("ec2Scheduler") &&
                item.ec2Scheduler.length > 0 ? (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleEditSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating" ||
                    item.InstanceType === "Container" ||
                    item.InstanceType === "EventOps" 
                  }
                  label={"Edit"}
                  className={classes.editScheduledButton}
                />
              ) : (
                <LongSecondaryButton
                  variant="outlined"
                  schedulecy="schedulecy"
                  onClick={() => handleSetSchedule(item.instanceId)}
                  disabled={
                    item.instanceState === "pending" ||
                    item.instanceState === "stopping" ||
                    item.instanceState === "terminating" ||
                    item.InstanceType === "Container" || 
                    item.InstanceType === "EventOps" 
                    //item.Initiative === "mywizard.techarch.core"
                  }
                  label={"Set"}
                  className={classes.editScheduledButton}
                />
              )}
            </div>
          </div>
        )}
      </div>
  }

  return (
    <Fragment>
      <div
        style={{
          // paddingTop: "4px",
          height: "100%",
        }}
      >
        <StyledLargeSubtitle
          style={{
            textAlign: "center",
            height: "20%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Schedule
        </StyledLargeSubtitle>
        {ec2ScheduleDisplay}
        {/* {item.Initiative === "mywizard.techarch.core" ?
          <div style={{ height: "80%" }}>
            {item.ec2Scheduler === "Loading" ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                <StyledSubtitle3
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "38%",
                    marginTop: "7%",
                  }}
                >
                  Run Time:
                </StyledSubtitle3>
                <StyledSubtextSolid
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "18%",
                    marginTop: "-4%",
                  }}
                >

                  {instanceTimeZone !== undefined &&
                    instanceTimeZone !== "-" &&
                    getInstanceTimezone(instanceTimeZone)}
                </StyledSubtextSolid>

                <div
                  className={classes.commonFlexCenter}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "28%",
                  }}
                >
                  {item.hasOwnProperty("ec2Scheduler") &&
                    item.ec2Scheduler.length > 0 ? (
                    <LongSecondaryButton
                      variant="outlined"
                      schedulecy="schedulecy"
                      onClick={() => handleEditSchedule(item.instanceId)}
                      disabled={
                        item.instanceState === "pending" ||
                        item.instanceState === "stopping" ||
                        item.instanceState === "terminating"
                      }
                      label={"Edit"}
                      className={classes.editScheduledButton}
                    />
                  ) : (
                    <LongSecondaryButton
                      variant="outlined"
                      schedulecy="schedulecy"
                      onClick={() => handleSetSchedule(item.instanceId)}
                      disabled={
                        item.instanceState === "pending" ||
                        item.instanceState === "stopping" ||
                        item.instanceState === "terminating"
                        //item.Initiative === "mywizard.techarch.core"
                      }
                      label={"Set"}
                      className={classes.editScheduledButton}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          :
          <div style={{ height: "80%" }}>
            {item.ec2Scheduler === "Loading" ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "18%",
                  }}
                >
                  {instanceScheduledDays &&
                    Object.entries(instanceScheduledDays).map(([key, value]) => {
                      return (
                        <StyledScheduledDays color={value} key={key}>
                          {key.substring(0, 1).toUpperCase()}
                        </StyledScheduledDays>
                      );
                    })}
                </div>
                <StyledSubtitle3
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "18%",
                  }}
                >
                  Run Time:
                </StyledSubtitle3>
                <Grid
                  container
                  className={classes.commonFlexCenter}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "18%",
                  }}
                >
                  <StyledSubtitle4>{instanceTimeStart}</StyledSubtitle4>
                  <KeyboardArrowRight />
                  <StyledSubtitle4>{instanceTimeEnd}</StyledSubtitle4>
                </Grid>

                <StyledSubtextSolid
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "18%",
                  }}
                >
                  Time Zone:
                  {instanceTimeZone !== undefined &&
                    instanceTimeZone !== "-" &&
                    getInstanceTimezone(instanceTimeZone)}
                </StyledSubtextSolid>

                <div
                  className={classes.commonFlexCenter}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "28%",
                  }}
                >
                  {item.hasOwnProperty("ec2Scheduler") &&
                    item.ec2Scheduler.length > 0 ? (
                    <LongSecondaryButton
                      variant="outlined"
                      schedulecy="schedulecy"
                      onClick={() => handleEditSchedule(item.instanceId)}
                      disabled={
                        item.instanceState === "pending" ||
                        item.instanceState === "stopping" ||
                        item.instanceState === "terminating"
                      }
                      label={"Edit"}
                      className={classes.editScheduledButton}
                    />
                  ) : (
                    <LongSecondaryButton
                      variant="outlined"
                      schedulecy="schedulecy"
                      onClick={() => handleSetSchedule(item.instanceId)}
                      disabled={
                        item.instanceState === "pending" ||
                        item.instanceState === "stopping" ||
                        item.instanceState === "terminating"
                        //item.Initiative === "mywizard.techarch.core"
                      }
                      label={"Set"}
                      className={classes.editScheduledButton}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        } */}
      </div>
    </Fragment>
  );
};

TimePickerInformation.propTypes = {
  /**
   * Selected instance to which a schedule will be established or changed
   */
  item: PropTypes.object,
  /**
   * Current state of the instance (running, stopped, terminated).
   */
  currentInstanceState: PropTypes.string,
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Handler of the status of the button that shows the extended information of the instance.
   */
  loadingIconStatusBool: PropTypes.bool,
  /**
   * Current users Token.
   */
  idToken: PropTypes.string,
};

export default connect(null)(TimePickerInformation);

