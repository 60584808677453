import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import StandardOrder from "./StandardOrder";
import ManagedPlatform from "./forms/ManagedPlatform";
import PlainInstance from "./forms/PlainInstance";
import PamInstance from "./forms/PamInstance";
import OnPremiseService from "./forms/OnPremiseService";
import AutomationAnywhereLicenses from "./forms/AutomationAnywhereLicenses";
import AutomaticTicketResolver from "./forms/AutomaticTicketResolver";
import EventOps from "./forms/EventOps";
import AutomaticTicketResolverSaaS from "./forms/AutomaticTicketResolverSaaS";
import StormSeer from "./forms/StormSeer";
import Turing from "./forms/Turing";


import AppStepper from "./AppStepper";
import { withRouter } from "react-router-dom";
import * as fuiRedux from "../../redux/fui/index";
import moment from "moment";
import PropTypes from "prop-types";

import { tokenValidation } from "../../helper/customizedHooks";
import DedicatedPlatform from "./forms/DedicatedPlatform";
import GenWizardPlatform from "./forms/GenWizardPlatform";
import ADPlatform from "./forms/ADPlatform";
import UTKMPlatform from "./forms/UTKM";
import WindowsProvisioningPlatform from "./forms/WindowsProvisioningPlatform";
import SplunkCluster from "./forms/SplunkCluster";
import Stack360 from "./forms/Stack360";
import SubmitSuccess from "./SubmitSuccess";
import SubmitFailed from "./SubmitFailed";
import {
  PageWrapper,
  StepOnePaper,
  ButtonWrapper,
  ConfigurationWrapper,
  StyledSpan,
  StyledSnackbar,
} from "./FuiStyles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import uuidv4 from "uuidv4";
import { PrimaryButton } from "../common/CommonComponents/Buttons";
import { appNameProjectId } from "./Constants/ProductIds";
import { FormValidate } from "./FormValidator";
import {
  generateDPSubmitData,
  generateGWLSubmitData,
  generateADSubmitData,
  generateWPSubmitData,
  generateMPSubmitData,
  generatePISubmitData,
  generateOPSSubmitData,
  generateAALSubmitData,
  generateATRSubmitData,
  generateEventOpsSubmitData,
  generateTuringSubmitData,
  generateStormSeerSubmitData,
  generateATRSaaSSubmitData,
  generatePAMSubmitData,
  generateUTKMSubmitData,
  generateSplunkClusterSubmitData,
  generateStack360SubmitData,
} from "../../helper/fuiDataConvert";

import { CommonDialog } from "../common/CommonComponents/Dialog";
import { AcceptButton, DeclineButton } from "../common/CommonComponents/Buttons";
import { DialogActions } from "@material-ui/core";
import { Table, TableCell, TableBody, TableRow, CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { BoldTableCell, DetailTableWrapper } from "./FuiStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";

/**
 * Fui page for platforms
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: FulfilmentUI
 */
const PlatformLicenseStepper = ({
  match: {
    params: { routeName },
  },
  updateASelectedInstance,
  listenerClearLocalData,
  classes,
  configurationType,
  email,
  isUserApprovalWhitelisted,
  DPForm,
  GWLForm,
  ADForm,
  WPForm,
  submitForm,
  submitFormNonApproval,
  validateForm,
  checkIsUserApprovalWhitelisted,
  isFormSubmittedSuccessful,
  isFormValidationSuccessful,
  isFormSubmittedFailed,
  MPForm,
  PIForm,
  OPSForm,
  AALForm,
  ATRForm,
  EventOpsForm,
  TuringForm,
  StormSeerForm,
  ATRSaaSForm,
  PAMForm,
  UTKMForm,
  SPLUCLUForm,
  Stack360Form,
  isRootExist,
  externalClientError,
  activeStep,
  isLoading,
  approversData,
  validationData,
  apiError,
  isSubmitFormLoading,
}) => {
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [selectedClientname, setSelectedClientname] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [sapContractIdValue, setSAPContractIDValue] = useState("");
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);

  const [serviceEndDateValue, setServiceEndDateValue] = useState("");
  const [isServiceEndDateInvalid, setIsServiceEndDateInvalid] = useState(false);
  //   const [serviceEndDateValid, setServiceEndDateValid] = useState(true);

  const [orderUId, setOrderUId] = useState("");
  const [orderItemUId, setOrderItemUId] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  // check whether all fields are validated
  const [isAllValid, setIsAllValid] = useState(false);

  const [notesValidations, setNotesValidations] = useState({
    emptyString: false,
    maxLength: false,
  });

  //   const [userValidations, setUsersValidations] = useState({
  //     emptyString: false,
  //   });

  // open and close the submit unsuccessful Snackbar
  const [open, setOpen] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openValidationPopup, setOpenValidationPopup] = useState(false);


  // const handleConfirmation = () => {
  //   setOpenConfirmationPopup(true);
  // };

  const generateConfirmPopup = (form, configurationType) => {
    setOpenConfirmationPopup(true);
    setSelectedForm(form)
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setOpenConfirmationPopup(false)
    setOpenValidationPopup(false)
  };
  // update the instance selected in step two, and changing when routeName changes
  useEffect(() => {
    updateASelectedInstance({});
  }, [routeName]);

  useEffect(() => {
    setOrderUId(uuidv4());
    // Using a different generation for this value due to new requirements.
    setOrderItemUId(uuidv4());
  }, []);

  // get user's EID from email
  useEffect(() => {
    checkIsUserApprovalWhitelisted();
    if (email) {
      let eid = email ? email.split("@")[0] : "";
      setCreatedBy(eid);
    }
  }, [email, isUserApprovalWhitelisted]);

  useEffect(() => {
    setSelectedEnvironment("");
    setSelectedClientname("");
    setSAPContractIDValue("");
    setMinDate(undefined);
    setMaxDate(undefined);
    setServiceEndDateValue("");
    setIsServiceEndDateInvalid(false);
    // setServiceEndDateValid(true);
    setNotesValidations({
      emptyString: false,
      maxLength: false,
    });
  }, [listenerClearLocalData]);

  let getMaxDate = (date) => {
    const maxDateEnv = moment()
      .add(date, "days")
      .format("YYYY-MM-DD");
    return maxDateEnv;
  };

  const getNotesValue = (values) => {
    setNotesValidations(values);
  };

  //   const getUsersValue = (values) => {
  //     setUsersValidations(values);
  //   };

  const getSelectedClientname = (selectedClientnameValue) => {
    setSelectedClientname(selectedClientnameValue)
  }

  const getSAPContractIDValue = (getSAPContractIDValue) => {
    setSAPContractIDValue(getSAPContractIDValue)
  }

  const getSelectedEnvironment = (selectedEnvironmentValue) => {
    setSelectedEnvironment(selectedEnvironmentValue);
    const currentDate = moment().format("YYYY-MM-DD");
    setMinDate(currentDate);
    if (selectedEnvironmentValue === "Dev") {
      const endDate = getMaxDate(28);
      setMaxDate(endDate);
    } else if (selectedEnvironmentValue === "Test") {
      const endDate = getMaxDate(15);
      setMaxDate(endDate);
    } else if (selectedEnvironmentValue === "Sales") {
      const endDate = getMaxDate(60);
      setMaxDate(endDate);
    } else if (selectedEnvironmentValue === "Production") {
      const endDate = moment(`2025-12-25`, "YYYY-MM-DD").format("YYYY-MM-DD");
      setMaxDate(endDate);
    }
  };

  const getServiceEndDate = (valueServiceEndDateValue) => {
    const formatDate = moment(valueServiceEndDateValue).format("YYYY-MM-DD");
    setServiceEndDateValue(formatDate);
    const [maxYear, maxMonth, maxDay] = maxDate ? maxDate.split("-") : "";
    const [minYear, minMonth, minDay] = minDate ? minDate.split("-") : "";
    const [year, month, day] = formatDate ? formatDate.split("-") : formatDate;
    let maxDateValidation = new Date(maxYear, maxMonth - 1, maxDay);
    let minDateValidation = new Date(minYear, minMonth - 1, minDay);
    let serviceEndDateValidation = new Date(year, month - 1, day);

    if (
      serviceEndDateValidation >= minDateValidation &&
      serviceEndDateValidation <= maxDateValidation
    ) {
      setIsServiceEndDateInvalid(false);
    } else {
      setIsServiceEndDateInvalid(true);
    }
  };

  useEffect(() => {
    let endDate;
    let currentDate = moment().format("YYYY-MM-DD");
    if (selectedEnvironment) {
      if (selectedEnvironment === "Dev") {
        endDate = getMaxDate(28);
      } else if (selectedEnvironment === "Test") {
        endDate = getMaxDate(15);
      } else if (selectedEnvironment === "Sales") {
        endDate = getMaxDate(60);
      } else if (selectedEnvironment === "Production") {
        endDate = moment(`2025-12-25`, "YYYY-MM-DD").format("YYYY-MM-DD");
      } else {
        setIsServiceEndDateInvalid(false);
      }

      if (serviceEndDateValue) {
        const [maxYear, maxMonth, maxDay] = endDate && endDate.split("-");
        const [minYear, minMonth, minDay] =
          currentDate && currentDate.split("-");
        const [year, month, day] =
          serviceEndDateValue && serviceEndDateValue.split("-");

        let maxDateValidation = new Date(maxYear, maxMonth - 1, maxDay);
        let minDateValidation = new Date(minYear, minMonth - 1, minDay);
        let serviceEndDateValidation = new Date(year, month - 1, day);

        if (
          serviceEndDateValidation >= minDateValidation &&
          serviceEndDateValidation <= maxDateValidation
        ) {
          //   setServiceEndDateValid(false);
          setIsServiceEndDateInvalid(false);
        } else {
          //   setServiceEndDateValid(true);
          setIsServiceEndDateInvalid(true);
        }
      }
    } else {
      setIsServiceEndDateInvalid(false);
    }
  }, [selectedEnvironment, serviceEndDateValue]);

  const isAALFinished = !FormValidate(
    AALForm,
    configurationType,
    "AutomationAnywhereLicenses"
  );

  const isOPSFinished = !FormValidate(
    OPSForm,
    configurationType,
    "OnPremiseService"
  );

  const isDPFinished = !FormValidate(
    DPForm,
    configurationType,
    "DedicatedPlatform",
    isRootExist,
    externalClientError
  );

  const isGWLFinished = !FormValidate(
    GWLForm,
    configurationType,
    "GenWizardPlatform",
    isRootExist,
    externalClientError
  );

  const isADFinished = !FormValidate(
    ADForm,
    configurationType,
    "ADPlatform",
    isRootExist,
    externalClientError
  );

  const isWPFinished = !FormValidate(
    WPForm,
    configurationType,
    "WindowsProvisioningPlatform",
    isRootExist,
    externalClientError
  );

  const isMPFinished = !FormValidate(
    MPForm,
    configurationType,
    "ManagedPlatform",
    isRootExist,
    externalClientError
  );

  const isPIFinished = !FormValidate(
    PIForm,
    configurationType,
    "PlainInstance",
    isRootExist,
    externalClientError
  );

  const isPAMFinished = !FormValidate(
    PAMForm,
    configurationType,
    "PamInstance",
    isRootExist,
    externalClientError
  );

  const isUTKMFinished = !FormValidate(
    UTKMForm,
    configurationType,
    "UTKMPlatform",
    isRootExist,
    externalClientError
  );

  const isATRFinished = !FormValidate(
    ATRForm,
    configurationType,
    "AutomaticTicketResolver",
    isRootExist,
    externalClientError
  );

  const isEventOpsFinished = !FormValidate(
    EventOpsForm,
    configurationType,
    "EventOps",
    isRootExist,
    externalClientError
  )

  const isTuringFinished = !FormValidate(
    TuringForm,
    configurationType,
    "Turing",
    isRootExist,
    externalClientError
  );

  const isStormSeerFinished = !FormValidate(
    StormSeerForm,
    configurationType,
    "StormSeer",
    isRootExist,
    externalClientError
  );

  const isATRSaaSFinished = !FormValidate(
    ATRSaaSForm,
    configurationType,
    "AutomaticTicketResolverSaaS",
    isRootExist
  );

  const isSPLUCLUFinished = !FormValidate(
    SPLUCLUForm,
    configurationType,
    "SplunkClusterPlatform",
    isRootExist,
    externalClientError
  );

  const isStack360Finished = !FormValidate(
    Stack360Form,
    configurationType,
    "Stack360"
  );

  // either user finish Full or Quick, isFinished would be true
  const isFinished =
    isDPFinished ||
    isGWLFinished ||
    isADFinished ||
    isWPFinished ||
    isMPFinished ||
    isPIFinished ||
    isPAMFinished ||
    isUTKMFinished ||
    isAALFinished ||
    isOPSFinished ||
    isATRFinished ||
    isEventOpsFinished ||
    isTuringFinished ||
    isStormSeerFinished ||
    isATRSaaSFinished ||
    isSPLUCLUFinished ||
    isStack360Finished;

  const generateNewForm = (form, configuration) => {
    const commonFullData = {};
    const commonData = {
      Approver: isUserApprovalWhitelisted ? "NA" : selectedApprover,
      ApprovalStatus: isUserApprovalWhitelisted ? "NA" : "Pending Approval",
      DMSId: form.DomainContextRoot ? form.DomainContextRoot : form.DMSId,
      EndToEndUId: form.EndToEndUId
        ? form.EndToEndUId
        : form.DomainContextRoot
          ? form.DomainContextRoot
          : form.DMSId,
      DeployRegion: form.DeployRegion ? form.DeployRegion : "UNKNOWN",
      ClientName: form.ClientName
        ? form.ClientName
        : form.VendorName === "MCC"
          ? "MCC"
          : "myWizard AiOps",
      SAPContractID: form.SAPContractID
        ? form.SAPContractID
        : form.DomainContextRoot
          ? form.DomainContextRoot
          : form.DMSId,

      GeographicArea: form.GeographicArea ? form.GeographicArea : "UNKNOWN",
      BenefitsExpected: form.BenefitsExpected ? form.BenefitsExpected : 1,
      DailyDataVolume: form.DailyDataVolume ? form.DailyDataVolume : 0,
      Users: form.Users ? form.Users : "UNKNOWN",
      ConcurrentUsers: form.ConcurrentUsers ? form.ConcurrentUsers : 0,
      CustomerMobileNo: form.CustomerMobileNo
        ? form.CustomerMobileNo
        : "UNKNOWN",
      ServiceGroup: form.ServiceGroup ? form.ServiceGroup : "UNKNOWN",
      UsersDistributionGroup: form.UsersDistributionGroup
        ? form.UsersDistributionGroup
        : "UNKNOWN",
      CustomerEnterpriseID: createdBy,
      TentativeServiceEndDate: `${form.TentativeServiceEndDate}T00:00:00.000Z`,
      VendorName: form.VendorName,
      Initiative:
        form.VendorName === "pam"
          ? "PAM"
          : form.VendorName === "MCC"
            ? "MCC"
            : "myWizard AiOps",
    };

    return configuration === "full"
      ? { ...form, ...commonData, ...commonFullData }
      : { ...form, ...commonData };
  };

  // the function to confirm submission
  const handleConfirm = () => {
    switch (routeName) {
      case "managedPlatform":
        generateConfirmPopup(MPForm, configurationType);
        break;

      case "dedicatedPlatform":
        generateConfirmPopup(DPForm, configurationType);
        break;

      case "genWizardPlatform":
        generateConfirmPopup(GWLForm, configurationType);
        break;

      case "adPlatform":
        generateConfirmPopup(ADForm, configurationType);
        break;

      case "windowsProvisioningPlatform":
        generateConfirmPopup(WPForm, configurationType);
        break;

      case "plainInstance":
        generateConfirmPopup(PIForm, configurationType);
        break;

      case "plainInstanceMcc":
        generateConfirmPopup(PIForm, configurationType);
        break;

      case "pamInstance":
        generateConfirmPopup(PAMForm, configurationType);
        break;

      case "utkmPlatform":
        generateConfirmPopup(UTKMForm, configurationType);
        break;

      case "automaticTicketResolver":
        generateConfirmPopup(ATRForm, configurationType);
        break;

      case "eventOpsPlatform":
        generateConfirmPopup(EventOpsForm, configurationType);
        break;

      case "quasarpp":
        generateConfirmPopup(TuringForm, configurationType);
        break;

      case "stormseer":
        generateConfirmPopup(StormSeerForm, configurationType);
        break;

      case "automaticTicketResolverSaaS":
        generateConfirmPopup(ATRSaaSForm, configurationType);
        break;

      case "splunkClusterPlatform":
        generateConfirmPopup(SPLUCLUForm, configurationType);
        break;

      case "stack360":
        generateConfirmPopup(Stack360Form, configurationType);
        break;

      case "onPremiseService":
        generateConfirmPopup(OPSForm, configurationType);
        break;

      case "automationAnywhereLicenses":
        generateConfirmPopup(AALForm, configurationType);
        break;
      default:
        break;
    }
  };


  // the function to complete submission
  const handleSubmit = () => {
    setOpenConfirmationPopup(false)
    setOpenValidationPopup(false)
    switch (routeName) {
      case "managedPlatform":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generateMPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(MPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(MPForm, appNameProjectId.managedPlatformId)
            )
          )
          : 
          submitForm(
            generateMPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(MPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(MPForm, appNameProjectId.managedPlatformId)
            )
          );
        break;

      case "dedicatedPlatform":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generateDPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(DPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(DPForm, appNameProjectId.dedicatedPlatformId)
            )
          ) 
          :
          submitForm(
            generateDPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(DPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(DPForm, appNameProjectId.dedicatedPlatformId)
            )
          );
        break;

      case "genWizardPlatform":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generateGWLSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(GWLForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(GWLForm, appNameProjectId.genWizardPlatformId)
            )
          ) 
          :
          submitForm(
            generateGWLSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(GWLForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(GWLForm, appNameProjectId.genWizardPlatformId)
            )
          );
        break;

      case "adPlatform":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generateADSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ADForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(ADForm, appNameProjectId.adPlatformId)
            )
          ) 
          :
          submitForm(
            generateADSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ADForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(ADForm, appNameProjectId.adPlatformId)
            )
          );
        break;

      case "windowsProvisioningPlatform":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generateWPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(WPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(WPForm, appNameProjectId.windowsProvisioningPlatformId)
            )
          ) 
          :
          submitForm(
            generateWPSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(WPForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(WPForm, appNameProjectId.windowsProvisioningPlatformId)
            )
          );
        break;

      case "plainInstance":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generatePISubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PIForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
              "myWizard AiOps"
            )
          )
          :
          submitForm(
            generatePISubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PIForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
              "myWizard AiOps"
            )
          );
        break;
      case "plainInstanceMcc":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generatePISubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PIForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
              "MCC"
            )
          )
          :
          submitForm(
            generatePISubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PIForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
              "MCC"
            )
          );
        break;

      case "pamInstance":
        isUserApprovalWhitelisted ? 
          submitFormNonApproval(
            generatePAMSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PAMForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PAMForm, appNameProjectId.pamInstanceId),
              "pam"
            )
          ) : 
          submitForm(
            generatePAMSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(PAMForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(PAMForm, appNameProjectId.pamInstanceId),
              "pam"
            )
          );
        break;

      case "utkmPlatform":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateUTKMSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(UTKMForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(UTKMForm, appNameProjectId.utkmPlatformId),
            )
          ):
          submitForm(
            generateUTKMSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(UTKMForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(UTKMForm, appNameProjectId.utkmPlatformId),
            )
          );
        break;

      case "automaticTicketResolver":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateATRSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ATRForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                ATRForm,
                appNameProjectId.automaticTicketResolverId
              )
            )
          ):
          submitForm(
            generateATRSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ATRForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                ATRForm,
                appNameProjectId.automaticTicketResolverId
              )
            )
          );
        break;

      case "eventOpsPlatform":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateEventOpsSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(EventOpsForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                EventOpsForm,
                appNameProjectId.eventOpsId
              )
            )
          ):
          submitForm(
            generateEventOpsSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(EventOpsForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                EventOpsForm,
                appNameProjectId.eventOpsId
              )
            )
          );
        break;

      case "quasarpp":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateTuringSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(TuringForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                TuringForm,
                appNameProjectId.turingId
              )
            )
          ):
          submitForm(
            generateTuringSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(TuringForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                TuringForm,
                appNameProjectId.turingId
              )
            )
          );
        break;

      case "stormseer":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateStormSeerSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(StormSeerForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                StormSeerForm,
                appNameProjectId.stormseerId
              )
            )
          ):
          submitForm(
            generateStormSeerSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(StormSeerForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                StormSeerForm,
                appNameProjectId.stormseerId
              )
            )
          );
        break;

      case "automaticTicketResolverSaaS":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateATRSaaSSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ATRSaaSForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                ATRSaaSForm,
                appNameProjectId.automaticTicketResolverSaaSId
              )
            )
          ):
          submitForm(
            generateATRSaaSSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(ATRSaaSForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                ATRSaaSForm,
                appNameProjectId.automaticTicketResolverSaaSId
              )
            )
          );
        break;

      case "splunkClusterPlatform":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateSplunkClusterSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(SPLUCLUForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                SPLUCLUForm,
                appNameProjectId.splunkClusterPlatformId
              )
            )
          ):
          submitForm(
            generateSplunkClusterSubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(SPLUCLUForm, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(
                SPLUCLUForm,
                appNameProjectId.splunkClusterPlatformId
              )
            )
          );
        break;

      case "stack360":
        isUserApprovalWhitelisted ?
          submitFormNonApproval(
            generateStack360SubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(Stack360Form, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(Stack360Form, appNameProjectId.stack360Id)
            )
          ):
          submitForm(
            generateStack360SubmitData(
              // create a JSON format payload that could accepted by controller by using a transfer function
              generateNewForm(Stack360Form, configurationType),
              orderUId,
              orderItemUId,
              createdBy,
              generateUserOrder(Stack360Form, appNameProjectId.stack360Id)
            )
          );
        break;

      case "onPremiseService":
        submitFormNonApproval(
          generateOPSSubmitData(
            generateNewForm(OPSForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy
          )
        );
        break;

      case "automationAnywhereLicenses":
        submitFormNonApproval(
          generateAALSubmitData(
            generateNewForm(AALForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy
          )
        );
        break;
      default:
        break;
    }
  };

  // the function to validate submission
  const handleValidate = () => {
    setOpenValidationPopup(true)
    switch (routeName) {
      case "managedPlatform":
        validateForm(
          generateMPSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(MPForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(MPForm, appNameProjectId.managedPlatformId)
          )
        );
        break;

      case "dedicatedPlatform":
        validateForm(
          generateDPSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(DPForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(DPForm, appNameProjectId.dedicatedPlatformId)
          )
        );
        break;

      case "genWizardPlatform":
        validateForm(
          generateGWLSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(GWLForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(GWLForm, appNameProjectId.genWizardPlatformId)
          )
        );
        break;

      case "adPlatform":
        validateForm(
          generateADSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(ADForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(ADForm, appNameProjectId.adPlatformId)
          )
        );
        break;

      case "windowsProvisioningPlatform":
        validateForm(
          generateWPSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(WPForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(WPForm, appNameProjectId.windowsProvisioningPlatformId)
          )
        );
        break;

      case "plainInstance":
        validateForm(
          generatePISubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(PIForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
            "myWizard AiOps"
          )
        );
        break;
      case "plainInstanceMcc":
        validateForm(
          generatePISubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(PIForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(PIForm, appNameProjectId.plainInstanceId),
            "MCC"
          )
        );
        break;

      case "pamInstance":
        validateForm(
          generatePAMSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(PAMForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(PAMForm, appNameProjectId.pamInstanceId),
            "pam"
          )
        );
        break;

      case "utkmPlatform":
        validateForm(
          generateUTKMSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(UTKMForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(UTKMForm, appNameProjectId.utkmPlatformId),
          )
        );
        break;

      case "automaticTicketResolver":
        validateForm(
          generateATRSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(ATRForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              ATRForm,
              appNameProjectId.automaticTicketResolverId
            )
          )
        );
        break;

      case "eventOpsPlatform":
        validateForm(
          generateEventOpsSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(EventOpsForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              EventOpsForm,
              appNameProjectId.eventOpsId
            )
          )
        );
        break;

      case "quasarpp":
        validateForm(
          generateTuringSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(TuringForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              TuringForm,
              appNameProjectId.turingId
            )
          )
        );
        break;

      case "stormseer":
        validateForm(
          generateStormSeerSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(StormSeerForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              StormSeerForm,
              appNameProjectId.stormseerId
            )
          )
        );
        break;

      case "automaticTicketResolverSaaS":
        validateForm(
          generateATRSaaSSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(ATRSaaSForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              ATRSaaSForm,
              appNameProjectId.automaticTicketResolverSaaSId
            )
          )
        );
        break;

      case "splunkClusterPlatform":
        validateForm(
          generateSplunkClusterSubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(SPLUCLUForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(
              SPLUCLUForm,
              appNameProjectId.splunkClusterPlatformId
            )
          )
        );
        break;

      case "stack360":
        validateForm(
          generateStack360SubmitData(
            // create a JSON format payload that could accepted by controller by using a transfer function
            generateNewForm(Stack360Form, configurationType),
            orderUId,
            orderItemUId,
            createdBy,
            generateUserOrder(Stack360Form, appNameProjectId.stack360Id)
          )
        );
        break;

      case "onPremiseService":
        validateForm(
          generateOPSSubmitData(
            generateNewForm(OPSForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy
          )
        );
        break;

      case "automationAnywhereLicenses":
        validateForm(
          generateAALSubmitData(
            generateNewForm(AALForm, configurationType),
            orderUId,
            orderItemUId,
            createdBy
          )
        );
        break;
      default:
        break;
    }
  };


  // get value of projectId, productUId, customerEnterpriseID and sapContractID, and combine them together as userOrder
  const generateUserOrder = (form, productUId) => {
    const userOrder = [
      form.DMSId === "" ? form.DomainContextRoot : form.DMSId,
      productUId,
      createdBy,
      form.SAPContractID === "" ? form.DomainContextRoot : form.SAPContractID,
    ].join("");
    return userOrder;
  };

  // create function that guide to selected products
  const renderProduct = () => {
    switch (routeName) {
      case undefined:
        return <StandardOrder />;
      case "managedPlatform":
        return (
          <ManagedPlatform
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            createdBy={createdBy}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "dedicatedPlatform":
        return (
          <DedicatedPlatform
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "utkmPlatform":
        return (
          <UTKMPlatform
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "genWizardPlatform":
        return (
          <GenWizardPlatform
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "adPlatform":
        return (
          <ADPlatform
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "windowsProvisioningPlatform":
        return (
          <WindowsProvisioningPlatform
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "plainInstance":
        return (
          <PlainInstance
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            createdBy={createdBy}
            vendorName="CAM"
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "plainInstanceMcc":
        return (
          <PlainInstance
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            createdBy={createdBy}
            vendorName="MCC"
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "pamInstance":
        return (
          <PamInstance
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            createdBy={createdBy}
            vendorName="pam"
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "onPremiseService":
        return (
          <OnPremiseService
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            classes={classes}
            setIsAllValid={setIsAllValid}
            createdBy={createdBy}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
          />
        );

      case "automaticTicketResolver":
        return (
          <AutomaticTicketResolver
            createdBy={createdBy}
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );

      case "eventOpsPlatform":
        return (
          <EventOps
            createdBy={createdBy}
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );

      case "quasarpp":
        return (
          <Turing
            createdBy={createdBy}
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );

      case "stormseer":
        return (
          <StormSeer
            createdBy={createdBy}
            selectedEnvironment={selectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            // serviceEndDateValid={serviceEndDateValid}
            getSelectedEnvironment={getSelectedEnvironment}
            getServiceEndDate={getServiceEndDate}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );

      case "automaticTicketResolverSaaS":
        return (
          <AutomaticTicketResolverSaaS
            createdBy={createdBy}
            classes={classes}
            setIsAllValid={setIsAllValid}
          />
        );
      case "splunkClusterPlatform":
        return (
          <SplunkCluster
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            getSelectedClientname={getSelectedClientname}
            getSAPContractIDValue={getSAPContractIDValue}
            selectedClientname={selectedClientname}
            sapContractIdValue={sapContractIdValue}
          />
        );
      case "stack360":
        return (
          <Stack360
            createdBy={createdBy}
            setIsAllValid={setIsAllValid}
            selectedEnvironment={selectedEnvironment}
            getSelectedEnvironment={getSelectedEnvironment}
            minDate={minDate}
            maxDate={maxDate}
            getServiceEndDate={getServiceEndDate}
            serviceEndDateValue={serviceEndDateValue}
            isServiceEndDateInvalid={isServiceEndDateInvalid}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
          />
        );
      case "automationAnywhereLicenses":
        return (
          <AutomationAnywhereLicenses
            createdBy={createdBy}
            getNotesValue={getNotesValue}
            notesValidations={notesValidations}
            classes={classes}
            setIsAllValid={setIsAllValid}
          />
        );
      default:
        return <AppStepper classes={classes} />;
    }
  };

  var approvers = []
  if (approversData.length !== 0) {
    if (configurationType === "full") {
      approvers = approversData[0]["ApproversFull"].split(";")
    } else {
      approvers = approversData[0]["ApproversQuick"].split(";")
    }
  }

  const [selectedApprover, setSelectedApprover] = useState("");

  const handleApproverChange = (event, value) => {
    setSelectedApprover(value);
  }

  return (
    <>
      <div>
        {!isFormSubmittedSuccessful && !isFormSubmittedFailed && !isSubmitFormLoading && (
          <PageWrapper>
            <ConfigurationWrapper>
              <StepOnePaper>
                {createdBy &&
                  <Fragment>
                    {renderProduct()}
                    {activeStep === 2 ?
                      <ButtonWrapper style={{ marginTop: "-5%" }}>
                        <PrimaryButton
                          onClick={isFinished && isAllValid ? handleConfirm : handleClick}
                          disabled={
                            isFinished &&
                            isAllValid &&
                            !isServiceEndDateInvalid &&
                            notesValidations.maxLength
                          }
                          className={classes.FuiPrimaryButton}
                          label={"Submit"}
                        />
                        {/* } */}
                      </ButtonWrapper> : " "}

                  </Fragment>}
              </StepOnePaper>

            </ConfigurationWrapper>
          </PageWrapper>
        )}
        {isFormSubmittedSuccessful && (
          <SubmitSuccess orderItemUId={orderItemUId} approverName={selectedApprover} requester={createdBy} isUserApprovalWhitelisted={isUserApprovalWhitelisted}/>
        )}
        {isFormSubmittedFailed && (
          <SubmitFailed orderItemUId={orderItemUId} />
        )}
        <StyledSnackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={7000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          style={{ width: "max-content" }}
          message={
            <StyledSpan id="message-id">
              SUBMIT FAILED: Please note that all the fields are mandatory. Kindly review the form and provide the necessary information.
            </StyledSpan>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {openConfirmationPopup && (
          <CommonDialog
            open={openConfirmationPopup}
            onClose={() => setOpenConfirmationPopup(false)}
            title={"Review Order Details"}
            dialogType={"fui"}
            // textContent={"Thank you for choosing our service! Before you submit your request, please take a moment to review the order details below. Ensuring accuracy now will help prevent any issues later on."}
            textContent={
              <div>
                <div>
                  <p style={{ fontWeight: "400" }}>Before you submit your request, please take a moment to review the order details below. Ensuring accuracy now will help prevent any issues later on.</p>
                  <DetailTableWrapper>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <BoldTableCell>Project Name</BoldTableCell>
                          <TableCell>{selectedForm.ProjectName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>WBSE Code</BoldTableCell>
                          <TableCell>{selectedForm.WBSEcode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Domain Context Root</BoldTableCell>
                          <TableCell>{selectedForm.DomainContextRoot === "" ? "NA" : selectedForm.DomainContextRoot}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Admin Distribution Group</BoldTableCell>
                          <TableCell>{selectedForm.AdminDistributionGroup === "" || selectedForm.AdminDistributionGroup === undefined ? "NA" : selectedForm.AdminDistributionGroup}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Environment</BoldTableCell>
                          <TableCell>{selectedForm.Environment}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>GDPR</BoldTableCell>
                          <TableCell>{selectedForm.GDPR.toUpperCase()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Deployment Region</BoldTableCell>
                          <TableCell>{selectedForm.DeploymentRegion.toUpperCase()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Instance Type</BoldTableCell>
                          <TableCell>{selectedForm.InstanceType}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Notes</BoldTableCell>
                          <TableCell>{selectedForm.RequestNotes}</TableCell>
                        </TableRow>
                        <TableRow>
                          <BoldTableCell>Tier</BoldTableCell>
                          <TableCell>{selectedForm.Tier === "" ? "NA" : selectedForm.Tier}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                  </DetailTableWrapper>
                </div>
                <div style={{ marginTop: "20%" }}>
                  <p style={{ fontWeight: "400" }}>By clicking the "Yes" button, you acknowledge that you have read, understood, and agree to be bound by the <a href="https://mywizard-aiops.accenture.com/accenture-splunk-msp-license-usage-policy/" target="_blank" rel="noopener noreferrer">
                    terms and conditions
                  </a> of our application.</p>
                  <p style={{ fontWeight: "600" }}>Are you sure you want to proceed with this order?</p>
                </div>

              </div>
            }
            dialogActions={
              <DialogActions >
                <DeclineButton
                  onClick={() => setOpenConfirmationPopup(false)}
                  label={"No"}
                  disabled={false}
                />
                <AcceptButton
                  // onClick={() => handleSubmit()}
                  onClick={() => (routeName === "onPremiseService" || routeName === "automationAnywhereLicenses" || isUserApprovalWhitelisted) ? handleSubmit() : handleValidate()}
                  label={"Yes"}
                />
              </DialogActions>
            }
          ></CommonDialog>
        )}
        {openValidationPopup && (
          <CommonDialog
            open={openValidationPopup}
            onClose={() => setOpenValidationPopup(false)}
            // title={!isFormValidationSuccessful ? "Validating Order Details" : "Add Approver"}
            title={(() => {
              if (!isFormValidationSuccessful) {
                return "Validating Order Details"
              } else if (validationData) {
                if (validationData.validation === "OK") {
                  return "Add Approver"
                } else {
                  return "Validation Failed"
                }
              } else {
                return ""
              }
            })()}
            dialogType={"fui"}
            textContent={
              <div>
                {!isFormValidationSuccessful ? (
                  <div>
                    <div
                      style={{
                        paddingTop: "8%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={40} />
                    </div>
                    <div
                      style={{
                        paddingTop: "8%",
                        paddingBottom: "8%",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>Please wait.... </h4>
                      <p style={{ fontWeight: "400" }}>Validating your request</p>
                      <p style={{ lineHeight: "0.1px", fontWeight: "400" }}><i>This may take up to a minute....</i></p>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingBottom: "5%",
                    }}
                  >
                    {(() => {
                      if (validationData.validation === "OK") {
                        return (
                          <div>
                            <p style={{ fontWeight: "500" }}>Your request has been successfully validated!</p>
                            <p style={{ fontWeight: "400" }}>Please select an approver from the dropdown to proceed with your order request.</p>
                            <Autocomplete
                              id="country-select-demo"
                              options={approvers.sort()}
                              onChange={handleApproverChange}
                              autoHighlight
                              getOptionLabel={(option) => option}
                              renderOption={(option) => (
                                <React.Fragment>
                                  {option}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Approver"
                                  variant="outlined"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                  }}
                                />
                              )}
                            />
                          </div>
                        )
                      } else if (validationData.validation === "ERROR") {
                        return (
                          <div>
                            <p><b>We regret to inform you that order validation has failed!</b></p>
                            <p style={{ color: "red" }}>ERROR: {validationData.responseDescription.toUpperCase()}</p>
                            <p>Please review the form again and try submitting it once more.</p>
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <p><b>We regret to inform you that order validation has failed due to an intermittent issue!</b></p>
                            <p>Please try again after some time. If the problem persists, please contact our support team for assistance.</p>
                          </div>
                        )
                      }
                    })()}
                  </div>
                )}
              </div>
            }
            dialogActions={
              <DialogActions >
                {isLoading ? "" : validationData && validationData.validation == "OK" ?
                  <AcceptButton
                    disabled={selectedApprover === "" ? true : false}
                    onClick={() => handleSubmit()}
                    // onClick={() => handleValidate()}
                    label={"Submit"}
                  /> :
                  <Button
                    style={{ background: "#7500C0", color: "#FFF" }}
                    onClick={handleClose}
                  >
                    OK
                  </Button>}
              </DialogActions>
            }
          ></CommonDialog>
        )}
      </div>
      {isSubmitFormLoading ? <div>
        <div
          style={{
            paddingTop: "8%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} />
        </div>
        <div
          style={{
            paddingTop: "8%",
            paddingBottom: "8%",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <h4>Please wait.... </h4>
          <p>Submitting your request</p>
          <p style={{ lineHeight: "0.1px" }}><i>This may take up to a minute....</i></p>
        </div>
      </div> : ""}
    </>
  );
};

const MSTP = (state) => ({
  activeStep: state.fui.activeStep,
  listenerClearLocalData: state.fui.listenerClearLocalData,
  DPForm: state.fui.DPForm,
  GWLForm: state.fui.GWLForm,
  ADForm: state.fui.ADForm,
  WPForm: state.fui.WPForm,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  isUserApprovalWhitelisted: state.fui.isUserApprovalWhitelisted,
  isFormSubmittedSuccessful: state.fui.isFormSubmittedSuccessful,
  apiError: state.fui.apiError,
  isFormValidationSuccessful: state.fui.isFormValidationSuccessful,
  isFormSubmittedFailed: state.fui.isFormSubmittedFailed,
  approversData: state.fui.approversData,
  validationData: state.fui.validationData,
  isLoading: state.fui.isLoading,
  isSubmitFormLoading: state.fui.isSubmitFormLoading,
  isRootExist: state.fui.isRootExist,
  externalClientError: state.fui.externalClientError,
  MPForm: state.fui.MPForm,
  PIForm: state.fui.PIForm,
  PAMForm: state.fui.PAMForm,
  UTKMForm: state.fui.UTKMForm,
  OPSForm: state.fui.OPSForm,
  AALForm: state.fui.AALForm,
  ATRForm: state.fui.ATRForm,
  EventOpsForm: state.fui.EventOpsForm,
  TuringForm: state.fui.TuringForm,
  StormSeerForm: state.fui.StormSeerForm,
  ATRSaaSForm: state.fui.ATRSaaSForm,
  SPLUCLUForm: state.fui.SPLUCLUForm,
  Stack360Form: state.fui.Stack360Form,
});
PlatformLicenseStepper.propTypes = {
  /**
   * Match property of route library
   */
  match: PropTypes.object,
  /**
   *
   */
  clearLocalComponentData: PropTypes.func,
  /**
   * Redux function to update an instance.
   */
  updateASelectedInstance: PropTypes.func,
};

export default withRouter(
  connect(MSTP, {
    updateASelectedInstance: fuiRedux.updateASelectedInstance,
    submitForm: fuiRedux.submitForm,
    submitFormNonApproval: fuiRedux.submitFormNonApproval,
    validateForm: fuiRedux.validateForm,
    checkIsUserApprovalWhitelisted: fuiRedux.checkIsUserApprovalWhitelisted,
    generateDPSubmitData,
    generateUTKMSubmitData,
    generateGWLSubmitData,
    generateADSubmitData,
    generateWPSubmitData,
    tokenValidation,
    updatePIForm: fuiRedux.updatePIForm,
    updatePAMForm: fuiRedux.updatePAMForm,
    updateUTKMForm: fuiRedux.updateUTKMForm,
  })(PlatformLicenseStepper)
);
