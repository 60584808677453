import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveTokens, getUserDetails } from "../../redux/auth/authActions";
import PropTypes from "prop-types";

//Get Token Id from url
const getIdToken = (url) => {
  const getIdTokenArr = /id_token=.*access_token=.*expires_in/.exec(url);

  if (getIdTokenArr) {
    let getIdTokenArr1 = /id_token=.*access_token/.exec(url);
    return getIdTokenArr1
      ? getIdTokenArr1[0]
        .split("")
        .splice(9, getIdTokenArr1[0].length - 22)
        .join("")
      : getIdTokenArr;
  } else {
    const getIdTokenArrAcc2 = /id_token=.*token_type/.exec(url);
    return getIdTokenArrAcc2
      ? getIdTokenArrAcc2[0]
        .split("")
        .splice(9, getIdTokenArrAcc2[0].length - 20)
        .join("")
      : getIdTokenArrAcc2;
  }
};

//Get access Token from url
const getAccessToken = (url) => {
  const getAccessTokenArr = /access_token=.*expires_in=.*token_type/.exec(url);

  if (getAccessTokenArr) {
    const myArr = /access_token=.*expires_in/.exec(url);
    return myArr
      ? myArr[0]
        .split("")
        .splice(13, myArr && myArr[0].length - 24)
        .join("")
      : myArr;
  } else {
    const getIdTokenArrAcc2 = /access_token=.*id_token/.exec(url);
    return getIdTokenArrAcc2
      ? getIdTokenArrAcc2[0]
        .split("")
        .splice(13, getIdTokenArrAcc2[0].length - 22)
        .join("")
      : getIdTokenArrAcc2;
  }
};

// const getExpiresIn = url => {
//   // const getExpiresIn = /expires_in=.*\&token_type/;
//   // const getExpiresInAcc = /expires_in=.*/;
//   // const getExpiresInArr = getExpiresIn.exec(url);
//   // const getExpiresInArrACC = getExpiresInAcc.exec(url);

//   // if (getExpiresInArr) {
//   //   if(getExpiresInArr){
//   //     return getExpiresInArr[0]
//   //     .split("")
//   //     .splice(11, getExpiresInArr[0].length - 22)
//   //     .join("")
//   //   }
//   //   else{
//   //     return getExpiresInArr
//   //   }
//   // } else {
//   //   if(getExpiresInArrACC){
//   //     return getExpiresInArrACC[0]
//   //     .split("")
//   //     .splice(11)
//   //     .join("")
//   //   }
//   //   else{
//   //     return getExpiresInArrACC
//   //   }
//   // }
// };

//Function for getTokens
const getTokens = (url) => ({
  idToken: getIdToken(url),
  accessToken: getAccessToken(url),
  // expiresIn: getExpiresIn(url)
});

/**
 * The RecommendationsAuthPage Authenticate the user and save Token obj in localStorage as in Redux
 * @author Allison Haire
 * @version 1.0
 * @see Used in component: Routes.js
 */

//Function for Auth the user and save Token obj in localStorage as in Redux
const RecommendationsAuthPage = ({ history, saveTokenFunc, getUserDetails }) => {
  useEffect(() => {
    const url = window.location.href;
    const tokenObj = getTokens(url);
    localStorage.setItem("tokenObj", JSON.stringify(tokenObj));
    getUserDetails(tokenObj.idToken);

    localStorage.setItem("token", tokenObj.idToken);
    localStorage.setItem("accessToken", tokenObj.accessToken);
    saveTokenFunc(tokenObj);
    history.push("/recommendations");
  });

  return <div>Getting your login details...</div>;
};

RecommendationsAuthPage.propTypes = {
  /** ID Token */
  getIdToken: PropTypes.string,
  /** Access Token */
  accessToken: PropTypes.string,
  /** Function for save tokens*/
  saveTokens: PropTypes.func,
  /** Function for get user details */
  getUserDetails: PropTypes.func,
  /** Function for get tokens */
  getTokens: PropTypes.func,
};

export default withRouter(
  connect(null, { saveTokenFunc: saveTokens, getUserDetails: getUserDetails })(
    RecommendationsAuthPage
  )
);
