import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../theme/theme";
import {
  LargeSubtitle,
  LargeHeader,
  Subtitle3,
  Subtitle4,
  Subtitle2
} from "../common/CommonStyles/CommonTypographyStyles";
import { Grid } from "@material-ui/core";
import { fetchPresignedUrl } from "../../redux/faq/index";
import { connect } from "react-redux";
import { BorderlessButton } from "../common/CommonComponents/Buttons";
import { Footer } from "../common/Footer";
import OpenInNew from "@material-ui/icons/OpenInNew";

/**
 * FAQ page
 * @author Isabel Sprengers
 * @version 1.0
 * @see Used in component: routes.js, ResponsiveSidebar.js
 */

const Faq = ({ fetchPresignedUrl, email, presignedUrls, isLoading }) => {
  const [openNewTab, setOpenNewTab] = useState(false);
  const [file, setFile] = useState("");

  const rootUrl = window.location.href.split("/faq")[0];
  const l3Url =
    "https://mywizard-aiops.accenture.com/resources/support-requests/";

  const exemptUrl = "https://customervoice.microsoft.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR7en2Ais5pxKtso_Pz4b1_xUOE9MUTFMUlpBNk5IQlZWWkcyUEpWWEhGOCQlQCN0PWcu"

  //   const raiseServiceRequest =
  //     "https://mywizard-aiops.accenture.com/resources/support-requests/";
  //   const Indexing =
  //     "http://docs.splunk.com/Documentation/Splunk/7.2.0/Indexer/Howindexingworks";
  //   const SettingRetentionpolicy =
  //     "http://docs.splunk.com/Documentation/Splunk/7.2.1/Indexer/Setaretirementandarchivingpolicy";
  //   const AWSLogsInfo =
  //     "https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/WhatIsCloudWatchLogs.html";

  const fetchFilePresignedUrl = (fileName) => {
    setFile(fileName);
    if (!presignedUrls.hasOwnProperty(fileName)) {
      fetchPresignedUrl(email, fileName);
      setOpenNewTab(true);
    } else {
      setFile(fileName);
      window.open(presignedUrls[fileName], "_blank");
    }
  };

  useEffect(() => {
    if (file !== "" && openNewTab && !isLoading) {
      window.open(presignedUrls[file], "_blank");
      setOpenNewTab(false);
    }
  }, [isLoading, openNewTab]);

  const instanceLicenseCosts =
    "https://mywizard-aiops.accenture.com/3rd-party-licenses/";
  const questionAnswers = {
    "Common SSH Questions": {
      "How do I Generate an SSH Key?": (
        <div>
          <Subtitle4>
            In order to generate an SSH Key, please see the instructions below.
          </Subtitle4>
          <BorderlessButton
            onClick={() => fetchFilePresignedUrl("ssh_instructions.pdf")}
            startIcon={<OpenInNew />}
            label={"Open Instructions"}
          ></BorderlessButton>
        </div>
      ),
      "How can I request SSH Access to an Instance?": (
        <div>
          <Subtitle4>
            If you haven't registered your SSH key, please register here:{" "}
            <a
              href={rootUrl + "/ssh/sshRegistration"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Registration Page
            </a>{" "}
          </Subtitle4>
          <Subtitle4>
            If you have registered your SSH Key, please follow the instructions
            below:
          </Subtitle4>

          <ul>
            <li>
              <Subtitle4>
                Navigate to your{" "}
                <a
                  href={rootUrl + "/ssh/sshDashboard"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SSH Dashboard
                </a>
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click "Request Access" on the top right of the page. This will
                open a Dialog.
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Fill in the expected information and submit the request.
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                You can now see your request on the SSH dashboard under
                "Requested Approvals"
              </Subtitle4>
            </li>
          </ul>
        </div>
      ),

      "How can I register my SSH Key in CAM?": (
        <div>
          <Subtitle4>
            Please navigate to{" "}
            <a
              href={rootUrl + "/ssh/sshRegistration"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Registration Page
            </a>{" "}
            to fill in your SSH Key details and register it in CAM
          </Subtitle4>
        </div>
      ),
    },

    "Common Splunk Questions": {
      "Do I need my own Splunk licence for my Instance?": (
        <Subtitle4>
          No, the instance provisioned on the GenWizard Platform comes with
          a Splunk licence. If you want to use your own Splunk licence, then
          raise a{" "}
          <a href={l3Url} target="_blank" rel="noopener noreferrer">
            Support Ticket
          </a>
        </Subtitle4>
      ),
      "How do I enable Splunk Indexing on my Instance?": (
        <Subtitle4>
          By default, we don't enable this on our instance. If you need this
          enabled then reach out to the support team by raising a{" "}
          <a href={l3Url} target="_blank" rel="noopener noreferrer">
            Support Ticket
          </a>
          . Indexing data does cost extra, please see our pricing here.{" "}
          <a
            href={instanceLicenseCosts}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </Subtitle4>
      ),
    },

    "Common Fulfillment Questions": {
      "Why is there notes section mandatory for Fulfillment? Is it important?": (
        <Subtitle4>
          Yes, the notes section can be used to add additional information about
          your instance, and allows the support team and you (the instance
          owner) to identify instances when you own a lot of instances. You can
          edit the notes for your owned instances on the{" "}
          <a
            href={rootUrl + "/servers"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Servers Page
          </a>
        </Subtitle4>
      ),
    },
    "General Server Management": {
      "How can I change Instance Ownership?": (
        <div>
          <Subtitle4>
            You can change Instance Ownership of your Instance with the
            following steps:
          </Subtitle4>
          <ul>
            <li>
              <Subtitle4>
                Navigate to the{" "}
                <a
                  href={rootUrl + "/servers"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Servers Page
                </a>
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Find the instance you wish to update the Instance Owner for
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click the expandable arrow on the right of your instance to
                reveal additional details.
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click 'Edit' and here you can change the Instance Owner.
              </Subtitle4>
            </li>
          </ul>
        </div>
      ),
      "How can I update the Service End Date of my Instance?": (
        <div>
          <Subtitle4>
            You can update the Service End Date of your Instance with the
            following steps:
          </Subtitle4>
          <ul>
            <li>
              <Subtitle4>
                Navigate to the{" "}
                <a
                  href={rootUrl + "/servers"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Servers Page
                </a>
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Find the instance you wish to update the Service End Date for
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click the expandable arrow on the right of your instance to
                reveal additional details.
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click 'Edit' and here you can update your Service End Date.
              </Subtitle4>
            </li>
          </ul>
        </div>
      ),

      "What is the Cost of my Instance?": (
        <Subtitle4>
          You can see the service cost of your instance{" "}
          <a
            href={instanceLicenseCosts}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </Subtitle4>
      ),

      "How do I upgrade (or downgrade) my Instance Tier?": (
        <Subtitle4>
          You can upgrade/downgrade your instance tier anytime you like by
          raising a{" "}
          <a href={l3Url} target="_blank" rel="noopener noreferrer">
            Support Ticket
          </a>{" "}
          Upgrading your instance tier will cost additional an charge, you can
          see our instance tier pricing <a href={instanceLicenseCosts}>here</a>
        </Subtitle4>
      ),

      "Is it possible to retrieve my Expired Instance?": (
        <Subtitle4>
          Yes, please raise an{" "}
          <a href={l3Url} target="_blank" rel="noopener noreferrer">
            L3 Ticket
          </a>
        </Subtitle4>
      ),

      "How do I schedule my Instance to Start and Stop Automatically?": (
        <div>
          <Subtitle4>
            You can schedule your instances to Start and Stop Automatically with
            the following steps:
          </Subtitle4>
          <ul>
            <li>
              <Subtitle4>
                Navigate to the{" "}
                <a
                  href={rootUrl + "/servers"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Servers Page
                </a>
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Find the instance you wish to schedule to start and stop
              </Subtitle4>
            </li>
            <li>
              <Subtitle4>
                Click the 'Set Schedule' or 'Edit Schedule' button and fill out
                the time and time zone you wish
              </Subtitle4>
            </li>
          </ul>
        </div>
      ),

      "Why is my Instance not visible on CAM's Servers Page?": (
        <div>
          <Subtitle4>
            Your instance may not be visible due to the following reasons:
          </Subtitle4>
          <ul>
            <li>
              <Subtitle4>Your instance has expired</Subtitle4>
            </li>
            <li>
              <Subtitle4>The instance owner has been changed</Subtitle4>
            </li>
          </ul>
          <Subtitle4>
            If this is not the case, please raise a{" "}
            <a href={l3Url} target="_blank" rel="noopener noreferrer">
              L3 Ticket
            </a>
          </Subtitle4>
        </div>
      ),
    },
    "Common Azure OpenAI Security Management Questions": {
      "How do you manage traffic to Azure OpenAI on your platform?": (
        <div>
          <Subtitle4>
            We regulate traffic to Azure OpenAI using selected networks or IP addresses. We employ firewall rules that block all incoming requests for data by default. Requests are only allowed if they originate from a service within an Azure Virtual Network on our allowed list or from an approved list of IP addresses.
          </Subtitle4>
        </div>
      ),
      "How can I be sure that my data is isolated and won't be accessible to other clients on your platform?": (
        <div>
          <Subtitle4>
            We ensure that each client and environment has its dedicated Azure OpenAI service. The prompts, completions, your embeddings, and your training data sent to Azure OpenAI are not available to other customers or to OpenAI. They are not used to improve OpenAI models, or any Microsoft or third-party products or services.
          </Subtitle4>
        </div>
      ),

      "How do you manage access to Azure OpenAI?": (
        <Subtitle4>
          Access to Azure OpenAI is managed using Azure role-based access control (Azure RBAC). This system allows us to assign different levels of permissions to different team members based on their roles, ensuring that only authorized personnel can access specific resources.
        </Subtitle4>
      ),

      "How is harmful content prevented from being generated by Azure OpenAI?": (
        <Subtitle4>
          Azure OpenAI Service includes content filtering and abuse monitoring features. The content filter works alongside core models to detect and prevent the output of harmful content. The abuse monitor detects and mitigates instances of recurring content and behaviors that suggest misuse of the service.
        </Subtitle4>
      ),

      "How do you ensure the security of my fine-tuned Azure OpenAI models?": (
        <Subtitle4>
          Your fine-tuned Azure OpenAI models are available exclusively for your use. They are not used to improve Azure OpenAI models for use in other resources. This ensures that your models remain confidential and secure.
        </Subtitle4>
      ),

      "Who controls the Azure OpenAI Service and where is it hosted?": (
        <div>
          <Subtitle4>
            The Azure OpenAI is fully managed PaaS service by Microsoft and hosted in Microsoft’s Azure environment. The Service does not interact with any services operated by OpenAI, such as ChatGPT or the OpenAI API, ensuring an additional layer of security and control.          </Subtitle4>
        </div>
      ),

      "Will my data, such as prompts or completions sent to Azure, be stored or used by Azure or your company?": (
        <div>
          <Subtitle4>
            No, your data such as prompts, completions, your embeddings, and your training data sent to Azure OpenAI are strictly confidential. They are not stored or used by Azure or our company to improve OpenAI models or any other products or services. We respect your data privacy and take all necessary measures to ensure it.
          </Subtitle4>
        </div>
      ),


      "Can my data sent to Azure OpenAI be accessed by other customers or OpenAI?": (
        <div>
          <Subtitle4>
            Absolutely not. Your data sent to Azure OpenAI is not available to other customers or to OpenAI. We ensure a high level of data isolation and privacy.          </Subtitle4>
        </div>
      ),


      "Will my data be used to automatically improve Azure OpenAI models for use in my resource?": (
        <div>
          <Subtitle4>
            No, your data will not be used for automatically improving Azure OpenAI models for use in your resource. The models are stateless unless you explicitly fine-tune models with your training data.          </Subtitle4>
        </div>
      ),


      "Is my fine-tuned Azure OpenAI model shared with other clients or used by other services?": (
        <div>
          <Subtitle4>
            No, your fine-tuned Azure OpenAI models are available exclusively for your use. They are not shared with other clients or used by other services. Your models are kept confidential and secure.
          </Subtitle4>
        </div>
      ),


      "How can I request for Azure OpenAI service on your platform?": (
        <div>
          <Subtitle4>
            You can request for Azure OpenAI service primarily through ordering our GenWizard app. This app will provision the necessary apps and OpenAI credentials, with the endpoint automatically baked into it.          </Subtitle4>
        </div>
      ),


      "What if I don't need the app, but only the Azure OpenAI service?": (
        <div>
          <Subtitle4>
            While we prefer users to consume OpenAI on our platform through the GenWizard app, we still provide the service for those who only need that. You can request the service by raising a support ticket and providing the following details: team name, WBSe code, cost owner, server IP, and the name of the app on which these credentials will be used.          </Subtitle4>
        </div>
      ),


      "How do you manage security for Azure OpenAI service?": (
        <div>
          <Subtitle4>
            We manage security by rotating the Azure OpenAI service credentials every 90 days as per our company policy. The cost owner will receive an email with the new credentials from the following address: "email id" This ensures that your access remains secure and up-to-date.          </Subtitle4>
        </div>
      ),


      "What if my existing version of the app is not compatible with or doesn't need OpenAI?": (
        <div>
          <Subtitle4>
            The GenAI feature is supported in versions (including ATR and Q++) of our apps. If your application version is lower and you need GenAI features, we recommend reaching out to our support team by raising a support ticket. They will guide you on how to proceed or upgrade to a compatible version.          </Subtitle4>
        </div>
      ),

      "What models are deployed within Azure OpenAI?": (
        <div>
          <Subtitle4>
            By default, apps are configured with the GPT-3.5 model. However, users can request to switch to different models. Please note that this may affect the price.
          </Subtitle4>
        </div>
      ),


      "Can I switch to a different model within Azure OpenAI?": (
        <div>
          <Subtitle4>
            Yes, you can request to switch to different models. However, keep in mind that this may affect the pricing. Also, new models are released by Azure periodically and some older models may get discontinued.
          </Subtitle4>
        </div>
      ),


      "What should I do if I have queries about the models or if I want to switch to a different model?": (
        <div>
          <Subtitle4>
            We recommend users to connect with our support team if they have any queries about the models or if they wish to switch to a different model. Our support team will guide you through the process and provide you with all the necessary information.
          </Subtitle4>
        </div>
      ),

      "How the client prompts or data sent to Azure is not stored or used by Azure?": (
        <Subtitle4>
          By default Microsoft Azure use to store the prompt data/message for abuse monitoring purpose. If client doesn't want Microsoft to monitor/ store the data in Azure data center, we need to submit the{" "}
          <a href={exemptUrl} target="_blank" rel="noopener noreferrer">
            form
          </a> for exempt.
        </Subtitle4>
      ),
    },
  };
  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "90%", padding: "0 1em" }}>
        <div
          style={{
            height: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LargeHeader>
            {`Frequently Asked Questions & Answers (FAQs)`}
          </LargeHeader>
        </div>
        <div style={{ height: "80%", maxHeight: "80%" }}>
          <div style={{ height: "90%", maxHeight: "90%", overflowY: "auto" }}>
            {Object.keys(questionAnswers).map(function (key) {
              return (
                <Accordion
                  style={{
                    boxShadow: "unset",
                    borderStyle: "solid",
                    borderColor: theme.backgroundBorderGreys.border,
                    borderWidth: "2px 0px 0px 0px",
                    // borderRadius: "0px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{ width: "2.5em", height: "2.5em" }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  // style={{ margin: "1% 0px" }}
                  >
                    <Subtitle2>{key}</Subtitle2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        {Object.keys(questionAnswers[key]).map(function (
                          innerkey
                        ) {
                          return (
                            <Accordion style={{ boxShadow: "none" }}>
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header-inner"
                              >
                                <Subtitle3>{innerkey}</Subtitle3>
                              </AccordionSummary>
                              <AccordionDetails>
                                {questionAnswers[key][innerkey]}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <div
              style={{
                // boxShadow: "none",
                boxShadow: "unset",
                borderStyle: "solid",
                borderColor: theme.backgroundBorderGreys.border,
                borderWidth: "2px 0px 0px 0px",
              }}
            ></div>
          </div>

          <div
            style={{
              height: "10%",
              maxHeight: "10%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ padding: "20px 0px" }}>
              <Subtitle4>
                If you are experiencing issues which is not listed here, please
                raise a{" "}
                <a href={l3Url} target="_blank" rel="noopener noreferrer">
                  L3 Ticket
                </a>{" "}
                here{" "}
              </Subtitle4>
            </div>
          </div>
        </div>
      </div>

      <div style={{
        left: 96,
        bottom: 0,
        right: 26,
      }}>
        <Footer />
      </div>
    </div>
  );
};

const MSTP = (state) => ({
  isLoading: state.faq.isLoading,
  presignedUrls: state.faq.presignedUrls,
  email: state.auth.userDetails.email,
});

export default connect(MSTP, {
  fetchPresignedUrl: fetchPresignedUrl,
})(Faq);
