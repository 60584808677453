import React, { useEffect, useState } from "react";
import {
  Grid,
  Tooltip,
  IconButton,
  // , Button
} from "@material-ui/core";
import {
  StyledResourceCard,
  StyledAdvancedCard,
  StyledFileCopyIcon,
  SubtitleWrapper,
  StyledDivider,
} from "./RegistrationStyles";
import {
  LargeSubtitle,
  Subtext,
  Subtitle4,
} from "../../common/CommonStyles/CommonTypographyStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import {
  SecondaryButton,
  BorderlessButton,
} from "../../common/CommonComponents/Buttons";
// import {
//   sshMacLinuxReference,
//   sshWindowsReference,
// } from "../../../constants/urls";
import { useSSHMakeStyles } from "../SSHMakeStyles";
import OpenInNew from "@material-ui/icons/OpenInNew";

/**
 * Renders the resources shown in the registration/update page
 * @author isabel sprengers
 * @version 1.0
 * @see Used in component: RegistrationForm.js
 */

const FormResources = ({
  downloadSSHConfigURL,
  presignedUrls,
  isLoading,
  fetchPresignedUrl,
  email,
}) => {
  const classes = useSSHMakeStyles();
  const [openNewTab, setOpenNewTab] = useState(false);
  const [file, setFile] = useState("");

  const fetchFilePresignedUrl = (fileName) => {
    setFile(fileName);
    if (!presignedUrls.hasOwnProperty(fileName)) {
      fetchPresignedUrl(email, fileName);
      setOpenNewTab(true);
    } else {
      setFile(fileName);
      window.open(presignedUrls[fileName], "_blank");
    }
  };

  useEffect(() => {
    if (file !== "" && openNewTab && !isLoading) {
      window.open(presignedUrls[file], "_blank");
      setOpenNewTab(false);
    }
  }, [isLoading, openNewTab]);

  return (
    <StyledResourceCard>
      <LargeSubtitle style={{ padding: "2%", textAlign: "center" }}>
        Please refer to the following resources on how to generate an SSH Key
      </LargeSubtitle>
      <div style={{ padding: "0% 5% 5% 5%" }}>
        <Grid container>
          <Grid item xs={12}>
            <StyledAdvancedCard>
              <SubtitleWrapper>
                <Subtitle4>SSH :: Generate a Key Pair</Subtitle4>
              </SubtitleWrapper>
              <StyledDivider />
              {/* <Launch /> */}
              <div
                style={{
                  margin: ".6em",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BorderlessButton
                    onClick={() =>
                      fetchFilePresignedUrl("ssh_instructions.pdf")
                    }
                    startIcon={<OpenInNew />}
                    label={"SSH Instructions"}
                    className={classes.SSHSecondaryButton}
                  ></BorderlessButton>
                </div>
              </div>
            </StyledAdvancedCard>
          </Grid>

          <StyledAdvancedCard>
            <SubtitleWrapper>
              <Subtitle4>[Advanced Users] Generate SSh Key Command</Subtitle4>
            </SubtitleWrapper>
            <StyledDivider />
            <Grid container>
              <Grid item xs={2}>
                <CopyToClipboard
                  text={
                    "ssh-keygen -t rsa -f ~/.ssh/[keypair_name] -C email@accenture.com"
                  }
                >
                  <Tooltip title="Copy">
                    <IconButton>
                      <StyledFileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </Grid>
              <Grid item xs={10}>
                <Subtext style={{ padding: "10px" }}>
                  ssh-keygen -t rsa -f ~/.ssh/[keypair_name] -C
                  "email@accenture.com"
                </Subtext>
              </Grid>
            </Grid>
          </StyledAdvancedCard>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SecondaryButton
              href={downloadSSHConfigURL}
              label={"Download SSH Config File"}
              className={classes.SSHSecondaryButton}
            />
          </div>
        </Grid>
      </div>
    </StyledResourceCard>
  );
};
FormResources.propTypes = {
  /**
   * String containing presigned url from s3 bucket to download the ssh config file.
   */
  downloadSSHConfigURL: PropTypes.string,
};
export default FormResources;
