import styled from "styled-components";
import {
  Typography,
  Select,
  CircularProgress,
  Avatar,
  Paper,
  TextField,
  Button,
  FormControl,
  DialogTitle,
  Card,
  Divider,
  Dialog,
  TableCell,
  TableHead,
  IconButton,
  TableRow,
  Grid,
} from "@material-ui/core";
import theme from "../../theme/theme";
import grey from "@material-ui/core/colors/grey";
import SSHKeyIcon from "@material-ui/icons/VpnKeyOutlined";
import Hourglass from "@material-ui/icons/HourglassEmptyOutlined";
import {
  LargeSubtitle,
  SmallHeader,
} from "../common/CommonStyles/CommonTypographyStyles";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { SmallButton } from "../common/CommonStyles/CommonButtons";

export const AccessFormSubtitle1 = styled(LargeSubtitle)`
  &&&&& {
    text-align: left;
    color: white;
  }
`;

export const SSHSmallHeader = styled(SmallHeader)`
  &&&&& {
    color: #000000;
    padding: 10px;
    @media (max-width: 1400px) {
      font-size: 28px;
    }
    @media (max-width: 1200px) {
      font-size: 24px;
    }
    @media (max-width: 1100px) {
      font-size: 15px;
    }
  }
`;

export const StyledPendingApprovalsGrid = styled(Grid)`
  &&&&& {
    @media (max-width: 700px) {
      max-width: 24%;
    }
  }
`;

export const StyledSSHKeyIcon = styled(SSHKeyIcon)`
  &&&&& {
    width: 5em;
    height: 10em;
    color: #e0e3e5;
    opacity: 54%;
    margin-right: 0.4em;
    @media (max-width: 1500px) {
      width: 5em;
    }
    @media (max-width: 1300px) {
      width: 4em;
      height: 7em;
    }
    @media (max-width: 1100px) {
      width: 3em;
      height: 5em;
    }
  }
`;

export const StyledHourglass = styled(Hourglass)`
  &&&&& {
    width: 6em;
    /* height: 5em; */
    height: 100%;
    color: #e0e3e5;
    opacity: 54%;

    @media (max-width: 1500px) {
      width: 5em;
      height: 5em;
    }
    @media (max-width: 1300px) {
      width: 4em;
      height: 5em;
    }
    @media (max-width: 1100px) {
      width: 3em;
      height: 3em;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  &&&&& {
    border-color: rgba(112, 112, 112, 0.25);
    background: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
    margin-left: 0px;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: 75%;
    border-style: solid;
    @media (max-width: 1100px) {
      height: auto;
    }
  }
`;

export const StyledCard = styled(Card)`
  &&&&& {
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
  }
`;

export const StyledHeaderCard = styled(Card)`
  &&&&& {
    height: 244px;
    @media (max-width: 1100px) {
      height: 200px;
    }
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
  }
`;

export const StyledSubmitButton = styled(Button)`
  &&&&& {
    color: #fff;
    background: ${(props) =>
      props.disabled ? theme.palette.primary.light : theme.palette.ssh.main};
    font-size: 15px;
    width: 138px;
    height: 35px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  &&&&& {
    width: 100%;
  }
`;

export const FirstLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 2em 0;
`;

export const StyledTypography = styled(Typography)`
  &&&&& {
    color: "#000000";
    font-weight: 600;
    margin-left: 1em;
    font-family: Graphik;
  }
`;

export const StyledArcher = styled.a`
  /* text-decoration: none; */
  text-decoration: underline;
  margin: 0 0.25em;
`;

export const HintTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;
`;

export const HintText = styled(Typography)`
  &&&&& {
    color: "#000000";
    font-weight: 500;
    font-family: Graphik;
  }
`;

export const StyledAvatar = styled(Avatar)`
  &&&&& {
    background: ${theme.palette.ssh.main};
  }
`;

export const FinalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8em 0 0 0;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

export const SuccessSecondLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  align-self: center;
`;

export const ResultTitleTypography = styled(Typography)`
  &&&&& {
    margin: 0 1em 0.5em 1em;
    color: "#000000";
    font-weight: 700;
    font-family: Graphik;
  }
`;

export const OkButton = styled(Button)`
  &&&&& {
    color: #fff;
    background: ${theme.palette.ssh.main};
    width: 25%;
    align-self: center;
    margin-top: 3em;
  }
`;

export const ToAccessButtonIndustry = styled(Button)`
  &&&&& {
    color: #fff;
    background: ${theme.palette.ssh.main};
    width: 300px;
    align-self: center;
    margin: 2em 0 5em 0.25em;
  }
`;

export const SuccessTypography = styled(Typography)`
  &&&&& {
    color: ${theme.palette.ssh.main};
    text-align: center;
  }
`;

export const StyledSelect = styled(Select)`
  text-align: left;
`;
export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1em 5em;
`;
export const ToAccessTypography = styled(Typography)`
  &&&&& {
    color: ${theme.palette.ssh.main};
    text-align: center;
    margin: 0.5em 0 0 0;
    font-size: 140%;
    margin-left: 1em;
    font-family: Graphik;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
`;

export const ProcessingCircularProgress = styled(CircularProgress)`
  &&&&& {
    color: "#000000";
    font-weight: 300;
    margin: 0 1em 0 -2em;
  }
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -0.7em 0 1em 0.35em;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  &&&&& {
    background: ${theme.palette.ssh.main};
    color: #fff;
  }
`;

export const DialogTextWrapper = styled.div`
  margin: 2em 1.5em 1.5em 1.5em;
`;

export const ApproveButton = styled(Button)`
  width: 180px;
  &&&&& {
    background: ${theme.palette.ssh.main};
  }
`;
export const AcceptButton = styled(Button)`
  &&&&& {
    display: flex;
    flex: auto;
    color: #fff;
    background: ${(props) =>
      props.disabled ? theme.palette.primary.light : theme.palette.ssh.main};
    width: 180px;
    margin: 0 3px 0.5em 1em;
  }
`;

export const DeclineButton = styled(Button)`
  width: 180px;
  &&&&& {
    border-color: ${theme.palette.ssh.main};
    color: ${theme.palette.ssh.main};
    margin: 0 1em 0.5em 3px;
  }
`;
export const StyledPaper = styled(Paper)`
  display: inline-flex;
  overflow-y: scroll;
  max-height: 600px;
  min-height: 100px;
  width: 95%;
`;

export const PendingApprovalWrapper = styled.div`
  min-height: 300px;
`;

export const StyledDialog = styled(Dialog)`
  &&& {
    display: ${(props) => props.display && "none"};
    padding: 10px;
  }
`;

export const AccessFormTextField = styled(TextField)`
  &&&&& {
    width: 100%;
    margin-top: 1em;
  }
`;

export const TableFirstRow = styled(TableCell)`
  &&&&& {
    background-color: #ffffff;
  }
`;

export const StyledTableHead = styled(TableHead)`
  &&&&& {
    color: ${theme.typography.mainColor};
  }
`;

export const RevokeButton = styled(Button)`
  &&&&& {
    border-color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.status.stopped}`};
    color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.status.stopped}`};
    width: 150px;
    height: 35px;
    position: relative;
    border-width: 2px;
    border-radius: 0px;
    font-size: 15px;
  }
`;

export const RevokeAccessButton = styled(Button)`
  &&&&& {
    color: rgb(206, 36, 36);
    border-color: rgb(206, 36, 36);
    width: 150px;
    height: 35px;
    position: relative;
    border-width: 2px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const AddUserButton = styled(Button)`
  &&&&& {
    color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.palette.ssh.main}`};
    border-color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.palette.ssh.main}`};
    width: 150px;
    height: 35px;
    position: relative;
    border-width: 2px;
    border-radius: 0px;
    text-size: 15px;
  }
`;

export const UpdateButton = styled(SmallButton)`
  &&&&& {
    font-size: 15px;
    font-weight: 600;
    /* border-color: ${theme.palette.ssh.main}; */
    /* color: ${theme.palette.ssh.main}; */
    color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.palette.ssh.main}`};
    border-color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.palette.ssh.main}`};
  }
`;

export const DownloadButton = styled(SmallButton)`
  &&&&& {
    font-size: 15px;
    // font-weight: bold;
    /* border-color: ${theme.palette.ssh.main}; */
    /* color: ${theme.palette.ssh.main}; */
    color: #ffffff;
    background: #A100FF;
    
  }
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  &&&&& {
  }
`;

export const StyledIconButton = styled(IconButton)`
  &&&&& {
    color: ${(props) =>
      props.disabled ? `${grey[400]}` : `${theme.status.stopped}`};
    border-radius: 0;
    padding: 0;
    margin: 12px;
    /* margin: ${(props) => (props.margin ? props.margin : "0")}; */
  }
`;

export const HoverTableRow = styled(TableRow)`
  &:hover {
    background: ${grey[100]};
  }
`;

export const ChipsWrapper = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
`;

export const ChipWrapper = styled.div`
  margin: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
