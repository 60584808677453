import { Card } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../theme/theme";
import { LargeSubtitle } from "../../common/CommonStyles/CommonTypographyStyles";

export const StyledCard = styled(Card)`
  &&&&& {
    height: 242px;
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
    border-radius: 0px;
    @media (max-width: 1300px) {
      height: 293px;
    }
    @media (max-width: 1100px) {
      height: 243px;
    }
  }
`;

export const StatusSideBar = styled.div`
  writing-mode: vertical-lr;
  width: 100%;
  text-align: center;
  transform: rotate(180deg);
  height: 242px;
  border-left-style: solid;
  border-color: ${theme.backgroundBorderGreys.border};
  border-width: 1px;
  @media (max-width: 1300px) {
    height: 293px;
  }
  @media (max-width: 1100px) {
    height: 243px;
  }
`;

export const StatusSideBarText = styled(LargeSubtitle)`
  &&&&& {
    font-weight: normal;
    font-size: 17px;
    color: white;
    @media (min-width: 1200px) {
      font-size: 24px;
    }
    @media (min-width: 1500px) {
      font-size: 28px;
    }
    @media (min-width: 2000px) {
      font-size: 32px;
    }
    @media (min-width: 2300px) {
      font-size: 36px;
    }
  }
`;

export const TitleBar = styled.div`
  height: 42px;
  background: ${theme.backgroundBorderGreys.background};
  border-bottom-style: solid;
  border-color: ${theme.backgroundBorderGreys.border};
  border-width: 1px;
  text-align: center;
`;
