import { Card, Divider, TextField } from "@material-ui/core";
import styled from "styled-components";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import theme from "../../../theme/theme";

export const StyledFileCopyIcon = styled(FileCopyIcon)`
  &&&&& {
    color: #000000;
    opacity: 80%;
  }
`;

export const StyledCard = styled(Card)`
  &&&&& {
    height: 242px;
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
    border-radius: 0px;
  }
`;

export const StyledResourceCard = styled(StyledCard)`
  &&&&& {
    margin: 3%;
    height: 80vh;
  }
`;
export const StyledGuideCard = styled(StyledCard)`
  &&&&& {
    margin: 3%;
    height: 100%;
    border-radius: 0px;
    border-color: ${theme.backgroundBorderGreys.border};
  }
`;

export const StyledAdvancedCard = styled(Card)`
  &&&&& {
    margin: 0% 0% 3% 0%;
    width: 100%;
    border-style: solid;
    border-color: ${theme.backgroundBorderGreys.border};
    border-width: 1px;
    border-radius: 0px;
  }
`;

export const SubtitleWrapper = styled.div`
  &&& {
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledDivider = styled(Divider)`
  &&&&& {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const NameTextField = styled(TextField)`
  &&&&& {
    width: 100%;
  }
`;

export const CenteredImage = styled.img`
  &&& {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledBanner = styled.img`
  &&& {
    width: 100%;
    margin: 20px 0px;
    margin-left: 0px;
  }
`;
