import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { List, ListItemAvatar, Avatar, Divider, DialogActions, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import {
  SingleCard,
  StyledListItem,
  ProductWrapper,
  TypeName,
  AvatarWrapper,
  NameIconWrapper,
} from "./FuiStyles";
import BookIcon from "@material-ui/icons/Book";
import WidgetsIcon from "@material-ui/icons/Widgets";
import DvrIcon from "@material-ui/icons/Dvr";
import { ProductsInfomation } from "./Constants/ProductsInfomation";
import {
  ProductsInformationPam,
  ProductsInformationMcc,
} from "./Constants/ProductsInformationOtherIntiatives";

import { withRouter } from "react-router-dom";
import * as fuiRedux from "../../redux/fui/index";
import { tokenValidation } from "../../helper/customizedHooks";
import PropTypes from "prop-types";
import { SmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
//import DialogComponent from "../ec2management/ActionItems/DialogComponent";
import { CommonDialog } from "../common/CommonComponents/Dialog";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import ProductCard from "./ProductCard";
import { Footer } from "../common/Footer";

/**
 *
 * @author Xiao Luo
 * @version 1.0
 * @see Used in component: FulfilmentUI and FuiHomePage
 */
const StandardOrder = ({ history, resetFuiState, belongingTo }) => {
  useEffect(() => {
    tokenValidation();
    resetFuiState();
  }, []);

  //New Sections to this page should be included here as well as the belongingTo input
  const availableTypes = ["aaammg", "pam"];

  const sectionsToBuild = belongingTo.filter((initiative) =>
    availableTypes.includes(initiative)
  );

  const [openSplunkClusterWarningDialog, setOpenSplunkClusterWarningDialog] = useState(false);

  const handleSplunkClusterWarningSubmit = (ProductsInfomation) => {
    //alert(ProductsInfomation)
    history.push(`/fui/order/splunkClusterPlatform`)
    // setOpenSplunkClusterWarningDialog(false);
  };

  const getTitle = (belong) => {
    var res = "";
    switch (belong) {
      case "aaammg":
        res = "AiOps Provisioning";
        break;
      case "mcc":
        res = "MCC Provisioning";
        break;
      case "pam":
        res = "PAM Provisioning";
        break;
      default:
        res = "";
    }
    return <SmallHeader style={{ float: "left" }}>{res}</SmallHeader>;
  };

  return (
    <div>
      {sectionsToBuild.map((belong) => (
        <List>
          <StyledListItem>{getTitle(belong)}</StyledListItem>
          <Divider component="li" />
          {/* New FUI Design - START */}
          {belong === "aaammg" && (
            <div>
              <StyledListItem>
                <AvatarWrapper>
                  <ListItemAvatar>
                    <Avatar>
                      <DvrIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <NameIconWrapper>
                    <TypeName>PLATFORMS</TypeName>
                  </NameIconWrapper>
                </AvatarWrapper>
                <ProductWrapper>
                  <Grid container spacing={3} style={{ flexGrow: "1" }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>
                        {ProductsInfomation.products.map((product) =>
                          product.type === "PLATFORMS" ? (

                            <Grid key={product.id} item >
                              <ProductCard product={product} />
                            </Grid>
                          ) : null
                        )}
                        {openSplunkClusterWarningDialog && (
                          <CommonDialog
                            open={openSplunkClusterWarningDialog}
                            onClose={() => setOpenSplunkClusterWarningDialog(false)}
                            title={"WARNING"}
                            dialogType={"servers"}
                            textContent={"If your daily data ingestion is less than 50Gb, go back to Fulfillment Order page and select Dedicated Platform instead of Splunk Cluster Platform. Do you still want to proceed in provisioning Splunk Cluster Platform?"}
                            dialogActions={
                              <DialogActions >
                                <DeclineButton
                                  onClick={() => setOpenSplunkClusterWarningDialog(false)}
                                  label={"No"}
                                  disabled={false}
                                />
                                <AcceptButton
                                  onClick={() => handleSplunkClusterWarningSubmit()}
                                  label={"Yes"}
                                />
                              </DialogActions>
                            }
                          ></CommonDialog>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ProductWrapper>
              </StyledListItem>
            </div>
          )}
         
          <Divider component="li" />
          {belong === "aaammg" && (
            <div>
              <StyledListItem>
                <AvatarWrapper>
                  <ListItemAvatar>
                    <Avatar>
                      <DvrIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <NameIconWrapper>
                    <TypeName>APPS</TypeName>
                  </NameIconWrapper>
                </AvatarWrapper>
                <ProductWrapper>
                  <Grid container spacing={3} style={{ flexGrow: "1" }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>
                        {ProductsInfomation.products.map((product) =>
                          product.type === "APPS" ? (
                            <Grid key={product.id} item>
                              <ProductCard product={product} />
                            </Grid>
                          ) : null
                        )}
                        {openSplunkClusterWarningDialog && (
                          <CommonDialog
                            open={openSplunkClusterWarningDialog}
                            onClose={() => setOpenSplunkClusterWarningDialog(false)}
                            title={"WARNING"}
                            dialogType={"servers"}
                            textContent={"If your daily data ingestion is less than 50Gb, go back to Fulfillment Order page and select Dedicated Platform instead of Splunk Cluster Platform. Do you still want to proceed in provisioning Splunk Cluster Platform?"}
                            dialogActions={
                              <DialogActions >
                                <DeclineButton
                                  onClick={() => setOpenSplunkClusterWarningDialog(false)}
                                  label={"No"}
                                  disabled={false}
                                />
                                <AcceptButton
                                  onClick={() => handleSplunkClusterWarningSubmit()}
                                  label={"Yes"}
                                />
                              </DialogActions>
                            }
                          ></CommonDialog>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ProductWrapper>
              </StyledListItem>
            </div>
          )}

          <Divider component="li" />
          {belong === "aaammg" && (
            <div>
              <Divider component="li" />
              <StyledListItem>
                <AvatarWrapper>
                  <ListItemAvatar>
                    <Avatar>
                      <BookIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <NameIconWrapper>
                    <TypeName>LICENSES</TypeName>
                  </NameIconWrapper>
                </AvatarWrapper>
                <ProductWrapper>
                  <Grid container spacing={3} style={{ flexGrow: "1" }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>

                        {ProductsInfomation.products.map((product) =>
                          product.type === "Lisence" ? (
                            <Grid key={product.id} item>
                              <ProductCard product={product} />
                            </Grid>
                          ) : null
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                </ProductWrapper>
              </StyledListItem>
            </div>
          )}
          <Divider component="li" />
          {/* New FUI Design - END */}


          {belong === "pam" && (
            <div>
              <StyledListItem>
                <AvatarWrapper>
                  <ListItemAvatar>
                    <Avatar>
                      <DvrIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <NameIconWrapper>
                    <TypeName>PLATFORMS</TypeName>
                  </NameIconWrapper>
                </AvatarWrapper>
                <ProductWrapper>
                  <Grid container spacing={3} style={{ flexGrow: "1" }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>
                        {(belong === "aaammg"
                          ? ProductsInfomation
                          : belong === "mcc"
                            ? ProductsInformationMcc
                            : ProductsInformationPam
                        ).products.map((product) =>
                          product.type === "Instance" ? (
                            <Grid key={product.id} item>
                              <ProductCard product={product} />
                            </Grid>

                          ) : null
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ProductWrapper>
              </StyledListItem>
              <Divider component="li" />
              <StyledListItem>
                <AvatarWrapper>
                  <ListItemAvatar>
                    <Avatar>
                      <WidgetsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <NameIconWrapper>
                    <TypeName>APPS</TypeName>
                  </NameIconWrapper>
                </AvatarWrapper>
                <ProductWrapper>
                  <Grid container spacing={3} style={{ flexGrow: "1" }}>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" spacing={3}>
                        {ProductsInformationPam.products.map((product) =>
                          product.type === "App" ? (
                            <Grid key={product.id} item>
                              <ProductCard product={product} />
                            </Grid>
                          ) : null
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ProductWrapper>
              </StyledListItem>
            </div>
          )}

        </List>
      ))}
      <div style={{
        left: 96,
        bottom: 0,
        right: 26,
      }}>
        <Footer />
      </div>
    </div>
  );
};
StandardOrder.propTypes = {
  /**
   * History property of route library
   */
  history: PropTypes.object,
  /**
   * Redux function to clear the current fulfilement state
   */
  resetFuiState: PropTypes.func,
};

const mapStateToProps = (state) => ({
  belongingTo: state.auth.initiativesBelongTo,
});

export default connect(mapStateToProps, {
  resetFuiState: fuiRedux.resetFuiState,
})(withRouter(StandardOrder));
