import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { Grid, CircularProgress, Button, TextField, FormHelperText } from "@material-ui/core";
import { Subtitle3, XSmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../assets";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { Footer } from "../common/Footer";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { tokenValidation } from "../../helper/customizedHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import base from "../../config";
import { headers } from "../../helper/api";
import { makeStyles } from "@material-ui/core/styles";
import { CommonDialog } from "../common/CommonComponents/Dialog.js";
import {
  Flex,
  Pharagraph,
  ButtonWrapper,
  PharagraphWrapper,
  StyledTextFieldForm,
} from "../ec2management/ServerList/styledServerList.js";
import { DialogActions, RadioGroup } from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { StyledFormControl, StyledSelect, StyledRadioGroup } from "../fui/FuiStyles";
import { MenuItem, InputLabel } from "@material-ui/core";
import moment from "moment";
import teamNames from "./TeamNames.js";
/**
 * Server Review page
 * @author shilpa.adikeshava
 * @version 1.0
 */
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    boxShadow: "unset",
    padding: "15px 32px 32px 32px",
  },
  count: {
    margin: "0 0 0 0.3em",
    color: "#ffffff",
    margin: "0 0 0 0.3em",
    fontSize: "1.8rem",
    background: "#7500c0",
    padding: "0.2rem 0.9rem 0.2rem 0.9rem",
    borderRadius: "3rem",
  },
}));

const ServerReview = (email, idToken) => {
  const [loaded, setLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setData] = useState({});
  const [modalStyle] = React.useState(getModalStyle);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [serverReviewList, setServerReviewList] = useState([]);
  const [newFormValueCollector, setNewFormValueCollector] = useState({});
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [currentServiceEndDate, setCurrentServiceEndDate] = useState("");
  const [clientInstance, setClientInstance] = useState(null);
  const classes = useStyles();
  const [clientCheck, setClientCheck] = useState("undefined");
  const [clientName, setClientName] = useState("undefined");
  const [teamName, setTeamName] = useState("undefined");
  const [env, setEnv] = useState("");
  const [sed, setSed] = useState("");
  const [appName, setAppName] = useState("");
  const [notes, setNotes] = useState("");
  const [customerEID, setCustomerEID] = useState("");
  const [secPoc, setSecPoc] = useState("");
  const [useraction, setUserAction] = useState("undefined");
  const [instanceID, setInstanceID] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [instance, setInstance] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [errorClientInstance, setErrorClientInstance] = useState(false);
  const [errorClientName, setErrorClientName] = useState(false);
  const [errorTeamName, setErrorTeamName] = useState(false);
  const [errorUserAction, setErrorUserAction] = useState(false);
  const [responseValid, setResponseValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openSaveError, setOpenSaveError] = useState(false);

  let getMaxDate = (date) => {
    const maxDateEnv = moment()
      .add(date, "days")
      .format("YYYY-MM-DD");
    return maxDateEnv;
  };

  const handleEditFieldChange = (e, field) => {
    let value = e.target.value;

    switch (field) {
      case "serviceEndDate":
        const [year, month, day] = value.split("-");
        let dateFormat = `${day}/${month}/${year}`;
        updatedFormValues = { ...modalData, [field]: dateFormat };
    }
    const updatedFormValues = { ...modalData, [field]: value };
    setNewFormValueCollector(updatedFormValues);
  };

  useEffect(() => {
    const currentDate = moment().format("DD-MM-YYYY");
    if (modalData.serviceEndDate === undefined) {      
      let [day, month, year] = currentDate.split("-");
      if (day && month && year) {
        setCurrentServiceEndDate(`${year}-${month}-${day}`);
      }
    } else {
      let [day, month, year] = modalData.serviceEndDate.split("/");

      if (day && month && year) {
        setCurrentServiceEndDate(`${year}-${month}-${day}`);
      }
    }
    setMinDate(currentDate);
    const endDate = getMaxDate(28);
    setMaxDate(endDate);
  }, [modalData, serverReviewList]);

  useEffect(() => {
    let timer;
    if (email && idToken) {
      fetchserverReviewList(email);
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchserverReviewList(email);
        };
        apiCall();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [email, idToken]);

  const fetchserverReviewList = async () => {
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];

    if (customerEnterpriseId && idToken) {
      return Axios.get(
        `${base.urls.apis.serverReview}` + `?username=${customerEnterpriseId}`,
        {
          headers: { ...headers, Authorization: idToken },
        }
      )
        .then((res) => {
          setIsLoading(false);          
          const data = res.data;
          setServerReviewList(data);
        })
        .catch((err) => {
          //toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
        });
    }
  };

  const handleClose = () => {
    setData(" ");
    setOpenActionModal(false);
    setOpenSave(false);
    setOpenSaveError(false);
    setClientCheck("undefined");
    setClientName("undefined");
    setTeamName("undefined");
    setErrorClientInstance(false);
    setErrorClientName(false);
    setErrorTeamName(false);
    setErrorUserAction(false);
    setResponseValid(false);
  };

  const onKeyUpFalse = (e) => {
    e.preventDefault();
    return false;
  };

  const onKeyDownFalse = (e) => {
    e.preventDefault();
    return false;
  };

  const handleEdit = (row) => {
    setData(row);
    setOpenActionModal(true);
    setInstanceID(row.resource_id)
    setName(row.name)
    setRegion(row.region)
    setInstance(row.instance)
    setResourceType(row.resource_type)
    setClientInstance(row.clientInstance)
    setClientName(row.client_name)
    setTeamName(row.teamName)
    setEnv(row.environment)
    setSed(row.serviceEndDate)
    setAppName(row.applicationName)
    setNotes(row.notes)
    setCustomerEID(row.customerEnterpriseID)
    setSecPoc(row.secondaryPOC)
    setUserAction("undefined")
    setClientInstance(row.clientInstance)

  };

  const handleUpdates = (value, fieldName) => {

    if (fieldName == "clientinstance") {
      if (value.length > 0) {
        setClientCheck(value);
        setErrorClientInstance(false)
      } else {
        setClientCheck("undefined")
        setErrorClientInstance(true);
      }
    } else if (fieldName == "clientname") {
      if (value.length > 0) {
        setClientName(value);
        setErrorClientName(false)
      } else {
        setClientName("undefined");
        setErrorClientName(true);
      }
    } else if (fieldName == "teamname") {
      if (value.length > 0 || value !== "undefined" || value !== "" || value !== " ") {
        setTeamName(value);
        setErrorTeamName(false)
      } else {
        setTeamName("undefined");
        setErrorTeamName(true);
      }
    } else if (fieldName == "environment") {
      if (value.length > 0) {
        setEnv(value)
      } else {
        setEnv(modalData.environment)
      }
    } else if (fieldName == "serviceenddate") {
      if (value.length > 0) {
        setSed(value)
      } else {
        setSed(modalData.serviceEndDate)
      }
    } else if (fieldName == "applicationname") {
      if (value.length > 0) {
        setAppName(value)
      } else {
        setAppName(modalData.applicationName)
      }
    } else if (fieldName == "notes") {
      if (value.length > 0) {
        setNotes(value)
      } else {
        setNotes(modalData.notes)
      }
    } else if (fieldName == "customereid") {
      if (value.length > 0) {
        setCustomerEID(value)
      } else {
        setCustomerEID(modalData.customerEnterpriseID)
      }
    } else if (fieldName == "secpoc") {
      if (value.length > 0) {
        setSecPoc(value)
      } else {
        setSecPoc(modalData.secondaryPOC)
      }
    } else if (fieldName == "useraction") {
      if (value.length > 0) {
        setUserAction(value)
        setErrorUserAction(false);
      } else {
        setUserAction("undefined");
        setErrorUserAction(true);
      }
    }
  };

  const handleValidation = () => {

    if (clientCheck == "undefined" || clientCheck == "") {
      setErrorClientInstance(true)
      setResponseValid(false)
    } else if (clientCheck == "Yes" && (clientName == "undefined" || clientName == "")) {
      setErrorClientName(true)
      setResponseValid(false)
    } else if (clientCheck == "No" && (teamName == "undefined" || teamName == "")) {
      setErrorTeamName(true)
      setResponseValid(false)
    } else if (useraction == "undefined" || useraction == "") {
      setErrorUserAction(true)
      setResponseValid(false)
    } else {
      setResponseValid(true)
      setErrorClientInstance(false)
      setErrorClientName(false)
      setErrorTeamName(false)
      setErrorUserAction(false)
      setOpenSave(true)
      handleSaveResponse()
    }
  }

  const handleSaveResponse = () => {
    setLoader(true);
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];
    const url = `${base.urls.apis.serverReview}?username=${customerEnterpriseId}`;

    let instanceValue = instance == null ? "undefined" : instance

    Axios.post(url, " ", {
      headers: { Authorization: idToken },
      params: {
        "resource_id": instanceID,
        "name": name,
        "region": region,
        "client_instance": clientCheck,
        "client_name": clientName,
        "team_name": teamName,
        "resource_type": resourceType,
        "instance": instanceValue,
        "environment": env,
        "service_enddate": sed,
        "app_name": appName,
        "notes": notes,
        "customer_eid": customerEID,
        "sec_poc": secPoc,
        "user_action": useraction,
      }
    }).then((res) => {
      // console.log("res::,res")
      setLoader(false)
    }).catch((err) => {
      console.log("Async error", err);
      setLoader(false)
      setOpenSave(false);
      setOpenSaveError(true);
    });

  }

  return (
    <div>
      <div className="mainDiv">
        <Grid className="headerItems" container>
          <Grid item md={3}>
            <img
              alt=""
              src={images.NavLogoImage}
              // width="70%"
              style={{ float: "left" }}
            />
          </Grid>
          <Grid item md={9} style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <XSmallHeader
              className="recomHeader"
              style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "500", float: "right" }}
            >
              {" "}
              Server Review{" "}
              {serverReviewList.length > 0 ? (
                <span className={classes.count}>{serverReviewList.length}</span>
              ) : (
                ""
              )}
            </XSmallHeader>
            <ToastContainer />
          </Grid>
        </Grid>
        <Grid>
          <h3 style={{ fontWeight: "500", display: "flex", justifyContent: "end"}}>Review servers to ensure governance and enhance data and lifecycle management</h3>
        </Grid>
        <Divider />
      </div>
      <div>
        <br></br>
        <div>
          {isLoading ? (
            <div
              id="loader"
              style={{
                paddingTop: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
          ) : (
            <div>
              <div id="myservers">
                <br></br>
                <br></br>
                {serverReviewList.length > 0 ? (
                  <div>
                    <Paper className={styles.root}>
                      <div
                        className={styles.tableWrapper}
                        style={{
                          background: "#f2f2f2",
                          maxWidth: "95% !important",
                        }}
                      >
                        <Table className={styles.table}>
                          <TableHead style={{ backgroundColor: "#7500C0" }}>
                            <TableRow>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Resource ID
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Environment
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Service End Date
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Application Name
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Notes
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Customer Enterprise ID
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Secondary POC
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {serverReviewList.map((row, index) => (
                            <TableRow
                              key={index}
                              style={{ backgroundColor: "#fff" }}
                            >
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.resource_id}
                              </TableCell>
                              
                              {/* Other table cells remain unchanged */}
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.environment}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.serviceEndDate}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.applicationName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.notes}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.customerEnterpriseID}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.secondaryPOC}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.action ? (
                                  row.action
                                ) : (
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Grid item style={{ cursor: "pointer" }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEdit(row)}
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        ATTEST
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </div>
                    </Paper>
                  </div>
                ) : (
                  <div style={{ alignContent: "left" }}>
                    <h3
                      style={{
                        textAlign: "left",
                        fontFamily: "Graphik",
                        paddingTop: "10px",
                        fontWeight: "500",
                      }}
                    >
                      There are no pending approvals for you to review!
                    </h3>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />

      <div>
        <CommonDialog
          open={openSave}
          onClose={() => handleClose()}
          title={"Save Response"}
          dialogType={"ssh"}
          dialogContent={
            loader ? (
              <div
                style={{
                  padding: "8%",
                  // paddingBottom: "8%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} />
              </div>
            ) : (
              <div
                style={{
                  padding: "8%",
                  // paddingBottom: "8%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <h4>Your response is saved successfully!</h4>
                <Flex style={{ justifyContent: "center", paddingTop: "3%" }}>
                  <AcceptButton
                    onClick={() => handleClose()}
                    label={"OK"}
                  />
                </Flex>
              </div>
            )

          }
        />
      </div>

      <div>
        <CommonDialog
          open={openSaveError}
          onClose={() => handleClose()}
          title={"Save Response"}
          dialogType={"ssh"}
          dialogContent={
            <div
              style={{
                padding: "8%",
                // paddingBottom: "8%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <h4>Oops! An error occurred while saving your response. Please try again later.</h4>
              <Flex style={{ justifyContent: "center", paddingTop: "3%" }}>
                <AcceptButton
                  onClick={() => handleClose()}
                  label={"OK"}
                />
              </Flex>
            </div>
          }
        />
      </div>

      <div>
        <CommonDialog
          open={openActionModal}
          onClose={() => handleClose()}
          title={"EDIT - " + modalData.resource_id}
          dialogType={"servers"}
          dialogContent={
            modalData && (
              <div>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Client Instance:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl variant="outlined" margin="dense" error={errorClientInstance}>
                    <StyledRadioGroup
                      onChange={(e) =>
                        handleUpdates(e.target.value, "clientinstance")
                      }
                    // defaultValue={modalData.clientInstance}
                    >
                      <Flex>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </Flex>
                    </StyledRadioGroup>
                    {errorClientInstance ? <FormHelperText style={{ margin: "0px 0px 0px 50px" }}>Please select if this is a client instance</FormHelperText> : ""}
                  </StyledFormControl>

                </Flex>

                {clientCheck == "Yes" ?
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Client Name:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledTextFieldForm
                      width={"100%"}
                      defaultValue={modalData.client_name == "undefined" ? "" : modalData.client_name}
                      label="Client Name"
                      // customerenterpriseidcy="Client_Name"
                      margin="dense"
                      variant="outlined"
                      onChange={(e) => handleUpdates(e.target.value, "clientname")}
                      helperText={
                        errorClientName && "Please enter the client name"
                      }
                      error={errorClientName}
                    />

                  </Flex>
                  : clientCheck == "No" ? <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Team Name:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledFormControl
                      variant="outlined"
                      margin="dense"
                      style={{ marginLeft: "25%" }}
                      error={errorTeamName}
                    >
                      <InputLabel htmlFor="outlined-service-group">
                        Team Name
                      </InputLabel>
                      <StyledSelect
                        onChange={(e) => handleUpdates(e.target.value, "teamname")}
                        label="Team Name"
                        margin="dense"
                        variant="outlined"
                        defaultValue={modalData.teamName}
                      >
                        <MenuItem value="">
                          <em>--Select Team Name--</em>
                        </MenuItem>
                        {teamNames.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      {/* {`errorTeamName: ${errorTeamName}`} */}

                      {errorTeamName ? <FormHelperText>Please select a team name</FormHelperText> : ""}
                    </StyledFormControl>

                  </Flex> : ""

                }

                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Environment:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ marginLeft: "25%" }}
                  >
                    <InputLabel htmlFor="outlined-service-group">
                      Environment
                    </InputLabel>
                    <StyledSelect
                      defaultValue={modalData.environment}
                      onChange={(e) => handleUpdates(e.target.value, "environment")}
                      label="Environment"
                      margin="dense"
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>--Select environment--</em>
                      </MenuItem>
                      <MenuItem value="Production">Production</MenuItem>
                      <MenuItem value="Dev">Development</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Test">Test</MenuItem>
                    </StyledSelect>
                  </StyledFormControl>
                </Flex>

                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Service End Date:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    defaultValue={currentServiceEndDate}
                    type="date"
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                      handleUpdates(e.target.value, "serviceenddate")
                    }
                    inputProps={{
                      min: minDate,
                      max: maxDate,
                      onKeyDown: onKeyDownFalse,
                      onKeyUp: onKeyUpFalse,
                    }}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Application Name:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    label="Application Name"
                    width={"100%"}
                    customerenterpriseidcy="applicationName"
                    defaultValue={modalData.applicationName}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                      handleUpdates(e.target.value, "applicationname")
                    }
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Notes:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    label="Notes"
                    width={"100%"}
                    customerenterpriseidcy="notes"
                    defaultValue={modalData.notes}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleUpdates(e.target.value, "notes")}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Customer Enterprise ID:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    width={"100%"}
                    label="Customer Enterprise ID"
                    customerenterpriseidcy="customerEnterpriseID"
                    defaultValue={modalData.customerEnterpriseID}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                      handleUpdates(e.target.value, "customereid")
                    }
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Secondary POC:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    width={"100%"}
                    label="Secondary POC"
                    customerenterpriseidcy="secondaryPOC"
                    defaultValue={modalData.secondaryPOC}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleUpdates(e.target.value, "secpoc")}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Action:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ marginLeft: "25%" }}
                    error={errorUserAction}
                  >
                    <InputLabel htmlFor="outlined-service-group">
                      Action
                    </InputLabel>
                    <StyledSelect
                      defaultValue="undefined"
                      onChange={(e) => handleUpdates(e.target.value, "useraction")}
                      label="Action"
                      margin="dense"
                      variant="outlined"
                    >
                      <MenuItem value="undefined">
                        <em>--Select an action--</em>
                      </MenuItem>
                      <MenuItem value="Continue">Continue to use</MenuItem>
                      <MenuItem value="Terminate">Terminate</MenuItem>
                    </StyledSelect>
                    {/* {`errorUserAction: ${errorUserAction}`} */}

                    {errorUserAction ? <FormHelperText>Please select an action</FormHelperText> : ""}
                  </StyledFormControl>

                </Flex>
              </div>
            )
          }
          dialogActions={
            <DialogActions style={{ marginTop: "10%", marginBottom: "5%" }}>
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => handleClose()}
                  canceledittagsbuttoncy="cancelEditTagsButtonCy"
                  label={"Cancel"}
                />&nbsp;&nbsp;
                <AcceptButton
                  saveedittagsbuttoncy="saveEditTagsButtonCy"
                  label={"Save"}
                  onClick={() => handleValidation()}
                >
                  {loader ? (
                    <CircularProgress
                      size={28}
                    // style={{ color: `${theme.palette.ssh.main}` }}
                    />
                  ) : "Save"}
                </AcceptButton>
              </ButtonWrapper>
            </DialogActions>
          }
        ></CommonDialog>
      </div>

      <div>
        <CommonDialog
          open={openClientModal}
          onClose={() => setOpenClientModal(false)}
          title={"Is it a client instance?"}
          dialogType={"servers"}
          dialogContent={
            clientInstance == "Yes" ? (
              <div>
                {" "}
                <p style={{ padding: "20px 30px 0px 30px" }}>You have chose <b>"Yes"</b>. Please input clientname and save it.</p>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Client Name:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    width={"100%"}
                    label="Client Name"
                    customerenterpriseidcy="Client_Name"
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleEditFieldChange(e, "client_name")}
                  />
                </Flex>
              </div>
            ) : (
              <div>
                {" "}
                <p style={{ padding: "20px 30px 0px 30px" }}>You have chose <b>"No"</b>. Please select team name from the dropdown and save it.</p>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Team Name:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ marginLeft: "25%" }}
                  >
                    <InputLabel htmlFor="outlined-service-group">
                      Team Name
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => handleEditFieldChange(e, "client_name")}
                      label="Team Name"
                      margin="dense"
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>--Select Team Name--</em>
                      </MenuItem>
                      {teamNames.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </StyledFormControl>
                </Flex>
              </div>
            )
          }
          dialogActions={
            <DialogActions style={{ marginTop: "10%", marginBottom: "5%" }}>
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => setOpenClientModal(false)}
                  canceledittagsbuttoncy="cancelEditTagsButtonCy"
                  label={"Cancel"}
                />&nbsp;&nbsp;
                <AcceptButton
                  saveedittagsbuttoncy="saveEditTagsButtonCy"
                  label={"Save"}
                // onClick={handleSaveResponse}
                />
              </ButtonWrapper>
            </DialogActions>
          }
        ></CommonDialog>
      </div>
    </div>
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
});

export default connect(MSTP)(withRouter(ServerReview));