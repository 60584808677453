const path = require("path");
require("dotenv").config();

var dev = process.env.NODE_ENV === "development";
const cloudRootNew = process.env.REACT_APP_CLOUD_ROOT;
const newBaseUrl = process.env.REACT_APP_API_URL;
var base = {
  app: {
    root: path.normalize(path.join(__dirname)),
    env: dev,
    images: process.env.PUBLIC_URL + "/images",
    redirectName: "mycloud",
  },
  urls: {
    apis: {
      recomAuth: `${cloudRootNew}auth/auth1/token`,
      approvalsAuth: `${cloudRootNew}auth/auth2/token`,
      serverReviewAuth: `${cloudRootNew}auth/auth3/token`,
      auth: `${cloudRootNew}auth/token`,
      ec2: `${newBaseUrl}ec2/ec2instances`,
      sshNew: `${newBaseUrl}ssh-management/`,
      notifications: `${newBaseUrl}notifications/`,
      fulfillment: `${newBaseUrl}fui`,
      fui: `${newBaseUrl}fui/orders`,
      dashboard: `${newBaseUrl}dashboard/`,
      sshConfig: `${newBaseUrl}ec2/`,
      faq: `${newBaseUrl}faq`,
      admin: `${newBaseUrl}admin-access-controls/`,
      rbac: `${newBaseUrl}role-based-access-control`,
      recommendations: `${newBaseUrl}recommendations`,
      azure: `${newBaseUrl}azure`,
      sshAccess: `${newBaseUrl}ssh-access`,
      details: `${newBaseUrl}get-details`,
      order: `${newBaseUrl}provision/mywizard-aiops`,
      serverReview: `${newBaseUrl}server-review`
    },
  },
};

module.exports = base;
