import React, { useState, useEffect, Fragment } from "react";

// Material UI
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Radio,
  TextField,
  InputLabel,
  FormControl,
  NativeSelect,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";

// Styled components
import {
  ButtonWrapper,
  StyledCheckbox,
  SchedulerWrapper,
  StyledRadioGroup,
  TimePickerWrapper,
  PickerDialogContent,
  TimerMessageErrorFormat,
} from "../StyledTimePicker";
import {
  StyledFormControl,
  StyledSelect,
} from "../../../fui/FuiStyles";
import {
  AcceptButton,
  DeclineButton,
} from "../../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../../common/CommonComponents/Dialog";

// Components
import Axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { timeZoneArr } from "../TimePickerActions/AvailableTimeZone";
import { tokenValidation } from "../../../../helper/customizedHooks";
import base from "../../../../config";
import { TimeFormatConverter } from "../TimePickerActions/TimeFormatConverter";
import { setLoadingByClick } from "../../../../redux/ServersReducer/ServersActions";
/**
 * In the TimePickerContainer the user should modify the schedule for an instance.
 * @author Carlos Montes
 * @version 1.0
 * @see Used in component: TimePickerContainer.js
 */

export const SetSchedule = ({
  item,
  currentInstanceState,
  email,
  loadingIconStatusBool,
  idToken,
  setHandleApiCall,
  openNewSchedule,
  setOpenNewSchedule,
  setLoadingByClick,
  activeAccount,
}) => {
  const [newTimeStart, setNewTimeStart] = useState("");
  const [newTimeEnd, setNewTimeEnd] = useState("");
  const [newTimeZone, setNewTimeZone] = useState("");
  const [newSchedulerStatus, setNewSchedulerStatus] = useState("");

  const [openConfirmationSchedule, setOpenConfirmationSchedule] = useState(
    false
  );
  const [openConfirmationScheduleForCoreServers, setOpenConfirmationScheduleForCoreServers] = useState(
    false
  );
  const [openConfirmationScheduleForIEMPServers, setOpenConfirmationScheduleForIEMPServers] = useState(
    false
  );
  const [saveState, setSaveState] = useState(true);
  const [saveStateForCoreServers, setSaveStateForCoreServers] = useState(true);
  const [saveStateForIEMPServers, setSaveStateForIEMPServers] = useState(true);
  const [wrongTimeStartFormat, setWrongTimeStartFormat] = useState(true);
  const [wrongTimeEndFormat, setWrongTimeEndFormat] = useState(true);

  useEffect(() => {
    if (
      newTimeStart === "" ||
      newTimeEnd === "" ||
      newTimeZone === "" ||
      newSchedulerStatus === ""
    ) {
      setSaveState(true);
    } else if (
      newTimeStart.length > 0 &&
      newTimeEnd.length > 0 &&
      newTimeZone.length > 0 &&
      newSchedulerStatus.length > 0
    ) {
      let startType00 = newTimeStart[2] === "0" && newTimeStart[3] === "0";
      let startType30 = newTimeStart[2] === "3" && newTimeStart[3] === "0";
      let endType00 = newTimeEnd[2] === "0" && newTimeEnd[3] === "0";
      let endType30 = newTimeEnd[2] === "3" && newTimeEnd[3] === "0";
      if (startType00 && endType00) {
        setSaveState(false);
      } else if (startType00 && endType30) {
        setSaveState(false);
      } else if (startType30 && endType00) {
        setSaveState(false);
      } else if (startType30 && endType30) {
        setSaveState(false);
      } else {
        setSaveState(true);
      }
    }
    if (newTimeStart) {
      let startType00 =
        newTimeStart &&
        newTimeStart[2] === "0" &&
        newTimeStart &&
        newTimeStart[3] === "0";
      let startType30 =
        newTimeStart &&
        newTimeStart[2] === "3" &&
        newTimeStart &&
        newTimeStart[3] === "0";
      if (startType00) {
        setWrongTimeStartFormat(true);
      } else if (startType30) {
        setWrongTimeStartFormat(true);
      } else {
        setWrongTimeStartFormat(false);
      }
    }
    if (newTimeEnd) {
      let endType00 =
        newTimeEnd &&
        newTimeEnd[2] === "0" &&
        newTimeEnd &&
        newTimeEnd[3] === "0";
      let endType30 =
        newTimeEnd &&
        newTimeEnd[2] === "3" &&
        newTimeEnd &&
        newTimeEnd[3] === "0";
      if (endType00) {
        setWrongTimeEndFormat(true);
      } else if (endType30) {
        setWrongTimeEndFormat(true);
      } else {
        setWrongTimeEndFormat(false);
      }
    }
  }, [newTimeStart, newTimeEnd, newTimeZone, newSchedulerStatus]);

  const handleCloseNewSchedule = () => {
    setNewTimeStart("");
    setNewTimeEnd("");
    setNewTimeZone("");
    setNewSchedulerStatus("");
    setOpenNewSchedule(false);
    setWrongTimeStartFormat(true);
    setWrongTimeEndFormat(true);
    setHandleApiCall(true);
    setSaveStateForCoreServers(true);
    setSaveStateForIEMPServers(true);
  };

  const setScheduleStatus = (e) => {
    const { value } = e.target;
    setNewSchedulerStatus(value);
  };

  const handleTimeZone = (e) => {
    const { value } = e.target;
    setNewTimeZone(timeZoneArr[value]);
  };

  const handleCoreServersTimeZone = (e) => {
    setSaveStateForCoreServers(false);
    const { value } = e.target;
    setNewTimeZone(e.target.value);
  };

  const handleIEMPServersTimeZone = (e) => {
    setSaveStateForIEMPServers(false);
    const { value } = e.target;
    setNewTimeZone(e.target.value);
  };

  const handleStartHour = (e) => {
    const value = e.target.value;
    setNewTimeStart(value.split(":").join(""));
  };

  const handleEndHour = (e) => {
    const value = e.target.value;
    setNewTimeEnd(value.split(":").join(""));
  };

  const handleClickOpenConfirmationSchedule = () => {
    setOpenConfirmationSchedule(true);
  };

  const handleClickOpenConfirmationScheduleForCoreServers = () => {
    setOpenConfirmationScheduleForCoreServers(true);
  };

  const handleClickOpenConfirmationScheduleForIEMPServers = () => {
    setOpenConfirmationScheduleForIEMPServers(true);
  };

  const handleCloseConfirmationSchedule = () => {
    setOpenConfirmationSchedule(false);
  };

  const handleCloseConfirmationScheduleForCoreServers = () => {
    setOpenConfirmationScheduleForCoreServers(false);
  };

  const handleCloseConfirmationScheduleForIEMPServers = () => {
    setOpenConfirmationScheduleForIEMPServers(false);
  };



  const timeFormat = (time) => {
    let newTimeFormat = TimeFormatConverter(time);
    return newTimeFormat;
  };

  const url = `${base.urls.apis.ec2}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const handleSaveForIEMPServers = (accountType, privateDNS, initiative, accountId) => {
    
   
    
    tokenValidation();
    const action = `schedule?userepid=${email}&timezone=${newTimeZone}&schedule_type=set&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
    const urls = `${url}/${item.instanceId}/${action}`;
    setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);
    
    
    Axios.get(urls, options)
      .then(function (res) {
        console.log("RES", res);
      })
      .catch(function (error) {
        console.log("ERROR", error);
      });
    setOpenConfirmationScheduleForIEMPServers(false);
    setSaveState(true);
    setOpenNewSchedule(false);
    setHandleApiCall(true);
  };

  const handleSaveForCoreServers = (accountType, privateDNS, initiative, accountId) => {
    tokenValidation();
    const action = `schedule?userepid=${email}&timezone=${newTimeZone}&schedule_type=set&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
    const urls = `${url}/${item.instanceId}/${action}`;
    setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);
    Axios.get(urls, options)
      .then(function(res) {
        console.log("RES", res);
      })
      .catch(function(error) {
        console.log("ERROR", error);
      });
    setOpenConfirmationScheduleForCoreServers(false);
    setSaveState(true);
    setOpenNewSchedule(false);
    setHandleApiCall(true);
  };

  const handleSave = (accountType, privateDNS, initiative, accountId) => {
    tokenValidation();
    const action = `schedule?userepid=${email}&start_time=${newTimeStart}&stop_time=${newTimeEnd}&timezone=${newTimeZone.split(" ")[0]
      }&schedule_day=${newSchedulerStatus}&schedule_type=set&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
    const urls = `${url}/${item.instanceId}/${action}`;
    setLoadingByClick("ec2Scheduler", "Loading", item.instanceId);
    Axios.get(urls, options)
      .then(function (res) {
        console.log("RES", res);
      })
      .catch(function (error) {
        console.log("ERROR", error);
      });
    setOpenConfirmationSchedule(false);
    setSaveState(true);
    setOpenNewSchedule(false);
    setHandleApiCall(true);
  };


  

  let displaySetSchedule
  if (item.Initiative === "mywizard.techarch.iemp") {
    if (item.accountId === "004439949415") {
      displaySetSchedule =
        < PickerDialogContent padding={"0px 20px 20px 20px"} >
          <div>
            <FormControl>
              <InputLabel shrink>Time Zone</InputLabel>
              <NativeSelect
                native="true"
                onChange={(e) => handleIEMPServersTimeZone(e)}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                inputProps={{ name: "timeZone", id: "timeZone" }}
              >
                <option value="">--Select desired scheduler option--</option>
                <option value="iemp-dev-schedule-weekdays-11AM-5PM">iemp-dev-schedule-weekdays-11AM-5PM</option>
                <option value="iemp-prod-schedule-weekdays">iemp-prod-schedule-weekdays</option>
                <option value="iemp-schedule-weekdays-7:30AM-11PM">iemp-schedule-weekdays-7:30AM-11PM</option>
              </NativeSelect>
            </FormControl>
          </div>
        </PickerDialogContent >
    } else {
      displaySetSchedule =
        < PickerDialogContent padding={"0px 20px 20px 20px"} >
          <div>
            <FormControl>
              <InputLabel shrink>Time Zone</InputLabel>
              <NativeSelect
                native="true"
                onChange={(e) => handleIEMPServersTimeZone(e)}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                inputProps={{ name: "timeZone", id: "timeZone" }}
              >
                <option value="">--Select desired scheduler option--</option>
                <option value="iemp-schedule-weekdays">iemp-schedule-weekdays</option>
                <option value="iemp-custom-schedule-weekdays-11AMto5PM">iemp-custom-schedule-weekdays-11AMto5PM</option>
              </NativeSelect>
            </FormControl>
          </div>
        </PickerDialogContent >
    }
  } else if (item.Initiative === "mywizard.techarch.core") {
    displaySetSchedule =
      <PickerDialogContent padding={"0px 20px 20px 20px"}>
        <div>
          <FormControl>
            <InputLabel shrink>Time Zone</InputLabel>
            <NativeSelect
              native="true"
              onChange={(e) => handleCoreServersTimeZone(e)}
              disabled={
                currentInstanceState === "terminated" ||
                  newSchedulerStatus === "remove_schedule"
                  ? true
                  : loadingIconStatusBool
              }
              inputProps={{ name: "timeZone", id: "timeZone" }}
            >
              <option value="">--Select desired scheduler option--</option>
              <option value="06-21-schedule">06-21-schedule</option>
              <option value="06-22-uptime-hours">06-22-uptime-hours</option>
              <option value="06-23-schedule">06-23-schedule</option>
              <option value="06-23-schedule-mon-sat">06-23-schedule-mon-sat</option>
              <option value="06-23-schedule-weekdays">06-23-schedule-weekdays</option>
              <option value="08-22-schedule-mon-sat">08-22-schedule-mon-sat</option>
              <option value="08-22-schedule-weekdays">08-22-schedule-weekdays</option>
              <option value="7.30-21-schedule weekdays">7.30-21-schedule weekdays</option>
              <option value="7.30-22-schedule">7.30-22-schedule</option>
              <option value="7.30-22-schedule-mon-sat">7.30-22-schedule-mon-sat</option>
              <option value="7.30-22-schedule-weekdays">7.30-22-schedule-weekdays</option>
              <option value="7.30-23-schedule-weekdays">7.30-23-schedule-weekdays</option>
              <option value="alc-10-21-schedule">alc-10-21-schedule</option>
              <option value="alc-7-23-schedule">alc-7-23-schedule</option>
              <option value="alc-7-23-schedule-weekdays">alc-7-23-schedule-weekdays</option>
              <option value="alc-9-1-schedule">alc-9-1-schedule</option>
              <option value="lifecycle-automation-schedule">lifecycle-automation-schedule</option>
              <option value="myWizard-SaaS-nonprod-schedule-1am-6am">myWizard-SaaS-nonprod-schedule-1am-6am</option>
              <option value="myWizard-SaaS-nonprod-schedule-1am-6am mon-sat ">myWizard-SaaS-nonprod-schedule-1am-6am mon-sat </option>
              <option value="mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679">mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679</option>
              <option value="mywizard-SaaS-schedule-9am-9pm">mywizard-SaaS-schedule-9am-9pm</option>
              <option value="mywizard-schedule-9am-9pm weekdays">mywizard-schedule-9am-9pm weekdays</option>
              <option value="schedule-6am-7.30am-alldays">schedule-6am-7.30am-alldays</option>
              <option value="schedule-6am-9am-alldays">schedule-6am-9am-alldays</option>
              <option value="schedule-9am-11am-alldays">schedule-9am-11am-alldays</option>
            </NativeSelect>
          </FormControl>
        </div>
      </PickerDialogContent>
  } else {
    displaySetSchedule =
      <PickerDialogContent padding={"0px 12px"}>
        <ButtonWrapper paddingLeft={"10px"}>
          <div>
            <StyledCheckbox>
              <FormControl component="fieldset">
                <StyledRadioGroup
                  aria-label="speak"
                  name="position"
                  onChange={(e) => setScheduleStatus(e)}
                  row
                >
                  <FormControlLabel
                    value="weekdays"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        setweekdaysradiocy="setWeekdaysRadioCy"
                        color="primary"
                      />
                    }
                    label="Weekdays"
                  />
                  <FormControlLabel
                    value="all"
                    disabled={
                      currentInstanceState === "terminated"
                        ? true
                        : loadingIconStatusBool
                    }
                    control={
                      <Radio
                        setalldaysradiocy="setAlldaysRadioCy"
                        color="primary"
                      />
                    }
                    label="All Days"
                  />
                </StyledRadioGroup>
              </FormControl>
            </StyledCheckbox>
          </div>
        </ButtonWrapper>

        <TimePickerWrapper padding-left={"10px"} padding={"14px"}>
          <FormControl>
            <InputLabel shrink>Time Zone</InputLabel>
            <NativeSelect
              native="true"
              disabled={
                currentInstanceState === "terminated" ||
                  newSchedulerStatus === "remove_schedule"
                  ? true
                  : loadingIconStatusBool
              }
              onChange={(e) => handleTimeZone(e)}
              inputProps={{ name: "timeZone", id: "timeZone" }}
            >
              <option value="">-- Select A Time Zone --</option>
              {timeZoneArr.map((item, index) => (
                <option key={index} value={index}>
                  {item}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <Fragment>
            <form noValidate>
              <TextField
                type="time"
                label="Time Start"
                name={"startHour"}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800,
                  settimestartcy: "setTimeStartCy",
                }}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                onChange={handleStartHour}
              />
            </form>
            <form noValidate>
              <TextField
                type="time"
                label="Time End"
                name={"endHour"}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1800,
                  settimeendcy: "setTimeEndCy",
                }}
                disabled={
                  currentInstanceState === "terminated" ||
                    newSchedulerStatus === "remove_schedule"
                    ? true
                    : loadingIconStatusBool
                }
                onChange={(e) => handleEndHour(e)}
              />
            </form>
            {wrongTimeStartFormat === false ||
              wrongTimeEndFormat === false ? (
              <TimerMessageErrorFormat
                className={`${wrongTimeStartFormat === false &&
                  wrongTimeEndFormat === false
                  ? "sinclase"
                  : "conclase"
                  }`}
                invalidminutesformatcy="invalidMinutesFormatCy"
              >
                The minute format is incorrect. Only 00 or 30 is
                accepted
              </TimerMessageErrorFormat>
            ) : null}
          </Fragment>
        </TimePickerWrapper>
      </PickerDialogContent>
  }

  let displayScheduleActions
  if (item.Initiative === "mywizard.techarch.iemp") {
    displayScheduleActions =
      <DialogActions paddingBottom={"15px"}>
        <DeclineButton
          onClick={handleCloseNewSchedule}
          label={"Cancel"}
        />
        <AcceptButton
          disabled={saveStateForIEMPServers}
          onClick={handleClickOpenConfirmationScheduleForIEMPServers}
          label={"Save"}
        />
      </DialogActions>
  } else if (item.Initiative === "mywizard.techarch.core") {
    displayScheduleActions =
      <DialogActions paddingBottom={"15px"}>
        <DeclineButton
          onClick={handleCloseNewSchedule}
          label={"Cancel"}
        />
        <AcceptButton
          disabled={saveStateForCoreServers}
          onClick={handleClickOpenConfirmationScheduleForCoreServers}
          label={"Save"}
        />
      </DialogActions>
  } else {
    displayScheduleActions =
      <DialogActions paddingBottom={"15px"}>
        <DeclineButton
          onClick={handleCloseNewSchedule}
          label={"Cancel"}
        />
        <AcceptButton
          disabled={saveState ? true : loadingIconStatusBool}
          onClick={handleClickOpenConfirmationSchedule}
          label={"Save"}
        />
      </DialogActions>
  }





  return (
    <Fragment>
      {openNewSchedule && (
        <SchedulerWrapper>
          <CommonDialog
            open={openNewSchedule}
            onClose={handleCloseNewSchedule}
            title={"Setting Schedule"}
            dialogType={"servers"}
            textContent={"Schedule the duration using the server:"}

            dialogContent={displaySetSchedule}
            dialogActions={displayScheduleActions}

          // dialogContent={item.Initiative === "mywizard.techarch.core" ? 
          //   <PickerDialogContent padding={"0px 20px 20px 20px"}>
          //     <div>
          //       <FormControl>
          //         <InputLabel shrink>Time Zone</InputLabel>
          //           <NativeSelect
          //             native="true"
          //             onChange={(e) => handleCoreServersTimeZone(e)}
          //             disabled={
          //               currentInstanceState === "terminated" ||
          //               newSchedulerStatus === "remove_schedule"
          //                 ? true
          //                 : loadingIconStatusBool
          //             }
          //             inputProps={{ name: "timeZone", id: "timeZone" }}
          //           >
          //             <option value="">--Select desired scheduler option--</option>
          //             <option value="06-21-schedule">06-21-schedule</option>
          //             <option value="06-22-uptime-hours">06-22-uptime-hours</option>
          //             <option value="06-23-schedule">06-23-schedule</option>
          //             <option value="06-23-schedule-mon-sat">06-23-schedule-mon-sat</option>
          //             <option value="06-23-schedule-weekdays">06-23-schedule-weekdays</option>
          //             <option value="08-22-schedule-mon-sat">08-22-schedule-mon-sat</option>
          //             <option value="08-22-schedule-weekdays">08-22-schedule-weekdays</option>
          //             <option value="7.30-21-schedule weekdays">7.30-21-schedule weekdays</option>
          //             <option value="7.30-22-schedule">7.30-22-schedule</option>
          //             <option value="7.30-22-schedule-mon-sat">7.30-22-schedule-mon-sat</option>
          //             <option value="7.30-22-schedule-weekdays">7.30-22-schedule-weekdays</option>
          //             <option value="7.30-23-schedule-weekdays">7.30-23-schedule-weekdays</option>
          //             <option value="alc-10-21-schedule">alc-10-21-schedule</option>
          //             <option value="alc-7-23-schedule">alc-7-23-schedule</option>
          //             <option value="alc-7-23-schedule-weekdays">alc-7-23-schedule-weekdays</option>
          //             <option value="alc-9-1-schedule">alc-9-1-schedule</option>
          //             <option value="lifecycle-automation-schedule">lifecycle-automation-schedule</option>
          //             <option value="myWizard-SaaS-nonprod-schedule-1am-6am">myWizard-SaaS-nonprod-schedule-1am-6am</option>
          //             <option value="myWizard-SaaS-nonprod-schedule-1am-6am mon-sat ">myWizard-SaaS-nonprod-schedule-1am-6am mon-sat </option>
          //             <option value="mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679">mywizard-SaaS-schedule-6am-1am-MYWIZCORE1679</option>
          //             <option value="mywizard-SaaS-schedule-9am-9pm">mywizard-SaaS-schedule-9am-9pm</option>
          //             <option value="mywizard-schedule-9am-9pm weekdays">mywizard-schedule-9am-9pm weekdays</option>
          //             <option value="schedule-6am-7.30am-alldays">schedule-6am-7.30am-alldays</option>
          //             <option value="schedule-6am-9am-alldays">schedule-6am-9am-alldays</option>
          //             <option value="schedule-9am-11am-alldays">schedule-9am-11am-alldays</option>
          //           </NativeSelect>
          //       </FormControl>
          //     </div>
          //   </PickerDialogContent>
          // :
          //   <PickerDialogContent padding={"0px 12px"}>
          //     <ButtonWrapper paddingLeft={"10px"}>
          //       <div>
          //         <StyledCheckbox>
          //           <FormControl component="fieldset">
          //             <StyledRadioGroup
          //               aria-label="speak"
          //               name="position"
          //               onChange={(e) => setScheduleStatus(e)}
          //               row
          //             >
          //               <FormControlLabel
          //                 value="weekdays"
          //                 disabled={
          //                   currentInstanceState === "terminated"
          //                     ? true
          //                     : loadingIconStatusBool
          //                 }
          //                 control={
          //                   <Radio
          //                     setweekdaysradiocy="setWeekdaysRadioCy"
          //                     color="primary"
          //                   />
          //                 }
          //                 label="Weekdays"
          //               />
          //               <FormControlLabel
          //                 value="all"
          //                 disabled={
          //                   currentInstanceState === "terminated"
          //                     ? true
          //                     : loadingIconStatusBool
          //                 }
          //                 control={
          //                   <Radio
          //                     setalldaysradiocy="setAlldaysRadioCy"
          //                     color="primary"
          //                   />
          //                 }
          //                 label="All Days"
          //               />
          //             </StyledRadioGroup>
          //           </FormControl>
          //         </StyledCheckbox>
          //       </div>
          //     </ButtonWrapper>

          //     <TimePickerWrapper padding-left={"10px"} padding={"14px"}>
          //       <FormControl>
          //         <InputLabel shrink>Time Zone</InputLabel>
          //         <NativeSelect
          //           native="true"
          //           disabled={
          //             currentInstanceState === "terminated" ||
          //             newSchedulerStatus === "remove_schedule"
          //               ? true
          //               : loadingIconStatusBool
          //           }
          //           onChange={(e) => handleTimeZone(e)}
          //           inputProps={{ name: "timeZone", id: "timeZone" }}
          //         >
          //           <option value="">-- Select A Time Zone --</option>
          //           {timeZoneArr.map((item, index) => (
          //             <option key={index} value={index}>
          //               {item}
          //             </option>
          //           ))}
          //         </NativeSelect>
          //       </FormControl>
          //       <Fragment>
          //         <form noValidate>
          //           <TextField
          //             type="time"
          //             label="Time Start"
          //             name={"startHour"}
          //             InputLabelProps={{
          //               shrink: true,
          //             }}
          //             inputProps={{
          //               step: 1800,
          //               settimestartcy: "setTimeStartCy",
          //             }}
          //             disabled={
          //               currentInstanceState === "terminated" ||
          //               newSchedulerStatus === "remove_schedule"
          //                 ? true
          //                 : loadingIconStatusBool
          //             }
          //             onChange={handleStartHour}
          //           />
          //         </form>
          //         <form noValidate>
          //           <TextField
          //             type="time"
          //             label="Time End"
          //             name={"endHour"}
          //             InputLabelProps={{
          //               shrink: true,
          //             }}
          //             inputProps={{
          //               step: 1800,
          //               settimeendcy: "setTimeEndCy",
          //             }}
          //             disabled={
          //               currentInstanceState === "terminated" ||
          //               newSchedulerStatus === "remove_schedule"
          //                 ? true
          //                 : loadingIconStatusBool
          //             }
          //             onChange={(e) => handleEndHour(e)}
          //           />
          //         </form>
          //         {wrongTimeStartFormat === false ||
          //         wrongTimeEndFormat === false ? (
          //           <TimerMessageErrorFormat
          //             className={`${
          //               wrongTimeStartFormat === false &&
          //               wrongTimeEndFormat === false
          //                 ? "sinclase"
          //                 : "conclase"
          //             }`}
          //             invalidminutesformatcy="invalidMinutesFormatCy"
          //           >
          //             The minute format is incorrect. Only 00 or 30 is
          //             accepted
          //           </TimerMessageErrorFormat>
          //         ) : null}
          //       </Fragment>
          //     </TimePickerWrapper>
          //   </PickerDialogContent>
          // }
          // dialogActions={item.Initiative === "mywizard.techarch.core" ?
          //   <DialogActions paddingBottom={"15px"}>
          //     <DeclineButton
          //       onClick={handleCloseNewSchedule}
          //       label={"Cancel"}
          //     />
          //     <AcceptButton
          //       disabled={saveStateForCoreServers}
          //       onClick={handleClickOpenConfirmationScheduleForCoreServers}
          //       label={"Save"}
          //     />
          //   </DialogActions> :
          //   <DialogActions paddingBottom={"15px"}>
          //   <DeclineButton
          //     onClick={handleCloseNewSchedule}
          //     label={"Cancel"}
          //   />
          //   <AcceptButton
          //     disabled={saveState ? true : loadingIconStatusBool}
          //     onClick={handleClickOpenConfirmationSchedule}
          //     label={"Save"}
          //   />
          // </DialogActions>

          // }
          ></CommonDialog>
          <CommonDialog
            open={openConfirmationSchedule}
            onClose={handleCloseConfirmationSchedule}
            title={"Confirm Scheduler Change"}
            dialogType={"servers"}
            dialogContent={
              newSchedulerStatus === "remove_schedule" ? (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Scheduler: ${newSchedulerStatus}`}
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm the following changes:
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Time Start: ${timeFormat(newTimeStart)}`}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Time End: ${timeFormat(newTimeEnd)}`}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Time Zone: ${newTimeZone}`}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Scheduler: ${newSchedulerStatus}`}
                  </DialogContentText>
                  <br />
                  <DialogContentText>
                    This request may take 5 minutes to reflect changes
                  </DialogContentText>
                </DialogContent>
              )
            }
            dialogActions={
              <DialogActions>
                <DeclineButton
                  onClick={handleCloseConfirmationSchedule}
                  setcancelconfirmationschedulecy="setCancelConfirmationScheduleCy"
                  label={"Cancel"}
                />

                <AcceptButton
                  onClick={() =>
                    handleSave(
                      item.accountType,
                      item.privateDNSName,
                      item.Initiative,
                      item.accountId
                    )
                  }
                  setconfirmschedulecy="setConfirmScheduleCy"
                  autoFocus
                  label={"Confirm"}
                />
              </DialogActions>
            }
          ></CommonDialog>
          <CommonDialog
            open={openConfirmationScheduleForCoreServers}
            onClose={handleCloseConfirmationScheduleForCoreServers}
            title={"Confirm Scheduler Change"}
            dialogType={"servers"}
            dialogContent={
              newSchedulerStatus === "remove_schedule" ? (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Scheduler: ${newSchedulerStatus}`}
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm the following changes:
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Time Zone: ${newTimeZone}`}
                  </DialogContentText>
                  <br />
                  <DialogContentText>
                    This request may take 5 minutes to reflect changes
                  </DialogContentText>
                </DialogContent>
              )
            }
            dialogActions={
              <DialogActions>
                <DeclineButton
                  onClick={handleCloseConfirmationScheduleForCoreServers}
                  setcancelconfirmationschedulecy="setCancelConfirmationScheduleCy"
                  label={"Cancel"}
                />

                <AcceptButton
                  onClick={() =>
                    handleSaveForCoreServers(
                      item.accountType,
                      item.privateDNSName,
                      item.Initiative,
                      item.accountId
                    )
                  }
                  setconfirmschedulecy="setConfirmScheduleCy"
                  autoFocus
                  label={"Confirm"}
                />
              </DialogActions>
            }
          ></CommonDialog>
          <CommonDialog
            open={openConfirmationScheduleForIEMPServers}
            onClose={handleCloseConfirmationScheduleForIEMPServers}
            title={"Confirm Scheduler Change"}
            dialogType={"servers"}
            dialogContent={
              newSchedulerStatus === "remove_schedule" ? (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`Scheduler: ${newSchedulerStatus}`}
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm the following changes:
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    {`Time Zone: ${newTimeZone}`}
                  </DialogContentText>
                  <br />
                  <DialogContentText>
                    This request may take 5 minutes to reflect changes
                  </DialogContentText>
                </DialogContent>
              )
            }
            dialogActions={
              <DialogActions>
                <DeclineButton
                  onClick={handleCloseConfirmationScheduleForIEMPServers}
                  setcancelconfirmationschedulecy="setCancelConfirmationScheduleCy"
                  label={"Cancel"}
                />

                <AcceptButton
                  onClick={() =>
                    handleSaveForIEMPServers(
                      item.accountType,
                      item.privateDNSName,
                      item.Initiative,
                      item.accountId
                    )
                  }
                  setconfirmschedulecy="setConfirmScheduleCy"
                  autoFocus
                  label={"Confirm"}
                />
              </DialogActions>
            }
          ></CommonDialog>
        </SchedulerWrapper>
      )}
    </Fragment>
  );
};

SetSchedule.propTypes = {
  /**
   * Selected instance to which a schedule will be established or changed
   */
  item: PropTypes.object,
  /**
   * Current state of the instance (running, stopped, terminated).
   */
  currentInstanceState: PropTypes.string,
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Handler of the status of the button that shows the extended information of the instance.
   */
  loadingIconStatusBool: PropTypes.bool,
  /**
   * Current users Token.
   */
  idToken: PropTypes.string,
};
const MSTP = (state) => ({
  activeAccount: state.auth.activeAccount,
});

export default connect(MSTP, {
  setLoadingByClick,
})(SetSchedule);
