const teamNames=[
    {
        "value": "myBizApps",
        "label": "myBizApps"
    },
    {
        "value": "CAF",
        "label": "CAF"
    },
    {
        "value": "Client Delivery Rio",
        "label": "Client Delivery Rio"
    },
    {
        "value": "RPA Deployment",
        "label": "RPA Deployment"
    },
    {
        "value": "myWizard AiOps SAP360",
        "label": "myWizard AiOps SAP360"
    },
    {
        "value": "Ticket Analyser",
        "label": "Ticket Analyser"
    },
    {
        "value": "ATR",
        "label": "ATR"
    },
    {
        "value": "EventOps",
        "label": "EventOps"
    },
    {
        "value": "Cloud Chaser",
        "label": "Cloud Chaser"
    },
    {
        "value": "Client Delivery ANZ",
        "label": "Client Delivery ANZ"
    },
    {
        "value": "Estimator",
        "label": "Estimator"
    },
    {
        "value": "Chatbot",
        "label": "Chatbot"
    },
    {
        "value": "Stack Monitoring Suite",
        "label": "Stack Monitoring Suite"
    },
    {
        "value": "StormWatch",
        "label": "StormWatch"
    },
    {
        "value": "myWizard AiOps RPA",
        "label": "myWizard AiOps RPA"
    },
    {
        "value": "Quasar++",
        "label": "Quasar++"
    },
    {
        "value": "myWizard AiOps Batch",
        "label": "myWizard AiOps Batch"
    },
    {
        "value": "AiOps Automation for Splunk Cluster",
        "label": "AiOps Automation for Splunk Cluster"
    },
    {
        "value": "Service Integrator",
        "label": "Service Integrator"
    },
    {
        "value": "Turing",
        "label": "Turing"
    },
    {
        "value": "Design",
        "label": "Design"
    },
    {
        "value": "BOPM",
        "label": "BOPM"
    },
    {
        "value": "Testing",
        "label": "Testing"
    },
    {
        "value": "Automation Fabric Monitoring",
        "label": "Automation Fabric Monitoring"
    },
    {
        "value": "Leadership",
        "label": "Leadership"
    },
    {
        "value": "CICD",
        "label": "CICD"
    },
    {
        "value": "Tech Arch",
        "label": "Tech Arch"
    },
    {
        "value": "Client Automation And Delivery (CAAD)",
        "label": "Client Automation And Delivery (CAAD)"
    },
    {
        "value": "Cloud Security and Compliance Checker",
        "label": "Cloud Security and Compliance Checker"
    },
    {
        "value": "DevOps",
        "label": "DevOps"
    },
    {
        "value": "Delivery Management",
        "label": "Delivery Management"
    },
    {
        "value": "Cloud Chargeback",
        "label": "Cloud Chargeback"
    },
    {
        "value": "Client Delivery ATC-B",
        "label": "Client Delivery ATC-B"
    },
    {
        "value": "Client Delivery ATC-C",
        "label": "Client Delivery ATC-C"
    },
    {
        "value": "ChatBoT",
        "label": "ChatBoT"
    },
    {
        "value": "myWizard DevOps SaaS",
        "label": "myWizard DevOps SaaS"
    },
    {
        "value": "AIAPPS & IO Tools",
        "label": "AIAPPS & IO Tools"
    },
    {
        "value": "Support - Infra Support",
        "label": "Support - Infra Support"
    },
    {
        "value": "Demo Hub",
        "label": "Demo Hub"
    },
    {
        "value": "Smart Asset (Smart Alerts/SCP)",
        "label": "Smart Asset (Smart Alerts/SCP)"
    },
    {
        "value": "Support - AiOps Support",
        "label": "Support - AiOps Support"
    },
    {
        "value": "PAM Apps",
        "label": "PAM Apps"
    },
    {
        "value": "Client Delivery ATC-I",
        "label": "Client Delivery ATC-I"
    },
    {
        "value": "Client Delivery ATC-M",
        "label": "Client Delivery ATC-M"
    },
    {
        "value": "Client Delivery",
        "label": "Client Delivery"
    },
    {
        "value": "SplunkWatch",
        "label": "SplunkWatch"
    },
    {
        "value": "ATA",
        "label": "ATA"
    },
    {
        "value": "Report Extractor",
        "label": "Report Extractor"
    },
    {
        "value": "Client Delivery EUR",
        "label": "Client Delivery EUR"
    },
    {
        "value": "Client Delivery Japan",
        "label": "Client Delivery Japan"
    },
    {
        "value": "ANZ Load Payment",
        "label": "ANZ Load Payment"
    },
    {
        "value": "SAP DevOps for NextGen AMS",
        "label": "SAP DevOps for NextGen AMS"
    },
    {
        "value": "DataOps & MLOps",
        "label": "DataOps & MLOps"
    },
    {
        "value": "ZeroTicket",
        "label": "ZeroTicket"
    },
    {
        "value": "Embedded RPA",
        "label": "Embedded RPA"
    },
    {
        "value": "Translation Tool",
        "label": "Translation Tool"
    },
    {
        "value": "Client Delivery ATC-P",
        "label": "Client Delivery ATC-P"
    }
]

export default teamNames