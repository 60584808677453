import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PieChart from "../../home/PieChart";
import { fetchEc2Data } from "../../../redux/home";
import {
  StyledMdGrid,
  StyledJustifyGridContent,
} from "../../home/DashboardStyles";
import { Grid } from "@material-ui/core";

export const StatisticsPieCharts = ({ ec2Data, serverList }) => {
  /**
   * Dashboard piechart for ec2 servers
   * @author Carlos Montes
   * @version 1.0
   * @see Used in component: Ec2Management.js
   */
  useEffect(() => {
    // fetchEc2Data();
  }, []);

  //const [smallScreen, setSmallScreen] = useState("");
  const [, setMdSizeResponsivePieChart] = useState();
  const [mdSizeFlex, setMdSizeFlex] = useState();
  const [, setLegendDisplay] = useState(true);

  const handleScreenSizeUpdate = () => {
    setMdSizeFlex(window.innerWidth < 1280 ? true : false);
    setMdSizeResponsivePieChart(
      window.innerWidth < 1080 ? 10 : window.innerWidth < 1150 ? 8 : 7
    );
    setLegendDisplay(window.innerWidth < 820 ? false : true);
  };

  useEffect(() => {
    handleScreenSizeUpdate();
    const callback = () => {
      handleScreenSizeUpdate();
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, []);

  return (
    <Grid item lg={12} md={12} xs={12}>
      <StyledJustifyGridContent container spacing={2}>
        {ec2Data &&
          ec2Data.map((v, i) => (
            <Grid item lg={4} md={4} xs={4} key={i}>
              <Grid container>
                <Grid item lg={9} md={9} xs={9}>
                  <StyledMdGrid
                    container
                    justifyflex={mdSizeFlex ? mdSizeFlex.toString() : ""}
                  >
                    <Grid item lg={12} md={12} xs={12}>
                      <PieChart
                        serverList={serverList}
                        pieData={v}
                        screen="servers"
                      />
                    </Grid>
                  </StyledMdGrid>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </StyledJustifyGridContent>
    </Grid>
  );
};

const MSTP = (state, props) => ({
  isLoading: state.home.isLoading,
  fuiInstanceTotalNumberData: state.home.fuiInstanceTotalNumberData,
  fuiMPNumberData: state.home.fuiMPNumberData,
  fuiDPNumberData: state.home.fuiDPNumberData,
  fuiPINumberData: state.home.fuiPINumberData,
  sshPendingApprovalsData: state.home.sshPendingApprovalsData,
  sshMyRequestsData: state.home.sshMyRequestsData,
  isRegistration: state.home.isRegistration,
});

export default connect(MSTP, { fetchEc2Data })(StatisticsPieCharts);
