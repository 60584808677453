import update from "immutability-helper";
import { headers } from "../../helper/api";
import axios from "axios";
import base from "../../config";

const UPDATE_ROLE_FORM = "admin/UPDATE_ROLE_FORM";
const CLEAR_ROLE_FORM = "admin/CLEAR_ROLE_FORM";
const UPDATE_AD_GROUP_FORM = "admin/UPDATE_AD_GROUP_FORM";
const CLEAR_AD_GROUP_FORM = "admin/CLEAR_AD_GROUP_FORM";
const FETCH_ROLES = "admin/FETCH_ROLES";
const FETCH_GROUPS = "admin/FETCH_GROUPS";
const FETCH_PERMISSIONS = "admin/FETCH_PERMISSIONS";

const ASYNC_ERROR = "admin/ASYNC_ERROR";
const FETCH_ROLES_START_LOADING = "admin/FETCH_ROLES_START_LOADING";
const FETCH_GROUPS_START_LOADING = "admin/FETCH_GROUPS_START_LOADING";
const CREATE_ROLE_START_LOADING = "admin/CREATE_ROLE_START_LOADING";
const CREATE_ROLE_RESULT = "admin/CREATE_ROLE_RESULT";
const CREATE_AD_GROUP_START_LOADING = "admin/CREATE_AD_GROUP_START_LOADING";
const CREATE_AD_GROUP_RESULT = "admin/CREATE_AD_GROUP_RESULT";

const adRoleForm = {
  roleName: "",
  permissionCount: 0,
  roleNotes: "",
  permissions: [],
};

const adGroupForm = {
  adGroupName: "",
};

const initState = {
  fetchRolesIsLoading: false,
  fetchGroupsIsLoading: false,
  createRoleIsLoading: false,
  createAdGroupIsLoading: false,

  roles: [],
  groups: [],
  permissions: [],

  createRole: {
    roleName: "",
    permissionCount: 0,
    roleNotes: "",
    permissions: [],
  },
  createAdGroup: {
    adGroupName: "",
  },
  createRoleResults: "",
  createRoleSuccess: false,
  createAdGroupResults: "",
  createAdGroupSuccess: false,
};

const rolesUrl = `${base.urls.apis.admin}roles`;
const groupsUrl = `${base.urls.apis.admin}groups`;
const permissionsUrl = `${base.urls.apis.admin}permissions`;

export default function adminReducer(state = initState, action) {
  const data = action.data;

  switch (action.type) {
    //Update data selected from instance list
    case UPDATE_ROLE_FORM:
      return update(state, {
        createRole: { [data.field]: { $set: data.value } },
      });
    case CLEAR_ROLE_FORM:
      return update(state, {
        createRole: { $set: data },
        createRoleResults: { $set: "" },
        createRoleSuccess: { $set: false },
      });
    case UPDATE_AD_GROUP_FORM:
      return update(state, {
        createAdGroup: { [data.field]: { $set: data.value } },
      });
    case CLEAR_AD_GROUP_FORM:
      return update(state, {
        createAdGroup: { $set: data },
        createAdGroupResults: { $set: "" },
        createAdGroupSuccess: { $set: false },
      });
    case FETCH_ROLES:
      return {
        ...state,
        roles: data[0],
        fetchRolesIsLoading: { $set: false },
      };
    case FETCH_ROLES_START_LOADING:
      return update(state, { fetchRolesIsLoading: { $set: true } });
    case FETCH_GROUPS:
      return {
        ...state,
        groups: data[0],
        fetchGroupsIsLoading: { $set: false },
      };

    case FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: data[0],
      };
    case FETCH_GROUPS_START_LOADING:
      return update(state, { fetchGroupsIsLoading: { $set: true } });
    case CREATE_ROLE_START_LOADING:
      return update(state, { createRoleIsLoading: { $set: true } });
    case CREATE_ROLE_RESULT:
      return update(state, {
        createRoleSuccess: { $set: true },
        createRoleResults: { $set: data[0] },
        createRoleIsLoading: { $set: false },
      });
    case CREATE_AD_GROUP_START_LOADING:
      return update(state, { createAdGroupIsLoading: { $set: true } });
    case CREATE_AD_GROUP_RESULT:
      return update(state, {
        createAdGroupSuccess: { $set: true },
        createAdGroupResults: { $set: data[0] },
        createAdGroupIsLoading: { $set: false },
      });
    default:
      return {
        ...state,
      };
  }
}

export const fetchGroups = (email) => (dispatch, getState) => {
  // dispatch({ type: START_LOADING });
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: FETCH_GROUPS_START_LOADING });

  if (email) {
    axios
      .get(groupsUrl, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          customer_enterprise_id: eid,
          inititive: activeAccount,
          page_number: 1,
          page_size: 999,
        },
      })
      .then((res) => {
        const { data } = res;
        return dispatch({
          type: FETCH_GROUPS,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const fetchRoles = (email) => (dispatch, getState) => {
  // dispatch({ type: START_LOADING });
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: FETCH_ROLES_START_LOADING });

  if (email) {
    axios
      .get(rolesUrl, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          customer_enterprise_id: eid,
          inititive: activeAccount,
          page_number: 1,
          page_size: 999,
        },
      })
      .then((res) => {
        const { data } = res;
        return dispatch({
          type: FETCH_ROLES,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const fetchPermissions = (email) => (dispatch, getState) => {
  // dispatch({ type: START_LOADING });
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  if (email) {
    axios
      .get(permissionsUrl, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          customer_enterprise_id: eid,
          inititive: activeAccount,
          page_number: 1,
          page_size: 999,
        },
      })
      .then((res) => {
        const { data } = res;
        return dispatch({
          type: FETCH_PERMISSIONS,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const submitNewRole = (email, data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  data.permissionCount = data.permissions.length;
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: CREATE_ROLE_START_LOADING });

  axios
    .post(rolesUrl + "/create_role", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: CREATE_ROLE_RESULT, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const deleteRole = (email, data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  axios
    .post(rolesUrl + "/delete_role", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .then((res) => {})
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const submitNewAdGroup = (email, data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  dispatch({ type: CREATE_AD_GROUP_START_LOADING });
  axios
    .post(groupsUrl + "/create_ad_group", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .then((res) => {
      dispatch({ type: CREATE_AD_GROUP_RESULT, data: res.data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const deleteAdGroup = (email, data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  axios
    .post(groupsUrl + "/delete_ad_group", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .then((res) => {})
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const updateAdGroup = (email, roles, adGroupName) => (
  dispatch,
  getState
) => {
  const activeAccount = getState().auth.activeAccount;

  const idToken = localStorage.getItem("token");
  const eid = email ? email.split("@")[0] : "";
  var data = {
    ad_group_name: adGroupName,
    cam_roles: roles,
    role_count: roles.length,
  };

  axios
    .put(groupsUrl + "/update_group_roles", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const submitUpdateRole = (email, permissions, roleName) => (
  dispatch,
  getState
) => {
  var data = {
    permissions: permissions,
    roleName: roleName,
    permissionCount: permissions.length,
  };
  const idToken = localStorage.getItem("token");
  const activeAccount = getState().auth.activeAccount;

  const eid = email ? email.split("@")[0] : "";
  axios
    .put(rolesUrl + "/update_role", data, {
      headers: { Authorization: idToken },
      params: {
        customer_enterprise_id: eid,
        inititive: activeAccount,
      },
    })
    .then((res) => {})
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const updateRoleForm = (field, value) => ({
  type: UPDATE_ROLE_FORM,
  data: { field, value },
});

export const clearRoleForm = () => ({
  type: CLEAR_ROLE_FORM,
  data: adRoleForm,
});

export const updateAdGroupForm = (field, value) => ({
  type: UPDATE_AD_GROUP_FORM,
  data: { field, value },
});

export const clearAdGroupForm = () => ({
  type: CLEAR_AD_GROUP_FORM,
  data: adGroupForm,
});
